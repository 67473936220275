import React from 'react';
import { Link } from 'react-router-dom';
import styles from './PageNotFound.module.scss';

function PageNotFound() {
    return (
        <div className={styles.flexContainer}>
            <img className={styles.logo} src={process.env.PUBLIC_URL + '/logos/orthopublish-logo.svg'} alt="logo" />
            <h4>Page not found!</h4>
            <div>
                <span>Go back to </span>
                <Link to="/home">Home</Link>
            </div>
        </div>
    )
}

export default PageNotFound;