import {ALERT_MODAL_OPEN, ALERT_MODAL_CLOSE, SIGN_UP_ALERT_MODAL_OPEN, SIGN_UP_ALERT_MODAL_CLOSE} from '../actions/AlertModalActions';

const initialState = {
    signupAlertModalToggle: false,
    alertModalToggle: false,
    keyword: ''
}

const alertModalReducer = (state=initialState, action) => {
    switch(action.type){
        case ALERT_MODAL_OPEN:
            return{...state, alertModalToggle: action.payload, keyword: action.keyword}
        case ALERT_MODAL_CLOSE:
            return{...state, alertModalToggle: action.payload}
        case SIGN_UP_ALERT_MODAL_OPEN:
            return{...state, signupAlertModalToggle: action.payload}
        case SIGN_UP_ALERT_MODAL_CLOSE:
            return{...state, signupAlertModalToggle: action.payload}
        default:
            return state;
    }
}

export default alertModalReducer;