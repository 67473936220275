export const LOGIN_MODAL_OPEN = "MODAL_OPEN";
export const LOGIN_MODAL_CLOSE = "MODAL_CLOSE";

export function openLoginModal(){
    return {
        type: LOGIN_MODAL_OPEN,
        payload: true
    }
}

export function closeLoginModal(){
    return {
        type: LOGIN_MODAL_CLOSE,
        payload: false
    }
}