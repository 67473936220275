import React, { useCallback, useEffect, useState, useImperativeHandle, forwardRef, useRef, createRef   } from 'react';
import './RemirrorEditor.scss'
import 'remirror/styles/all.css';

import {
  BoldExtension,
  HeadingExtension,
  ParagraphExtension ,
  FontSizeExtension ,
  ItalicExtension,
  StrikeExtension,
  UnderlineExtension,
  ListItemExtension,
  BulletListExtension,
  OrderedListExtension,
  SupExtension,
  SubExtension,
  TableExtension,
  NodeFormattingExtension,
  BlockquoteExtension,
  DropCursorExtension,
  ImageExtension,
  EntityReferenceExtension,
  findMinMaxRange,
  TextHighlightExtension,
} from "remirror/extensions";
import { FindExtension } from '@remirror/extension-find';

import {
  BasicFormattingButtonGroup,
  HeadingLevelButtonGroup,
  HistoryButtonGroup,
  ToggleSuperscriptButton,
  ToggleSubscriptButton,
  DataTransferButtonGroup,
  ToggleStrikeButton,
  CommandButtonGroup,
  CommandMenuItem,
  DecreaseFontSizeButton,
  DropdownButton,
  IncreaseFontSizeButton,
  IndentationButtonGroup,
  TextAlignmentButtonGroup,
  ListButtonGroup,
  useRemirrorContext,
  useRemirror,
  Remirror,
  ThemeProvider,
  Toolbar,
  CreateTableButton,
  TableDeleteButton,
  TableCellMenu,
  FindReplaceComponent, CommandButton,
  useHelpers, useKeymap, useActive, useCommands, useChainedCommands, VerticalDivider, PositionerPortal, usePositioner, useEditorEvent
} from "@remirror/react";

import { getStyle, uniqueId } from 'remirror';
import EditingSuggestion from '../TooltipButton/EditingSuggestion';
import { toast } from 'react-toastify';
import { CellMenu } from './tableCellMenu';
import { Decoration } from '@remirror/pm/view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import FindReplace from './FindAndReplace';
import TooltipButton from '../TooltipButton/TooltipButton';
import { FieldLoader } from '../Loader/CommonLoader';
import history from '../../history';
import AxiosService from '../../utils/AxiosService';
import { Spinner } from 'react-bootstrap';

let testData = []
function findNestedObj(entireObj, keyToFind, valToFind) {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });

  return foundObj;
};


export const decorateHighlights = (highlights) => {
  const decorations = highlights.map((overlappingHighlights) => {
      // var outs = findNestedObj(testData, "id", overlappingHighlights[0]?.id);
      const style = `background: #ffbfbf;padding: 1px 0; cursor: pointer`;
      // const title = overlappingHighlights[0]?.id === outs?.id ?  `Error: ${outs?.text}` :""
      const [from, to] = findMinMaxRange(overlappingHighlights);
      // Add decoration to all inline nodes in the given range. 
      return Decoration.inline(from, to, { style, class:"highlightClass" });
  });
  return [...decorations];
};

const Popup = () => { 
  
  const { helpers } = useRemirrorContext({ autoUpdate: true });
  const positioner = usePositioner("cursor");
  
  if (!positioner.active) { 
    return null;
  }
  
  const entityReferencesAt = helpers.getEntityReferencesAt();
  const active = entityReferencesAt.length > 0;
  let activeEntity ;
  
  if (active) { 
    // activeEntity = entityReferencesAt[0]
    activeEntity = entityReferencesAt 
  } else { 
    return null
  }

  // var outs = findNestedObj(testData, "id", activeEntity.id);
  var outs = []
  activeEntity.map((item) => {outs.push(findNestedObj(testData, "id", item.id))})
  // const label = outs[0]?.text

  return ( 
    <PositionerPortal>
      <div style={{ top: positioner.y + positioner.height + 15, left: 10, padding: '5px', position: 'absolute', whiteSpace: 'pre-line', maxWidth: 'auto', fontSize: '14px', color: 'white', background: 'black', zIndex: '1010' }} title='Floating annotation' ref={positioner.ref}> 
        {outs.map((item, index) => {
          return (
            <>
              {item?.type && 
                <>
                  {item?.type === "error" && <b className="text-danger">Error: </b>}
                  {item?.type === "warning" && <b className="text-warning">Warning: </b>}
                </>
              }
              <span className='p-0 m-0' dangerouslySetInnerHTML={{__html: item?.text}}></span> <br/>
            </>
          )
        })} 
      </div> 
      {/* <div style={{ top: positioner.y + positioner.height, left: positioner.x, padding: '5px', position: 'absolute', whiteSpace: 'pre-line', maxWidth: 'auto', fontSize: '14px', color: 'white', background: 'black', zIndex: '1010' }} title='Floating annotation' ref={positioner.ref}> 
        <b className="text-danger">Error</b> 
        {label} 
      </div>  */}
    </PositionerPortal> 
  );
   
};

const extensions = () => [
  new HeadingExtension(),
  new BoldExtension(),
  new ItalicExtension(),
  new FontSizeExtension({  unit: 'px' }),
  new ParagraphExtension({
    extraAttributes: {
      id: () => uniqueId()
    }
  }),
  new UnderlineExtension(),
  new ListItemExtension(),
  new BulletListExtension(),
  new OrderedListExtension(),
  new BlockquoteExtension(),
  new NodeFormattingExtension(),
  new SupExtension(),
  new SubExtension(),
  new TableExtension({tabKeyboardShortcuts: true}),
  new StrikeExtension(),
  new ImageExtension({enableResizing: true}),
  new DropCursorExtension(),
  new FindExtension(),
  new EntityReferenceExtension({ getStyle: decorateHighlights, }),
  new TextHighlightExtension()
];


const DefaultStyleButton = () => {
  // const {setFontFamily, setFontSize, setBold} = useCommands()
  const commands = useCommands()
  const {fontFamily, fontSize} = useActive()
  return (
    <>
      <CommandButton aria-label='Default Style' icon='text'
        key='DefaultTextStyle'
        commandName='Set default style'
        onSelect={(e) => {
          commands.removeFontSize()
          commands.removeBold()
          commands.convertParagraph()
        }}
      />
    </>
  )
}

const PasteRules = ({manuscriptStatus, templateType}) => {
  useEditorEvent('paste', (e) => {
    let tempData = editorRef?.current?.helpers?.getJSON()
    if(manuscriptStatus === "Formatted") {
      e.preventDefault()
      toast.error("Copying and pasting is disabled for formatted manuscripts.")
      return
    }
    if(tempData.content.length < 1) {
      e.preventDefault()
      if(templateType === "Generic") {
        toast.error("To begin, please click on 'Import file' button", {position: "top-center", autoClose: 3000})
      } else {
        toast.error("To begin, please 'Import file' or select the 'Populate template' button.", {position: "top-center", autoClose: 3000})
      }
      return
    } else if (tempData.content.length === 1 && !tempData.content[0].content) {
      e.preventDefault()
      if(templateType === "Generic") {
        toast.error("To begin, please click on 'Import file' button", {position: "top-center", autoClose: 3000})
      } else {
        toast.error("To begin, please 'Import file' or select the 'Populate template' button.", {position: "top-center", autoClose: 3000})
      }
      return
    }
  })
}
 
const EditorWithRef = forwardRef((props, ref)  => {

  const {content, isEditable, getSuggestion, 
    showToolbar, showModal, hideModal, loading,
    saveManuscript, downloadErrorFile, editorChanged, 
    setEditorChanged, editorStateSize, setEditorStateSize, 
    manuscriptStatus, blindOrganizationClick, templateType
  } = props

  const onError = useCallback(({ json, invalidContent, transformers }) => {
    // Automatically remove all invalid nodes and marks.
    return transformers.remove(json, invalidContent);
  }, []);

  const { manager, state, onChange, getContext } = useRemirror({
    extensions,
    onError,
    content: content,
    selection: 'start',
    stringHandler: 'html',
	});

  function navigateTo(uri) {
    history.push(uri)
  }

  function saveAndExit() {
    saveManuscript()
    navigateTo("/my-dashboard")
  }
  useImperativeHandle(ref, () => getContext(), [getContext]);

  const [showFind, setShowFind] = useState(false)

  useEffect(()=> {
    testData = content
  },[content])

  //   function getSuggestion() {
	// 	let obj = []
	// 	obj = window.WEBSPELLCHECKER.getInstances()
	// 	if (obj[0]) {
	// 		obj[0].openDialog()
	// 	} else {
	// 		toast.error(`Please select text area!`, {
	// 			position: toast.POSITION.TOP_CENTER
	// 		});
	// 	}
	// }

    // useEffect(()=>{		
    //   setLanguage()
    // },[content])
  
    //   const setLanguage = () => {
    //     window.WEBSPELLCHECKER.init({
    //       container: document.querySelector('.ProseMirror.remirror-editor'),
    //   });
  
    //     let obj =[]
    //     obj =  window.WEBSPELLCHECKER.getInstances()
    //     obj?.[1]?.setLang(language)
    //  }

  return (
          
    <div className={showToolbar ? "border rounded remirrorEditorDiv" : "remirrorEditorDiv"} ref={ref}>
	    <ThemeProvider>
	      <Remirror
	        manager={manager}
	        // autoFocus
	        onChange={(args) => {
            if(editorStateSize === -1 || editorStateSize === 0) {
              setEditorStateSize(state.doc.content.size)
            }
            if((args.previousState.doc.content.size !== args.state.doc.content.size || editorChanged) && args.state.doc.content.size !== editorStateSize) {
              setEditorChanged(true)
            } else {
              setEditorChanged(false)
            }
            onChange(args)
          }}
          // onChange={onChange}
	        initialContent={state}
	        autoRender='end'
          editable={isEditable }
	      >
          { isEditable && true ?
            <div className=' border-bottom rounded sticky-toolbar'>
              {/* {isEditable && 
              <span onClick={()=> setShowToolbar(v => !v)} >
                <TooltipButton tooltipMessage={showToolbar ?"Hide toolbar" : "Open toolbar"}><FontAwesomeIcon className='pr-2 pt-2' icon={!showToolbar? faBars : faXmark} /></TooltipButton>
                </span>
              } */}
                <Toolbar className="flex-wrap-toolbar">
                  {/* <div className='align-items-center w-100 d-flex flex-row justify-content-between px-2 mb-2'>
                    <p className='m-0'><strong>Populate template or manually enter your manuscript</strong></p>
                    {loading.isPopulateTemplateLoading ?
                      <FieldLoader className="wide-bordered-button-secondary" isLoading={loading.isPopulateTemplateLoading} />
                      :
                      <button
                        className='wide-bordered-button-secondary'
                        onClick={(e) => handlePopulate(e)}
                        disabled={loading.isFileLoading || loading.isUpload}
                      >
                        Populate template
                      </button>
                    }
                  </div> */}
                  <HistoryButtonGroup/>
                  <BasicFormattingButtonGroup />
                  <ToggleSuperscriptButton />
                  <ToggleSubscriptButton />
                  <HeadingLevelButtonGroup showAll />
                  <TextAlignmentButtonGroup showAll/>
                  <IndentationButtonGroup />
                  <ListButtonGroup />
                  <CommandButtonGroup>
                    <CreateTableButton/>
                    <DefaultStyleButton/>
                  </CommandButtonGroup>
                  <EditingSuggestion getSuggestion={getSuggestion} />
                  <div className='d-flex flex-grow'>
                    <button className='btnOutline small tooltip-text-btn' onClick={() => hideModal({ ...showModal, isClearAll: true })}>Clear all changes</button>
                    <button className='btn btn-outline-danger btn-sm tooltip-text-btn ml-2 ' onClick={() => hideModal({ ...showModal, isDeleteManuscript: true })}>Delete manuscript</button>
                  </div>
                  <button className='btnOutline small tooltip-text-btn' onClick={()=> setShowFind(v=>!v)}>
                    Find & Replace
                  </button>
                  {/* <button className='btn-sm border small bg-transparent text-secondary' onClick={()=> saveAndExit()}>
                    Save & return to dashboard
                  </button> */}
                  <button className='btnOutline small tooltip-text-btn' disabled={loading.isDownloadError}
                   onClick={()=> {
                      let tempData = editorRef?.current?.helpers?.getJSON()
                      if (tempData.content.length < 1) {
                        toast.error("There is no manuscript to check. Please upload/paste in a manuscript.", {position: "top-center", autoClose: 3000})
                      } else if (tempData.content.length === 1 && !tempData.content[0].content) {
                        toast.error("There is no manuscript to check. Please upload/paste in a manuscript.", {position: "top-center", autoClose: 3000})
                      } else {
                        downloadErrorFile()
                      }
                    }}
                  >
                    {loading.isDownloadError ? 
                      <div className='d-flex flex-row align-items-center'>
                        <span className='mr-2'>Download list of errors</span> 
                        <Spinner animation='grow' size='sm'/>
                      </div> 
                    : 
                      "Download list of errors"
                    }
                  </button>
                  {templateType !== "Generic" ? 
                    <button className='btnOutline small tooltip-text-btn' onClick={()=> blindOrganizationClick()}>
                      Blind Organisations
                    </button>
                  :
                    null
                  }
                  {showFind && 
                    <div className='findReplaceStyle'>
                      {/* <FindReplaceComponent /> */}
                      <FindReplace />
                    </div>
                  }  
                </Toolbar>
              <TableDeleteButton/>
              <TableCellMenu Component={(args) => (CellMenu(args))}/>
              <Popup /> 
            </div>
          : 
            ""
          }
          <PasteRules manuscriptStatus={manuscriptStatus} templateType={templateType}/>
	      </Remirror>
	    </ThemeProvider>

    </div>
  )
});



export const editorRef = React.createRef();

function RemirrorEditor({
  data,language, isEditable=true, getSuggestion, 
  setShowToolbar, showToolbar, loader, 
  handlePopulate, loading, showModal,
  hideModal, saveManuscript, downloadErrorFile, templateType,
  editorChanged, setEditorChanged, manuscriptStatus, blindOrganizationClick
}) {

  const [editorStateSize, setEditorStateSize] = useState(-1)

return (
    	<> 
          <EditorWithRef 
            ref={editorRef} 
            language={language} 
            content={data} 
            isEditable={isEditable} 
            getSuggestion={getSuggestion} 
            showToolbar={showToolbar} 
            setShowToolbar={setShowToolbar} 
            loader={loader} 
            loading={loading} 
            handlePopulate ={handlePopulate}
            showModal = {showModal}
            hideModal = {hideModal}
            saveManuscript = {saveManuscript}
            downloadErrorFile = {downloadErrorFile}
            editorChanged={editorChanged} 
            setEditorChanged={setEditorChanged}
            editorStateSize = {editorStateSize}
            setEditorStateSize = {setEditorStateSize}
            manuscriptStatus = {manuscriptStatus}
            blindOrganizationClick = {blindOrganizationClick}
            templateType={templateType}
          />
     	</>
)

}


export default RemirrorEditor;
