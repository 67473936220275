export const TOKEN_EXPIRED = "TOKEN_EXPIRED";
export const TOKEN_REFRESHED = "TOKEN_REFRESHED";

export function tokenExpired(){
    return{
        type: TOKEN_EXPIRED,
        payload: false
    }
}

export function tokenRefreshed(){
    return{
        type: TOKEN_REFRESHED,
        payload: true
    }
}