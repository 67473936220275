import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Modal, Tab, Table, Tabs, Spinner } from "react-bootstrap";
import { CircleLoader, FieldLoader } from "../../../../../components/Loader/CommonLoader";
import styles from "./ValidationErrorTable.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown, faAngleDoubleUp, faAnglesRight, faArrowsAlt, faArrowsAltH, faChevronDown, faChevronRight, faChevronUp, faMaximize, faSort, faXmark} from "@fortawesome/free-solid-svg-icons";
import ErrorItem from "./ErrorItem";
import AxiosService from "../../../../../utils/AxiosService";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { set } from "remirror";
import { useRef } from "react";
import { faWindowMaximize } from "@fortawesome/free-regular-svg-icons";
import TooltipButton from '../../../../../components/TooltipButton/TooltipButton';

const ValidationErrorTable = ({errors, selectedTab, loader, 
	handleSkipStep, fetchData, setErrorMessage, 
	apiCount, setApiCount, visibleSkipErrorBtn, 
	skipStepTwoError,setSkipStepTwoError, handleValidate, 
	type2Error, type3Error, type4Error, setLoader,
	type5Error, type6Error, skipButtonVisible, 
	skipAndContinue, setType2Error, setType3Error, 
	setType4Error, setType5Error, setType6Error, 
	typeAutoCorrect, setTypeAutoCorrect, showToolbar, 
	setShowToolbar, renderSaveAndValidateButtonText, renderSkipErrorButtonText,
	setIgnoreErrorChanged, ignoreErrorChanged, ignoredErrorList,
	ignoreCaptionChanged, setIgnoreCaptionChanged, ignoredCaptionList,
	ignoreOrganizationNameList, setIgnoreOrganizationNameChanged, ignoreOrganizationNameChanged,
	editorChanged, setEditorChanged, handleSave, undoErrorControl, setUndoErrorControl, modal
}) => {
	const apiService = new AxiosService()
	const {manuscriptId} = useParams()
	const [loading , setLoading]= useState({isAutoCorrect: false})
	const [errorType, setErrorType] = useState("skipError")
	// const [instructionExpanded, setInstructionExpanded] = useState(true)

	function updateTitleAbstractValidation() {
		apiService.validateData(manuscriptId, "Title_Abstract")
		.then(res => {
			setLoading({...loading, isAutoCorrect: false})
			setErrorMessage()
		}).catch(err => {
			setLoading({...loading, isAutoCorrect: false})
			setErrorMessage(err.response.data.message)
		})
	}

	function handleAutoCorrect(err){
		setLoading({...loading, isAutoCorrect: true})
		apiService.autoCorrect(manuscriptId , {
				"field": err.field,
				"errorKey": err.errorKey
		}).then(res=> {
			fetchData()
			updateTitleAbstractValidation()
			toast.success(res.result, {autoClose: 3000})
		}).catch(err=> {
			toast.error(err?.response?.data?.message, {autoClose: 3000})
		})
	}


		return(
				<section className="">
				<div className="d-flex justify-content-between align-items-center">
				{/* <h5 className="text-danger my-2">Errors and Warnings!</h5> */}
				{/* {loader.skipBtnLoader ?<FieldLoader isLoading={loader.skipBtnLoader} /> : <button onClick={()=> handleSkipStep(selectedTab)} className="btnOutline btn-sm w-auto py-1 px-3 ml-auto" disabled = {loader.nextBtnLoader || skipStepTwoError.isPrevError || skipStepTwoError.isSkipError}>Skip Errors <FontAwesomeIcon icon={faAnglesRight} /></button>} */}
				</div>
				{modal !== true ? 
					<div>
						<p className={selectedTab === 2 ? "mt-2 mb-1 text-muted small" : "mt-2 mb-3 text-muted small"}>Please correct the error/s below that do not comply with your chosen journal's guidelines.</p>
						{selectedTab === 2 && true && <p className="mb-1 text-muted small">Please make the changes directly into the manuscript field on the left then click '{renderSaveAndValidateButtonText(selectedTab)}' or edit your original document and reupload (your paper can be found in 'Drafts' in your Dashboard).</p>}
						{selectedTab === 2 && true &&<p className='mt-0 mb-3 text-muted small'>Please note - you must click '{renderSaveAndValidateButtonText(selectedTab)}' to save any changes you have made on screen before proceeding to Step Three.</p>}
					</div>
				:
					null
				}
				{/* {selectedTab === 2 ? 
					instructionExpanded ? 
						<p onClick={(e) => {setInstructionExpanded(false)}} className={selectedTab !== 3 ? "mb-3 text-danger small cursor-pointer" : "mb-3 text-danger small cursor-pointer"}>
							Hide instructions <FontAwesomeIcon icon={faAngleDoubleUp} size="sm"/>
						</p> 
					: 
						<p onClick={(e) => {setInstructionExpanded(true)}} className={selectedTab !== 3 ? "mb-3 text-success small cursor-pointer" : "mb-3 text-success small cursor-pointer"}>
							Show more <FontAwesomeIcon icon={faAngleDoubleDown} size="sm"/>
						</p> 
				: 
					null
				} */}
				
				<div className={styles.errorAccordian}>
				{ typeAutoCorrect ? 
					<TableItem 
						errors={typeAutoCorrect} 
						fetchData={fetchData} 
						handleAutoCorrect={handleAutoCorrect} 
						loading={loading} 
						setErrorMessage={setTypeAutoCorrect} 
						isSaveValidateLoading={loader.nextBtnLoader} 
						showToolbar={showToolbar} 
						setShowToolbar={setShowToolbar}
						expanded = {true}
						selectedTab={selectedTab}
						setIgnoreErrorChanged = {setIgnoreErrorChanged}
              			ignoreErrorChanged = {ignoreErrorChanged} 
						ignoredErrorList = {ignoredErrorList}
						ignoreCaptionChanged = {ignoreCaptionChanged}
              			setIgnoreCaptionChanged = {setIgnoreCaptionChanged}
						ignoredCaptionList = {ignoredCaptionList}
						ignoreOrganizationNameChanged = {ignoreOrganizationNameChanged}
						setIgnoreOrganizationNameChanged = {setIgnoreOrganizationNameChanged}
						ignoreOrganizationNameList = {ignoreOrganizationNameList}
						loader = {loader}
						setLoader = {setLoader}
						handleValidate = {handleValidate}
						editorChanged={editorChanged} setEditorChanged={setEditorChanged}
						handleSave={handleSave}
						undoErrorControl = {undoErrorControl}
						setUndoErrorControl = {setUndoErrorControl}
						modal = {modal}
					/>
					: null
				}
				{(errors?.details?.length) || errors?.length ?
					<TableItem 
						fetchData={fetchData} 
						errors={errors} 
						handleAutoCorrect={handleAutoCorrect} 
						loading={loading} 
						isSaveValidateLoading={loader.nextBtnLoader} 
						selectedTab={selectedTab} 
						setErrorMessage={setErrorMessage} 
						showToolbar={showToolbar} 
						setShowToolbar={setShowToolbar}
						expanded = {!typeAutoCorrect ? true : false}
						setIgnoreErrorChanged = {setIgnoreErrorChanged}
              			ignoreErrorChanged = {ignoreErrorChanged}  
						ignoredErrorList = {ignoredErrorList}
						ignoreCaptionChanged = {ignoreCaptionChanged}
              			setIgnoreCaptionChanged = {setIgnoreCaptionChanged}
						ignoredCaptionList = {ignoredCaptionList}
						ignoreOrganizationNameChanged = {ignoreOrganizationNameChanged}
						setIgnoreOrganizationNameChanged = {setIgnoreOrganizationNameChanged}
						ignoreOrganizationNameList = {ignoreOrganizationNameList}
						loader = {loader}
						setLoader = {setLoader}
						handleValidate = {handleValidate}
						editorChanged={editorChanged} setEditorChanged={setEditorChanged}
						handleSave={handleSave}
						undoErrorControl = {undoErrorControl}
						setUndoErrorControl = {setUndoErrorControl}
						modal = {modal}
					/>
					: null
				}
				{type2Error?.details?.length || type2Error?.length ? 
					<TableItem 
						errors={type2Error}
						fetchData={fetchData} 
						handleAutoCorrect={handleAutoCorrect} 
						loading={loading} 
						isSaveValidateLoading={loader.nextBtnLoader} 
						setErrorMessage={setType2Error} 
						showToolbar={showToolbar} 
						setShowToolbar={setShowToolbar}
						expanded = {!errors?.details?.length && !typeAutoCorrect ? true : false}
						selectedTab={selectedTab}
						setIgnoreErrorChanged = {setIgnoreErrorChanged}
              			ignoreErrorChanged = {ignoreErrorChanged}
						ignoredErrorList = {ignoredErrorList}
						ignoreCaptionChanged = {ignoreCaptionChanged}
              			setIgnoreCaptionChanged = {setIgnoreCaptionChanged}
						ignoredCaptionList = {ignoredCaptionList}
						ignoreOrganizationNameChanged = {ignoreOrganizationNameChanged}
						setIgnoreOrganizationNameChanged = {setIgnoreOrganizationNameChanged}
						ignoreOrganizationNameList = {ignoreOrganizationNameList}
						loader = {loader}
						setLoader = {setLoader}
						handleValidate = {handleValidate}
						editorChanged={editorChanged} setEditorChanged={setEditorChanged}
						handleSave={handleSave}
						undoErrorControl = {undoErrorControl}
						setUndoErrorControl = {setUndoErrorControl}
						modal = {modal}
					/>
					: null
				}
		
				{type3Error?.details?.length || type3Error?.length ? 
					<TableItem 
						errors={type3Error} 
						fetchData={fetchData} 
						handleAutoCorrect={handleAutoCorrect} 
						loading={loading} 
						isSaveValidateLoading={loader.nextBtnLoader} 
						setErrorMessage={setType3Error} 
						showToolbar={showToolbar} 
						setShowToolbar={setShowToolbar}
						expanded = {!type2Error?.details?.length && !errors?.details?.length && !typeAutoCorrect ? true : false}
						selectedTab={selectedTab}
						setIgnoreErrorChanged = {setIgnoreErrorChanged}
              			ignoreErrorChanged = {ignoreErrorChanged}
						ignoredErrorList = {ignoredErrorList}
						ignoreCaptionChanged = {ignoreCaptionChanged}
              			setIgnoreCaptionChanged = {setIgnoreCaptionChanged}
						ignoredCaptionList = {ignoredCaptionList}
						ignoreOrganizationNameChanged = {ignoreOrganizationNameChanged}
						setIgnoreOrganizationNameChanged = {setIgnoreOrganizationNameChanged}
						ignoreOrganizationNameList = {ignoreOrganizationNameList}
						loader = {loader}
						setLoader = {setLoader}
						handleValidate = {handleValidate}
						editorChanged={editorChanged} setEditorChanged={setEditorChanged}
						handleSave={handleSave}
						undoErrorControl = {undoErrorControl}
						setUndoErrorControl = {setUndoErrorControl}
						modal = {modal}
					/>
					: null
				}

				{type4Error?.details?.length || type4Error?.length? 
					<TableItem 
						errors={type4Error} 
						fetchData={fetchData} 
						handleAutoCorrect={handleAutoCorrect} 
						loading={loading} 
						isSaveValidateLoading={loader.nextBtnLoader} 
						setErrorMessage={setType4Error} 
						showToolbar={showToolbar} 
						setShowToolbar={setShowToolbar}
						expanded = {!type3Error?.details?.length && !type2Error?.details?.length && !errors?.details?.length && !typeAutoCorrect ? true : false}
						selectedTab={selectedTab}
						setIgnoreErrorChanged = {setIgnoreErrorChanged}
              			ignoreErrorChanged = {ignoreErrorChanged}
						ignoredErrorList = {ignoredErrorList}
						ignoreCaptionChanged = {ignoreCaptionChanged}
              			setIgnoreCaptionChanged = {setIgnoreCaptionChanged}
						ignoredCaptionList = {ignoredCaptionList}
						ignoreOrganizationNameChanged = {ignoreOrganizationNameChanged}
						setIgnoreOrganizationNameChanged = {setIgnoreOrganizationNameChanged}
						ignoreOrganizationNameList = {ignoreOrganizationNameList}
						loader = {loader}
						setLoader = {setLoader}
						handleValidate = {handleValidate}
						editorChanged={editorChanged} setEditorChanged={setEditorChanged}
						handleSave={handleSave}
						undoErrorControl = {undoErrorControl}
						setUndoErrorControl = {setUndoErrorControl}
						modal = {modal}
					/>
					: null
				}

				{type5Error?.details?.length || type5Error?.length? 
					<TableItem 
						errors={type5Error} 
						fetchData={fetchData} 
						setErrorMessage={setType5Error} 
						showToolbar={showToolbar} 
						isSaveValidateLoading={loader.nextBtnLoader} 
						setShowToolbar={setShowToolbar} 
						selectedTab={selectedTab}
						expanded = {!type4Error?.details?.length && !type3Error?.details?.length && !type2Error?.details?.length && !errors?.details?.length && !typeAutoCorrect ? true : false}
						setIgnoreErrorChanged = {setIgnoreErrorChanged}
              			ignoreErrorChanged = {ignoreErrorChanged}
						ignoredErrorList = {ignoredErrorList}
						ignoreCaptionChanged = {ignoreCaptionChanged}
              			setIgnoreCaptionChanged = {setIgnoreCaptionChanged}
						ignoredCaptionList = {ignoredCaptionList}
						ignoreOrganizationNameChanged = {ignoreOrganizationNameChanged}
						setIgnoreOrganizationNameChanged = {setIgnoreOrganizationNameChanged}
						ignoreOrganizationNameList = {ignoreOrganizationNameList}
						loader = {loader}
						setLoader = {setLoader}
						handleValidate = {handleValidate}
						editorChanged={editorChanged} setEditorChanged={setEditorChanged}
						handleSave={handleSave}
						undoErrorControl = {undoErrorControl}
						setUndoErrorControl = {setUndoErrorControl}
						modal = {modal}
					/>
					: null
				}

				{type6Error?.details?.length || type6Error?.length? 
					<TableItem 
						errors={type6Error} 
						fetchData={fetchData} 
						handleAutoCorrect={handleAutoCorrect} 
						loading={loading} 
						isSaveValidateLoading={loader.nextBtnLoader} 
						setErrorMessage={setType6Error} 
						showToolbar={showToolbar} 
						setShowToolbar={setShowToolbar}
						selectedTab={selectedTab}
						expanded = {!type5Error?.details?.length && !type4Error?.details?.length && !type3Error?.details?.length && !type2Error?.details?.length && !errors?.details?.length && !typeAutoCorrect ? true : false}
						setIgnoreErrorChanged = {setIgnoreErrorChanged}
              			ignoreErrorChanged = {ignoreErrorChanged}
						ignoredErrorList = {ignoredErrorList}
						ignoreCaptionChanged = {ignoreCaptionChanged}
              			setIgnoreCaptionChanged = {setIgnoreCaptionChanged}
						ignoredCaptionList = {ignoredCaptionList}
						ignoreOrganizationNameChanged = {ignoreOrganizationNameChanged}
						setIgnoreOrganizationNameChanged = {setIgnoreOrganizationNameChanged}
						ignoreOrganizationNameList = {ignoreOrganizationNameList}
						loader = {loader}
						setLoader = {setLoader}
						handleValidate = {handleValidate}
						editorChanged={editorChanged} setEditorChanged={setEditorChanged}
						handleSave={handleSave}
						undoErrorControl = {undoErrorControl}
						setUndoErrorControl = {setUndoErrorControl}
						modal = {modal}
					/>
					: null
				}

			</div>
				</section>
		)
}

const TableItem =({errors, isSaveValidateLoading, selectedTab, 
	fetchData, setErrorMessage, showToolbar, ignoredErrorList,
	setShowToolbar, expanded, ignoreErrorChanged, setIgnoreErrorChanged,
	ignoreCaptionChanged, setIgnoreCaptionChanged, ignoreOrganizationNameChanged,
	setIgnoreOrganizationNameChanged, loader, setLoader, handleValidate,
	editorChanged, setEditorChanged, handleSave, undoErrorControl, setUndoErrorControl, modal
}) => { 
	const [show, setHide] = useState(false)
	const apiService = new AxiosService()
	const {manuscriptId} = useParams()
	const [loading , setLoading]= useState({isAutoCorrect: false, isUndoChanges: false, isCorrectError: false, isGotoError: false, isRemoveFigure: false, isIgnoreFigure: false, isIgnoreOrganization: false, isRemoveOrganization: false})
	const [undoModal, setUndoModal] = useState(false)
	const [removeFigureModal, setRemoveFigureModal] = useState(false)
	const [removeOriganizationModal, setRemoveOriganizationModal] = useState(false)
	const [deleteCaptionStrings, setDeletecaptionStrings] = useState(errors?.details ? errors?.details[0]?.figureCaptionList ? [...errors?.details[0]?.figureCaptionList] : [] : [])
	const [deleteOrganizationNames, setDeleteOrganizationNames] = useState(errors?.details ? errors?.details[errorKeyIndex("blindOriganization")]?.details ? [...errors?.details[errorKeyIndex("blindOriganization")]?.details] : [] : [])
	const [viewDetailsVisible, setViewDetailsVisible] = useState(false)
	const [ignoreCaptionStrings, setIgnoreCaptionStrings] = useState([])
	const [ignoreOrganizationNames, setIgnoreOrganizationNames] = useState([])
	const tableRef = useRef()
	const [editorChangeModal, setEditorChangeModal] = useState({state: false, trigger: false})
	const [activeTab, setActiveTab] = useState(errors?.details?.length ? "errors" : "validations")
	const [errorWindowControl, setErrorWindowControl] = useState({maximize: false, loading: false})
	const [ignoreCaptionLoader, setIgnoreCaptionLoader] = useState(false)

	function updateValidation(type) {
		if (!loader.nextBtnLoader) {
			setLoader({...loader, nextBtnLoader: true})
		} 
		apiService.validateData(manuscriptId, type)
		.then(res => {
			setLoading({...loading, isAutoCorrect: false})
			setErrorMessage()
			if(type === "Figure" || type === "Other") {
				fetchData()
			}
			setLoader({...loader, nextBtnLoader: false})
		}).catch(err => {
			setLoading({...loading, isAutoCorrect: false})
			if(type === "Figure" || type === "Other") {
				fetchData()
			}
			setErrorMessage(err.response.data.message)
			setLoader({...loader, nextBtnLoader: false})
		})
	}

	function handleAutoCorrect(err, header){
		setLoading({...loading, isAutoCorrect: true})
		apiService.autoCorrect(manuscriptId , {
				"field": err.field,
				"errorKey": err.errorKey
		}).then(res=> {
			fetchData()
			if(header === "Title/Abstract errors and warnings") updateValidation("Title_Abstract")
			else if (header === "Table errors and warnings") updateValidation("Table")
			else if (header === "Figure errors and warnings") updateValidation("Figure")
			else if (header === "Reference errors and warnings") updateValidation("Reference")
			else if (header === "Other errors and warnings") updateValidation("Other")
			toast.success(res.result, {autoClose: 3000})
		}).catch(err=> {
			toast.error(err?.response?.data?.message, {autoClose: 3000})
		})
	}

	function handleAutoCorrectError() {
		setLoading({...loading, isCorrectError: true})
		apiService.validateData(manuscriptId, "AutoCorrect", "yes").then(res => {
			setLoading({...loading, isCorrectError: false})
			setErrorMessage()
			fetchData(true)
			return res
		}).catch(err => {
			setLoading({...loading, isCorrectError: false})
			setErrorMessage(err.response.data.message)
			setUndoErrorControl({...undoErrorControl, errors: err.response.data.message, visible: true})
			fetchData(true)
			return err.response
		})
	}

	function handleUndoError() {
		setLoading({...loading, isUndoChanges: true})

		apiService.undoCorrectedChanges(manuscriptId).then(res => {
			setLoading({...loading, isCorrectError: false})
			setErrorMessage()
			fetchData(true)
			toast.success(res?.result , {position: "top-center", autoClose: 3000})
			// handleSave(selectedTab)
			return res
		}).catch(err => {
			setLoading({...loading, isCorrectError: false})
			setErrorMessage(err.response.data.message)
			fetchData()
			return err.response
		})
	}

	function handleRemoveFigures() {
		setLoading({...loading, isRemoveFigure: true})
		let formData = new FormData()
		formData.append("captions", deleteCaptionStrings)
		if(errors?.details[0]?.figureCaptionList?.length === ignoreCaptionStrings.length && errors?.details[0]?.figureCount === 0) {
			toast.warning("No caption to remove, all captions are ignored.", {autoClose: 3000})
			setLoading({...loading, isIgnoreFigure: false})
		} else if(deleteCaptionStrings?.length <= 0 && errors?.details[0]?.figureCount === 0) {
			// toast.error("Kindly choose at least one caption/legend to remove.")
			setLoading({...loading, isIgnoreFigure: false})
		} else {
			apiService.removeFiguresAndCaptions(manuscriptId, {captions: deleteCaptionStrings}).then(res=>{
				setLoading({...loading, isRemoveFigure: false})
				toast.success(res.result, {position: "top-center" ,autoClose: 3000})
				handleValidate(selectedTab)
				setDeletecaptionStrings([])
			}).catch(err=> {
				setLoading({...loading, isRemoveFigure: false})
				toast.error(err?.response?.data?.message, {position: "top-center", autoClose: 3000})
				setDeletecaptionStrings([])
			})
		}
	}


	function showRemoveFigureDialog() {
		handleRemoveFigures()
		setRemoveFigureModal(false)
		setIgnoreCaptionChanged(ignoreCaptionChanged+1)
		setIgnoreCaptionStrings([])
	}

	function isChecked(value) {
		if(deleteCaptionStrings.includes(value)) {
			return true
		} else {
			return false
		}
	}

	function figureCaptionCheckBoxChange(e) {
		if(e.target.checked) {
			setDeletecaptionStrings([...deleteCaptionStrings, e.target.value])
		} else {
			setDeletecaptionStrings(deleteCaptionStrings.filter((each) => each !== e.target.value))
		}
	}

	function ignoreCaptionButtonClick(caption) {
		const body = {figureCaptions: [caption]}
		setIgnoreCaptionLoader(true)
		apiService.ignoreFigureCaption(manuscriptId, body).then((res) => {
			setIgnoreCaptionStrings([...ignoreCaptionStrings, caption])
			setDeletecaptionStrings(deleteCaptionStrings.filter((each) => each !== caption))
			toast.success(res.result, {autoClose: 3000})
			setIgnoreCaptionLoader(false)
		}, (err) => {
			console.log("Error in ignoreCaption: ", err.response)
			toast.error(err?.response?.data?.result, {autoClose: 3000})
			setIgnoreCaptionLoader(false)
		})
	}

	function checkCaptionIgnored(caption) {
		if(ignoreCaptionStrings.includes(caption)) {
			return true
		} else {
			return false
		}
	}

	function selectAllCaptions(e) {
		if (e.target.checked) {
			setDeletecaptionStrings(errors?.details[0]?.figureCaptionList ? [...errors?.details[0]?.figureCaptionList] : [])
		} else {
			setDeletecaptionStrings([])
		}
	}

	function selectAllChecked() {
		// if(errors?.details[0]?.figureCaptionList !== undefined) {
			if(deleteCaptionStrings?.length === errors?.details[0]?.figureCaptionList?.length) {
				return true
			} else {
				return false
			}
		// }
	}

	function scrollToTop() {
		setTimeout(() => {
			if(tableRef.current.children.length > 1) {
				tableRef.current.children[1].scrollIntoView({
					behavior: 'smooth',
					block: 'nearest',
				})
			}
		}, 10)
	}

	function handleViewDetailClick(err) {
		setViewDetailsVisible(!viewDetailsVisible)
	}

	function removeOrganizationNames () {
		setLoading({...loading, isRemoveOrganization: true})
		let formData = new FormData()
		formData.append("organizations", deleteOrganizationNames)
		if(errors?.details[errorKeyIndex("blindOriganization")]?.details?.length === ignoreOrganizationNames.length) {
			toast.warning("No organisation names to remove, all names are ignored.", {autoClose: 3000})
			setLoading({...loading, isIgnoreOrganization: false, isRemoveOrganization: false})
		} else if(deleteOrganizationNames?.length <= 0) {
			// toast.error("Kindly choose at least one organisation name to remove.")
			setLoading({...loading, isIgnoreOrganization: false, isRemoveOrganization: false})
		} else {
			apiService.removeOrganizationNames(manuscriptId, {organizations: deleteOrganizationNames}).then(res=>{
				setIgnoreErrorChanged(ignoreErrorChanged+1)
				setLoading({...loading, isRemoveOrganization: false})
				toast.success(res.result, {position: "top-center"}, {autoClose: 3000})
				updateValidation("Other")
				setDeleteOrganizationNames([])
			}).catch(err=> {
				setLoading({...loading, isRemoveOrganization: false})
				toast.error(err?.response?.data?.message, {position: "top-center", autoClose: 3000})
				setDeleteOrganizationNames([])
			})
		}
	}

	function handleRemoveOriganizationClick () {
		removeOrganizationNames()
		setRemoveOriganizationModal(false)
		setIgnoreOrganizationNameChanged(ignoreOrganizationNameChanged+1)
		setIgnoreOrganizationNames([])
		updateValidation("Other")
	}

	function errorKeyIndex(errorKey) {
		let keyIndex = -1
		errors?.details?.map((item, index) => {
			if(item.errorKey === errorKey) {
				keyIndex = index 
			} 
		})
		return keyIndex;
	}

	function selectAllOrganizationNames(e) {
		let index = errorKeyIndex("blindOriganization")
		if (e.target.checked) {
			setDeleteOrganizationNames(errors?.details[index]?.details ? [...errors?.details[index]?.details] : [])
		} else {
			setDeleteOrganizationNames([])
		}
	}

	function selectAllOrganizationNamesChecked() {
		let index = errorKeyIndex("blindOriganization")
		if(deleteOrganizationNames?.length === errors?.details[index]?.details?.length) {
			return true
		} else {
			return false
		}
	}

	function organizationNameCheckBoxChange(e) {
		if(e.target.checked) {
			setDeleteOrganizationNames([...deleteOrganizationNames, e.target.value])
		} else {
			setDeleteOrganizationNames(deleteOrganizationNames.filter((each) => each !== e.target.value))
		}
	}

	function isOrganizationNameChecked(value) {
		if(deleteOrganizationNames.includes(value)) {
			return true
		} else {
			return false
		}
	}

	function checkOrganizationNameIgnored(name) {
		if(ignoreOrganizationNames.includes(name)) {
			return true
		} else {
			return false
		}
	}

	function ignoreOrganizationNameButtonClick(name) {
		const body = {organizations: [name]}
		apiService.ignoreOrganizationName(manuscriptId, body).then((res) => {
			setIgnoreOrganizationNames([...ignoreOrganizationNames, name])
			setDeleteOrganizationNames(deleteOrganizationNames.filter((each) => each !== name))
			toast.success(res.result, {autoClose: 3000})
		}, (err) => {
			console.log("Error in ignoreOrganization: ", err.response)
			toast.error(err?.response?.data?.result, {autoClose: 3000})
		})
	}

	const maximizeErrorWindow = (e) => {
		e.stopPropagation(); 
		setErrorWindowControl({...errorWindowControl, maximize: true})
	}

	useEffect(() => {
		if(expanded) {
			setHide(true)
		} else {
			setHide(false)
		}
	}, [expanded])

	return (
	<div>	
		<Table responsive ref={tableRef} className="text-break border rounded">
			{modal !== true ?
				<thead className={classNames("cursor-pointer h6")} onClick={(e)=> {setHide(!show); scrollToTop()}}>
					<tr className="sticky-top">
						<th className="border-0">
							<p className="flex-between mb-0">
								<span>
									{errors?.header ? errors?.header: "Validation errors and warnings"} 
									{ errors?.details ? ` (${errors?.details?.length})`: ""}
									<span>
										{loading?.isAutoCorrect && <CircleLoader size="sm" />}
									</span>
								</span>
								<span>
									<TooltipButton tooltipMessage="Expand error messages">
										<span onClick={(e) => {maximizeErrorWindow(e)}} className={classNames(styles.iconColor, "mx-3")}>
											<FontAwesomeIcon icon={faMaximize} />
										</span>
									</TooltipButton>
									<FontAwesomeIcon icon={show ? faChevronDown : faChevronUp} />
								</span>
							</p>
						</th>
					</tr>
				</thead>
			:
				null
			}
			{show &&
				// <Tabs defaultActiveKey={activeTab} onSelect={(k) => {setActiveTab(k)}} className={styles.customTabset}>
					// {errors?.details.length ?
						// <Tab id="tab-Errors" eventKey='errors' title={errors?.details?.length > 1 ? errors?.details.length + ' Errors/Warnings' : errors?.details.length + ' Error/Warning'} tabClassName={activeTab === "errors" ? styles.customActiveTab : styles.customTab}> 
							<tbody className="">
								{typeof errors ==="string" ?
									<tr>
										<td><p>{errors}</p></td>
									</tr>
								:
									<>
										{errors?.details?.map((err, index) => 
											<tr key={index}>	
												<td>
													<h6>{err.field}</h6>
													{err.dataType ==="Array"?
														<ErrorItem err={err} index={index} fetchData={fetchData} setErrorMessage={setErrorMessage} isSaveValidateLoading={isSaveValidateLoading} header={errors?.header}>
															<GoToErrorSection err={err} apiService={apiService} setShowToolbar={setShowToolbar} />
														</ErrorItem>
													: 
														err?.newText ? 
															<p className="mb-0">
																<p>
																	<span className={err.type==="error"?"text-danger text-capitalize font-weight-bold":"text-warning text-capitalize font-weight-bold"}>Error: </span>
																	<span dangerouslySetInnerHTML={{ __html: `${err?.oldText}` }}></span>
																</p>
																<p className="mb-0">
																	<span className={"font-weight-bold text-success"}>Corrected text: </span>
																	<span dangerouslySetInnerHTML={{ __html: `${err?.newText}` }}></span>
																</p>
																{err?.errorDetails &&  
																	<>
																		<p className="my-2 linkText cursor-pointer p-0" onClick={(e) => {handleViewDetailClick(err)}}>
																			View details <FontAwesomeIcon icon={!viewDetailsVisible ? faAngleDoubleDown: faAngleDoubleUp}/>
																		</p>
																		<Collapse key={index} in={viewDetailsVisible} className="multi-collapse">
																			<div>
																				{err.errorDetails.map((discription, discriptionIndex) => {
																					return (
																						<>
																							<p>
																								<h6 className="p-0">Reference {discription.referenceNo}</h6>
																								<p className="text-danger m-0">Incorrect Reference</p>
																								<p className="mb-2">{discription.reference}</p>
																								<p className="text-success m-0">Corrected Reference</p>
																								<p className="mb-2">{discription.updatedReference}</p>
																							</p>
																						</>
																					)
																				})}
																			</div>
																		</Collapse>
																	</>
																}
															</p>
														:
															<p className="mb-0">
																<span className={err.type==="error"?"text-danger text-capitalize font-weight-bold":"text-warning text-capitalize font-weight-bold"}>{err.type}: </span>
																<span dangerouslySetInnerHTML={{ __html: `${err.description}` }}></span>
															</p>
													}
													<section className="d-flex align-items-center justify-content-evenly gap1">
														{err?.autoCorrect && errors?.header !=="Autocorrections" ? loading?.isAutoCorrect ? <FieldLoader className="btn-sm w-auto mt-2 btnOutline" isLoading={loading.isAutoCorrect} /> :<button className="btn-sm btnOutline w-auto mt-2 mb-2" onClick={()=> handleAutoCorrect(err, errors?.header)} disabled={isSaveValidateLoading}>Auto correct error/s</button> :""}
														{err?.id && ( err?.errorKey !== "referencesNotReferred" && err?.errorKey !== "referenceNotFoundForCitations" && err?.errorKey !== "tablesMissingForCitation")? <GoToErrorSection err={err} apiService={apiService} setShowToolbar={setShowToolbar} isSaveValidateLoading={isSaveValidateLoading} />: ""}
														{err.errorKey ==="figureNotAllowed" ? 
															loading.isRemoveFigure ? 
																<FieldLoader className="btn-sm btnDefault mt-2" isLoading={loading.isRemoveFigure} message="Removing" /> 
															: 
																<>
																	<button 
																		className={classNames(styles.removeFigureBtn, "btn-sm btnDefault mt-2")} 
																		onClick={()=> {
																			if(editorChanged) {
																				setEditorChangeModal({state: true, trigger: "removeFigure"})
																			} else {
																				setRemoveFigureModal(true)
																			}
																		}}  
																		disabled={isSaveValidateLoading}>
																		Remove figures/captions/legends
																	</button>
																</>
														: 
															""
														}
														{err.errorKey ==="blindOriganization" ? 
															loading.isRemoveFigure ? 
																<FieldLoader className="btn-sm btnDefault mt-2" isLoading={loading.isRemoveFigure} message="Removing" /> 
															: 
																<>
																	<button 
																		className={classNames(styles.removeFigureBtn, "btn-sm btnDefault mt-2")} 
																		onClick={()=> {
																			if(editorChanged) {
																				setEditorChangeModal({state: true, trigger: "blindOrganization"})
																			} else {
																				setRemoveOriganizationModal(true)
																			}
																		}}  
																		disabled={isSaveValidateLoading}>
																		Select organisations to blind
																	</button>
																</>
														: 
															""
														}
														{ (selectedTab === 2 || selectedTab === 3) && !err?.newText ?
															<>
																{err.errorKey === "figureNotAllowed" ? 
																	""
																:
																	<IgnoreError 
																		error={err} 
																		manuscriptId={manuscriptId} 
																		tab={selectedTab} 
																		setIgnoreErrorChanged={setIgnoreErrorChanged} 
																		ignoreErrorChanged={ignoreErrorChanged}
																		ignoredErrorList = {ignoredErrorList}
																		updateValidation = {updateValidation}
																	/>
																}
															</> 
														:
															"" 
														}
													</section>
												</td>
											</tr>
										)}

										{errors?.isAutoCorrectError && errors?.header ==="Autocorrections"  ? 
											loading.isCorrectError ? <FieldLoader isLoading={loading.isCorrectError} className="btn-sm btnDefault w-auto mt-2 ml-3" /> :<button className="btn-sm btnDefault w-auto mt-2 ml-2 mb-2" onClick={()=>handleAutoCorrectError()} disabled={isSaveValidateLoading}>Auto correct error/s</button>
										: 
											errors?.header ==="Autocorrections" && !errors?.isAutoCorrectError && modal !== true ?
												<button className="btn-sm w-auto my-2 ml-2 secondary-text-button align-items-center" onClick={()=>setUndoModal(true)}>Undo</button>
											: 
												""
										}

										{/* {errors?.passList ? 
											errors?.passList?.map((passList, index) => 
												<tr index={"passList" + index}>
													<td>
														<h6>{passList.field}</h6>
														<p className="mb-0">
															<span className={"font-weight-bold text-success"}>Verified: </span>
															<span dangerouslySetInnerHTML={{ __html: `${passList?.description}` }}></span>
														</p>
													</td>
												</tr>
											)
										:
											null
										} */}
										
										<Modal show={undoModal} onHide={() => setUndoModal(false)} dialogClassName="">
											<Modal.Header className="h6">Warning!</Modal.Header>
											<Modal.Body className="pt-0">
												<p>Automated corrections for journal style have been made. Click 'Undo' if you wish to remove these changes.</p>					
												<button className="btnOutline w-100" onClick={() => setUndoModal(false)}>Cancel</button>
												{loading.isUndoChanges ? <FieldLoader isLoading={loading.isUndoChanges} className="btnDefault mt-2 w-100" /> :<button className="w-100 btnDefault mt-2" onClick={()=>handleUndoError()}>Undo</button>}
											</Modal.Body>
										</Modal>

										{/* Remiove figure modal */}
										<Modal show={removeFigureModal} size={errors?.details[0]?.figureCaptionList?.length > 0 ? "lg" : "md"}  dialogClassName="" 
											onHide={() => {
												setRemoveFigureModal(false)
												if(ignoreCaptionStrings.length > 0) {
													setIgnoreCaptionChanged(ignoreCaptionChanged+1)
													setIgnoreCaptionStrings([])
													handleValidate(selectedTab)
												}
											}}
										>
											<Modal.Header closeButton className="h6 align-items-center p-3">
												<p className="m-0 p-0">
													{errors?.details[0]?.figureCaptionList?.length > 0 ?
														"Warning!"
														:
														"Remove figures"
													}
												</p>
											</Modal.Header>
											<Modal.Body className="pt-0 ">
												<section className={styles.figureCaptionList}>
													<p>
														{ errors?.details[0]?.figureCaptionList?.length > 0 ?
															<>
																<span>
																	OrthoPublish has detected the following as figure legends. Figure legends should be removed at Step Two and uploaded with figure files in Step Three, and can be removed by clicking on ‘Remove figures/captions/legends’. Click on ‘Ignore Caption’ if it is an explanation of the figure in the body text and should not be removed at this stage.
																</span>
																{/* <p className="mt-2 mb-2">
																	Please note, any unchecked captions will be marked as 'Ignored'. You can review all ignored captions in the 'Ignore Error' dialog.
																</p> */}
															</>
														:
															<span>
																This will remove total of <strong> {errors?.details[0]?.figureCount} </strong>figure(s)
															</span>
														}
													</p>
													<ol>
														{/* {errors?.details[0]?.figureCaptionList?.length > 0 ? 
															<li className="mb-3 ml-0 align-items-center d-flex">
																<input 
																	type="checkbox" 
																	className="mr-3"
																	onChange={(e) => {selectAllCaptions(e)}}
																	value="select all"
																	checked = {selectAllChecked()}
																/> 
																Select All
															</li>
															:
															null
														} */}
														<Table responsive borderless className="m-0 p-0">
															{errors?.details[0]?.figureCaptionList?.map((item, index) => {
																return (
																	<tr>
																		<td width="5%" className="px-0">
																			{/* <input 
																				type="checkbox" 
																				className="mr-3"
																				onChange={(e) => {figureCaptionCheckBoxChange(e)}}
																				checked={isChecked(item)}
																				value={item}
																				disabled={checkCaptionIgnored(item)}
																			/>  */}
																			{index + 1}.
																		</td>
																		<td className="text-left pl-0" >
																			{item}
																		</td>
																		<td width="20%">
																		{!checkCaptionIgnored(item) ? 
																			<button 
																				className="d-flex btn-sm w-auto mt-2 pr-1 btn btn-outline-warning align-items-center ml-auto"
																				onClick={() => {
																					ignoreCaptionButtonClick(item)
																				}}
																			>
																				{ignoreCaptionLoader ? <Spinner  animation="grow" size="sm"/> : "Ignore Caption"}
																			</button>
																		:
																			<span className="d-flex btn-sm w-auto mt-2 pr-1 text-secondary align-items-center ml-auto">
																				Caption ignored
																			</span>
																		}
																		</td>
																	</tr>
																)
															})}
														</Table>
													</ol>
												</section>
												<section className="d-flex justify-content-end align-items-center gap1">
													{/* {!loading.isIgnoreFigure && 
														<button className="btn-sm mt-2 btn btn-outline-secondary mr-auto" onClick={() => {handleIgnoreFigure()}} disabled={loading.isIgnoreFigure || loading.isRemoveFigure}>
															Ignore captions/legends
														</button>
													} */}
													{loading.isIgnoreFigure && <FieldLoader className="btn-sm mt-2 btn btn-outline-secondary mr-auto" isLoading={loading.isRemoveFigure}/>}
													<button className="btnOutline mt-2" onClick={(e) => {
														setRemoveFigureModal(false)
														if (ignoreCaptionStrings.length > 0) {
															setIgnoreCaptionChanged(ignoreCaptionChanged+1)
															setIgnoreCaptionStrings([])
															handleValidate(selectedTab)
														}
													}}>
														Cancel
													</button>
													{!loading.isRemoveFigure && <button onClick={() => {showRemoveFigureDialog()}} className="mt-2" disabled={loading.isIgnoreFigure || loading.isRemoveFigure}>
														Remove figures/captions/legends
													</button>}
													{loading.isRemoveFigure && <FieldLoader className="btn-info btn mt-2 w-200" isLoading={loading.isRemoveFigure}/>} 
												</section>
											</Modal.Body>
										</Modal>

										<Modal show={removeOriganizationModal} size={"lg"}  dialogClassName="" 
											onHide={() => {
												setRemoveOriganizationModal(false)
												if(ignoreOrganizationNames.length > 0) {
													updateValidation("Other")
													setIgnoreOrganizationNames([])
													setIgnoreOrganizationNameChanged(ignoreOrganizationNameChanged+1)
												}
											}}
										>
											<Modal.Header closeButton className="h6 align-items-center p-3">
												<p className="m-0 p-0">
													Blind Organisations
												</p>
											</Modal.Header>
											<Modal.Body className="pt-0 ">
												<section className={styles.figureCaptionList}>
													<p>
														<span>
															OrthoPublish has detected the following as organisation names. Click on ‘Ignore organisation’ if the organisation does not need to be blinded, then click on 'Blind organisation names' to blind the remaining organisations.
														</span>
														<br/><br/>
														<span>
															Organisations listed in Ringgold will be identified in OrthoPublish. Please check your manuscript for any other organisation names that should be blinded before submission.
														</span>
														{/* <p className="mt-2 mb-2">
															Please note, any unchecked captions will be marked as 'Ignored'. You can review all ignored captions in the 'Ignore Error' dialog.
														</p> */}
													</p>
													<ol>
														{/* {errors?.details[errorKeyIndex("blindOriganization")]?.details?.length > 0 ? 
															<li className="mb-3 ml-0 align-items-center d-flex">
																<input 
																	type="checkbox" 
																	className="mr-3"
																	onChange={(e) => {selectAllOrganizationNames(e)}}
																	value="select all"
																	checked = {selectAllOrganizationNamesChecked()}
																/> 
																Select All
															</li>
															:
															null
														} */}
														<Table responsive borderless className="m-0 p-0">
															{errors?.details[errorKeyIndex("blindOriganization")]?.details?.map((item, index) => {
																return (
																	<tr>
																		<td width="5%" className="px-0">
																			{/* <input 
																				type="checkbox" 
																				className="mr-3"
																				onChange={(e) => {organizationNameCheckBoxChange(e)}}
																				checked={isOrganizationNameChecked(item)}
																				value={item}
																				disabled={checkOrganizationNameIgnored(item)}
																			/>  */}
																			{index + 1}.
																		</td>
																		<td className="text-left pl-0" >
																			{item}
																		</td>
																		<td width="25%">
																		{!checkOrganizationNameIgnored(item) ? 
																			<button 
																				className="d-flex btn-sm w-auto mt-2 pr-1 btn btn-outline-warning align-items-center ml-auto"
																				onClick={() => {
																					ignoreOrganizationNameButtonClick(item)
																				}}
																			>
																				Ignore organisation
																			</button>
																		:
																			<span className="d-flex btn-sm w-auto mt-2 pr-1 text-secondary align-items-center ml-auto">
																				Organisation ignored
																			</span>
																		}
																		</td>
																	</tr>
																)
															})}
														</Table>
													</ol>
												</section>
												<section className="d-flex justify-content-end align-items-center gap1">
													{loading.isIgnoreOrganization && <FieldLoader className="btn-sm mt-2 btn btn-outline-secondary mr-auto" isLoading={loading.isRemoveOrganization}/>}
													<button className="btnOutline mt-2" 
														onClick={(e) => {
															setRemoveOriganizationModal(false)
															if (ignoreOrganizationNames.length > 0) {
																updateValidation("Other")
																setIgnoreOrganizationNames([])
																setIgnoreOrganizationNameChanged(setIgnoreOrganizationNameChanged+1)
															}
														}}>
															Cancel
														</button>
													{!loading.isRemoveOrganization && <button onClick={() => {handleRemoveOriganizationClick()}} className="mt-2" disabled={loading.isIgnoreOrganization || loading.isRemoveOrganization}>
														Blind Organisation Names
													</button>}
													{loading.isRemoveOrganization && <FieldLoader className="btn-info btn mt-2 w-200" isLoading={loading.isRemoveOrganization}/>} 
												</section>
											</Modal.Body>
										</Modal>

										<Modal show={editorChangeModal.state} onHide={() => {setEditorChangeModal({state: false, trigger: false})}} size="md">
											<Modal.Header className="h6" closeButton>
												Unsaved changes found!
											</Modal.Header>
											<Modal.Body>
												<p>There are unsaved changes in the manuscript. Do you want to save them before {editorChangeModal.trigger === "removeFigure" ? "removing figures/captions/legends" : "blinding organisation"}?</p>
												<span className="text-secondary">If you don't save the changes they will be removed {editorChangeModal.trigger === "removeFigure" ? "with figures/captions/legends" : "while blinding organisation"}.</span><br/>
												<button className="btnOutline mt-2 w-100"
													onClick={() => {
														setEditorChanged(true)
														if(editorChangeModal.trigger === "removeFigure") {
															setRemoveFigureModal(true)
														} else if (editorChangeModal.trigger === "blindOrganization") {
															setRemoveOriganizationModal(true)
														}
														setEditorChangeModal({state: false, trigger: false})
													}}
												>
													Cancel{editorChangeModal.trigger === "removeFigure" ? " & Remove Figures/Caption/Legends" : " & Blind Organisation"} 
												</button>
												<button className="my-2 w-100"
													onClick={() => {
														handleSave(selectedTab)
														setEditorChanged(false)
														setEditorChangeModal({state: false, trigger: false})
													}}
												>
													Save changes
												</button>
											</Modal.Body>
										</Modal>
									</>
								}
							</tbody>
						// </Tab>
					// :
					// 	null
					// }
					// {errors?.passList?.length > 0 ?
					// 	<Tab id="tab-Validations" eventKey='validations' title={errors?.passList?.length > 1 ? errors?.passList?.length + ' Validations passed!' : errors?.passList?.length + ' Validation passed!'} tabClassName={activeTab === "validations" ? styles.customActiveTab : styles.customTab}>
					// 		<tbody>
					// 			{errors?.passList?.map((passList, index) => 
					// 				<tr index={"passList" + index}>
					// 					<td>
					// 						<h6>{passList.field}</h6>
					// 						<p className="mb-0">
					// 							<span className={"font-weight-bold text-success"}>Verified: </span>
					// 							<span dangerouslySetInnerHTML={{ __html: `${passList?.description}` }}></span>
					// 						</p>
					// 					</td>
					// 				</tr>
					// 			)}
					// 		</tbody>
					// 	</Tab>
					// :
					// 	null
					// }
				// </Tabs>
			} 
		</Table>
		
		{/* Expanded view modal */}
		<Modal size="lg" show={errorWindowControl.maximize} onHide={() => {setErrorWindowControl({maximize: false, loading: false})}} dialogClassName="modal-dialog-scrollable">
			<Modal.Header closeButton className="h5">
				{errors?.header ? errors?.header: "Validation errors and warnings"} 
				{ errors?.details ? ` (${errors?.details?.length})`: ""}
			</Modal.Header>
			<Modal.Body>
				<Table responsive className="text-break border rounded">
					<tbody className="">
						{typeof errors ==="string" ?
							<tr>
								<td><p>{errors}</p></td>
							</tr>
						:
							<>
								{errors?.details?.map((err, index) => 
									<tr key={index}>	
										<td>
											<h6>{err.field}</h6>
											{err.dataType ==="Array"?
												<ErrorItem err={err} index={index} fetchData={fetchData} setErrorMessage={setErrorMessage} isSaveValidateLoading={isSaveValidateLoading} header={errors?.header} errorWindowControl={errorWindowControl} setErrorWindowControl={setErrorWindowControl}>
													<GoToErrorSection err={err} apiService={apiService} setShowToolbar={setShowToolbar} errorWindowControl={errorWindowControl} setErrorWindowControl={setErrorWindowControl}/>
												</ErrorItem>
											: 
												err?.newText ? 
													<p className="mb-0">
														<p>
															<span className={err.type==="error"?"text-danger text-capitalize font-weight-bold":"text-warning text-capitalize font-weight-bold"}>Error: </span>
															<span dangerouslySetInnerHTML={{ __html: `${err?.oldText}` }}></span>
														</p>
														<p className="mb-0">
															<span className={"font-weight-bold text-success"}>Corrected text: </span>
															<span dangerouslySetInnerHTML={{ __html: `${err?.newText}` }}></span>
														</p>
														{err?.errorDetails &&  
															<>
																<p className="my-2 linkText cursor-pointer p-0" onClick={(e) => {handleViewDetailClick(err)}}>
																	View details <FontAwesomeIcon icon={!viewDetailsVisible ? faAngleDoubleDown: faAngleDoubleUp}/>
																</p>
																<Collapse key={index} in={viewDetailsVisible} className="multi-collapse">
																	<div>
																		{err.errorDetails.map((discription, discriptionIndex) => {
																			return (
																				<>
																					<p>
																						<h6 className="">Reference {discription.referenceNo}</h6>
																						<span className="text-danger mb-1">Incorrect Reference</span><br/>
																						<span className="text-secondary mb-2">{discription.reference}</span><br/>
																						<span className="text-success mb-1">Corrected Reference</span><br/>
																						<span className="text-secondary mb-2">{discription.updatedReference}</span><br/>
																					</p>
																				</>
																			)
																		})}
																	</div>
																</Collapse>
															</>
														}
													</p>
												:
													<p className="mb-0">
														<span className={err.type==="error"?"text-danger text-capitalize font-weight-bold":"text-warning text-capitalize font-weight-bold"}>{err.type}: </span>
														<span dangerouslySetInnerHTML={{ __html: `${err.description}` }}></span>
													</p>
											}
											<section className="d-flex align-items-center justify-content-evenly gap1">
												{err?.autoCorrect && errors?.header !=="Autocorrections" ? loading?.isAutoCorrect ? <FieldLoader className="btn-sm w-auto mt-2 btnOutline" isLoading={loading.isAutoCorrect} /> :<button className="btn-sm btnOutline w-auto mt-2 mb-2" onClick={()=> handleAutoCorrect(err, errors?.header)} disabled={isSaveValidateLoading}>Auto correct error/s</button> :""}
												{err?.id && ( err?.errorKey !== "referencesNotReferred" && err?.errorKey !== "referenceNotFoundForCitations" && err?.errorKey !== "tablesMissingForCitation")? <GoToErrorSection err={err} apiService={apiService} setShowToolbar={setShowToolbar} isSaveValidateLoading={isSaveValidateLoading} errorWindowControl={errorWindowControl} setErrorWindowControl={setErrorWindowControl}/>: ""}
												{err.errorKey ==="figureNotAllowed" ? 
													loading.isRemoveFigure ? 
														<FieldLoader className="btn-sm btnDefault mt-2" isLoading={loading.isRemoveFigure} message="Removing" /> 
													: 
														<>
															<button 
																className={classNames(styles.removeFigureBtn, "btn-sm btnDefault mt-2")} 
																onClick={()=> {
																	if(editorChanged) {
																		setEditorChangeModal({state: true, trigger: "removeFigure"})
																	} else {
																		setRemoveFigureModal(true)
																	}
																}}  
																disabled={isSaveValidateLoading}>
																Remove figures/captions/legends
															</button>
														</>
												: 
													""
												}
												{err.errorKey ==="blindOriganization" ? 
													loading.isRemoveFigure ? 
														<FieldLoader className="btn-sm btnDefault mt-2" isLoading={loading.isRemoveFigure} message="Removing" /> 
													: 
														<>
															<button 
																className={classNames(styles.removeFigureBtn, "btn-sm btnDefault mt-2")} 
																onClick={()=> {
																	if(editorChanged) {
																		setEditorChangeModal({state: true, trigger: "blindOrganization"})
																	} else {
																		setRemoveOriganizationModal(true)
																	}
																}}  
																disabled={isSaveValidateLoading}>
																Select organisations to blind
															</button>
														</>
												: 
													""
												}
												{ (selectedTab === 2 || selectedTab === 3) && !err?.newText ?
													<>
														{err.errorKey === "figureNotAllowed" ? 
															""
														:
															<IgnoreError 
																error={err} 
																manuscriptId={manuscriptId} 
																tab={selectedTab} 
																setIgnoreErrorChanged={setIgnoreErrorChanged} 
																ignoreErrorChanged={ignoreErrorChanged}
																ignoredErrorList = {ignoredErrorList}
																updateValidation = {updateValidation}
															/>
														}
													</> 
												:
													"" 
												}
											</section>
										</td>
									</tr>
								)}

								{errors?.isAutoCorrectError && errors?.header ==="Autocorrections"  ? 
									loading.isCorrectError ? <FieldLoader isLoading={loading.isCorrectError} className="btn-sm btnDefault w-auto mt-2 ml-3" /> :<button className="btn-sm btnDefault w-auto mt-2 ml-2 mb-2" onClick={()=>handleAutoCorrectError()} disabled={isSaveValidateLoading}>Auto correct error/s</button>
								: 
									errors?.header ==="Autocorrections" && !errors?.isAutoCorrectError ?
										<button className="btn-sm w-auto my-2 ml-2 secondary-text-button align-items-center" onClick={()=>setUndoModal(true)}>Undo</button>
									: 
										""
								}

								{/* {errors?.passList ? 
									errors?.passList?.map((passList, index) => 
										<tr index={"passList" + index}>
											<td>
												<h6>{passList.field}</h6>
												<p className="mb-0">
													<span className={"font-weight-bold text-success"}>Verified: </span>
													<span dangerouslySetInnerHTML={{ __html: `${passList?.description}` }}></span>
												</p>
											</td>
										</tr>
									)
								:
									null
								} */}
								
								<Modal show={undoModal} onHide={() => setUndoModal(false)} dialogClassName="">
									<Modal.Header className="h6">Warning!</Modal.Header>
									<Modal.Body className="pt-0">
										<p>Automated corrections for journal style have been made. Click 'Undo' if you wish to remove these changes.</p>					
										<button className="btnOutline w-100" onClick={() => setUndoModal(false)}>Cancel</button>
										{loading.isUndoChanges ? <FieldLoader isLoading={loading.isUndoChanges} className="btnDefault mt-2 w-100" /> :<button className="w-100 btnDefault mt-2" onClick={()=>handleUndoError()}>Undo</button>}
									</Modal.Body>
								</Modal>

								{/* Remiove figure modal */}
								<Modal show={removeFigureModal} size={errors?.details[0]?.figureCaptionList?.length > 0 ? "lg" : "md"}  dialogClassName="" 
									onHide={() => {
										setRemoveFigureModal(false)
										if(ignoreCaptionStrings.length > 0) {
											setIgnoreCaptionChanged(ignoreCaptionChanged+1)
											setIgnoreCaptionStrings([])
											handleValidate(selectedTab)
										}
									}}
								>
									<Modal.Header closeButton className="h6 align-items-center p-3">
										<p className="m-0 p-0">
											{errors?.details[0]?.figureCaptionList?.length > 0 ?
												"Warning!"
												:
												"Remove figures"
											}
										</p>
									</Modal.Header>
									<Modal.Body className="pt-0 ">
										<section className={styles.figureCaptionList}>
											<p>
												{ errors?.details[0]?.figureCaptionList?.length > 0 ?
													<>
														<span>
															OrthoPublish has detected the following as figure legends. Figure legends should be removed at Step Two and uploaded with figure files in Step Three, and can be removed by clicking on ‘Remove figures/captions/legends’. Click on ‘Ignore Caption’ if it is an explanation of the figure in the body text and should not be removed at this stage.
														</span>
														{/* <p className="mt-2 mb-2">
															Please note, any unchecked captions will be marked as 'Ignored'. You can review all ignored captions in the 'Ignore Error' dialog.
														</p> */}
													</>
												:
													<span>
														This will remove total of <strong> {errors?.details[0]?.figureCount} </strong>figure(s)
													</span>
												}
											</p>
											<ol>
												{/* {errors?.details[0]?.figureCaptionList?.length > 0 ? 
													<li className="mb-3 ml-0 align-items-center d-flex">
														<input 
															type="checkbox" 
															className="mr-3"
															onChange={(e) => {selectAllCaptions(e)}}
															value="select all"
															checked = {selectAllChecked()}
														/> 
														Select All
													</li>
													:
													null
												} */}
												<Table responsive borderless className="m-0 p-0">
													{errors?.details[0]?.figureCaptionList?.map((item, index) => {
														return (
															<tr>
																<td width="5%" className="px-0">
																	{/* <input 
																		type="checkbox" 
																		className="mr-3"
																		onChange={(e) => {figureCaptionCheckBoxChange(e)}}
																		checked={isChecked(item)}
																		value={item}
																		disabled={checkCaptionIgnored(item)}
																	/>  */}
																	{index + 1}.
																</td>
																<td className="text-left pl-0" >
																	{item}
																</td>
																<td width="20%">
																{!checkCaptionIgnored(item) ? 
																	<button 
																		className="d-flex btn-sm w-auto mt-2 pr-1 btn btn-outline-warning align-items-center ml-auto"
																		onClick={() => {
																			ignoreCaptionButtonClick(item)
																		}}
																	>
																		{ignoreCaptionLoader ? <Spinner  animation="grow" size="sm"/> : "Ignore Caption"}
																	</button>
																:
																	<span className="d-flex btn-sm w-auto mt-2 pr-1 text-secondary align-items-center ml-auto">
																		Caption ignored
																	</span>
																}
																</td>
															</tr>
														)
													})}
												</Table>
											</ol>
										</section>
										<section className="d-flex justify-content-end align-items-center gap1">
											{/* {!loading.isIgnoreFigure && 
												<button className="btn-sm mt-2 btn btn-outline-secondary mr-auto" onClick={() => {handleIgnoreFigure()}} disabled={loading.isIgnoreFigure || loading.isRemoveFigure}>
													Ignore captions/legends
												</button>
											} */}
											{loading.isIgnoreFigure && <FieldLoader className="btn-sm mt-2 btn btn-outline-secondary mr-auto" isLoading={loading.isRemoveFigure}/>}
											<button className="btnOutline mt-2" onClick={(e) => {
												setRemoveFigureModal(false)
												if (ignoreCaptionStrings.length > 0) {
													setIgnoreCaptionChanged(ignoreCaptionChanged+1)
													setIgnoreCaptionStrings([])
													handleValidate(selectedTab)
												}
											}}>
												Cancel
											</button>
											{!loading.isRemoveFigure && <button onClick={() => {showRemoveFigureDialog()}} className="mt-2" disabled={loading.isIgnoreFigure || loading.isRemoveFigure}>
												Remove figures/captions/legends
											</button>}
											{loading.isRemoveFigure && <FieldLoader className="btn-info btn mt-2 w-200" isLoading={loading.isRemoveFigure}/>} 
										</section>
									</Modal.Body>
								</Modal>

								<Modal show={removeOriganizationModal} size={"lg"}  dialogClassName="" 
									onHide={() => {
										setRemoveOriganizationModal(false)
										if(ignoreOrganizationNames.length > 0) {
											updateValidation("Other")
											setIgnoreOrganizationNames([])
											setIgnoreOrganizationNameChanged(ignoreOrganizationNameChanged+1)
										}
									}}
								>
									<Modal.Header closeButton className="h6 align-items-center p-3">
										<p className="m-0 p-0">
											Blind Organisations
										</p>
									</Modal.Header>
									<Modal.Body className="pt-0 ">
										<section className={styles.figureCaptionList}>
											<p>
												<span>
													OrthoPublish has detected the following as organisation names. Click on ‘Ignore organisation’ if the organisation does not need to be blinded, then click on 'Blind organisation names' to blind the remaining organisations.
												</span>
												<br/><br/>
												<span>
													Organisations listed in Ringgold will be identified in OrthoPublish. Please check your manuscript for any other organisation names that should be blinded before submission.
												</span>
												{/* <p className="mt-2 mb-2">
													Please note, any unchecked captions will be marked as 'Ignored'. You can review all ignored captions in the 'Ignore Error' dialog.
												</p> */}
											</p>
											<ol>
												{/* {errors?.details[errorKeyIndex("blindOriganization")]?.details?.length > 0 ? 
													<li className="mb-3 ml-0 align-items-center d-flex">
														<input 
															type="checkbox" 
															className="mr-3"
															onChange={(e) => {selectAllOrganizationNames(e)}}
															value="select all"
															checked = {selectAllOrganizationNamesChecked()}
														/> 
														Select All
													</li>
													:
													null
												} */}
												<Table responsive borderless className="m-0 p-0">
													{errors?.details[errorKeyIndex("blindOriganization")]?.details?.map((item, index) => {
														return (
															<tr>
																<td width="5%" className="px-0">
																	{/* <input 
																		type="checkbox" 
																		className="mr-3"
																		onChange={(e) => {organizationNameCheckBoxChange(e)}}
																		checked={isOrganizationNameChecked(item)}
																		value={item}
																		disabled={checkOrganizationNameIgnored(item)}
																	/>  */}
																	{index + 1}.
																</td>
																<td className="text-left pl-0" >
																	{item}
																</td>
																<td width="25%">
																{!checkOrganizationNameIgnored(item) ? 
																	<button 
																		className="d-flex btn-sm w-auto mt-2 pr-1 btn btn-outline-warning align-items-center ml-auto"
																		onClick={() => {
																			ignoreOrganizationNameButtonClick(item)
																		}}
																	>
																		Ignore organisation
																	</button>
																:
																	<span className="d-flex btn-sm w-auto mt-2 pr-1 text-secondary align-items-center ml-auto">
																		Organisation ignored
																	</span>
																}
																</td>
															</tr>
														)
													})}
												</Table>
											</ol>
										</section>
										<section className="d-flex justify-content-end align-items-center gap1">
											{loading.isIgnoreOrganization && <FieldLoader className="btn-sm mt-2 btn btn-outline-secondary mr-auto" isLoading={loading.isRemoveOrganization}/>}
											<button className="btnOutline mt-2" 
												onClick={(e) => {
													setRemoveOriganizationModal(false)
													if (ignoreOrganizationNames.length > 0) {
														updateValidation("Other")
														setIgnoreOrganizationNames([])
														setIgnoreOrganizationNameChanged(setIgnoreOrganizationNameChanged+1)
													}
												}}>
													Cancel
												</button>
											{!loading.isRemoveOrganization && <button onClick={() => {handleRemoveOriganizationClick()}} className="mt-2" disabled={loading.isIgnoreOrganization || loading.isRemoveOrganization}>
												Blind Organisation Names
											</button>}
											{loading.isRemoveOrganization && <FieldLoader className="btn-info btn mt-2 w-200" isLoading={loading.isRemoveOrganization}/>} 
										</section>
									</Modal.Body>
								</Modal>
							</>
						}
					</tbody>
				</Table>
				<div className="w-100 d-flex flex-row justify-content-end">
					<button className="px-5" onClick={() => {setErrorWindowControl({maximize: false, loading: false})}}>Close</button>
				</div>
			</Modal.Body>
		</Modal>
	</div>	
	)
}

export const GoToErrorSection = ({err, setShowToolbar, isSaveValidateLoading, text, errorWindowControl, setErrorWindowControl }) => {
	const [isLoading, setLoading] = useState(false)

	function setEndOfContenteditable(contentEditableElement) {
		var range,selection;
		if(document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
		{
			range = document.createRange();//Create a range (a range is a like the selection but invisible)
			range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
			range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
			selection = window.getSelection();//get the selection object (allows you to change selection)
			selection.removeAllRanges();//remove any selections already made
			selection.addRange(range);//make the range you have just created the visible selection
		}
		else if(document.selection)//IE 8 and lower
		{ 
			range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
			range.moveToElementText(contentEditableElement);//Select the entire contents of the element with the range
			range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
			range.select();//Select the range (make it the visible selection
		}
	}

	function gotoError(err) {
		if(err) {
			// setLoading(true)
			const violation = document.querySelector(`span[data-entity-reference="${err?.id}"]`);
			// setShowToolbar(false)
			// violation?.scrollIntoView()
			// setTimeout(() => {
			// 	setLoading(false)
			// }, 300);

			setTimeout(() => {
				window.document.querySelector(".ProseMirror.remirror-editor").scrollIntoView({ block: "start"})
				// violation?.scrollIntoView({
				// 	block: 'center',
				// })
				scrollIntoViewPolyfill(violation.children[0])
				setEndOfContenteditable(violation.children[0])
			}, 10);
			// setTimeout(() => {
			// 	setLoading(false)
			// }, 300);
		}
		if(errorWindowControl?.maximize) {
			setErrorWindowControl({maximize: false, loading: false})
		}
	}

	function scrollIntoViewPolyfill(element, options = { block: 'center', behavior: 'smooth' }) {
		// Get the element’s position relative to the viewport
		const rect = element.getBoundingClientRect();
		const absoluteElementTop = rect.top + window.pageYOffset;
	
		let offset = 0;
		if (options.block === 'center') {
			offset = window.innerHeight / 2 - element.clientHeight / 2;
		} else if (options.block === 'end') {
			offset = window.innerHeight - element.clientHeight;
		}
	
		const targetPosition = absoluteElementTop - offset;
	
		// Animate scroll
		window.scrollTo({
			top: targetPosition,
			behavior: options.behavior
		});
	}

	return(
		isLoading ? 
			<FieldLoader isLoading={isLoading} className="btn-sm btnOutline w-auto mt-2" />
			:
			<button 
				className="d-flex btn-sm w-auto mt-2 pr-1 text-right secondary-text-button align-items-center" 
				id={err.id} 
				onClick={()=>gotoError(err)} 
				disabled={isSaveValidateLoading}
			>
				<span className="mr-2">{text ? text : "Go to error section"}</span>
				<FontAwesomeIcon icon={faChevronRight}/>
			</button>
	)
}

export const IgnoreError = ({error, manuscriptId, ignoreErrorChanged, 
	setIgnoreErrorChanged, ignoredErrorList, btnText, updateValidation
}) => {
	const [loading, setLoading] = useState(false)
	const [errorIgnored, setErrorIgnored] = useState(false)
	const [ignoreText, setIgnoreText] = useState("")
	
	const apiService = new AxiosService()

	function ignoreError(errorType, errorKey, errorDescription) {
		if (errorKey !== "figureNotAllowed") {
			const data = {
				errorKey: errorKey,
				errorType: errorType,
				description: errorDescription
			}
			setLoading(true)
			apiService.ignoreErrors(manuscriptId, data).then((res) => {
				setIgnoreText(res.result)
				toast.success(res.result, {autoClose: 3000})
				setIgnoreErrorChanged(ignoreErrorChanged+1)
				setErrorIgnored(true)
				setLoading(false)
			}, (err) => {
				console.log("Error in errorIgnore: ", err.response)
				setLoading(false)
				toast.error(err?.response?.data?.result, {autoClose: 3000})
			})	
		} else if (errorKey === "figureNotAllowed") {
			const body = {figureCaptions: [errorDescription]}
			setLoading(true)
			apiService.ignoreFigureCaption(manuscriptId, body).then((res) => {
				updateValidation("Figure")
				toast.success(res.result, {autoClose: 3000})
				setIgnoreErrorChanged(ignoreErrorChanged+1)
				setErrorIgnored(true)
				setLoading(false)
				setIgnoreText("Caption ignored")
			}, (err) => {
				console.log("Error in ignoreCaption: ", err.response)
				setLoading(false)
				toast.error(err?.response?.data?.result, {autoClose: 3000})
			})
		}
	}
	useEffect(() => {
		if(error.errorKey !== "figureNotAllowed") {
			ignoredErrorList?.map((item) => {
				if(item.errorKey === error.errorKey) {
					setErrorIgnored(true)
					setIgnoreText("Error/Warning ignored.")
				} 
			})
		} else if (error.errorKey === "figureNotAllowed") {
			ignoredErrorList?.map((item) => {
				if (item === error.description) {
					setErrorIgnored(true)
					setIgnoreText("Caption ignored")
				}
			})
		}
		}, [ignoredErrorList])	
	return (
		<>
			{ !errorIgnored || error.errorKey === "figureNotAllowed"?

				<button 
					className="d-flex btn-sm w-auto mt-2 pr-1 btn btn-outline-warning align-items-center ml-auto"
					onClick={() => {
						ignoreError(error.type, error.errorKey, error.description)
					}}
					disabled={(loading || errorIgnored) && (error.errorKey !== "figureNotAllowed" || loading)? true : false}
				>
					{btnText ? btnText : "Ignore Error/Warning"}
				</button>
			:
				<span className="d-flex btn-sm w-auto mt-2 pr-1 text-secondary align-items-center ml-auto">
					{ignoreText}
				</span>
			}
		</>
	)
}

export default ValidationErrorTable;