import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import styles from "./StepThreeForm.module.scss";
import stepTwoStyles from  "../StepTwoForm/StepTwoForm.module.scss";
import AxiosService from "../../../../../utils/AxiosService";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import AsyncSelect from 'react-select/async';

import classNames from "classnames";
import { CircleLoader, FieldLoader } from "../../../../../components/Loader/CommonLoader";
import debounce from "lodash.debounce";
import CustomeAutoComplete from "../../../../../components/CustomeAutoComplete/CustomeAutoComplete";
import TooltipButton from "../../../../../components/TooltipButton/TooltipButton";
import { faAdd, faPlus, faRedo, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToWords } from "to-words";
import { useSelector } from "react-redux";

const StepThreeForm = ({manuscriptId, handelError, journalId, mandatoryFieldSet, handleMandatoryCheck}) => {
	const [keyword, setKeyword] = useState("");
	const [keywordList, setKeywordList]= useState([])
	const apiService = new AxiosService()
	const [keywordOptions, setKeywordOptions] = useState([]);
	const [force, setForce] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedKeyword, setSelectedKeyword] = useState({})
	const [loader, setLoader] = useState({isAddBtn: false, isResetbtn: false})
	const [isKeywordLoading, setIsKeywordLoading]= useState(false);
	const [refreshKeywordModalControl, setRefreshKeywordModalControl] = useState({visible: false})
	const toWords = new ToWords({localeCode: "en-GB"})
	const journalConfig = useSelector(store => store.journalConfig.config)

	const getKeywordList = () => {
		setLoading(true);
		apiService.getKeywords(manuscriptId).then(res=> {
			setLoading(false);
			setKeywordList(res);
			handelError();
		}).catch(e=> {
			toast.error(e?.response?.data?.message, {autoClose: 3000})
			setLoading(false)
		})
	}

	const handleDeleteKeyword = (keyword) => {
		apiService.deleteKeyWord(manuscriptId, keyword.cid).then(res=>{
			toast.success(res.result,  {
				autoClose: 3000,
			});
			getKeywordList();
		})
	}

	const repopulateKeywords = () => {
		setForce(true);
		let body = {
			"force": true
		}
		setLoading(true);
		setLoader({...loader, isResetbtn: true})
		apiService.repopulateKeywords(manuscriptId, body).then(res => {
			setLoader({...loader, isResetbtn: false})
			setLoading(false);
			getKeywordList();
			toast.success(res.result,  {
				autoClose: 3000,
			});
			setRefreshKeywordModalControl({...refreshKeywordModalControl, visible: true})
		}).catch(err => {
			setLoader({...loader, isResetbtn: false})
			setLoading(false);
			toast.error(err?.response?.data?.message, {autoClose: 3000})
		})

	}

	// custome auto complete with free text
	const handleChange =(e)=> {
		// console.log("journal config: ", journalConfig)
		let input = e.target.value
		setKeyword(input);		
	}
	const handleSelect = (selectedValue) => {
		setKeyword(selectedValue.name)
		setSelectedKeyword(selectedValue)
	}

	const addKeyword = (argsKeyword) => {
		if (mandatoryFieldSet?.keywords[0]?.limit === undefined || keywordList?.result?.length < mandatoryFieldSet?.keywords[0]?.limit) {
			let data = {}
			if(argsKeyword) {
				data = argsKeyword	
			} else {
				data = selectedKeyword.cid ? selectedKeyword: {
					cid: uuidv4(),
					name: keyword?.trim()
				}
			}

			if(data.cid && data.name){
				setLoader({...loader, isAddBtn: true})
				apiService.addKeyword(manuscriptId, data).then(res=> {
					setLoader({...loader, isAddBtn: false});
					toast.success(res.result,  {
						autoClose: 3000,
					})
					getKeywordList();
				}).catch(e=> {
					setLoader({...loader, isAddBtn: false});
					if(e.response.status === 500) { toast.error(e.response.data.message, {autoClose: 3000})}
					else if(e.response.status === 409) { toast.error(e.response.data.message, {autoClose: 3000})}
					else {
						toast.error(e?.response?.data?.message, {autoClose: 3000})
					}
				})
				setSelectedKeyword({})
				setKeyword("")
			}else {
				toast.error("Please enter a keyword.")
			}
		} else {
			toast.error(`The maximum number of keywords (${toWords.convert(mandatoryFieldSet?.keywords[0]?.limit).toLowerCase()} allowed) has been added: ${toWords.convert(keywordList?.result?.length).toLowerCase()}`)
		}
	}

	function getKewords(searchValue) {
		setIsKeywordLoading(true);
		apiService.suggestKeywords({
			"name": searchValue,
			"searchType": " "
		}).then(res=> {
			setIsKeywordLoading(false)
			setKeywordOptions(res.result);
			// console.log(res.result)
		}).catch(e=> {
			setIsKeywordLoading(false)
			toast.error(e?.response?.data?.message, {autoClose: 3000})
		})
	  }
	const getData = () => {
		apiService.getStepOneDetails(manuscriptId).then(
			res => { 
				const data= res.result;
				handleMandatoryCheck(data?.manuscriptType)
			}).catch(e=> {
				toast.error(e?.response?.data?.message, {autoClose: 3000})
			})
	}

	const isKeywordSelected = (keyword) => {
		let keywordExists = false
		keywordList?.result?.map((item) => {
			if(keyword.name === item.name && keyword.cid === item.cid) {
				keywordExists = true
			}
		})
		return keywordExists
	}

	const getSuggestedKeywordsCount = () => {
		let delta = 0
		let tempKeywordArr = []
		keywordList?.result?.map((item) => {
			tempKeywordArr.push(item.cid)
		})
		keywordList?.suggestedKeywords?.map((item) => {
			if (tempKeywordArr?.includes(item.cid)) {
				delta += 1
			}
		}) 
		return keywordList?.suggestedKeywords?.length - delta
	}

	const checkAllKeywordsAdded = () => {
		let count = 0
		keywordList?.result?.map((addedKeyword) => {
			keywordList?.suggestedKeywords?.map((keyword) => {
				if(addedKeyword.cid === keyword.cid && addedKeyword.name === keyword.name) {
					count += 1
				}
			})
		})
		if(count === keywordList?.suggestedKeywords?.length && keywordList?.suggestedKeywords?.length > 0) {
			return true
		} else {
			return false
		}
	}

	useEffect(()=>{
		getKeywordList();
		getData()
	}, [])

		return (
			<div className="mb-5">
				<section className="">
					<h5>Select keywords</h5>
					<p className="">Choose from the suggested keywords or enter your own, then click 'Add keyword' to confirm.</p>
				</section>
				<Row className={classNames("my-3", styles.mainSection)}>
					<Col md={9} className={classNames("pr-0 mb-1 ", styles.searchBar)}>
						<label className={styles.labelText}>
							Enter your own keywords {mandatoryFieldSet?.keywords[0]?.limit && `(maximum ${toWords.convert(mandatoryFieldSet?.keywords[0]?.limit).toLowerCase()} allowed)`} 
						</label>
						<CustomeAutoComplete
							name ={'keyword'}
							placeholder='Enter keyword'
							searchValue={keyword} 
							setSearchValue={handleChange} 
							handleSelect={handleSelect}
							optionList={keywordOptions}
							getOptions={getKewords}
							loading={isKeywordLoading}
							component="keyword"
						/>
					</Col>
					<Col md={3} className={classNames("d-flex flex-row align-items-end mb-1",styles.actionBtn)}>
						{loader.isAddBtn ? 
							<FieldLoader className="ml-0" isLoading={loader.isAddBtn} />
						:
							<TooltipButton tooltipMessage="Add keyword" placement="top">
								<button 
									className="ml-0 w-100" 
									onClick={()=> addKeyword()}
									disabled={keyword.length < 3}
								>
									Add keyword 
								</button>
							</TooltipButton>
						}
					</Col>
				</Row>
				<Row className={classNames(styles.keywordBlock, "mx-0 mb-3 p-3 border rounded")}>
					{loading ?
						<p className="text-center w-100"><CircleLoader /> </p>
					:
						<div className="w-100">
							<div className="d-flex flex-row align-items-center justify-content-between border-bottom pb-3">
								<h6 className="text-secondary m-0">Selected keywords ({keywordList?.result?.length})</h6>
							</div>
							<div className="py-4 d-flex flex-row flex-wrap">
								{keywordList?.result?.length > 0 ? 
									keywordList?.result?.map(keyword=>( 
										<div key={keyword.cid} className={classNames(styles.keywordSpan, "d-flex flex-row align-items-center py-1")}>
											<TooltipButton tooltipMessage={keyword.name}>
												<div className="text-truncate pb-1">
													<span>{keyword.name}</span> 
												</div>
											</TooltipButton>
											<button className={classNames(styles.btn, "py-0 px-1 ml-2")} onClick={() => handleDeleteKeyword(keyword)}>
												<FontAwesomeIcon icon={faTrash}/>
											</button>
										</div>
									))
								:
									<span>No keywords have been added yet. To add keywords, you can use the field above or choose from the suggested keywords section below.</span>
								}
							</div>
							<div className="d-flex flex-row align-items-center justify-content-between border-bottom pb-3">
								<h6 className="text-secondary m-0">Suggested keywords ({getSuggestedKeywordsCount()})</h6>
								{/* {loader.isResetbtn? <FieldLoader className={styles.borderlessButton} isLoading={loader.isResetbtn} />: <TooltipButton tooltipMessage="Keywords will be fetched from the manuscript title and abstract." placement="top"><button className={styles.borderlessButton} onClick={repopulateKeywords}>Refresh suggested keywords</button></TooltipButton>} */}
							</div>
							<div className="py-4 d-flex flex-row flex-wrap">
								{keywordList?.suggestedKeywords?.length > 0 ? 
									keywordList?.suggestedKeywords?.map(keyword=>{
										if(!isKeywordSelected(keyword)) {
											return (
												<div key={keyword.cid} className={classNames(styles.keywordSpan, "d-flex flex-row align-items-center py-1")}>
													<TooltipButton tooltipMessage={keyword.name}>
														<div className="text-truncate pb-1">
															<span>{keyword.name}</span> 
														</div>
													</TooltipButton>
													<button className={classNames("btn btn-outline-success border-0 py-0 px-1 ml-2")} onClick={()=> {addKeyword(keyword)}}>
														<FontAwesomeIcon icon={faAdd}/>
													</button>
												</div>
											)
										} 
									})
								:
									<span>No suggested keywords found. Feel free to add keywords using the field above.</span>
								}
								{checkAllKeywordsAdded() ? 
									<span>All suggested keywords have been added. Expand your list by using the field above to add additional keywords.</span>
								:
									""
								}
							</div>
						</div>
					}
				</Row>

				<Modal show={refreshKeywordModalControl.visible}>
					<Modal.Header className="h6" closeButton onHide={() => {setRefreshKeywordModalControl({...refreshKeywordModalControl, visible: false})}}>
						Note!
					</Modal.Header>
					<Modal.Body>
						Keywords have been successfully fetched from the manuscript title and abstract.
					</Modal.Body>
					<Modal.Footer>
						<button onClick={() => {setRefreshKeywordModalControl({...refreshKeywordModalControl, visible: false})}}>Close</button>
					</Modal.Footer>
				</Modal>
			</div>
		);
}

export default StepThreeForm;