import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import styles from  "./StepTwoForm.module.scss";
import { Col, Form, FormControl, Modal, Row, Tab, Table, Tabs } from "react-bootstrap";
import manuscriptStyles from "../../MainContent/ManuscriptSubmission.module.scss";
import classNames from "classnames";
import AxiosService from "../../../../../utils/AxiosService";
import { toast } from "react-toastify";
import { CircleLoader, FieldLoader } from "../../../../../components/Loader/CommonLoader";
import fileDownload from "js-file-download";
import GuidelineTab from "./guidelineTab";
import TableItem from "./TableItem";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom";


const StepTwoForm = ({manuscriptId, handelError, journalId, resetError}) => {

	const apiService = new AxiosService();
	const [fileDesignation, setFileDesignation] = useState("");
	const [citationText, setCitationtext] = useState("")
	const [options, setOptions] = useState([]);
	const [fileList, setFileList] = useState([]);
	const [tableLoading, setTableLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [removeBtnLoading, setRemoveBtnLoading] = useState(false);
	const [selectedFile, setSelectedFile] = useState();
	const [formError, setFormError]= useState({fileDesignationError: "", fileDescriptionError: "", referenceTypeError:"", fileError:"", allowedFileError: "", citationTextError: ""})
	const [helpText, setHelpText]=useState([]);
	const [show, setShow] = useState(false);
	const [superscriptModal , setSuperscriptModal] = useState(false)
	const [superscriptData, setSuperscriptData] = useState([])
	const [myFiles, setMyFiles] = useState([]);
	const [figureCaptionList, setFigureCaptionList] = useState([])
	const [figureCaptionLoading, setFigureCaptionLoading] = useState(false)
	const [viewDetailInstruction, setViewDetailInstruction] = useState(false)
	const [deleteFileIds, setDeleteFileIds] = useState([])
	const [deleteSelectedFileLoader, setDeleteSelectedFileLoader] = useState(false)
	const [deleteManyFileModal, setDeleteManyFileModal] = useState(false)
	const [splitModal, setSplitModal] = useState({visible: false, data: {}, splitType: ""})
	const [splitLoader, setSplitLoader] = useState(false)
	const [splitNumberValue, setSplitNumberValue] = useState(0)
	const journalConfig = useSelector(store => store.journalConfig.config)

	const search = useLocation().search;
	const tabIndex = Number(new URLSearchParams(search).get("step"))
	
	const [allowedDesinationFiles, setAllowedDestinationFiles] = useState([
		{name: "Main document", files: ["DOCX"]},
		{name: "Figure", files: ["PNG", "JPG", "JPEG", "TIF", "TIFF", "DOCX", "DOC", "PPT", "PPTX", "PPTM", "PDF", "EPS"]},
		{name: "Multimedia", files: ["MOV", "AVI", "MPG", "MPEG", "MP4"]},
		{name: "Supplementary Information", files: ["ALL FILE FORMATS"]},
		{name: "Title page", files: ["ALL FILE FORMATS"]},
		{name: "Copy right agreement", files: ["ALL FILE FORMATS"]},
		{name: "Other", files: ["ALL FILE FORMATS"]},
		{name: "Form", files: ["ALL FILE FORMATS"]},
		{name: "Tables", files: ["ALL FILE FORMATS"]}
	])

	const getFileDesignationOption = () => {
		apiService.getDropdownOptions("fileDesignation", journalId, manuscriptId).then(res=> {
			if(res.result) {
				setOptions(res.result);
			}
		})
	}

	const handleValidation = (event) => {
		const {name, value} = event.target;
		switch (name) {
				case "fileDesignation":
						setFormError({
							...formError,
							fileDesignationError:  value ? "": "* Please provide a file designation.",
						});
						break;
				case "citationText":
					setFormError({
						...formError,
						citationTextError: fileDesignation==="Figure" && value?.trim()?.length ===0 ? "A figure caption is required.": fileDesignation!=="Figure" ? "":""
					});
					break;	
				default:
						break;
		}
	}

	const setAllowedDestinationFile = () => {
		let tempArray = [...allowedDesinationFiles]
		options.map((item, index) => { 
			let name = item.name.toLowerCase()
			let files = []
			let fileFound = false
			if(journalConfig[name+"Format"]) {
				files = journalConfig[name+"Format"]
			} else if (journalConfig[name+"Formats"]) {
				files = journalConfig[name+"Formats"]
			}
			tempArray.map((fileType, index) => {
				if(fileType.name === item.name) {
					if(files.length > 0) {
						tempArray[index].files = files
					}  
					fileFound = true
				}
			})
			if(!fileFound) {
				if(files.length > 0) {
					tempArray.push({name: item.name, files: files})
				} else {	
					tempArray.push({name: item.name, files: ["ALL FILE FORMATS"]})
				}
			}
		})
		setAllowedDestinationFiles(tempArray)
	}
	
	const handleDeleteFile = async(file) => {
		setSelectedFile(file)
		setRemoveBtnLoading(true)
		const body= {
			"fileName": file.fileName
		}
		await apiService.deleteFile(manuscriptId, body).then(res=>{
			setRemoveBtnLoading(false)
			toast.success(res.result, {autoClose: 3000})
			getStepTwoFiles();
		}).catch(err => {
			setRemoveBtnLoading(true)
			toast.error(err?.response?.data?.message, {autoClose: 3000})
		})
		handelError("")
	}

	const handleUpload = (e) => {
		e.preventDefault();
		const formData = new FormData();

		formData.append("file", myFiles[0]);
		formData.append("fileDesignation", fileDesignation)
		if(citationText?.trim() && fileDesignation ==="Figure"){
			formData.append("citationOrCaption", citationText?.trim())
		}else {
			formData.append("citationOrCaption", " ")
		}
		formData.append("referenceFormat", "")
		
		setBtnLoading(true);
		if(myFiles.length === 0  || !fileDesignation || fileDesignation==="Figure" && citationText?.trim()?.length === 0) {
			let toastError = ""
			if (myFiles.length === 0) {
				toastError = "* File is required!"
			}else if (!fileDesignation) {
				toastError = "Please provide a file designation."
			} else if (fileDesignation==="Figure" && citationText?.trim()?.length ==0) {
				toastError = "A figure caption is required."
			}
			toast.error(toastError, {autoClose: 3000})
			setFormError({
				...formError,
				fileError: myFiles.length === 0 ? "* File is required!" : "",
				fileDesignationError: fileDesignation ? " ":"* Please provide a file designation.",
				citationTextError: fileDesignation==="Figure" && citationText?.trim()?.length ==0 ? "A figure caption is required.": fileDesignation !=="Figure"? "": ""
			})
			setBtnLoading(false);
			return
		}
		else if(myFiles.length>0 && fileDesignation && ((fileDesignation==="Figure" && citationText.length>0)|| true)) {
			let ext = myFiles[0]?.path.split(".").pop()
			let filesAllowed = allowedDesinationFiles.filter(obj => obj.name === fileDesignation)

			if(filesAllowed[0]?.files?.includes(ext) || fileDesignation ==="Title page" || fileDesignation ==="Copy right agreement" || fileDesignation ==="Other" || fileDesignation ==="Form" ){
				apiService.uploadStepTwoFileInfo(manuscriptId, formData).then(res=>{
					setBtnLoading(false);
					toast.success(res.result, {autoClose: 3000});
					getStepTwoFiles();
				}).catch(err => {
					setBtnLoading(false);
					if(err.response){
						toast.error(err.response.data.message, {autoClose: 3000})
					}
					
				})
				setMyFiles([])
				setFileDesignation("");
				setCitationtext("")
				setFormError({...formError, allowedFileError: ""})
				handelError("")
			}
			else {
				toast.error(`Files must be ${filesAllowed[0]?.files.map(v=> v).join(", ").toUpperCase()}`, {autoClose: 3000})
				setBtnLoading(false);
			}
		
		}
		else {
			setBtnLoading(false);
			setFormError({
				...formError,
				fileError: myFiles.length === 0 ? "* File is required!" : "",
				fileDesignationError: fileDesignation ? " ":"* Please provide a file designation.",
				citationTextError: fileDesignation==="Figure" && citationText?.trim()?.length ==0 ? "A figure caption is required.": fileDesignation !=="Figure"? "": ""
			})
			handelError("")
		}
	
	}

	const handleDownloadFile= (e, file) => {
		e.preventDefault();
		const body = {
			"fileName": file
		}
		apiService.downloadFile(body, manuscriptId).then(res=> {
			toast.success("Downloading...", {autoClose: 3000})
            fileDownload(res, file);
		}).catch(e=> {
			toast.error(e.result, {autoClose: 3000})
			toast.error(e?.response?.data?.message, {autoClose: 3000})
		});
	}

	const getHelpHext = () => {
		apiService.getHelpText(journalId).then(res=>{
			setHelpText(res.result)
		}).catch(err=>{
			toast.error(err?.response?.data?.message, {autoClose: 3000})
		})
	}

	const getStepTwoFiles = () =>{
		setTableLoading(true);
		apiService.getStepTwoFiles(manuscriptId).then(res=>{
			setTableLoading(false);
			setFileList([...res.result, {caption: "", fileDesignation:"", fileName:"", referenceFormat: "", lastRow: true}])
		}).catch(e=> {
			toast.error(e?.response?.data?.message, {autoClose: 3000})
			setTableLoading(false)
		});
	}

	const handleHelpText = () => {
		setShow(true);
	}

	const getSuperscriptValidation = () => {
		apiService.fetchSuperscriptFormats().then(response => {
			setSuperscriptData(response.result)
		}).catch(error=> {
			toast.error(error?.response?.data?.message, {autoClose: 3000})
		})
	}

	const handleSuperscriptDetails = ()=> {
		setSuperscriptModal(true)
	}

	const handleSelectDesignattion = (e) => {
		let val = e.target.value
		setFileDesignation(val)
		setCitationtext("")
		setFormError({...formError, allowedFileError: allowedDesinationFiles.map(obj=> {
			if(val === obj.name) {
				return  `Files allowed for ${val}: ${obj.files.map(v=> v).join(", ")}`
			}else {
				return ''
			}
		}), citationTextError: val !=="Figure" ? "" : "" })
		if(val === "Figure") {
		getCitationTextOptions()
		}
	}

	function getCitationTextOptions() {
		apiService.getFigureCaptions(manuscriptId).then(res => {
			setFigureCaptionList(res.result)

		}).catch(err => {
			console.log("err==",err)
		})
	}

	function searchCaptionText(arr, letter) { 
		var matches = arr.filter(value =>{
		  //get rid of all falsely objects
		  if(value) {
			return (value.substring(0, letter.length) === letter);
		  }
		  })
		return (matches.length > 0) ? matches : letter;
	}
	  
	
	function getCaptions(searchValue, fileDesignation) {
		setFigureCaptionLoading(true); 
		const body = {
			prefix: searchValue,
			fileDesignation: fileDesignation
		} 
		apiService.getFileCaptions(manuscriptId, body).then(res => {
			setFigureCaptionLoading(false);
			let updatedArr = searchCaptionText(res.result, searchValue);
			if(typeof updatedArr ==="object") {
				setFigureCaptionList(updatedArr)
			}else if(typeof updatedArr ==="string"){
				setFigureCaptionLoading(false);
				setFigureCaptionList([])
			}

		}).catch(err => {
			setFigureCaptionLoading(false);
			toast.error(err?.response?.data?.message, {autoClose: 3000})
		})
		
	}

	const handleChange =(e)=> {
		let input = e.target.value
		setCitationtext(input);
	}

	const handleSelect = (selectedValue) => {
		setCitationtext(selectedValue)
		setFormError({
			...formError,
			citationTextError: fileDesignation==="Figure" && selectedValue?.trim()?.length ===0 ? "A figure caption is required.": fileDesignation!=="Figure" ? "":""
		});
	}

	function isChecked(value) {
		if(deleteFileIds.includes(value)) {
			return true
		} else {
			return false
		}
	}

	function fileCheckBoxChange(e) {
		if(e.target.checked) {
			setDeleteFileIds([...deleteFileIds, e.target.value])
		} else {
			setDeleteFileIds(deleteFileIds.filter((each) => each !== e.target.value))
		}
	}

	function deleteSelectedFiles() {
		setDeleteSelectedFileLoader(true)
		setDeleteManyFileModal(false)
		const data = {
			fileIds: deleteFileIds
		}
		apiService.deleteManyFiles(manuscriptId, data).then((res) => {
			toast.success(res.result, {autoClose: 3000})
			getStepTwoFiles()
			setDeleteFileIds([])
			resetError()
			setDeleteSelectedFileLoader(false)
		}, (err) => {
			console.log("Delete many files error: ", err)
			setDeleteSelectedFileLoader(false)
		})
	}

	function selectAllFileIds(e) {
		if(e.target.checked) {
			let tempFileId = []
			fileList.map((file) => {
				if(file.fileId) {
					tempFileId.push(file.fileId)
				}
			})
			setDeleteFileIds(tempFileId)
		} else {
			setDeleteFileIds([])
		}
	}

	function isSelecteAllChecked() {
		if(fileList.length -1 === deleteFileIds.length && deleteFileIds.length > 0) {
			return true
		} else {
			return false
		}
	}

	const splitCaption = (file) => {
		if(splitModal.splitType === "") {
			toast.warning("Please select the type of split caption!", {autoClose: 3000})
		} else {
			const body = {
				"fileId": file?.fileId
			}
			if(splitModal.splitType === "manual") {
				if(splitNumberValue < 2) {
					toast.warning("Minimum number of splited captions should be 2.", {autoClose: 3000})
					return
				} else {
					body.noOfSplits = splitNumberValue
				}
			}
			setSplitLoader(true)
			apiService.splitFigurecaption(manuscriptId, body).then((res) => {
				toast.success(res.result, {autoClose: 3000})
				getStepTwoFiles();
				setSplitLoader(false)
				resetError()
				setSplitModal({...splitModal, visible: false, splitType: ""})
				setSplitNumberValue(0)
			}).catch((err) => {
				console.log("Error in splitting figure caption: ", err.response)
				toast.error(err?.response?.data?.message, {autoClose: 3000})
				setSplitLoader(false)
				setSplitModal({...splitModal, visible: false, splitType: ""})
				setSplitNumberValue(0)
			})
		}
	}

	const SplitCaptionRadioChange = (e) => {
		setSplitModal({...splitModal, splitType: e.target.value})
	}

	const splitNumberChange = (e) => {
		setSplitNumberValue(e.target.value)
	}

	useEffect(()=>{
		getFileDesignationOption();
		getStepTwoFiles();
		getSuperscriptValidation()
		if(tabIndex === 3) {
			getCitationTextOptions()
		}
	},[])

	useEffect(() => {
		if(options.length && journalConfig !== undefined) {
			setAllowedDestinationFile()
		}
	}, [options, tableLoading, fileList, journalConfig])

	useEffect(() => {
		if(journalConfig !== undefined) {
			getFileDesignationOption()
		}
	},[fileList, journalConfig])

	useEffect(()=>{
		if(fileDesignation ==="Main document"){
			getHelpHext()
		}
	},[fileDesignation])

	return (
				<div className="">
					<div className="d-flex flex-row align-items-center justify-content-between">
						<h5>Upload supplementary files</h5>
					</div>
					<p className="">If you wish to check your figures comply with journal style and would like them included in the final export folder, please upload them here. Please check if the journal you are submitting to requires that figures are included in the main document and add them if necessary after the final export.</p>
					<p className="">An upload field is available below for each figure citation found in Step Two. Please upload your figures (DOCX, PDF, PNG, JPEG, MP4, TIFF, EPS etc; max. file size 50MB) and check/edit the corresponding caption. You can add multiple parts for each figure. Add any additional files you wish to include, enter a corresponding caption if it is a figure, then click on 'Submit'. Repeat this process for each file you wish to add.</p>
					<p className="">Supplementary information files and forms required by the journal (e.g. Conflict of Interest, Permissions, Copyright) can be uploaded here if you would like them included in the final export folder. No checks or changes will be made to these files; only figures will be checked.</p>
					<div className="mt-5 border rounded px-3">
						<div className="d-flex flex-row justify-content-between align-items-start pt-3">
							<div className="w-75">
								<h5 className="">Upload files</h5>
								<p className="text-secondary  small">Click on 'Upload file' to add files or choose 'Select file designation' to attach additional figures or documents. For each figure, add a caption then click on 'Upload file'. Multiple parts can be uploaded for each figure.</p>
							</div>
							<button 
								className={!deleteFileIds.length || deleteSelectedFileLoader ? "btn btn-md border-secondary text-secondary" : "btn btn-md border-danger text-danger"}
								disabled={!deleteFileIds.length || deleteSelectedFileLoader}
								onClick={(e) => {
									e.preventDefault()
									setDeleteManyFileModal(true)
								}}
							>
								{ deleteSelectedFileLoader ? 
									<CircleLoader size={"sm"}/> 
								: 
									<span>
										<FontAwesomeIcon icon={faTrash} className="mr-2"/>
										Delete selected files
									</span>
								}
							</button>
						</div>
						<section className="mb-2 d-flex flex-column">
							<div className="d-flex flex-row align-self-end mb-3 align-items-center">
							</div>
							<Table responsive>
								<thead className={styles.tableHead}>
									<tr className="border border-bottom-0">
										<th width="1%">
											<div className="d-flex flex-row align-items-center">
												<input
													className="mr-2" 
													type="checkbox"
													onChange={(e) => {selectAllFileIds(e)}}
													checked={isSelecteAllChecked()}
												/>
												#
											</div>
										</th>
										<th width="35%">FILE DESIGNATION</th>
										<th width="39%">CAPTION</th>
										<th width="20%" className="text-right pr-4">ACTION</th>
									</tr>
								</thead>
								<tbody className={styles.tBody}>
									{ tableLoading ? 
										<tr>
											<td colSpan={4} className="text-center"><CircleLoader /></td>
										</tr> 
										:  
										fileList.length !==0 ?
											fileList.map((fileData,index) => (
												<TableItem 
													index={index} 
													fileData={fileData} 
													handleDownloadFile={handleDownloadFile} 
													removeBtnLoading={removeBtnLoading} 
													selectedFile={selectedFile} 
													handleDeleteFile={handleDeleteFile}
													getCaptions= {getCaptions}
													handleSelect={handleSelect}
													figureCaptionList={figureCaptionList}
													figureCaptionLoading={figureCaptionLoading}
													manuscriptId={manuscriptId}
													getData={getStepTwoFiles}
													btnLoading={btnLoading}
													myFiles={myFiles} 
													setMyFiles={setMyFiles}
													options={options}
													allowedDesinationFiles = {allowedDesinationFiles}
													isChecked = {isChecked}
													fileCheckBoxChange = {fileCheckBoxChange}
													deleteFileIds = {deleteFileIds}
													resetError={resetError}
													splitModal = {splitModal}
													setSplitModal = {setSplitModal}
													fileList = {fileList}
												/>
											))
										:
											<tr>
												<td colSpan={5} className="text-center">Data not found</td>
											</tr>
									}
								</tbody>
							</Table>
						</section>

						{/* Reference format details modal */}
						<Modal show={show} onHide={()=> setShow(false)} size="xl" dialogClassName="text-justify">
						<Modal.Header closeButton>
							<Modal.Title>
								<span>Reference format details</span> <br/>
								<span className={classNames(styles.modalHeaderSubtext, "small text-secondary")}>Please note, some journals may use a variation of this formatting. Please check requirements for your chosen journal.</span>
							</Modal.Title>
						</Modal.Header>
							<Modal.Body className={classNames(styles.modalBody, "py-0")} scrollable>
							{helpText.length && <div className=""><GuidelineTab data={helpText}  /></div>}
							</Modal.Body>
						</Modal>

						{/* In-text Citation guide modal */}
						<Modal show={superscriptModal} onHide={()=> setSuperscriptModal(false)} size="xl" dialogClassName="text-justify">
							<Modal.Header className="h6" closeButton><Modal.Title>In-text citation guidelines</Modal.Title></Modal.Header>
							<Modal.Body className={classNames(styles.modalBody, "py-0")} scrollable>
							{superscriptData.length && <div className=""><GuidelineTab data={superscriptData}  /></div>}
							</Modal.Body>
						</Modal>

						{/* Upload files detail instruction modal */}
						<Modal show={viewDetailInstruction} onHide={()=> setViewDetailInstruction(false)} size="md" dialogClassName="text-justify">
							<Modal.Header>
								<div className='d-flex flex-column'>
									<h6>Upload supplementary files</h6>
									<p className='m-0 text-secondary'>detailed instruction</p>
								</div>
								<div>
									<button className={classNames(styles.secondaryTextButton)}>
										<FontAwesomeIcon icon={faXmark} onClick={() => setViewDetailInstruction(false)}/>
									</button>
								</div>
							</Modal.Header>
							<Modal.Body className={classNames("py-0")} scrollable>
								<p className="mb-2">If you wish to check your figures comply with journal style and would like them included in the final export folder, please upload them here. Please check if the journal you are submitting to requires that figures are included in the main document and add them if necessary.</p>
								<p className="mb-2">An upload field is available below for each figure citation found in Step Two. Please upload your figures (DOCX, PDF, PNG, JPEG, MP4, TIFF, EPS etc. Max. file size 50MB) and check/edit the corresponding caption.</p>
								<p>Supplementary information files and forms required by the journal (e.g. Conflict of Interest, Permissions, Copyright) can be uploaded here if you would like them included in the final export folder. No checks or changes will be made to these files; only figures will be checked.</p>
							</Modal.Body>
						</Modal>
						
						{/* Delete many files Modal */}
						<Modal show={deleteManyFileModal} onHide={() => {setDeleteManyFileModal(false)}} dialogClassName="text-justify">
							<Modal.Header className="h6" closeButton>
								<Modal.Title>Warning!</Modal.Title>
							</Modal.Header>
							<Modal.Body className="pt-0">
								<p>Are you sure you want to delete <strong>{deleteFileIds.length}</strong> file(s)?</p>
								<div className="d-flex flex-column align-self-end w-100">
									<button className="btn btn-md border-danger text-danger w-100" onClick={(e) => {deleteSelectedFiles()}}>Delete</button>
									<button className="btnOutline mt-2" onClick={(e) => {setDeleteManyFileModal(false)}}>Cancel</button>
								</div>
							</Modal.Body>
						</Modal>

						<Modal show={splitModal.visible} onHide={() => {setSplitModal({...splitModal, visible: false, splitType: ""}); setSplitNumberValue(0)}} dialogClassName="text-justify">
							<Modal.Header className="h6" closeButton>
								<Modal.Title>Split caption</Modal.Title>
							</Modal.Header>
							<Modal.Body className="pt-0">
								<div>
									<p>Please select how you want to split the caption?</p>
									<div className="d-flex flex-row justify-content-around mb-3">
										<span>
											<input name="splitCaptionRadio" type="radio" className="mx-2" 
												value="automatic"
												disabled={!splitModal?.data?.isSplit}
												onChange={(e) => SplitCaptionRadioChange(e)}
											/> 
											Automatic
										</span>
										<span>
											<input name="splitCaptionRadio" type="radio" className="mx-2"
												value="manual"
												onChange={(e) => SplitCaptionRadioChange(e)}
											/> 
											By numbers
										</span>
									</div>
									<div className="d-flex flex-column my-3">
										<span>Enter number of captions.</span>
										<input type="number" className="form-control" placeholder="Enter caption number here." 
											disabled={splitModal.splitType !== "manual"}
											onChange={(e) => {splitNumberChange(e)}}
											value = {splitNumberValue}
										/>
									</div>
								</div>
								<div className="d-flex flex-column align-self-end w-100">
									<button className="btnOutline" onClick={(e) => {splitCaption(splitModal.data)}}>
										{splitLoader ? <CircleLoader/> :  "Split caption"}
									</button>
									<button className="btn btn-md mt-2 border-secondary text-secondary w-100" onClick={(e) => {setSplitModal({...splitModal, visible: false, splitType: ""}); setSplitNumberValue(0)}}>Cancel</button>
								</div>
							</Modal.Body>
						</Modal>
					</div>
				</div>
			
	);
}


export default StepTwoForm;