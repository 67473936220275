import { faInfoCircle, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import {  Col, Form, FormControl, Modal} from "react-bootstrap";
import { toast } from "react-toastify";
import TooltipButton from "../../../../../components/TooltipButton/TooltipButton";
import AxiosService from "../../../../../utils/AxiosService";
import GuidelineTab from "../StepTwoForm/guidelineTab";
import styles from "./StepOneForm.module.scss";
import { useSelector } from "react-redux";


const SteOneForm = ({
	referenceType, setReferenceType, manuscriptId, 
	handelError,language, setLanguage, templateType, 
	journalId, handleSave, setTitle, setAbstract, title, 
	abstract, manuscriptType, setManuscriptType, 
	handleMandatoryCheck, mandatoryFieldSet, studyType, 
	setStudyType, referenceCheck, setReferenceCheck, 
	articleTitle, setArticleTitle, stepStatus, setStepOneInfo,
	referenceCitationFormat, setReferenceCitationFormat, 
	referenceCitationFormatError, setReferenceCitationFormatError
}) => {
	const apiService = new AxiosService();
	const journalConfig = useSelector(store => store.journalConfig.config)
	const [manuscriptTypeOptions, setManuscriptTypeOptions] = useState([{id: "P5", name: "Original Article", mainArticle: true}])
	const [studyTypesOptions, setStudyTypesOptions] = useState([{id: "S30", name: "Randomised controlled trial"}, {id: "S31", name: "Other"}])
	const [showNote, setShowNote] = useState(false)
	const [showGenericNote, setShowGenericNote] = useState(false)
	const [originalArticle, setOriginalArticle] = useState("")
	const [helpText, setHelpText]=useState([]);
	const [show, setShow] = useState(false);
	const [languageOptions, setLanguageOptions] = useState([])
	const [titleLength, setTitleLength] = useState(-1)
	const [manuscriptTypeFound, setManuscriptTypeFound] = useState(false)

	const getHelpHext = () => {
		apiService.getHelpText(journalId).then(res=>{
			setHelpText(res.result)
			setLanguageOptions(res.languages)
			if(language==="") {
				setLanguage(res?.languages[0].code)
			}
			if(helpText.length>0){
				setReferenceType(helpText[0].name)
			}
		}).catch(err=>{
			toast.error(err?.response?.data?.message, {autoClose: 3000})
		})
	}
	
	const handleSelect = (e) => {
		const {value} = e.target
		setManuscriptType(value);
		const obj = manuscriptTypeOptions.filter(op => op.name === e.target.value)
		if(!obj[0]?.mainArticle && value !=="" && templateType !== "Generic") {
			setShowNote(true)
		}
		handleMandatoryCheck(e.target.value);
	}

	const handleHelpText = () => {
		setShow(true);
	}

	const handleStudyType = (e) => {
		setStudyType(e.target.value)
	}

	const getManuscriptTypes = () => {
		apiService.getDropdownOptions("manuscript", journalId, manuscriptId).then(res=> {
			let tempArticleList = []
			if(res.result){
				setManuscriptTypeOptions(res.result);
				res.result.map(op =>{
					if(op.mainArticle) {
						tempArticleList.push(op.name)
					}
				} )
				setOriginalArticle(tempArticleList.join(", "))
			}
		}).catch((err) => {
			setManuscriptTypeOptions([{id: "P5", name: "Original Article", mainArticle: true}])
			if(err?.response?.data?.message) {
				toast.error(err?.response?.data?.message)
			} else {
				toast.error("Something went wrong! Please refresh the page.")
			}
		})
	}

	const getData =() => {
		apiService.getStepOneDetails(manuscriptId).then(
			res => { 
				const data= res.result;
				let localManuscriptTypeFound = false
				let studyTypeCheck = false
				let referenceFormatCheck = false
				setStepOneInfo({...data, templateType: templateType, manuscriptId: manuscriptId})
				setArticleTitle(data?.manuscriptTitle)
				
				if(manuscriptTypeOptions?.length && data?.manuscriptType?.length) {
					manuscriptTypeOptions.map((option) => {
						if(option.name === data?.manuscriptType) {
							localManuscriptTypeFound = true
						}
					})
				}
				if(!localManuscriptTypeFound) {
					if(templateType === "Generic") {
						setManuscriptType("Original Article")
					} else {
						setManuscriptType("")
					}
				} else {
					if(templateType === "Generic") {
						setManuscriptType("Original Article")
					} else {
						setManuscriptType(data?.manuscriptType)
					}
					if(data?.manuscriptType?.length && templateType === "Generic") {
						setManuscriptTypeFound(true)
					}
				}
				
				if (studyTypesOptions.length && data?.studyType?.length) {
					studyTypesOptions.map((item) => {
						if (data?.studyType === item.name) {
							studyTypeCheck = true
						}
					})
				}
				if(!studyTypeCheck) {
					setStudyType("")
				} else {
					setStudyType(data?.studyType)
				}
				
				if (helpText.length && data?.referenceFormat?.length) {
					helpText.map((item) => {
						if (data?.referenceFormat === item.name) {
							referenceFormatCheck = true
						}
					})
				}
				if(!referenceFormatCheck && helpText.length !== 1) {
					setReferenceType("")
				} else if (helpText.length === 1) {
					setReferenceType(helpText[0]?.name)	
				} else {
					setReferenceType(data?.referenceFormat)
				}
				
				setLanguage(data?.language)
				if(data?.manuscriptTitle.length > 0) {
					setTitleLength(data.manuscriptTitle.length)
				}
				if(data?.referenceCheck === "yes" || data?.referenceCheck === "no") {
					setReferenceCheck(data?.referenceCheck)
				}
				if(data?.referenceCitationFormat === "superScripts" || data?.referenceCitationFormat === "squareBrackets") {
					setReferenceCitationFormat(data?.referenceCitationFormat)
				}
				handleMandatoryCheck(data?.manuscriptType)
			}).catch(e=> {
				toast.error(e?.response?.data?.message, {autoClose: 3000})
			})
	}

	const getStudyTypeOptions= () => {
		apiService.getDropdownOptions("studyTypes", journalId, manuscriptId).then(res=> {
			if(res.result){
				setStudyTypesOptions(res?.result)
			}
		}).catch((err) => {
			setStudyTypesOptions([{id: "S30", name: "Randomised controlled trial"}, {id: "S31", name: "Other"}])
			if(err?.response?.data?.message) {
				toast.error(err?.response?.data?.message)
			} else {
				toast.error("Something went wrong! Please refresh the page.")
			}
		})
	}

	const handleShowNote = () => {
		setShowNote(false)
	}

	const handleShowGenericNote = () => {
		setShowGenericNote(false)
	}

	// const getReferencesCitations = () => {
	// 	apiService.getReferencesCitations(journalId).then((res) => {
	// 		console.log("response from refrence citation: ", res)
	// 	}).catch((err) => {
	// 		console.log("Error in refrence citation : ", err.response)
	// 	})
	// 	console.log("Journal config obje: ", journalConfig)
	// }

	useEffect(() => {
		if (templateType === "Generic") {
			setShowGenericNote(true)
		}
	}, [])

	useEffect(() => {
		if(journalConfig?.referenceCitationsFormat?.length === 1) {
			setReferenceCitationFormat(journalConfig?.referenceCitationsFormat[0])
		}
	}, [journalConfig])

	useEffect(()=>{
		getManuscriptTypes()
		getStudyTypeOptions()
		getHelpHext()
		// getReferencesCitations()	
	},[])

	useEffect(() => {
		if(manuscriptTypeOptions.length && studyTypesOptions.length && helpText.length) {
			getData()
		}
	}, [manuscriptTypeOptions, studyTypesOptions, helpText])

	return (
			<div className={styles.stepOneStyle}>
				<h5>Article type selection </h5>
				<p className="mb-0">Start the formatting process by selecting the type of manuscript and study type. This will then be validated against the Instructions to Authors for your chosen journal.</p>
				<Form>
					<section className="my-2 mt-4 row">
						<Col md={6}>
							<section className="pb-3">
								<label className="d-flex justify-content-between">
									<span>Article Type <span className="text-danger">*</span></span>
									{templateType !== "Generic" && <span className={classNames(styles.noteButton, "cursor-pointer")} 
										onClick={(e)=> {
											e.preventDefault()
											setShowNote(true)
										}}
									>
										View Note
									</span>}
								</label>
								<select 
									className="form-control" 
									value={manuscriptType} 
									onChange={handleSelect}
									disabled={templateType==="Generic" && manuscriptTypeFound}
								>
									<option value="">Select article type</option>
									{manuscriptTypeOptions && manuscriptTypeOptions.map( (op) => { 
											if(templateType==="Generic") {
												if(op.name === "Original Article") {
													return (
														<option key={op.id} value={op.name} >{op.name}</option>
													)	
												}
											} else {
												return (
													<option key={op.id} value={op.name} >{op.name}</option>
												)
											}
										})
									}
								</select>
							</section>
							<section className="pb-3">
								<label className="">Manuscript Language <span className="text-danger">*</span></label>
								{languageOptions?.length === 1?
								<FormControl
								value={languageOptions[0]? languageOptions?.[0]?.name: ""}
								name="language"
								readOnly
								/>
								: <select 
									className="form-control"
									name="language" 
									defaultValue={languageOptions?.[0]?.code}
									value={language} 
									onChange={(e)=>setLanguage(e.target.value)}
								>
									{languageOptions && languageOptions.map((value, index)=> 
									<option key={index} value={value.code}>{value.name}</option>
									)
									}
								</select>}
							</section>
						</Col>
						<Col md={6}>
							<section className="pb-3">
								<label className="">Study Type <span className="text-danger">*</span></label>
								<select 
									className="form-control" 
									value={studyType} 
									onChange={handleStudyType}
								>
									<option value="">Select study type</option>
									{studyTypesOptions && studyTypesOptions.map((value, index)=> 
									<option key={value.id} value={value.name}>{value.name}</option>
									)
									}
								</select>
							</section>
							<section className="pb-3">
								<label className="d-flex justify-content-between align-items-center">
									<div>
										Reference Style
										<TooltipButton key="Reference style" placement="top" tooltipMessage="This journal uses the following reference style. Your references will be converted to this format. To use our reference checking tool please ensure that reference citations are all in the same format (e.g. either all in square brackets or all in superscript, NOT round brackets/parentheses).">
											<FontAwesomeIcon icon={faInfoCircle} className={classNames(styles.labelButtonLink,"align-middle pl-1")}/>
										</TooltipButton>
									</div>
									<span className={classNames("textLink cursor-pointer float-right")} onClick={handleHelpText} >View reference format details</span>
									{/* <br />
									Your references will be converted to this format.
									<br/>
									To use our reference checking tool please ensure that reference citations are all in the same format (e.g. either all in square brackets or all in superscript, NOT curly brackets). */}
								</label>
								{ helpText.length === 1 ?
									<FormControl
										type="text"
										aria-label="referenceType"
										name="referenceType"
										value={ helpText[0]? helpText[0].name: ""}
										className={classNames(styles.customInput)}
										placeholder="Enter file description"
										readOnly
									/>
								:
									<select  
										value={referenceType} 
										onChange={(e)=> setReferenceType(e.target.value)} 
										name="referenceType"
										className="form-control"
									>
										<option value="">Select reference format</option>
										{helpText.map(help => <option key={help.id}>{help.name}</option>)}
									</select>
								}
							</section>
						</Col>
						<Col md={12} className="mb-3">
							<label>
								Manuscript Title <span className="text-danger">*</span>
								<TooltipButton tooltipMessage="Please copy and paste in your Manuscript title. This is a mandatory field and you won't be able to change this later." placement="top">
									<FontAwesomeIcon icon={faInfoCircle} className={classNames(styles.labelButtonLink, "mx-2 align-middle")} />
								</TooltipButton>
							</label> <br/>
							<span className="small text-secondary">Please ensure the title you enter here is correct and matches the title that will be used in your manuscript in Step Two. <b>When Step One is complete this title cannot be changed.</b></span>
							{stepStatus > 1 && titleLength > 0?
								<>
									<p className="mt-3 border p-2 rounded">{articleTitle}</p>
								</>
							:
								<FormControl
									className="mt-3"
									as="textarea"
									value={articleTitle}
									rows={3}
									placeholder="Please copy and paste in your Manuscript title."
									onChange={(e)=> {setArticleTitle(e.target.value)}}
									area-label="Manuscript Title"
									name="articleTitle"
								/>
							}
						</Col>
						<Col md={12}>
							<label className="d-flex justify-content-between align-items-center mt-1">
								Validate my references
							</label>
							<div className="d-flex flex-column justify-content-between">
								<div className="d-flex">
									<input className="mr-2" type="radio" name="referenceCheck" value="yes" onChange={(e) => setReferenceCheck(e.target.value)} checked={referenceCheck === "yes"}/>
									{journalConfig?.referenceCitationsFormat?.length === 1 ?
										journalConfig?.referenceCitationsFormat[0] === "superScripts" ?
											`I would like my references checked and confirm they are in superscript, not square brackets or round brackets/parentheses`
										:
											`I would like my references checked and confirm they are in square brackets, not superscript or round brackets/parentheses`
									:
										<div className="d-flex flex-column">
											<p className="mb-2 p-0">I would like my references checked and confirm they are in square brackets or superscript, not round brackets/parentheses</p>
											<span className="small text-secondary mb-2">If your references are in superscript and appear mid-sentence please be aware that the reference checker will not detect these and you may prefer to change your reference citations to square brackets throughout</span>
										</div>
									}
								</div>
								{referenceCheck === "yes" && 
									<div className="w-50 my-2 ml-4">
										<div className="d-flex flex-row">
											<select  
												value={journalConfig?.referenceCitationsFormat?.length === 1 ? journalConfig?.referenceCitationsFormat[0] : referenceCitationFormat} 
												onChange={(e)=> {setReferenceCitationFormat(e.target.value); setReferenceCitationFormatError("")}} 
												name="referenceCitationFormat"
												className="form-control"
												disabled={journalConfig?.referenceCitationsFormat?.length === 1}
											>
												<option value="">Select reference citation format type</option>
												{journalConfig?.referenceCitationsFormat?.length > 0 ? 
													journalConfig?.referenceCitationsFormat?.map((item) => {
														return (
															<>
																{item === "superScripts" ?
																	<option value="superScripts">Superscript</option>
																:
																	null
																}
																{item === "squareBrackets" ? 
																	<option value="squareBrackets">Square brackets (please ensure no other text is within square brackets)</option>
																:
																	null
																}
															</>		
														)
													})
												:
													<>
														<option value="superScripts">Superscript</option>
														<option value="squareBrackets">Square brackets (please ensure no other text is within square brackets)</option>
													</>
												}
											</select>
											<span className="text-danger ml-2">*</span>
										</div>
										<span className="text-danger">{referenceCitationFormatError}</span>
									</div>
								}
								<div className="d-flex">
									<input className="mr-2" type="radio" name="referenceCheck" value="no" onChange={(e) => setReferenceCheck(e.target.value)} checked={referenceCheck === "no"}/>
									Don’t check my references
								</div>
							</div>
						</Col>
							

					</section>
					
				</Form> 
				
				<Modal show={showNote} onHide={()=> handleShowNote()} dialogClassName={classNames("", styles.styleModal)}>
					<Modal.Header  className={classNames("h5 bg-light pb-2")}>
						<span>Note</span><buton onClick={handleShowNote} className="cursor-pointer"><FontAwesomeIcon icon={faXmark} /> </buton>
					</Modal.Header>
					<Modal.Body className="py-0">
					<p className="text-secondary">The formatting tool will apply the template for <strong>{originalArticle}</strong> only and does not format other article types. Please use the template as the basis of your formatting only, and refer to the publisher’s Instructions to Authors for any other formatting that needs to be applied to the document after using this tool.</p>
					</Modal.Body>
				</Modal>

				<Modal show={showGenericNote} onHide={()=> handleShowGenericNote()} dialogClassName={classNames("", styles.styleModal)}>
					<Modal.Header  className={classNames("h5 bg-light pb-2")}>
						<span>Note</span><buton onClick={handleShowGenericNote} className="cursor-pointer"><FontAwesomeIcon icon={faXmark} /> </buton>
					</Modal.Header>
					<Modal.Body className="py-0">
						<p className="text-secondary"><strong>Please note:</strong> our generic template formatting tool will not apply specific journal style. Add your manuscript and OrthoPublish will check your figure, table and reference citations. You will be able to upload your figures to be checked, create keywords, add author details and export your document and title page. You can use this document as a basis to add the specific styles of the journal you wish to submit to.</p>
					</Modal.Body>
				</Modal>

				<Modal show={show} onHide={()=> setShow(false)} size="xl" dialogClassName="text-justify">
					<Modal.Header closeButton>
						<Modal.Title>
							<span>Reference format details</span> <br/>
							<span className={classNames(styles.modalHeaderSubtext, "small text-secondary")}>Please note, some journals may use a variation of this formatting. Please check requirements for your chosen journal.</span>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className={classNames(styles.modalBody, "py-0")} scrollable>
					{helpText.length && <div className=""><GuidelineTab data={helpText}  /></div>}
					</Modal.Body>
				</Modal>
			</div>
	);
}

export default SteOneForm;