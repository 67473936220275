import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCommands } from '@remirror/react-core'
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import './RemirrorEditor.scss'

export const CellMenu = () => {
    const commands = useCommands()
    return (
        <>
          <Dropdown>
            <Dropdown.Toggle as="div" className='dropDownStyle'>
              <FontAwesomeIcon icon={faChevronDown} className="iconStyle"/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => {commands.addTableRowBefore()}}>Add row above</Dropdown.Item>
              <Dropdown.Item onClick={() => {commands.addTableRowAfter()}}>Add row below</Dropdown.Item>
              <Dropdown.Item onClick={() => {commands.deleteTableRow()}}>Delete row</Dropdown.Item>
              <Dropdown.Item onClick={() => {commands.addTableColumnBefore()}}>Add column left</Dropdown.Item>
              <Dropdown.Item onClick={() => {commands.addTableColumnAfter()}}>Add column right</Dropdown.Item>
              <Dropdown.Item onClick={() => {commands.deleteTableColumn()}}>Delete column</Dropdown.Item>
              {/* <Dropdown.Item onClick={() => {
                    console.log("Split cells enable: ", commands.splitTableCell())
                }}
              >
                Split cells
              </Dropdown.Item>
              <Dropdown.Item onClick={() => {
                    console.log("Split cells enable: ", commands.mergeTableCells().enabled())
                }}
              >
                Merge cells
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </>
    )
}