import { API_BASE_URL, origin } from './Constant';
import Axios from 'axios';
import {dataDisplayRequest} from './auth_service/AuthServiceHelper';

/* Service layer class for all API related calls */
export default class AxiosService{

		constructor() { }

		getSubjectsWithNarrowTerms() {
			return Axios.get(API_BASE_URL + '/subjects');
		}

		getSpecialties(){
			return Axios.get(API_BASE_URL + '/suggestsub');
		}

		getOnlySubjects(){
			return Axios.get(API_BASE_URL + '/subjects?relation=' + false);
		}

		getSubjectInfo(id) {
			const data = {
				"cid" : id
			}
			return Axios.post(API_BASE_URL + '/subject', data);
		};

		getSubjectInfoByName (name) {
			const data = {
					"name" : name
			}
			return Axios.post(API_BASE_URL + '/subjectinfo', data);
		};

		getSubjectArticles() {
			return Axios.get(API_BASE_URL + '/latestarticles');
		};

		getArticlesWithFilters(pageNumber, size, data){
			return Axios.post(API_BASE_URL + '/articles?page=' + pageNumber + '&size=' + size, data);
		}

		getPrePrintArticlesWithFilters(pageNumber, size, data){
			return Axios.post(API_BASE_URL + '/preprint?page=' + pageNumber + '&size=' + size, data);
		}

		getHomeArticles(getData){
			return Axios.post(API_BASE_URL + '/homearticles', getData)
		}

		getProceedingsWithFilters(pageNumber, size, data){
			return Axios.post(API_BASE_URL + '/proceeding?page=' + pageNumber + '&size=' + size, data);
		}

		getVideosWithFilters(pageNumber, size, data){
			return Axios.post(API_BASE_URL + '/videos?page=' + pageNumber + '&size=' + size, data);
		}

		getPodcastsWithFilters(pageNumber, size, data){
			return Axios.post(API_BASE_URL + '/podcasts?page=' + pageNumber + '&size=' + size, data);
		}

		getStandardsWithFilters(pageNumber, size, data){
			return Axios.post(API_BASE_URL + '/standards?page=' + pageNumber + '&size=' + size, data);
		}

		getSearchResults(pageNumber, size, data){
			return Axios.post(API_BASE_URL + '/search?page=' + pageNumber + '&size=' + size, data);
		}

		getSpecilaitySearch(pageNumber, size, data){
			return Axios.post(API_BASE_URL + '/specialitysearch?page=' + pageNumber + '&size=' + size, data);
		}

		getAlerts(){
			const config = {
					url: API_BASE_URL + '/manageAlerts/fetch',
					method: "get",
			};
			return dataDisplayRequest(config).then(response => {
					return response
			});
		}

		register(data) {
			const headers = {
				'x-alt-origin': origin
			}
			return Axios.post(API_BASE_URL + '/orthopubInterfaceApis/api/v1/auth/create', data, {headers: headers});
		}
	
		registerConfirm(token) {
			const headers = {
				'x-alt-origin': origin
			}
			return Axios.get(API_BASE_URL + '/orthopubInterfaceApis/api/v1/auth/registrationConfirm?token='+ token, {headers: headers})
		}
	
		forgetPassword(data){
			const headers = {
				'x-alt-origin': origin
			}
			return Axios.post(API_BASE_URL + '/orthopubInterfaceApis/api/v1/auth/forgetPassword', data, {headers: headers});
		}
	
		forgetPasswordConfirmation(token, data){
			const headers = {
				'x-alt-origin': origin
			}
			return Axios.post(API_BASE_URL + '/orthopubInterfaceApis/api/v1/auth/resetPassword', {...data, "token": token}, {headers: headers})
		}
	
		resendVerificationLink(token,data){
			if (token) {
				const headers = {'token': token, 'x-alt-origin': origin}
				return Axios.get(API_BASE_URL + '/orthopubInterfaceApis/api/v1/auth/resendVerificationLink', {headers: headers})
			} else {
				const headers = {'x-alt-origin': origin}
				return Axios.get(API_BASE_URL + '/orthopubInterfaceApis/api/v1/auth/resendVerificationLink?email='+encodeURIComponent(data.email), {headers: headers})
			}
		}

		autoSuggest(data){
			return Axios.post(API_BASE_URL + '/search/autoSuggest?page=0&size=5', data);
		}

		getDateRange(){
			return Axios.get(API_BASE_URL + '/search/dateRange');
		}

		getCitationScoreRange(){
			return Axios.get(API_BASE_URL + '/search/citeRange');
		}

		didYouMean(data){
			return Axios.post(API_BASE_URL + '/didyoumean', data)
		}
		
		getAuthorDetails(pageNumber, size, data){
			return Axios.post(API_BASE_URL + '/authorsearch?page=' + pageNumber + '&size=' + size, data);
		}

		getSimilarArticles(pageNumber, size, data){
			return Axios.post(API_BASE_URL + '/similar?page=' + pageNumber + '&size=' + size, data)
		}

		getSimilarProceedings(pageNumber, size, data){
			return Axios.post(API_BASE_URL + '/similar?page=' + pageNumber + '&size=' + size, data)
		}

		addToFavourite(data){
			const config = {
					url: API_BASE_URL + '/queryLogs/favourite',
					method: "post",
					data: data
			};
			return dataDisplayRequest(config).then(response => {
					return response
			});
		}

		deleteFromFavourite(data){
			const config = {
					url: API_BASE_URL + '/queryLogs/favourite',
					method: "delete",
					data: data
			};
			return dataDisplayRequest(config).then(response => {
					return response
			});
		}

		getFavouriteArticles(data, getStartLimit, getRecordsPerPage){
			const config = {
					url: API_BASE_URL + '/queryLogs/favourites?start=' + getStartLimit +'&offset=' + getRecordsPerPage,
					method: "post",
					data: data
			};
			return dataDisplayRequest(config).then(respone => {
					return respone
			})
		}

		fetchSimilarArticlesCount(data){
			return Axios.post(API_BASE_URL + '/similarCount', data) 
		}

		//Orthopub apis
		getJournalDetails(lexiconId){
			return Axios.get(API_BASE_URL + '/orthopubInterfaceApis/api/v1/serch-service/getDetail/' + lexiconId);
		}

		getSampleAbstracts(journalTitle){
			return Axios.get(API_BASE_URL + '/orthopubInterfaceApis/api/v1/serch-service/abstracts?journal_title=' + journalTitle)
		}

		getJournalSearchRecords(getStart, getOffset, getSort, body){
			return Axios.post(API_BASE_URL + '/orthopubInterfaceApis/api/v1/serch-service/findJournal?start=' + getStart + '&offset=' + getOffset + '&sort=' + getSort + "&operator=AND", body)
		}

		getFieldSearchRecords(query, body){
			return Axios.post(API_BASE_URL + '/orthopubInterfaceApis/api/v1/serch-service/fieldSearch' + query, body)
		}

		getTextSearchRecords(query, body){
			return Axios.post(API_BASE_URL + '/orthopubInterfaceApis/api/v1/serch-service/textSearch' + query, body)
		}

		getImpactFactorRange(){
			return Axios.get(API_BASE_URL + '/orthopubInterfaceApis/api/v1/serch-service/impactFactorRange')
		}

		// Manuscript Submission API's

		getJournalTitle(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/manuscript/${manuscriptId}`,
				method: "get"
			}
			return dataDisplayRequest(config).then(res=> {
				return res
			})
		}

		getStepOneDetails(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepOne/${manuscriptId}`,
				method: "get"
			}
			return dataDisplayRequest(config).then(res=> {
				return res
			})
		}

		getStepOneStudyType() {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/studyTypes`,
				method: "get"
			}
			return dataDisplayRequest(config).then(res=> {
				return res
			})
		}

		getDropdownOptions(type, journalId, manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/types/${manuscriptId}?type=${type}&journalId=${journalId}`,
				method: "get"
			}
			return dataDisplayRequest(config).then(res=> {
				return res
			})
		}

		updateStepOneDetails(manuscriptId,data) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepOne/${manuscriptId}`,
				method: "put",
				data: data
			}
			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		createManuscript(body) {
			const config = {
					url: API_BASE_URL + '/orthopubInterfaceApis/api/v1/data/createManuscript',
					method: "post",
					data: body
			};
			return dataDisplayRequest(config).then(response => {
					return response
			});
		}
		//  step two api
		uploadStepTwoFileInfo(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepTwo/fileInfo/${manuscriptId}`,
				method: "post",
				data: body,
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		uploadStepTwoFileInfoWithCaption(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepTwo/fileInfoWithCaption/${manuscriptId}`,
				method: "post",
				data: body,
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		uploadExtraFile(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/uploadFile/${manuscriptId}`,
				method: "post",
				data: body,
				headers: {
					"Content-Type": "multipart/form-data"
				}
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		deleteExtraFile(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/removeFile/${manuscriptId}`,
				method: "delete",
				data: body,
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		getStepTwoFiles(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepTwo/fetchFiles/${manuscriptId}`,
				method: "get",
			}
			
			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		deleteFile(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepTwo/deleteFile/${manuscriptId}`,
				method: "delete",
				data: body
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		splitFigurecaption(manuscriptId, body) {
			const config= {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/splitFigureCaptions/${manuscriptId}`,
				method: "post",
				data: body
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		downloadFile(body, manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepTwo/downloadFile/${manuscriptId}`,
				method: "post",
				data: body,
				responseType: 'blob'
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		// Step three API

		addKeyword(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepThree/keywords/${manuscriptId}`,
				method: "post",
				data: body
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		deleteKeyWord(manuscriptId, cid) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepThree/keywords/${manuscriptId}/${cid}`,
				method: "delete",
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		suggestKeywords(body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepThree/suggestKeywords`,
				method: "post",
				data: body
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		getKeywords(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepThree/keywords/${manuscriptId}`,
				method: "get",
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		repopulateKeywords(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepThree/populateKeywords/${manuscriptId}`,
				method: "put",
				data: body
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		// Step four API's

		getAuthorList(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepFour/authors/${manuscriptId}`,
				method: "get",
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		addAuthor(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepFour/authors/${manuscriptId}`,
				method: "post",
				data: body
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		deleteAuthor(manuscriptId, emailId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepFour/authors/${manuscriptId}`,
				method: "delete",
				data: emailId
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		// Validate Data

		validateStepsData(manuscriptId, step, isSkipped) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/validateData/${manuscriptId}?stepName=${step}&isSkipped=${isSkipped}`,
				method: "get",
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		exportManuscript(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/export/${manuscriptId}`,
				method: "get",
				responseType: 'blob'
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		fetchManuscripts(sortField, orderBy, start, limit, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/manuscripts?sortField=${sortField}&orderBy=${orderBy}&start=${start}&limit=${limit}`,
				method: "post",
				data: body
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		// step five
		fetchPreviewManuscript(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/previewManuscript/${manuscriptId}`,
				method: "get",
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		completeManuscript(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/completeManuscript/${manuscriptId}`,
				method: "put",
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		cloneManuscript(body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/cloneManuscript`,
				method: "put",
				data: body
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		updateManuscriptStatus(body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/updateManuscriptStatus`,
				method: "put",
				data: body
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}
		
		deleteManuscript(body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/deleteManuscript`,
				method: "delete",
				data: body
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		getJournalGuidelines(journalId, manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/guidelines/${manuscriptId}/${journalId}`,
				method: "get",
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		getReferencesCitations(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/referencesCitations/${manuscriptId}`,
				method: "get",
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		getJournalConfig(journalId, manuscriptId, articleType) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/fetchGuidelinesConfig/${manuscriptId}/${journalId}?articleType=${articleType}`,
				method: "get",
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		getHelpText(journalId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/referenceFormats?journalId=${journalId}`,
				method: "get",
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		getErroredReferences(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/references/${manuscriptId}`,
				method: "post",
				data:body
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		convertReferences(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/convertReferences/${manuscriptId}`,
				method: "post",
				data: body
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		applyConvertedReferences(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/applyConvertedReferences/${manuscriptId}`,
				method: "put",
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		fetchCorrectedReferences(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/fetchCorrectedReferences/${manuscriptId}`,
				method: "get",
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		requestTemplate(journalId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/requestTemplate/${journalId}`,
				method: "get",
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		getJournalNameOptions(body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis//api/v1/serch-service/autoSuggest`,
				method: "post",
				data: body
				
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		getDidYouMean(body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/didyoumean`,
				method: "post",
				data: body
				
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		fecthMandatoryFieldsInfo(journalId,articleType, manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/fecthMandatoryFieldsInfo/${journalId}?articleType=${articleType}&id=${manuscriptId}`,
				method: "get",				
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		validateReferences(manuscriptId, referenceType) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/validateReferences/${manuscriptId}?referenceStyle=${referenceType}`,
				method: "get",				
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		fetchTemplate(manuscriptId, journalId, articleType) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/fetchTemplate/${manuscriptId}/${journalId}?articleType=${articleType}`,
				method: "get",				
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		fetchSuperscriptFormats() {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/superscriptFormats`,
				method: "get",				
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		archiveManuscript(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/archiveManuscript/${manuscriptId}`,
				method: "delete",				
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		checkIrregularInputSearch(body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/irregular_input_finder/check_irregular_input`,
				method: "post",
				data: body			
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		deleteAllManuscript(body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/deleteAllManuscript`,
				method: "delete",
				data: body			
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		editManuscript(body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/editManuscriptStatus`,
				method: "put",
				data: body			
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		languageDetection(body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/detect/language`,
				method: "post",
				data: body			
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		uploadManuscriptDocument(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepTwo/uploadDocument/${manuscriptId}`,
				method: "post",
				data: body,
				headers: {
					"Content-Type": "multipart/form-data"
				}
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		populateTemplate(journalId, articleType, manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/templatePopulate/${journalId}?articleType=${articleType}&id=${manuscriptId}`,
				method: "get",
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		fetchDocumentData(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepTwo/fetchData/${manuscriptId}`,
				method: "get",
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		saveData(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepTwo/saveData/${manuscriptId}`,
				method: "post",
				data: body
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		validateData(manuscriptId, type, autoCorrect="") {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepTwo/validateData/${manuscriptId}?type=${type}&autoCorrect=${autoCorrect}`,
				method: "get",
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		deleteStepTwoManuscript(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepTwo/deleteData/${manuscriptId}`,
				method: "delete",
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}
		// auto correct for step two title and abstract 
		autoCorrect(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepTwo/autoCorrect/${manuscriptId}`,
				method: "post",
				data: body
			}

			return dataDisplayRequest(config).then(res=> {
				return res;
			})
		}

		addStatements(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepFour/statements/${manuscriptId}`,
				method: "post",
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res;
			})
		}

		addEthicalReviewCommitteeStatement(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepFour/ethicalReviewCommitteeStatement/${manuscriptId}`,
				method: "post",
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res;
			})
		}

		addConflictOfInterestStatement(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepFour/conflictOfInterestStatement/${manuscriptId}`,
				method: "post",
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res;
			})
		}

		getStatements(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepFour/statements/${manuscriptId}`,
				method: 'get'
			}
			return dataDisplayRequest(config).then(res => {
				return res;
			})
		}

		getEthicalReviewCommitteeStatement(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepFour/ethicalReviewCommitteeStatement/${manuscriptId}`,
				method: 'get'
			}
			return dataDisplayRequest(config).then(res => {
				return res;
			})
		}

		getConflictOfInterestStatement(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepFour/conflictOfInterestStatement/${manuscriptId}`,
				method: 'get'
			}
			return dataDisplayRequest(config).then(res => {
				return res;
			})
		}

		undoCorrectedChanges(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepTwo/restoreVersion/${manuscriptId}?type=`,
				method: 'post',
				data: {list: []}
			}
			return dataDisplayRequest(config).then(res => {
				return res;
			})
		}

		undoCorrectedOrganizations(manuscriptId, type, wordList) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepTwo/restoreVersion/${manuscriptId}?type=${type}`,
				method: 'post',
				data: wordList
			}
			return dataDisplayRequest(config).then(res => {
				return res;
			})
		}

		removeFiguresAndCaptions(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/removeFiguresAndCaptions/${manuscriptId}`,
				method: 'delete',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res;
			})
		}

		removeOrganizationNames(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/removeOrganizations/${manuscriptId}`,
				method: 'delete',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res;
			})
		}

		getFigureCaptions(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/figureCaptions/${manuscriptId}`,
				method: 'get'
			}
			return dataDisplayRequest(config).then(res => {
				return res;
			})
		}

		getFileCaptions(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/fileCaptions/${manuscriptId}`,
				method: 'post',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res;
			})
		}

		editFigureCaptions(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/editFigureCaption/${manuscriptId}`,
				method: 'put',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res;
			})
		}

		deleteManyFiles(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/deleteManyFiles/${manuscriptId}`,
				method: 'delete',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		getExportErrorFile(manuscriptId, step) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/exportError/${manuscriptId}?stepName=${step}`,
				method: 'get',
				responseType: 'blob'
			}
			return dataDisplayRequest(config).then(res => {
				return res
			}) 
		}

		updateAuthor(manuscriptId, authorId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/stepFour/authors/${manuscriptId}/${authorId}`,
				method: 'put',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		ignoreErrors(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/ignoreErrors/${manuscriptId}`,
				method: 'post',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		fetchAllIgnoredError(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/fetchAllIgnoredErrors/${manuscriptId}`,
				method: 'get'
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		fetchAllIgnoredCaptions(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/fetchAllIgnoredFigureCaptions/${manuscriptId}`,
				method: 'get'
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		fetchAllIgnoredOrganization(manuscriptId) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/fetchAllIgnoredOrganizations/${manuscriptId}`,
				method: 'get'
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		deleteIgnoredError(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/deleteIgnoredErrors/${manuscriptId}`,
				method: 'delete',
				data: body			
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		deleteIgnoredCaption(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/restoreIgnoredFigureCaptions/${manuscriptId}`,
				method: 'post',
				data: body			
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		deleteIgnoredOrganizationName(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/restoreIgnoredOrganizations/${manuscriptId}`,
				method: 'post',
				data: body			
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		ignoreFigureCaption(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/ignoreFigureCaptions/${manuscriptId}`,
				method: 'post',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		ignoreOrganizationName(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/ignoreOrganizations/${manuscriptId}`,
				method: 'post',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		updateStepStatus(manuscriptId, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/data/updateStepTwoStatus/${manuscriptId}`,
				method: 'put',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res;
			})
		}

		// Payment APIs

		fetchPaymentDetails(body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/paymentGateWay/fetchPaymentDetails`,
				method: 'post',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		updateUserWallet(body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/paymentGateWay/updateUserWallet`,
				method: 'post',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		creditUserWallet(body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/paymentGateWay/credituserWallet`,
				method: "post",
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		getStripeUrl(body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/paymentGateWay/create-checkout-session`,
				method: 'post',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		updateTransaction(id, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/paymentGateway/updateTransaction/${id}`,
				method: 'post',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		createTransaction(id, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/paymentGateway/createTransaction/${id}`,
				method: 'post',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		getPaymentStatus(id) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/paymentGateway/fetchPaymentStatus/${id}`,
				method: 'get'
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		checkCoupon(body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/paymentGateway/checkCoupon`,
				method: 'post',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		userPaymentProfile() {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/paymentGateway/userPaymentProfile`,
				method: 'get'
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		purchaseCredit(body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/paymentGateway/purchaseCredit`,
				method: 'post',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}
		
		fetchPurchasedTransaction(queryParams, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/paymentGateway/fetchUserTransactions?start=${queryParams.start}&limit=${queryParams.limit}&sortField=${queryParams.sortField}&orderBy=${queryParams.orderBy}`,
				method: 'post',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		fetchSpentTransaction(queryParams, body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/paymentGateway/fetchUserPurchaseDetails?start=${queryParams.start}&limit=${queryParams.limit}&sortField=${queryParams.sortField}&orderBy=${queryParams.orderBy}`,
				method: 'post',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}
		//End of payment APIs

		//Geocoding API
		getCountryName(lat, long) {
			return Axios.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?&longitude=${lat}&longitude=${long}`).then(res => {
				return res
			})
		}
		//End of Geocoding API

		getPriceTable() {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/paymentGateway/prices`,
				method: 'get'
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		// Admin API

		fetchUsers(body, queryParams) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/admin/users?start=${queryParams.start}&limit=${queryParams.limit}&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}`,
				method: 'post',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		fetchUserDetails(body, queryParams) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/admin/userDetails?start=${queryParams.start}&limit=${queryParams.limit}&sortField=${queryParams.sortField}&orderBy=${queryParams.orderBy}`,
				method: 'post',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		fetchUserSpentTransaction (body, queryParams) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/admin/fetchUserPurchaseDetails?start=${queryParams.start}&limit=${queryParams.limit}&sortField=${queryParams.sortField}&orderBy=${queryParams.orderBy}`,
				method: 'post',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		fetchUserPurchaseTransaction (body, queryParams) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/admin/fetchUserTransactions?start=${queryParams.start}&limit=${queryParams.limit}&sortField=${queryParams.sortField}&orderBy=${queryParams.orderBy}`,
				method: 'post',
				data: body
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		exportUsers (body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/admin/exportUsers`,
				method: 'post',
				data: body,
				responseType: 'blob'
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		exportUserTransctions (body) {
			const config = {
				url: `${API_BASE_URL}/orthopubInterfaceApis/api/v1/admin/exportUserTransctions`,
				method: 'post',
				data: body,
				responseType: 'blob'
			}
			return dataDisplayRequest(config).then(res => {
				return res
			})
		}

		// End of Admin API
}