import { HAS_SUBJECT_FILTER, HAS_JOURNAL_FILTER, HAS_PUBLICATION_YEAR_FILTER, HAS_OA_FILTER, HAS_BJ_FILTER, HAS_PUBMED_FILTER, HAS_NO_SUBJECT_FILTER, HAS_NO_JOURNAL_FILTER, HAS_NO_PUBLICATION_YEAR_FILTER, HAS_NO_OA_FILTER, HAS_NO_BJ_FILTER, HAS_NO_PUBMED_FILTER } from '../actions/FilterConfigAction';

const initialState = {
    subject_filter: false,
    journal_filter: false,
    publication_year_filter: false,
    oa_filter: false,
    bj_filter: false,
    pubmed_filter: false
}

const filterConfigReducer = (state = initialState, action) => {
    switch(action.type){
        case HAS_SUBJECT_FILTER:
            return {...state, subject_filter: action.payload}
        case HAS_JOURNAL_FILTER:
            return { ...state, journal_filter: action.payload }
        case HAS_PUBLICATION_YEAR_FILTER:
            return { ...state, publication_year_filter: action.payload }
        case HAS_OA_FILTER:
            return { ...state, oa_filter: action.payload }
        case HAS_BJ_FILTER:
            return { ...state, bj_filter: action.payload }
        case HAS_PUBMED_FILTER:
            return { ...state, pubmed_filter: action.payload }
        case HAS_NO_SUBJECT_FILTER:
            return { ...state, subject_filter: action.payload}
        case HAS_NO_JOURNAL_FILTER:
            return { ...state, journal_filter: action.payload }
        case HAS_NO_PUBLICATION_YEAR_FILTER:
            return { ...state, publication_year_filter: action.payload }
        case HAS_NO_OA_FILTER:
            return { ...state, oa_filter: action.payload }
        case HAS_NO_BJ_FILTER:
            return { ...state, bj_filter: action.payload }
        case HAS_NO_PUBMED_FILTER:
            return { ...state, pubmed_filter: action.payload }
        default:
            return state;
    }
}

export default filterConfigReducer;