import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import styles from "./AdminDashboard.module.scss"
import classNames from "classnames";
import history from "../../history";
import CreditPurchased from "../Profile/creditPurchased";
import { toast } from "react-toastify";

const AdminUserPurchaseHistory = ({activeTab, location}) => {

    const user = location?.state?.user

    useEffect(() => {
        if(user === undefined) {
            toast.error("User's details are missing. Please select user from Admin Dashboard.")
            setTimeout(() => {
                history.push("/admin-dashboard")
            }, 1000);
        }
    }, [])

    return (
        user ? 
        <div>
            <div className="d-flex flex-row my-3 mx-2 align-items-center">
                <span className={classNames(styles.secondaryTextColor, "cursor-pointer")} onClick={() => {history.push("/admin-dashboard")}}>Admin Dashboard</span>
                <span className="border-left border-dark pl-2 ml-2 text-secondary">{user ? user.metadata.first_name + " " + user.metadata.last_name : <Spinner animation="grow" size="sm"/>}</span>
            </div>
            <div className="d-flex flex-column my-3 mx-2 justify-content-center">
                <h2>{user ? user.metadata.first_name + " " + user.metadata.last_name : <Spinner animation="grow" size="sm"/>}</h2>
                <span className="text-secondary justify-content-center text-truncate">
                    {user.email ? <span>{user.email}</span> : ""}
                    {user.metadata.phone ? <span className="border-left border-dark pl-2 ml-2">{user.metadata.phone}</span> : ""}
                    {user.metadata.affiliation ? <span className="border-left border-dark pl-2 ml-2">{user.metadata.affiliation}</span> : ""}
                    {user.metadata.designation ? <span className="border-left border-dark pl-2 ml-2">{user.metadata.designation}</span> : ""}
                    {user.metadata.orcid ? <span className="border-left border-dark pl-2 ml-2">{user.metadata.orcid}</span> : ""}
                    {user.metadata.country ? <span className="border-left border-dark pl-2 ml-2">{user.metadata.country}</span> : ""}
                </span>
            </div>
            <div className="card shadow">
                <CreditPurchased activeTab="creditPurchased" userId={user.id}/>
            </div>
        </div>
        :
        <div className="d-flex flex-row justify-content-center mt-5 mb-5">
            <Spinner animation="grow" className={styles.secondaryTextColor}/>
        </div>
    )
}

export default AdminUserPurchaseHistory