import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const TooltipButton = ({children, key, placement, tooltipMessage}) => {
	return(
		tooltipMessage?.length ? 
			<OverlayTrigger
				trigger="hover"
				key={key}
				placement={placement}
				overlay={
					<Tooltip id={key}>
						{tooltipMessage}
					</Tooltip>
				}
			>
				{children}
			</OverlayTrigger>
		:
			<>
				{children}		
			</>
	)
}

export default TooltipButton;