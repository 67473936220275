// Live Constants
export const API_BASE_URL = "https://orthopublish.org.uk/orthopub_service";
// export const googleAnalyticsTrackingId = 'G-3J8XHKWS70';
export const googleAnalyticsTrackingId = 'G-FYVZSQNW94';  
export const origin = window.location.origin


//Test Constants
// export const API_BASE_URL = "https://orthopub-dev.molecularconnections.com/orthopub_service";
// export const googleAnalyticsTrackingId = 'G-4FPKYZLE9Z' //Personal GA 
// export const origin = window.location.origin + "/orthopub" 

// export const authContentType = "application/x-www-form-urlencoded";
