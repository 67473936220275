import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./AccessDenied.module.scss"

const AccessDenied = () => {
    const search = useLocation().search;
    const message = new URLSearchParams(search).get("message");

    return(
        <div className={styles.accessDenied}>
            <img className={styles.logo} src={process.env.PUBLIC_URL + '/logos/orthopublish-logo.svg'} alt="logo" />
            <h1 className="mt-4 mb-3 text-danger">{message}</h1>
            <div>
                <span>Go back to </span>
                <Link to="/home">Home</Link>
            </div>
        </div>
    );
}

export default AccessDenied;