export const SET_GATE_OPEN = "GATE_OPEN";
export const SET_GATE_CLOSE = "GATE_CLOSE";
export const SET_DISCARD_TRUE = "DISCARD_TRUE";
export const SET_DISCARD_FALSE = "DISCARD_FALSE";

export function setGateOpen(){
    return{
        type: SET_GATE_OPEN,
        payload: false
    }
}

export function setGateClose(){
    return{
        type: SET_GATE_CLOSE,
        payload: true
    }
}

export function setDiscardTrue(){
    return {
        type: SET_DISCARD_TRUE,
        payload: true
    }
}

export function setDiscardFalse(){
    return {
        type: SET_DISCARD_FALSE,
        payload: false
    }
}