export const HAS_RELEVANCE_SORT = "HAS_RELEVANCE_SORT";
export const HAS_LATEST_SORT = "HAS_LATEST_SORT";
export const HAS_OA_SORT = "HAS_OA_SORT";
export const HAS_JOURNAL_SORT = "HAS_JOURNAL_SORT";
export const HAS_CITATION_SCORE_SORT = "HAS_CITATION_SCORE_SORT";
export const HAS_NO_RELEVANCE_SORT = "HAS_NO_RELEVANCE_SORT";
export const HAS_NO_LATEST_SORT = "HAS_NO_LATEST_SORT";
export const HAS_NO_JOURNAL_SORT = "HAS_NO_JOURNAL_SORT";
export const HAS_NO_OA_SORT = "HAS_NO_OA_SORT";
export const HAS_NO_CITATION_SCORE_SORT = 'HAS_NO_CITATION_SCORE_SORT';

export function hasRelevanceSort(){
    return {
        type: HAS_RELEVANCE_SORT,
        payload: true
    }
}

export function hasLatestSort(){
    return {
        type: HAS_LATEST_SORT,
        payload: true
    }
}

export function hasOASort(){
    return {
        type: HAS_OA_SORT,
        payload: true
    }
}

export function hasJournalSort(){
    return {
        type: HAS_JOURNAL_SORT,
        payload: true
    }
}

export function hasCitationScoreSort(){
    return {
        type: HAS_CITATION_SCORE_SORT,
        payload: true
    }
}

export function hasNoRelevanceSort(){
    return {
        type: HAS_NO_RELEVANCE_SORT,
        payload: false
    }
}

export function hasNoLatestSort(){
    return {
        type: HAS_NO_LATEST_SORT,
        payload: false
    }
}

export function hasNoOASort(){
    return{
        type: HAS_NO_OA_SORT
    }
}

export function hasNoJournalSort(){
    return {
        type: HAS_NO_JOURNAL_SORT,
        payload: false
    }
}

export function hasNoCitationScoreSort(){
    return {
        type: HAS_NO_CITATION_SCORE_SORT,
        payload: false
    }
}