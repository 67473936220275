import { SET_GATE_CLOSE, SET_GATE_OPEN, SET_DISCARD_TRUE, SET_DISCARD_FALSE } from "../actions/NavigateAwayAlertAction";

const initialState = {
    gateStatus: false,
    discardButton: true
}

const navigateAwayAlertReducer = (state=initialState, action) => {
    switch(action.type){
        case SET_GATE_OPEN:
            return{...state, gateStatus: action.payload}
        case SET_GATE_CLOSE:
            return{...state, gateStatus: action.payload}
        case SET_DISCARD_TRUE: 
            return{...state, discardButton: true}
        case SET_DISCARD_FALSE: 
            return{...state, discardButton: false}
        default:
            return state;
    }
}

export default navigateAwayAlertReducer;