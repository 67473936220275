import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagination.css';
import { Col, FormControl } from 'react-bootstrap';
import { useState } from 'react';
import classNames from 'classnames';


const Pagination = props => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className,
        handleGoToPagination,
        pageNo,
        setPageNo
    } = props;

    const screen = window.screen.width < 576 ? "small" : "large"
 
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
        screen
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    const handleChange = (event) => {
        event.preventDefault();
        const {value} = event.target
        const isInteger = /^[0-9]+$/;
        if (value === '' || isInteger.test(value)) {
          setPageNo(value)
        }
    }

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <div className='border rounded bg-white row mx-0'>					
        <Col md={7} sm={12} className='mx-0 px-0'>
            <ul
            className={classnames('pagination-container justify-content-start mb-0', { [className]: className })}
        >
            <li key="prev"
                className={classnames('pagination-item', {
                    disabled: currentPage === 1
                })}
                onClick={onPrevious}
            >
                <div className="arrow left" />
            </li>
            {paginationRange.map((pageNumber, index) => {
                if (pageNumber === DOTS) {
                    return <li  key={index} className="pagination-item dots">&#8230;</li>;
                }

                return (
                    <li  key={index}
                        className={classnames('pagination-item', {
                            selected: pageNumber === currentPage
                        })}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        {pageNumber}
                    </li>
                );
            })}
            <li
                key="next"
                className={classnames('pagination-item', {
                    disabled: currentPage === lastPage
                })}
                onClick={onNext}
            >
                <div className="arrow right" />
            </li>
            </ul>
        </Col>
        <Col md={5} sm={12} className="d-flex justify-content-end align-items-center">
            <FormControl
            className={classNames('mr-2 goToInput')} 
            pattern="\d*"
            type="text"
            value={pageNo}
            onChange={(e)=> handleChange(e)}
			onKeyUp={(event)=>event.keyCode ===13? handleGoToPagination(): null}
            />/{lastPage}
            <button className='ml-2' onClick={()=> handleGoToPagination()}>Go</button>
        </Col>
		</div>
       
    );
};

export default Pagination;
