import React, { useEffect, useState } from 'react';
import { Link, useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import styles from './JournalDetails.module.scss';
import { Card, Col, Modal, Row, ProgressBar, Spinner } from 'react-bootstrap';
import AxiosService from '../../utils/AxiosService';
import { useParams } from 'react-router-dom';
import { SampleAbstract } from '../../components/SampleAbstract/SampleAbstract';
import JournalInfo from '../../components/Journals/JournalInfo';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { dataDisplayRequest } from '../../utils/auth_service/AuthServiceHelper';
import { FieldLoader } from '../../components/Loader/CommonLoader';
import ConfirmationModal from '../Menuscript/ConfirmationModal/ConfirmationModal';
import TooltipButton from '../../components/TooltipButton/TooltipButton';
import PaymentModals from '../PaymentPages/paymentModal';

function JournalDetails(){

	const [preLoader, setPreLoader] = useState(false);
	const [journalDetails, setJournalDetails] = useState([]);
	const [abstractPreLoader, setAbstractPreLoader] = useState(false);
	const [abstractDetails, setAbstractDetails] = useState([]);
	const [show, setShow] = useState(false);
	const [force, setForce] = useState(false);
	const [isloading, setIsLoading] = useState(false);
	const [isBtnLoader, setButtonLoader] = useState({isDraftBtn: false, isCreateBtn: false});
	const [manuscriptDetails, setManuscriptDetails] = useState([]);
	const [showJournalConfirmationModal, setShowJournalConfirmationModal] = useState({isConfirmationCheck: false})
	const [creditModalControl, setCreditModalControl] = useState({type: "generic", sufficientCreditModal: false, insufficientCreditModal: false})
	const [userCreditDetails, setUserCreditDetails] = useState({})
	const [genericLoading, setGenericLoading] = useState(false)
	const [cloneModalControler, setCloneModalControler] = useState({visible: false, loading: false})
	const {lexiconId} = useParams();
	const apiService = new AxiosService();
	const history = useHistory();
	const queryParams = new URLSearchParams(window.location.search)
	const creditModal = queryParams.get("creditModal")
	const clone = queryParams.get("clone")
	const manuscriptId = queryParams.get("manuscriptId")
	const templateType = queryParams.get('templateType')

	const[loading, setLoading] = useState(false)
	const[genericTemplate, setGenericTemplate] = useState(false)

	const handleRequestTemaplate =() => {
		setLoading(true)
		apiService.requestTemplate(journalDetails.lexicon_id).then(res=> {
			setLoading(false)
			toast.success(res.message,  {
				autoClose: 3000,
			})
		}).catch(err=> {
			setLoading(false)
			toast.error(err.response.data.message,  {
				autoClose: 3000,
			})
		})
	}

	function getJournalDetails(){
		setPreLoader(true);
		const config = {
			method: 'get',
			url: '/api/v1/serch-service/getDetail/' + lexiconId,
		}
		dataDisplayRequest(config).then(response => {
			setPreLoader(false);
			setJournalDetails(response.data);
			getJournalSampleAbstracts(response.data.journal_title);
		}, error => {
			setPreLoader(false);
			toast.error(error.response.data,  {
				autoClose: 3000,
			});
		})
	}

	function getJournalSampleAbstracts(journal_title){
		setAbstractPreLoader(true);
		const config = {
			method: "get",
			url: "/api/v1/serch-service/abstracts/" + lexiconId
		}
		dataDisplayRequest(config).then(response => {
			setAbstractPreLoader(false);
			setAbstractDetails(response.data);
		}, error => {
			setAbstractPreLoader(false);
			toast.error(error.response.data,  {
				autoClose: 3000,
			});
		})
	}

	const getManuscriptData = (force, genericTemplate) => {
			const body = {
					"journalId": lexiconId,
					"force": force
			}
			if(genericTemplate) {
				body.templateType = "Generic"
			}
			apiService.createManuscript(body).then(res => {
				setIsLoading(false);
				setButtonLoader({...isBtnLoader, isCreateBtn: false})
				toast.success("Created successfully.",  {
					autoClose: 3000,
				});
				history.push(`/manuscript-submission/${res.manuscriptId}`);
			}).catch(e=> {
				setIsLoading(false);
				setButtonLoader({...isBtnLoader, isCreateBtn: false})
				if(e.response.status === 409) {
					setManuscriptDetails(e.response.data)
					setShow(true);
				}else{
					toast.error(e.response.data.message,  {
						autoClose: 3000,
					})
				}
			});
	}

	const createDefaultManuscript = () => {
		setIsLoading(true);
		setForce(false)
		if(genericTemplate) {
			getManuscriptData(false, true);
		} else {
			getManuscriptData(false, false);
		}
	}

	const forceToCreateManuscript = () => {
		setForce(true);
		if(genericTemplate) {
			setButtonLoader({...isBtnLoader, isCreateBtn: true});
			getManuscriptData(true, true);
		} else {
			setButtonLoader({...isBtnLoader, isCreateBtn: true});
			getManuscriptData(true, false);
		}
	}

	const handleDrafts = () => {
		setButtonLoader({...isBtnLoader, isDraftBtn: true})
		history.push(`/my-dashboard?journalTitle=${encodeURIComponent(journalDetails.journal_title)}&status=Draft`)
	}

	const genericTemplateClick = () => {
		setGenericLoading(true)
		apiService.userPaymentProfile().then((res) => {
			setUserCreditDetails(res)
			setGenericLoading(false)
			if (creditModal === "generic" && clone === "true" && templateType === "Generic") {
				setCloneModalControler({...cloneModalControler, visible: true})
			} else if(res.credit >= 1 ) {
				setCreditModalControl({...creditModalControl, type: "Generic", sufficientCreditModal: true})
			} else {
				setCreditModalControl({...creditModalControl, type: "Generic", insufficientCreditModal: true})
			}
		}).catch((err) => {
			toast.error(err.response.data.message, {autoClose: 3000})
			setGenericLoading(false)
		})
	} 
 
	const specificTemplateClick = () => { 
		setLoading(true)
		apiService.userPaymentProfile().then((res) => {
			setUserCreditDetails(res)
			setLoading(false)
			if(res.credit >= 2 ) {
				setCreditModalControl({...creditModalControl, type: "Specific", sufficientCreditModal: true})
			} else {
				setCreditModalControl({...creditModalControl, type: "Specific", insufficientCreditModal: true})
			}
		}).catch((err) => {
			setLoading(false)
			toast.error(err.response.data.message, {autoClose: 3000})
		})
	}

	const handleClone = () => {
		setCloneModalControler({...cloneModalControler, loading: true})
		apiService.cloneManuscript({"id": manuscriptId, "journalId": journalDetails.lexicon_id, "templateType":"Generic" }).then(res=>{
			toast.success(res.result,  {
				autoClose: 3000,
			});
			history.push(`/manuscript-submission/${res.manuscriptId}`)
			setGenericLoading(false)
			setCloneModalControler({...cloneModalControler, visible: false, loading: false})	
		}).catch(err=>{
			toast.error("Journal Not Found!", {autoClose: 3000})
			setGenericLoading(false)
			setCloneModalControler({...cloneModalControler, visible: false, loading: false})
		})
	}

	useEffect(() => {
			getJournalDetails();
	}, [])

	useEffect(() => {
		if (creditModal === "generic") {
			genericTemplateClick()
		} else if (creditModal === "specific") {
			specificTemplateClick()
		}
	}, [])
 
		return(
				<div className='mt-3'>
						<div className={styles.actionDiv}>
							<button className={styles.customLink} onClick={() => history.goBack()}>
								<FontAwesomeIcon icon={faArrowLeftLong} className='mr-2'/> 
								Back to journal list
							</button>
							{ isloading || preLoader ? 
								<FieldLoader className={classNames("my-3", styles.loader)} message="Loading" isLoading={isloading} /> 
							: 
								journalDetails.formatting ==="1" ? 
									<button className='my-3' onClick={(e) => {specificTemplateClick()}}>
										{loading ? 
											<>
												<Spinner animation='grow' size='sm'/>
												<Spinner animation='grow' size='sm'/>
												<Spinner animation='grow' size='sm'/>
											</>  
										: 
											<>
												Format Manuscript 
												<FontAwesomeIcon icon={faAngleRight} />
											</>
										}
									</button>
								: 
										<div>
											<TooltipButton placement="left" tooltipMessage="Our generic template will help you meet common requirements for submission from reference, figure and table citations to figure quality and formatting errors.">
												<button className='my-3 mr-2' disabled={loading} 
													onClick={(e)=> {
														genericTemplateClick()
													}}>
													{genericLoading ? 
															<>
																<Spinner animation='grow' size='sm'/>
																<Spinner animation='grow' size='sm'/>
																<Spinner animation='grow' size='sm'/>
															</>  
														: 
															"Use generic template"
														}
												</button> 
											</TooltipButton>
											<TooltipButton placement="left" tooltipMessage="There is no template available for this journal yet. Please click to here to notify our team so we can prioritise future development.">
												<button className='btn-outline-info btn my-3' disabled={genericLoading} onClick={()=> handleRequestTemaplate()}>
													{loading ? 
														<>
															<Spinner animation='grow' size='sm'/>
															<Spinner animation='grow' size='sm'/>
															<Spinner animation='grow' size='sm'/>
														</>  
													: 
														"Request template"
													}
												</button>
											</TooltipButton>
										</div>
							}
							<Modal show={show} onHide={()=> setShow(false)}>
								<Modal.Header closeButton className='border-bottom-0'>
									<Modal.Title className='h5'>Multiple drafts alert</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<div>
										<p className={styles.message}>You have previously created <strong>{manuscriptDetails.count}</strong> submission drafts for this journal. Would you like to create a new submission or continue working on a previous draft? </p>
										<section>
											{isBtnLoader.isDraftBtn ? 
											<FieldLoader className={classNames('w-100 mb-2', styles.customButton)} isLoading={isBtnLoader.isDraftBtn} /> :
											<button className={classNames('w-100 mb-2', styles.customButton)} onClick={handleDrafts}>Continue working on a draft</button>}
											{isBtnLoader.isCreateBtn ? 
											<FieldLoader className="w-100" isLoading={isBtnLoader.isCreateBtn} /> :
											<button className="w-100" onClick={forceToCreateManuscript}>Create a new submission</button>
											 }
										</section>
									</div>
								</Modal.Body>
							</Modal>
						</div>
						{preLoader ?	
							<Card id="journalInfo" className='mb-5'>
								<Card.Body>
									<>
										<ProgressBar animated now={100} variant="secondary" className='mb-2'/>
										<ProgressBar animated now={100} variant="secondary" className='mb-2'/>
										<ProgressBar animated now={100} variant="secondary" />
									</>
								</Card.Body>
							</Card>	
						:	<JournalInfo journalInfo={journalDetails} isloading={preLoader} key={1} visible={false} /> }
						
						<Card id="scope" className='mb-5'>
							<Card.Body>
								<h5>Scope</h5>
								{preLoader ?
									<>
										<ProgressBar animated now={100} variant="secondary" className='mb-2'/>
										<ProgressBar animated now={100} variant="secondary" className='mb-2'/>
										<ProgressBar animated now={100} variant="secondary" />
									</>
								: 
								<Row>
									<Col md={7}>
										<span className={styles.scopeData} dangerouslySetInnerHTML={{ __html: `${journalDetails.scope}` }}></span>
										{journalDetails.article_processing_charge ? <div className='my-4'>
											<h5>Article processing charges</h5>
											{/* <h6 className={classNames(styles.customLink, styles.font12, 'font-weight-bold')}>ARTICLE PROCESSING CHARGES</h6> */}
											{/* <h6 className={styles.font18}>{journalDetails.article_processing_charge}</h6> */}
											<span className={styles.scopeData}>{journalDetails.article_processing_charge}</span>
										</div> : null}
										{journalDetails.time_for_first_decision ? <div className='mt-4'>
											<h5>Average time to first decision</h5>
											<span className={styles.scopeData}>{journalDetails.time_for_first_decision}</span>
											{/* <h6 className={classNames(styles.customLink, styles.font12, 'font-weight-bold text-uppercase')}>Average time to first decision</h6> */}
											{/* <h6 className={styles.font18}>{journalDetails.time_for_first_decision}</h6> */}
										</div> : null}
									</Col>
									<Col md={1} className='d-flex justify-content-center align-items-center'>
											<div className={styles.verticalDivider}></div>
									</Col>
									<Col md={4}>
										{/* <h6 className={classNames(styles.customLink, styles.font12, 'font-weight-bold')}>EXTERNAL LINKS</h6> */}
										<h5>External links</h5>
										<ul className={styles.nonDotList}>
											{journalDetails.journal_homepage ? 
												<li>
													<a className={styles.customButton} href={journalDetails.journal_homepage} target="_blank">Journal homepage <FontAwesomeIcon icon={faExternalLink} /></a>
												</li> : null}
											{journalDetails.editiorial_information ? 
												<li>
													<a className={styles.customButton} href={journalDetails.editiorial_information} target="_blank">Editor information <FontAwesomeIcon icon={faExternalLink} /></a>
												</li> : null}
											{journalDetails.author_instructions ? 
												<li>
													<a className={styles.customButton} href={journalDetails.author_instructions} target="_blank">Author instructions <FontAwesomeIcon icon={faExternalLink} /></a>
												</li> : null}
											{journalDetails.article_submission_information ? 
												<li>
													<a className={styles.customButton} href={journalDetails.article_submission_information} target="_blank">Article submisson site <FontAwesomeIcon icon={faExternalLink} /></a>
												</li> : null}
										</ul>
									</Col>
								</Row>}
							</Card.Body>
						</Card>
						<Card>
							<Card.Body>
								<h5>Sample Content for this Journal</h5>
								{abstractPreLoader ?
									<>
										<ProgressBar animated now={100} variant="secondary" className='mb-2'/>
										<ProgressBar animated now={100} variant="secondary" className='mb-2'/>
										<ProgressBar animated now={100} variant="secondary" />
									</>
								: abstractDetails.map(detail => (
										SampleAbstract(detail)
								))}
							</Card.Body>
						</Card>
						<ConfirmationModal
							show={showJournalConfirmationModal}
							setShow={setShowJournalConfirmationModal}
							loader={isloading}
							createDefaultManuscript={createDefaultManuscript}
						/>
						<PaymentModals
							clone={clone === "true" ? true : false}
							journalInfo={journalDetails}
							creditModalControl={creditModalControl}
							setCreditModalControl={setCreditModalControl}
							userCreditDetails={userCreditDetails}
							manuscriptId={manuscriptId}
						/>
						<Modal show={cloneModalControler.visible} 
							onHide={() => {
								setCloneModalControler({...cloneModalControler, visible: false, loading: false})
							}}
						>
							<Modal.Header closeButton className='align-items-center'>
								<div className='d-flex flex-column'>
									<h6>Proceed to manuscript formatting for</h6>
									<span className='text-secondary small'><strong>{journalDetails.journal_title}</strong></span>
								</div>
							</Modal.Header>
							<Modal.Body>
								<p className='text-secondary'>
									Please note that you are reformatting between two journals which use OrthoPublish’s generic template. No changes will be made to the manuscript and only the name of the chosen journal will be changed in the output files.
								</p>
								<div className='d-flex justify-content-center mt-3'>
									<button className='w-50' onClick={() => {
										handleClone()
									}}>
										{cloneModalControler.loading ? <><Spinner animation='grow' size='sm'/></> : "Continue"}
									</button>
								</div>
							</Modal.Body>
						</Modal>
				</div>
		)
}

export default JournalDetails;