import {TOKEN_EXPIRED, TOKEN_REFRESHED} from '../actions/RefreshTokenAction';

const initialState = {
    tokenRefresh: false,
}

const refreshTokenReducer = (state=initialState, action) => {
    switch(action.type){
        case TOKEN_EXPIRED:
            return{...state, tokenRefresh: action.payload}
        case TOKEN_REFRESHED:
            return{...state, tokenRefresh: action.payload}
        default:
            return state;
    }
}

export default refreshTokenReducer;