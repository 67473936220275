import React, { useEffect } from "react";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { Handle, Track, Tick, TooltipRail } from "./CustomSlider"; // example render components - source below

const sliderStyle = {
    position: 'relative',
    width: '100%',
}

function SliderBar(props) {

    const domain = props.domain;
    const defaultValues = [props.fromRange, props.toRange],
    values = defaultValues.slice();

    function handleSlide(range){
        props.getDateRange(range)
    }

    useEffect(() => { }, [])

    return (
        <div style={{ marginTop: '1rem', marginBottom: '3.75rem', width: '100%' }} className="px-2">

            <Slider
                mode={2}
                step={1}
                domain={domain}
                rootStyle={sliderStyle}
                values={values}
                onSlideEnd={(range) => handleSlide(range)}
            >
                <Rail>{railProps => <TooltipRail {...railProps} />}</Rail>
                <Handles>
                    {({ handles, activeHandleID, getHandleProps }) => (
                        <div className="slider-handles">
                            {handles.map(handle => (
                                <Handle
                                    key={handle.id}
                                    handle={handle}
                                    domain={domain}
                                    isActive={handle.id === activeHandleID}
                                    getHandleProps={getHandleProps}
                                />
                            ))}
                        </div>
                    )}
                </Handles>
                <Tracks left={false} right={false}>
                    {({ tracks, getTrackProps }) => (
                        <div className="slider-tracks">
                            {tracks.map(({ id, source, target }) => (
                                <Track
                                    key={id}
                                    source={source}
                                    target={target}
                                    getTrackProps={getTrackProps}
                                />
                            ))}
                        </div>
                    )}
                </Tracks>
                <Ticks count={10}>
                    {({ ticks }) => (
                        <div className="slider-ticks">
                            {ticks.map(tick => (
                                <Tick key={tick.id} tick={tick} count={ticks.length} />
                            ))}
                        </div>
                    )}
                </Ticks>
            </Slider>
        </div>
    );
}

export default SliderBar;