import React, { useState} from 'react'
import { Tabs, Tab } from 'react-bootstrap'

export default function GuidelineTab({data}) {
  const [key, setKey] = useState(0)

  return (
      <Tabs id="tinyTab" activeKey={key} onSelect={(e) => setKey(e)}>
        {data.map((tabData, index)=>
                    <Tab eventKey={index} title={tabData.name}>
                    <ul>{tabData.helpText.map(h => 
                        <li className="my-2">{h.header}:
                            <ul>
                                {h.list.map(list => <li>{list}</li>)}
                            </ul>
					    </li>)}
                    </ul>
                  </Tab>
            )}
      </Tabs>
  )
}