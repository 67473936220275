import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Col, FormControl, InputGroup, ListGroup, Row } from "react-bootstrap";
import "./CustomeAutoComplete.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const CustomeAutoComplete = ({isEditable, searchValue, setSearchValue,name, handleSelect,  optionList, loading,  placeholder, getOptions, component, getEnterSearch, onBlur, fileDesignation }) => {
    const wrapperRef = useRef(null)
    const [display, setDisplay]= useState(false)
    
    const debounceOnChange = useCallback(debounce(getOptions, 1000),[])

    const handleChange = (e) => {
      setDisplay(false)
      setSearchValue(e);
      if(e.target.value?.trim()?.length >3){
        setDisplay(true)
        debounceOnChange(e.target.value?.trim())
      }
      if(component ==="fileCaption")
      {
        setDisplay(true)
        debounceOnChange(e.target.value?.trim(), fileDesignation) 
      }
    }

    const handleSelectOption = (option) => {
      handleSelect(option)
      setDisplay(!display)
    }

    const handleKeyUp = (event) => {
      if(event.keyCode ===13){
        setDisplay(!display)
        if(!component) {
          getEnterSearch(event)
        }
      }
    }

    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);
        return () => {
          window.removeEventListener("mousedown", handleClickOutside);
        };
      },[]);

      const handleClickOutside = event => {
        const { current: wrap } = wrapperRef;
        if (wrap && !wrap.contains(event.target)) {
          setDisplay(false);
        }
      };

      useEffect(()=> {
        window?.WEBSPELLCHECKER.stopAutoSearch()
      },[])
      
    return(
      <Row className="mx-0">
        <Col md={12} className="px-0">
        <div ref={wrapperRef} className="w-100">
            <InputGroup className="border rounded">
           
            {isEditable ? <textarea contentEditable
            id="editText"
            className="form-control  border-0"
            name={name}
            value={searchValue}
            onClick={()=> setDisplay(!display)}
            onChange={(e)=> handleChange(e)}
            onKeyUp={(e)=> handleKeyUp(e)}
            placeholder={placeholder}
            autoComplete='off'
            onBlur={onBlur}
            />:
            <input
            className="form-control  border-0 h-auto"
            id="auto"
            name={name}
            value={searchValue}
            onClick={()=> setDisplay(!display)}
            onChange={(e)=> handleChange(e)}
            onKeyUp={(e)=> handleKeyUp(e)}
            placeholder={placeholder}
            autoComplete='off'
            onBlur={onBlur}
          />}
            {component==="figureCaption" && <InputGroup.Text className="bg-transparent border-0 px-1" onClick={()=> setDisplay(!display)}><FontAwesomeIcon  icon={faAngleDown} size="sm" /></InputGroup.Text>}
          </InputGroup>
            
            {display && optionList && <div className="position-absolute z-4 w-100"><ListGroup className="dropDown border">
                {loading? <ListGroup.Item className="w-100 border-0">Loading...</ListGroup.Item>
                :component==="keyword" ?
                 optionList.map(option =><ListGroup.Item className="w-100 border-0 cursor-pointer customeStyle" key={option.cid} onClick={()=> handleSelectOption(option)}>{option.name}</ListGroup.Item>)
                :optionList.map(option =><ListGroup.Item className="w-100 border-0 cursor-pointer customeStyle" key={option} onClick={()=> handleSelectOption(option)}>{option}</ListGroup.Item>)} </ListGroup>
            </div>}
        </div>
        </Col>
      </Row>
        
    )
}

export default CustomeAutoComplete;

