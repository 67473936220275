import { SET_JOURNAL_CONFIG } from "../actions/JournalConfigAction";

const initialState = {
    config: {}
}

const journalConfigReducer = (state=initialState, action) => {
    switch(action.type){
        case SET_JOURNAL_CONFIG:
            return { config: action.payload }
        default:
            return state;
    }
}

export default journalConfigReducer;