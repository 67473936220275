import React, { useState, useEffect } from 'react';
import AxiosService from '../../utils/AxiosService';
import {Row, Col, InputGroup, FormControl, Spinner, Card} from 'react-bootstrap';
import { toast } from 'react-toastify';
import history from '../../history'
import styles from './ForgetPassword.module.scss';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

function ForgetPassword(){

    const apiService = new AxiosService();
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const [isLoading, setIsLoading] = useState(false);
    const [invalidCredentials, setInvalidCredentials] = useState('');
    const [newPasswordToggle, setNewPasswordToggle] = useState(false);
    const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);

    const [formValues, setFormValues] = useState({newPassword: '', confirmPassword: ''});
    const [formErrors, setFormErrors] = useState({newPassword: '', confirmPassword: ''})
    const [errorResponseStatus, setErrorResponseStatus] = useState(200);
    const [resendMailSuccess, setResendMailSuccess] = useState(false);
    const validPassword = RegExp("\\s");

    function handleChange(event) {
        event.preventDefault();
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
        setFormErrors({ ...formErrors, [name]: value.length > 0 && ""})
    }

    function handleValidation(event){
        const { name, value } = event.target;
        switch (name) {
            case "newPassword":
                setFormErrors({
                    ...formErrors,
                    [name]: value.length > 0 ? value.length > 5 ? (validPassword.test(value) ? "Whitespaces not allowed" : "") : "Password must be min 6 characters" : " Password is required",
                    confirmPassword: formValues.confirmPassword.length > 5 ? value === formValues.confirmPassword ? "" : "Passwords didn't match" : ""
                });
                break;
            case "confirmPassword":
                setFormErrors({ ...formErrors, [name]: value === formValues.newPassword ? "" : "Passwords didn't match" })
                break;
            default:
                break;
        }
    }

    function validateForm() {
        return formValues.newPassword.length >= 6 && formValues.confirmPassword.length === formValues.newPassword.length && !(validPassword.test(formValues.newPassword));
    }

    function resetFormFields() {
        setFormValues({ ...formValues, newPassword: "", confirmPassword: "" });
    }

    function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        apiService.forgetPasswordConfirmation(token, formValues)
            .then(response => {
                setIsLoading(false);
                setInvalidCredentials("");
                resetFormFields();
                toast.success("Password changed successfully!",  {
                    autoClose: 3000,
                });
                history.push('/search');
            })
            .catch(error => {
                const errorData =  error.response.data;
                setIsLoading(false);
                switch (errorData.errorCode) {
                    case 400:
                    case 401:
                        setInvalidCredentials(errorData.message);
                        resetFormFields();
                        break;
                    default:
                        break;
                }
            });
    }

    function errorResponseHandler(){
        if(errorResponseStatus === 400){
            return <div><h5>Oops! Invalid link.</h5><button className='w-100' onClick={() => {history.push("/search")}}>Continue to site</button></div>
        }
        else if(errorResponseStatus === 406){
            return <div><h5>Link expired!</h5><button className='w-100' onClick={() => resendVerificationLink()}>Resend verification link</button></div>
        }
        else{
            return <div><h5>Something went wrong.</h5><button className='w-100' onClick={() => {history.push("/search")}}>Continue to site</button></div>
        }
    }

    function resendVerificationLink(){
        setIsLoading(true);
        apiService.resendVerificationLink(token).then(response => {
            setIsLoading(false);
            setResendMailSuccess(true)
        }).catch(error => {
            setIsLoading(false);
            setResendMailSuccess(false)
        })
    }

    useEffect(() => {
        setIsLoading(true);
        function getConfirmation(){
            apiService.registerConfirm(token).then((response) => {
                setIsLoading(false);
                setErrorResponseStatus(response.status);
            }).catch((error) => {
                setIsLoading(false)
                const data = error.response;
                setErrorResponseStatus(data.status)
            })
        }
        getConfirmation();
    }, [])

    return( 
          
        <Card className='mt-5'>
            <Card.Body>
                <Row className={styles.minContainer}>
                    {errorResponseStatus === 200 ? <Col md={5}>

                    {invalidCredentials && <div className={styles.validators}><span>{invalidCredentials}</span></div>} 

                    <form onSubmit={handleSubmit}>

                        <h4 className="mb-3">Reset Password</h4>

                        <label htmlFor="newPassword" className="m-0">New Password</label>
                        <InputGroup className={classNames(styles.customInput, "mb-2 mt-2")}>
                            <FormControl
                                className={styles.customFormField}
                                type={newPasswordToggle ? "text" : "password"}
                                aria-label="New Password"
                                name="newPassword"
                                placeholder='Enter new password'
                                value={formValues.newPassword}
                                onChange={event => handleChange(event)}
                                onBlur={event => handleValidation(event)}
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    {newPasswordToggle ? <FontAwesomeIcon icon={faEye} onClick={() => setNewPasswordToggle(!newPasswordToggle)}></FontAwesomeIcon> : <FontAwesomeIcon icon={faEyeSlash} onClick={() => setNewPasswordToggle(!newPasswordToggle)}></FontAwesomeIcon>}
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        <span className={styles.formErrors}>{formErrors.newPassword}</span>

                        <label htmlFor="confirmPassword" className="m-0">Confirm Password</label>
                        <InputGroup className="mb-2 mt-2">
                            <FormControl
                                className={styles.customFormField}
                                type={confirmPasswordToggle ? "text" : "password"}
                                aria-label="Confirm Password"
                                name="confirmPassword"
                                value={formValues.confirmPassword}
                                placeholder="Confirm your new password"
                                onChange={event => handleChange(event)}
                                onBlur={event => handleValidation(event)}
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    {confirmPasswordToggle ? <FontAwesomeIcon icon={faEye} onClick={() => setConfirmPasswordToggle(!confirmPasswordToggle)}></FontAwesomeIcon> : <FontAwesomeIcon icon={faEyeSlash} onClick={() => setConfirmPasswordToggle(!confirmPasswordToggle)}></FontAwesomeIcon>}
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        <span className={styles.formErrors}>{formErrors.confirmPassword}</span>

                        {!isLoading ? 
                            <button className="px-5" disabled={!validateForm()} type="submit">Submit</button>
                            : <button className="px-5" disabled><span>Loading
                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                            </span></button>
                        }

                    </form> 
                    </Col>
                    : 
                    <Col md={{span: 6, offset: 3}} className="d-flex justify-content-center align-items-center">
                        {isLoading ? <p>"Loading... Please wait"</p> : resendMailSuccess ?  <h4>The mail has been sent successfully. Please check your mail id</h4> : errorResponseHandler()}
                    </Col>}
                </Row>
            </Card.Body>
        </Card>                   
        
    )
}

export default ForgetPassword;