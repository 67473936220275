import React, { useEffect } from 'react';
import {PageView} from '../../tracking';
import {Row, Col} from 'react-bootstrap';
import classNames from 'classnames';

function AboutOrthopub(){

    useEffect(() => {
        PageView();
    }, [])

    let pricingInfoLink = ''
    if(window.location.origin === "https://orthopublish.org.uk" || window.location.origin === "https://www.orthopublish.org.uk") {
        pricingInfoLink = '/pricing-info'
    } else {
        pricingInfoLink = '/orthopub/pricing-info'
    }
    return(
        <Row className={classNames("mt-3 bg-white mx-0 rounded")}>
            <Col md={12} className="p-4">
                <h5 className=''>About OrthoPublish</h5><hr/>
                <p>
                    OrthoPublish is a unique journal search and formatting tool which enables you to quickly and easily get your manuscript ready for submission. The ‘Journal Finder’ helps you select the best publication for your work. Use ‘Format my manuscript’ to select the journal template you need and let OrthoPublish’s intuitive formatting tool take your manuscript through a series of short steps to produce a file that is ready to download and submit to your chosen journal. 
                </p>
                <div>
                    <h6>How to use OrthoPublish</h6>
                    <ol>
                        <li>Use the free Journal Finder to find the best journal for your manuscript or simply select the template for your chosen publication.</li>
                        <li>Let OrthoPublish guide you through formatting your manuscript.</li>
                        <li>Access your personal dashboard at any time to update your paper’s journey or select a new template to re-format it for another publication.</li>
                    </ol>
                </div>
                <p>
                    OrthoPublish is brought to you by Bone & Joint, an imprint of The British Editorial of Bone & Joint Surgery, a not-for-profit charity and Company limited by Guarantee. Bone & Joint are the publishers of The Bone & Joint Journal and Bone & Joint 360, Open Access journals Bone & Joint Research and Bone & Joint Open, and owners of the digital products OrthoSearch and OrthoMedia.
                </p>
                <p>
                    OrthoPublish is the tool you need to revolutionise your manuscript journey. Use the unique Journal Finder to explore a wide range of journals and articles to help you decide the best place to submit your manuscript. When you know which publication you want to format your paper for, the OrthoPublish formatting tool will efficiently take your manuscript through a series of short steps, resulting in a file which is ready for you to download and submit to your chosen journal.
                </p>
                <p>
                    After your manuscript has been formatted, you have the option to keep it in your Dashboard on OrthoPublish and return to it to re-format for another publication or update its publication journey for your own reference. All your manuscripts held on OrthoPublish are kept confidentially within your own Dashboard controlled by a password you set. They can be removed permanently at any time by you or kept on your OrthoPublish Dashboard indefinitely.
                </p>
                <p>
                    OrthoPublish currently offers formatting templates for The Bone & Joint Journal, Bone & Joint Research and Bone & Joint Open, plus a ‘generic’ template which will allow for some formatting across most orthopaedic journals. Templates for five more specific orthopaedic journals are expected to be launched very soon!
                </p>
                <p>
                    Find out more about the pricing models for the journal-specific templates and the generic template <a href={pricingInfoLink}>here</a>.
                </p>
                <h5><b>Our Offices</b></h5>
                <p className="font-weight-bold">The British Editorial Society of Bone & Joint Surgery</p>
                <p>22 Buckingham Street, London WC2N 6ET, UK</p>
                <p>E-Mail: <a href="mailto:orthopublish@boneandjoint.org.uk?subject=Query about the information&body=Your query here...">orthopublish@boneandjoint.org.uk</a></p>
                <h5><b>Managing Director</b></h5>
                <p>Richard Hollingsworth</p>
                <h5><b>Director of Publishing</b></h5>
                <p>Emma Vodden</p>
                <h5><b>Head of Marketing & Sales</b></h5>
                <p>Emma Nankervis</p>
                <h5><b>Product Manager</b></h5>
                <p>Charlotte Parkins</p>
            </Col>
        </Row>
    )
}

export default AboutOrthopub;