import ReactGA from "react-ga4"; 
import {googleAnalyticsTrackingId} from '../utils/Constant'; 
 
export const initGA = () => { 
    ReactGA.initialize(googleAnalyticsTrackingId); 
} 
 
export const PageView = () => { 
    ReactGA._gaCommandSendPageview(window.location.pathname + window.location.search); 
} 
 
/** 
 * Event - Add custom tracking event. 
 * @param {string} category  
 * @param {string} action  
 * @param {string} label  
 */ 
export const Event = (category, action, label) => { 
    ReactGA.event({ 
      category: category, 
      action: action, 
      label: label 
    }); 
}; 