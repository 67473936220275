import {LOGIN_MODAL_OPEN, LOGIN_MODAL_CLOSE} from '../actions/LoginModalActions';

const initialState = {
    loginModalToggle: false,
}

const loginModalReducer = (state=initialState, action) => {
    switch(action.type){
        case LOGIN_MODAL_OPEN:
            return{...state, loginModalToggle: action.payload}
        case LOGIN_MODAL_CLOSE:
            return{...state, loginModalToggle: action.payload}
        default:
            return state;
    }
}

export default loginModalReducer;