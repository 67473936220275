import React from 'react';
import history from './history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import DefaultLayout from './components/Layout/DefaultLayout';
import Register from './components/Register/Register';
import Home from './pages/Home/Home';
import RegistrationConfirmation from './components/RegistrationConfirmation/RegistrationConfirmation';
import ForgetPassword from './components/ForgetPassword/ForgetPassword';
import TermsNConditions from './pages/TermsNConditions/TermsNConditions';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import ScrollToTop from './utils/ScrollToTop';
import AboutBoneNJoint from './pages/AboutUs/AboutBoneNJoint';
import AboutOrthopub from './pages/AboutUs/AboutOrthopub';
import JournalDetails from './pages/JournalDetails/JournalDetails';
import JournalSearch from './pages/JournalSearch/JournalSearch'
import AccessDenied from './pages/AccessDenied/AccessDenied';
import MyDashboardPage from './pages/Menuscript/MyDashboardPage/MyDashboardPage';
import SelectJournal from './pages/Menuscript/SelectJournal/SelectJournal';
import Profile from './pages/Profile/Profile';
import PreviewPage from './pages/PreviewPage/PreviewPage';
import PaymentSuccess from './pages/PaymentPages/PaymentSuccess';
import PaymentFailed from './pages/PaymentPages/PaymentFailed';
import ManuscriptSubmission from './pages/Menuscript/ManuscriptSubmission/MainContent/ManuscriptSubmission';
import PaymentPage from './pages/PaymentPages/paymentPage';
import Home1 from './pages/Home/Home1';
import AdminDashboard from './pages/AdminDashboard/AdminDashboard';
import AdminUserDetails from './pages/AdminDashboard/AdminsUserDetails';
import AdminUserPurchaseHistory from './pages/AdminDashboard/AdminUserPurchaseHistory';
import AdminUserUsageHistory from './pages/AdminDashboard/AdminUserUsageHistory';
import PricingInfo from './pages/PaymentPages/PricingInfo';
import FAQs from './pages/FAQs/FAQs';


function Routes() {

  return (
   <Router history={history} basename={process.env.PUBLIC_URL}>
     <ScrollToTop>
      <Switch>
        <DefaultLayout exact path="/home" component={Home1}/>
        <Route exact path="/"><Redirect to="/home" /></Route>
        <Route exact path="/register" component={Register}></Route>
        <Route path="/registrationConfirm" component={RegistrationConfirmation}></Route>
        <DefaultLayout path="/resetPassword" component={ForgetPassword} />
        <DefaultLayout path="/details/:lexiconId" component={JournalDetails}/>
        <DefaultLayout path="/search" component={JournalSearch}/>
        <DefaultLayout path="/terms-and-conditions" component={TermsNConditions} />
        <DefaultLayout path="/privacy-policy" component={PrivacyPolicy} />
        <DefaultLayout path="/about-bone-n-joint" component={AboutBoneNJoint} />
        <DefaultLayout path="/about-orthopublish" component={AboutOrthopub} />
        <DefaultLayout path="/my-dashboard" component={MyDashboardPage} />
        <DefaultLayout path="/manuscript-submission/:manuscriptId" component={ManuscriptSubmission} />
        <DefaultLayout path="/profile" component={Profile} />
        <DefaultLayout path="/manuscript-preview/:manuscriptId" component={PreviewPage} />
        <DefaultLayout path="/select-journal" component={SelectJournal}/>
        <DefaultLayout path="/paymentSuccess" component={PaymentSuccess}/>
        <DefaultLayout path="/paymentFailed" component={PaymentFailed}/>
        <DefaultLayout path="/admin-dashboard" component={AdminDashboard}/>
        <DefaultLayout path="/admin-user-details" component={AdminUserDetails}/>
        <DefaultLayout path="/admin-user-purchase" component={AdminUserPurchaseHistory}/>
        <DefaultLayout path="/admin-user-usage" component={AdminUserUsageHistory}/>
        <DefaultLayout path="/pricing-info" component={PricingInfo}/>
        <DefaultLayout path="/frequently-asked-questions" component={FAQs}/>
        <Route exact path="/403" component={AccessDenied} />
        <Route exact path="/404" component={PageNotFound} />
        <Route exact path="*"><Redirect to="/404" /></Route>
      </Switch>
      </ScrollToTop>
    </Router>
  );

}

export default Routes;
