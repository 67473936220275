import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { FieldLoader, ProgressLoader } from "../../../../../components/Loader/CommonLoader";
import RemirrorEditor from "../../../../../components/RemirrorEditor/RemirrorEditor";
import AxiosService from "../../../../../utils/AxiosService";
import "./StepFiveForm.scss";
import TooltipButton from "../../../../../components/TooltipButton/TooltipButton";
import fileDownload from "js-file-download";
import { useSelector } from "react-redux";


const StepFiveForm = ({manuscriptId, handleEdit, stepStatus, journalTitle, step, editorChanged, setEditorChanged}) => {
    const [previewData, setPreviewData] = useState([]);
    const apiService = new AxiosService();
    const [isLoading, setIsLoading] = useState(false);
    const urlPath = window.location.pathname.split("/").filter(v => v==="manuscript-preview")
    const [abstractData, setAbstractData] = useState('')
    const [downloadLoader, setDownloadLoader] = useState(false)
    const journalConfig = useSelector(store => store.journalConfig.config)

    const getPreviewData = () => {
        setIsLoading(true);
        apiService.fetchPreviewManuscript(manuscriptId).then(res => {
            setPreviewData(res);
            setAbstractData({
                content: res?.jsonAbstract,
                type: "doc"
            })
            setIsLoading(false);

        }).catch(err => {            
            setIsLoading(false)
			toast.error(err?.response?.data?.message,  {
                autoClose: 3000,
            })
        })
    }

    const getLanguageName = (lang) => {
        if (lang === "en_GB") {
            return "UK English"
        } else if (lang === "en_US") {
            return "US English"
        } else {
            return "Language not found"
        }
    }

    const handleDownloadClick = () => {
        setDownloadLoader(true)
        apiService.fetchPreviewManuscript(manuscriptId).then(res => {
            let manuscriptName = res.title ? res?.title.split(" ",4).join("_"): ""
            let fileName = journalTitle.split(" ").join("_") + "_"+ manuscriptName + ".zip";
            apiService.exportManuscript(manuscriptId).then(res=> {
              toast.success("Manuscript has been downloaded successfully.",  {
                autoClose: 3000,
              })
              fileDownload(res, fileName);
              setDownloadLoader(false)
            }).catch(async err=> {
              setDownloadLoader(false)
              let data = JSON.parse(await err?.response?.data.text())
              toast.error(data?.message,  {
                autoClose: 3000,
              })	
            })
          }).catch(err => {        
            setDownloadLoader(false)    
            toast.error(err?.response?.data?.message, {autoClose: 3000})
          })	
    }

    useEffect(()=>{
        // console.log(stepStatus)
        if(step === 6 || stepStatus === 6){
            getPreviewData();
        }
    },[])           
    const EditSection = ({title, id}) =>(
        <div className="d-flex justify-content-between editHeader mb-3 py-4 border-bottom">
            <h6 className="mb-0">{title}</h6>
            { stepStatus >= id && 
                <p className="mb-0 editLink" onClick={()=> handleEdit(id)}>
                    Edit this section 
                </p>
            }
        </div>
    )
    
    const DataNotFound = () => <tr>
    <td colSpan={4} className="text-center">Data not found</td>
    </tr>

    const TableItem = ({file, index}) => {
        const [showFullCaption, setShowFullCaption] = useState(false)
        const [showFullFileName, setShowFullFileName] = useState(false)
        return(
            <tr key={index} className="border-bottom">
                <td>{index+1}</td>
                <td>{file.fileName && file.fileName.length > 0 ? 
                        <div className="d-flex flex-column">
                            <p className="m-0">
                                {typeof file.fileName === "string" ?
                                    file.fileName.length > 16 ? 
                                        <>
                                            {showFullFileName ? file.fileName : `${file.fileName.substring(0,16)+"..."}`}
                                        </>
                                        :
                                        file.fileName
                                    :
                                        ""
                                }
                                {typeof file.fileName === "object" ?
                                    file.fileName.join(", ").length > 16 ? 
                                        <>
                                            {showFullFileName ? file.fileName.join(", ") : `${file.fileName.join(", ").substring(0,16)+"..."}`}
                                        </>
                                        :
                                        file.fileName
                                    :
                                        ""
                                }
                            </p>
                            {file.fileName.join(", ").length > 16 && <button className="btn btn-sm btn-link p-0 align-self-start" onClick={() => {setShowFullFileName(!showFullFileName)}}>{showFullFileName ? "Hide" : "View more"}</button>}
                        </div> 
                    : 
                    "---"
                    }			
                </td>
                <td>{file.fileDesignation}</td>
                <td>{file.caption ?
                        <div className="d-flex flex-column">
                        <p className="m-0">
                            {file?.caption.length > 100 ? 
                                <>
                                    {showFullCaption ? file?.caption : `${file?.caption.substring(0,100) + "..."}`}
                                </>
                                :
                                file?.caption
                            }
                        </p>
                        {file?.caption.length > 100 && <button className="btn btn-sm btn-link p-0 align-self-start" onClick={() => {setShowFullCaption(!showFullCaption)}}>{showFullCaption ? "Hide" : "View more"}</button>}
                        </div> 
                    : 
                    "---" 
                }
                </td>
            </tr>
        )
    }

    return (
        <div className="previewSection">
           	<div className="d-flex justify-content-between">
				<div>
                    <h5>Manuscript preview</h5>
                    <p className="mt-3">Review your manuscript and verify all details.</p>
                </div>
                {urlPath[0]==="manuscript-preview" &&
                    <div className="d-flex flex-column">
                        <span>Journal: <span className="journalTitleStyle">{journalTitle}</span></span>
                        {downloadLoader ?
                            <FieldLoader isLoading={downloadLoader}  message="Downloading" className="small align-self-end mt-2"  />
                        :
                            <button disabled={isLoading} onClick={handleDownloadClick} className="small align-self-end mt-2">Download manuscript</button>
                        }
                    </div>
                }
			</div>
            <div className="my-3 border rounded px-3">           
                <EditSection title="SUMMARY" id={1} />
                <div className="py-2">
                    <div className="row">
                        <span className="col-md-6">
                            <label>Article Type</label>
                            <p className="font-weight-semi-bold">
                                { isLoading ?
                                    <ProgressLoader />
                                : 
                                    previewData.manuscriptType ? previewData.manuscriptType : ""
                                }
                            </p>
                        </span>
                        <span className="col-md-6">
                            <label>Study Type</label>
                            <p className="font-weight-semi-bold">
                                { isLoading ?
                                    <ProgressLoader />
                                : 
                                    previewData?.studyType ? previewData?.studyType : "" 
                                }
                            </p>
                        </span>
                        <span className="col-md-6">
                            <label>Reference format</label>
                            <p className="font-weight-semi-bold">
                                { isLoading ?
                                    <ProgressLoader />
                                : 
                                    previewData?.referenceType ? previewData?.referenceType : "" 
                                }
                            </p>
                        </span>
                        <span className="col-md-6">
                            <label>Language</label>
                            <p className="font-weight-semi-bold">
                                { isLoading ?
                                    <ProgressLoader />
                                : 
                                    previewData.language ? getLanguageName(previewData.language) : ""
                                }
                            </p>
                        </span>
                    </div>
                </div>
            </div>
            <div className="my-3 border rounded px-3 stepFiveForm">
                <EditSection title="MAIN DOCUMENT" id={2} />
                <p className="p-2">
                    { isLoading ?
                        <ProgressLoader />
                    :
                        previewData?.jsonAbstract && 
                        <div className="titleAstractDiv p-2 border rounded py-3">
                            <RemirrorEditor data={abstractData} isEditable={false} editorChanged={editorChanged} setEditorChanged={setEditorChanged}/>
                        </div>
                    }
                </p>
            </div>
            <div className="my-3 border rounded px-3">
                <EditSection title="FILES" id={3} />
                <Table responsive borderless>
                    <thead className="border-bottom">
                        <tr>
                            <th width="1%">ORDER</th>
                            <th width="20%">FILE NAME</th>
                            <th width="30%">FILE DESIGNATION</th>
                            <th width="44%">CAPTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading?
                        <tr>
                            <td colSpan={4}><ProgressLoader/></td>
                        </tr>
                        : 
                        previewData?.files?.length ? previewData.files.map((file,index)=>(
                           <TableItem file={file} index={index}/> 
                        )): 
                        <DataNotFound />
                        }
                    </tbody>
                </Table>
            </div>
            <div className="my-3 border rounded px-3">
                <EditSection title="KEYWORDS" id={4} />
                <div className="keywordSection mt-3 mb-3">
                    {isLoading ?
                    <div><ProgressLoader/></div>
                    : previewData.keywords?.length 
                    ? previewData.keywords.map(keyword => (
                        <TooltipButton tooltipMessage={keyword.name}>
                            <span className="p-2 m-1 keywordSpan text-truncate" key={keyword.cid}>{keyword.name}</span> 
                        </TooltipButton>
                    ))
                    : <div className="text-center w-100">Data not found</div>}
                </div>
            </div>
            <div className="my-3 border rounded px-3">
                <EditSection title="AUTHORS" id={5} />
                <Table responsive borderless>
                    <thead className="border-bottom">
                        <tr>
                            <th>AUTHOR/GROUP NAME</th>
                            <th>DETAILS</th>
                            <th>CONTACT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading?
                        <tr>
                            <td colSpan={2}><ProgressLoader/></td>
                        </tr>
                        :
                        previewData.authors?.length ? previewData.authors.map((author,index) => (
                            <tr key={index} className="border-bottom">
                                <td>
                                    <p className="mb-1 text-secondary">{author.firstName || ""} {author.lastName || ""}</p>
                                    {/* {author.orcid && <p className="lightText mb-0"><strong>ORCID ID: </strong>{author.orcid || ""}</p>} */}
                                    <p className="mb-1 text-secondary">{author.groupName}</p>
                                    {author.authorType && <p className="mb-1 text-secondary">{author.authorType.join(", ")}</p>}
                                </td>
                                {/* <td>
                                    {author.authorType && <p className="mb-1">Role: {author.authorType}</p>}
                                    {author.emailId && <p className="mb-1">Email: {author.emailId}</p>}
                                </td> */}
                                <td className="align-middle">
                                    {/* {author.authorType && <p className="mb-1">Role: {author.authorType}</p>} */}
                                    {/* {author.emailId && <p className="mb-1">Email: {author.emailId}</p>} */}
                                    {author.affilliation && <p className="mb-1 text-secondary">Company/Institution: {author.affilliation}</p>}
                                    {/* {author.phone && <p className="mb-1">Phone: {author.phone}</p>} */}
                                    {author.contribution && <p className="mb-1 text-secondary">Contribution: {author.contribution.join(", ")}</p>}
                                    {author.orcid && <p className="mb-1 text-secondary">ORCID ID: {author.orcid || ""}</p>}
                                    {author.authorStatment && <p className="mb-1 text-secondary"><span>Author Statment: </span>{author.authorStatment}</p>}
                                </td>
                                <td>
                                    {author.emailId && <p className="mb-1 text-secondary">Email: {author.emailId}</p>}
                                    {author.phone && <p className="mb-1 text-secondary">Phone: {author.phone}</p>}
                                </td>
                            </tr>
                        )): <DataNotFound /> }
                    </tbody>
                </Table>
                <h6 className="editHeader">Statements</h6>
                <p className="text-secondary">
                    { isLoading ? 
                        <ProgressLoader />
                    : 
                        previewData.statements ? 
                            previewData.statements.split("\n").map((line) => {
                                return(
                                    <p>{line}</p>
                                )
                            })
                        : 
                            "" 
                    }
                </p>
                {journalConfig?.ethicalReviewCommitteeStatementRequired === "yes"? 
                    <>
                        <h6 className="editHeader">Ethical Review Committee Statement</h6>
                        <p className="text-secondary">
                            { isLoading ? 
                                <ProgressLoader />
                            : 
                                previewData?.ercStatements ? 
                                    previewData?.ercStatements.split("\n").map((line) => {
                                        return(
                                            <p>{line}</p>
                                        )
                                    })
                                : 
                                    "" 
                            }
                        </p>
                    </>
                :
                    null
                }
                {journalConfig?.conflictOfInterestStatementRequired === "yes" ? 
                    <>
                        <h6 className="editHeader">Conflict of Interest Statement</h6>
                        <p className="text-secondary">
                            { isLoading ? 
                                <ProgressLoader />
                            : 
                                previewData?.coiStatements ? 
                                    previewData?.coiStatements.split("\n").map((line) => {
                                        return(
                                            <p>{line}</p>
                                        )
                                    })
                                : 
                                    "" 
                            }
                        </p>
                    </>
                :
                    null
                }
            </div>

        </div>
    );
}

export default StepFiveForm;