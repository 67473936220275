import React, { useEffect } from 'react';
import { Row, Col, Modal, Spinner, Carousel, Card } from 'react-bootstrap';
import { hasJournalFilter, hasPublicationYearFilter, hasSubjectFilter } from '../../store/actions/FilterConfigAction';
import { useDispatch, useSelector } from 'react-redux';
import {PageView} from '../../tracking';
import classNames from 'classnames';
import styles from './Home.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScroll, faUserPlus, faFileCircleCheck, faTable, faAngleRight  } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { closeLoginModal } from '../../store/actions/LoginModalActions';
import LocalStorageService from '../../utils/auth_service/AuthServiceStorage'
import { useState } from 'react';
import { authLogout } from '../../utils/auth_service/AuthServiceHelper';
import { toast } from 'react-toastify';
import { tokenExpired } from '../../store/actions/RefreshTokenAction';
import history from '../../history';
import step1Icon from './assets/step1.svg'
import step2Icon from './assets/step2.svg'
import step3Icon from './assets/step3.svg'
import step4Icon from './assets/step4.svg'
import journalFinderButton from './assets/homepage button - journal finder.svg'
import formatManuscriptButton from './assets/homepage button - format manuscript.svg'

function Home1() {
	const dispatch = useDispatch();
    const loginModalToggle = useSelector(state => state.login.loginModalToggle);
	const localService = LocalStorageService.getService()
	const authDetails = JSON.parse(localService.getAuthDetails())
	const [loggedInRegistermodal, setLoggedInRegisterModal] = useState(false)
	const [logoutModalLoader, setLogoutModalLoader] = useState(false)

	const loginCheck = () => {
		if(authDetails !== null) {
			setLoggedInRegisterModal(true)
		}
	}

	const loggedInRegisterAction = () => {
		const params = new URLSearchParams();
        params.append("access_token", localService.getAccessToken());
        const config = {
            url: "api/v1/auth/getLogout",
            method: "post",
            data: params
        };
		setLogoutModalLoader(true)
		authLogout(config).then(response => {
			localService.removeTokens()
			toast.success("Logged out!", {autoClose: 3000})
			dispatch(tokenExpired())
			history.push("/register")
			setLogoutModalLoader(false)
		}, err => {
			toast.error("Something went wrong!", {autoClose: 3000})
			setLogoutModalLoader(false)
		})
	}
 
	useEffect(() => {
		PageView();
		dispatch(hasSubjectFilter());
		dispatch(hasJournalFilter());
		dispatch(hasPublicationYearFilter());
	}, [])
	
    useEffect(() => {
		if(loginModalToggle) {
			dispatch(closeLoginModal())
		}
    }, []);

	const CardItem = ({icon, title, description, path, pathName, action}) => (
		<div className={classNames(styles.cardStyle,"card my-2 shadow-sm")} style={{width: "100%", height:"15rem"}}>
			<div className="card-body pb-0">
				<FontAwesomeIcon icon={icon} className={styles.iconStyle} />
				<h4 className="card-title mt-2">{title}</h4>
				<p className="card-text text-muted">{description}</p>
			</div>
			<div className='card-footer bg-white border-top-0 h6'>
				{ action && authDetails !== null ? 
					<span onClick={() => action()} className={styles.spanText}>{pathName} <FontAwesomeIcon icon={faAngleRight} /></span>
				:
					<Link to={path} className={styles.linkText}>{pathName} <FontAwesomeIcon icon={faAngleRight} /></Link>
				}
			</div>
		</div>
	)

	return (
		<div className={classNames(styles.homePageBackground, 'rounded')}>
			<Col className={classNames(styles.fontLato, 'p-0 m-0')}>
				<Carousel controls={false}>  
					<Carousel.Item key={0}>
						<div className={classNames(styles.streamlineBcakground, "align-content-center flex-wrap d-flex flex-column")}>
							
							<div className={classNames(styles.headingDivHeight,'d-none d-md-block container-xl')}> {/* Large screen text */}
								<h1 className='text-center py-5'>
									<span className={classNames(styles.carouselHeading, 'font-italic')}><u>Streamline</u></span> <span className={styles.hightlightText}> your journey towards publication.</span>
								</h1>
								<p className={classNames(styles.carouselText, 'text-center p-0 m-0')}>OrthoPublish is a unique journal search and formatting</p>
								<p className={classNames(styles.carouselText, 'text-center p-0 m-0')}>tool which enables you to quickly and easily get your</p>
								<p className={classNames(styles.carouselText, 'text-center p-0 m-0')}>manuscript ready for submission.</p>
							</div>

							<div className={classNames(styles.headingDivHeightMobile, 'd-sm-block d-md-none container-xl px-4')}> {/* Small screen text */}
								<h2 className='text-center py-5'>
									<span className={classNames(styles.carouselHeading, 'font-italic')}><u>Streamline</u></span> <span className={styles.hightlightText}> your journey towards publication.</span>
								</h2>
								<p className={classNames(styles.carouselText, 'text-center p-0 m-0')}>OrthoPublish is a unique journal search and formatting tool which enables you to quickly and easily get your manuscript ready for submission.</p>
							</div>
							
							<div className='d-flex flex-row justify-content-center pb-3 my-5 container-xl'> {/* Button */}
								<button className={styles.carouselButton} onClick={() => {history.push("/select-journal?sort=journal_title_asc")}}>GET STARTED NOW</button>
							</div>

							<div className='d-sm-block d-md-none container-xl px-0 mt-3'>
								<img alt="Homepage streamline backround" src={require('./assets/homepage\ background\ -\ streamline\ -\ mobile.png')} width="100%"/>
							</div>
							
							<Row className={classNames(styles.JustifyContentEvenly, 'pt-0 pt-md-5 mx-5 container-xl')}> {/* Cards */}
								<Col lg={5} md={5} sm={12} className={classNames(styles.roundedCorner, 'shadow bg-white py-3 mt-5')}>
									<Row className='text-center align-items-start p-2 mb-2'>
										<Col lg={4} md={12} sm={12} className='align-self-center'>
											<img alt="Homepage Journal finder logo" className={styles.btnImgMaxHeight} src={journalFinderButton}/>
										</Col>
										<Col lg={8} md={12} sm={12} className='d-flex flex-column'>
											<h3 className={classNames("my-3",styles.hightlightText)}>Journal finder</h3>
											<span className={classNames(styles.carouselText, 'my-2 pb-3')}>Find the right publication for your work <br/> using the <b>FREE</b> journal finder.</span>
											<div className='my-4'><button className={styles.cardButton} onClick={() => {history.push("/search")}}>FIND A JOURNAL</button></div>
										</Col>
									</Row>
								</Col>
								<Col lg={5} md={5} sm={12} className={classNames(styles.roundedCorner, 'shadow bg-white py-3 mt-5')}>
									<Row className='ml-md-2 text-center flex-row align-items-start p-2 mb-2'>
										<Col lg={4} md={12} sm={12} className='align-self-center'>
											<img alt="Homepage Format Manuscript logo" className={styles.btnImgMaxHeight} src={formatManuscriptButton}/>
										</Col>
										<Col lg={8} md={12} sm={12} className='d-flex flex-column'>
											<h3 className={classNames("my-3",styles.hightlightText)}>Format my manuscript</h3>
											<span className={classNames(styles.carouselText, 'my-2')}>Purchase your credits and the intuitive formatting tool will help you prepare your paper for submission.</span>
											<div className='my-3'><button className={styles.cardButton} onClick={() => {history.push("/select-journal?sort=journal_title_asc")}}>ADD A MANUSCRIPT</button></div>
										</Col>
									</Row>
								</Col>
							</Row>
						
						</div>
					</Carousel.Item>
					<Carousel.Item key={1}>
						<div className={classNames(styles.saveTimeBcakground, "align-content-center flex-wrap d-flex flex-column")}>
							
							<div className={classNames(styles.headingDivHeight,'d-none d-md-block container-xl')}> {/* Large screen text */}
								<h1 className='text-center py-5'>
									<span className={classNames(styles.carouselHeading, 'font-italic')}><u>Save time</u></span> <span className={styles.hightlightText}> with our intuitive formatting tool.</span>
								</h1>
								<p className={classNames(styles.carouselText, 'text-center p-0 m-0')}>Once you have purchased your credits, OrthoPublish will guide you</p>
								<p className={classNames(styles.carouselText, 'text-center p-0 m-0')}>through a series of short steps to produce a file that is ready</p>
								<p className={classNames(styles.carouselText, 'text-center p-0 m-0')}>to download and submit to your chosen journal.</p>
							</div>
							
							<div className={classNames(styles.headingDivHeightMobile, 'd-sm-block d-md-none container-xl px-4')}> {/* Small screen text */}
								<h2 className='text-center py-5'>
									<span className={classNames(styles.carouselHeading, 'font-italic')}><u>Save time</u></span> <span className={styles.hightlightText}> with our intuitive formatting tool.</span>
								</h2>
								<p className={classNames(styles.carouselText, 'text-center p-0 m-0')}>Once you have purchased your credits, OrthoPublish will guide you through a series of short steps to produce a file that is ready to download and submit to your chosen journal.</p>
							</div>
							
							<div className='d-flex flex-row justify-content-center pb-3 my-5 container-xl'> {/* Button */}
								<button className={styles.carouselButton} onClick={() => {history.push("/select-journal?sort=journal_title_asc")}}>FORMAT NEW MANUSCRIPT</button>
							</div>

							<div className='d-sm-block d-md-none container-xl px-0 mt-5'>
								<img alt="Homepage save time backround" src={require('./assets/homepage\ background\ -\ save\ time\ -\ mobile.png')} width="100%"/>
							</div>
							
							<Row className={classNames(styles.JustifyContentEvenly, 'pt-0 pt-md-5 mx-5 container-xl')}> {/* Cards */}
								<Col lg={5} md={5} sm={12} className={classNames(styles.roundedCorner, 'shadow bg-white py-3 mt-5')}>
									<Row className='text-center align-items-start p-2 mb-2'>
										<Col lg={4} md={12} sm={12} className='align-self-center'>
											<img alt="Homepage Journal finder logo" className={styles.btnImgMaxHeight} src={journalFinderButton}/>
										</Col>
										<Col lg={8} md={12} sm={12} className='d-flex flex-column'>
											<h3 className={classNames("my-3",styles.hightlightText)}>Journal finder</h3>
											<span className={classNames(styles.carouselText, 'my-2 pb-3')}>Find the right publication for your work <br/> using the <b>FREE</b> journal finder.</span>
											<div className='my-4'><button className={styles.cardButton} onClick={() => {history.push("/search")}}>FIND A JOURNAL</button></div>
										</Col>
									</Row>
								</Col>
								<Col lg={5} md={5} sm={12} className={classNames(styles.roundedCorner, 'shadow bg-white py-3 mt-5')}>
									<Row className='ml-md-2 text-center flex-row align-items-start p-2 mb-2'>
										<Col lg={4} md={12} sm={12} className='align-self-center'>
											<img alt="Homepage Format Manuscript logo" className={styles.btnImgMaxHeight} src={formatManuscriptButton}/>
										</Col>
										<Col lg={8} md={12} sm={12} className='d-flex flex-column'>
											<h3 className={classNames("my-3",styles.hightlightText)}>Format my manuscript</h3>
											<span className={classNames(styles.carouselText, 'my-2')}>Purchase your credits and the intuitive formatting tool will help you prepare your paper for submission.</span>
											<div className='my-3'><button className={styles.cardButton} onClick={() => {history.push("/select-journal?sort=journal_title_asc")}}>ADD A MANUSCRIPT</button></div>
										</Col>
									</Row>
								</Col>
							</Row>
						
						</div>
					</Carousel.Item>
					<Carousel.Item key={2}>
						<div className={classNames(styles.quoteBcakground, 'align-content-center flex-wrap d-flex flex-column')}>
							
							<div className={classNames(styles.headingDivHeight,'d-none d-md-block container-xl')}> {/* Large screen text */}
								<div className='py-4'>
									<h1 className='text-center'>
										<span className={styles.hightlightText}> ‘A tool that will </span>
										<span className={classNames(styles.carouselHeading, 'font-italic')}><u>revolutionise</u></span> 
										<span className={styles.hightlightText}> the journey <br/> to publication and save so much time’</span>
									</h1>
									<h6 className={classNames(styles.hightlightText,'text-center font-italic')}>Professor Fares S Haddad, University College London Hospitals, UK.</h6>
								</div>
								<p className={classNames(styles.carouselText, 'text-center p-0 m-0')}>OrthoPublish is trusted by some of the world's leading</p>
								<p className={classNames(styles.carouselText, 'text-center p-0 m-0')}>orthopaedic surgeons and brought to you by Bone & Joint</p>
								<p className={classNames(styles.carouselText, 'text-center p-0 m-0')}>-a pioneer of orthopaedic education over 70 years.</p>
							</div>
							
							<div className={classNames(styles.headingDivHeightMobile, 'd-sm-block d-md-none container-xl px-4')}> {/* Small screen text */}
								<div className='py-4'>
										<h2 className='text-center'>
											<span className={styles.hightlightText}> ‘A tool that will </span>
											<span className={classNames(styles.carouselHeading, 'font-italic')}><u>revolutionise</u></span> 
											<span className={styles.hightlightText}> the journey to publication and save so much time’</span>
										</h2>
										<h6 className={classNames(styles.hightlightText,'text-center')}>- Professor Fares S Haddad, University College London Hospitals, UK.</h6>
									</div>
									<p className={classNames(styles.carouselText, 'text-center p-0 m-0')}>OrthoPublish is trusted by some of the world's leading orthopaedic surgeons and brought to you by Bone & Joint - a pioneer of orthopaedic education over 70 years.</p>
							</div>
							
							<div className='d-flex flex-row justify-content-center pb-3 my-5 container-xl'> {/* Button */}
								<button className={styles.carouselButton} onClick={() => {history.push("/about-orthopublish")}}>FIND OUT MORE</button>
							</div>

							<div className='d-sm-flex justify-content-end d-md-none container-xl px-0 mt-5'>
								<img alt="Homepage quote backround" align="right" src={require('./assets/homepage\ background\ -\ quote\ -\ mobile.png')} width="100%"/>
							</div>
							
							<Row className={classNames(styles.JustifyContentEvenly, 'pt-0 pt-md-5 mx-5 container-xl')}> {/* Cards */}
								<Col lg={5} md={5} sm={12} className={classNames(styles.roundedCorner, 'shadow bg-white py-3 mt-5')}>
									<Row className='text-center align-items-start p-2 mb-2'>
										<Col lg={4} md={12} sm={12} className='align-self-center'>
											<img alt="Homepage Journal finder logo" className={styles.btnImgMaxHeight} src={journalFinderButton}/>
										</Col>
										<Col lg={8} md={12} sm={12} className='d-flex flex-column'>
											<h3 className={classNames("my-3",styles.hightlightText)}>Journal finder</h3>
											<span className={classNames(styles.carouselText, 'my-2 pb-3')}>Find the right publication for your work <br/> using the <b>FREE</b> journal finder.</span>
											<div className='my-4'><button className={styles.cardButton} onClick={() => {history.push("/search")}}>FIND A JOURNAL</button></div>
										</Col>
									</Row>
								</Col>
								<Col lg={5} md={5} sm={12} className={classNames(styles.roundedCorner, 'shadow bg-white py-3 mt-5')}>
									<Row className='ml-md-2 text-center flex-row align-items-start p-2 mb-2'>
										<Col lg={4} md={12} sm={12} className='align-self-center'>
											<img alt="Homepage Format Manuscript logo" className={styles.btnImgMaxHeight} src={formatManuscriptButton}/>
										</Col>
										<Col lg={8} md={12} sm={12} className='d-flex flex-column'>
											<h3 className={classNames("my-3",styles.hightlightText)}>Format my manuscript</h3>
											<span className={classNames(styles.carouselText, 'my-2')}>Purchase your credits and the intuitive formatting tool will help you prepare your paper for submission.</span>
											<div className='my-3'><button className={styles.cardButton} onClick={() => {history.push("/select-journal?sort=journal_title_asc")}}>ADD A MANUSCRIPT</button></div>
										</Col>
									</Row>
								</Col>
							</Row>
						
						</div>
					</Carousel.Item>
					<Carousel.Item key={3}>
						<div className={classNames(styles.findPublicationBcakground, 'align-content-center flex-wrap d-flex flex-column')}>
							
							<div className={classNames(styles.headingDivHeight,'d-none d-md-block container-xl')}> {/* Large screen text */}
								<h1 className='text-center py-5'>
									<span className={classNames(styles.carouselHeading, 'font-italic')}><u>Find the right publication</u></span> <span className={styles.hightlightText}> for your work.</span>
								</h1>
								<p className={classNames(styles.carouselText, 'text-center p-0 m-0')}>Use the unique Journal Finder to explore a wide</p>
								<p className={classNames(styles.carouselText, 'text-center p-0 m-0')}>range of journals and articles to help you find the</p>
								<p className={classNames(styles.carouselText, 'text-center p-0 m-0')}>best match for your paper.</p>
							</div>
							
							<div className={classNames(styles.headingDivHeightMobile, 'd-sm-block d-md-none container-xl px-4')}> {/* Small screen text */}
								<h2 className='text-center py-5'>
									<span className={classNames(styles.carouselHeading, 'font-italic')}><u>Find the right publication</u></span> <span className={styles.hightlightText}> for your work.</span>
								</h2>
								<p className={classNames(styles.carouselText, 'text-center p-0 m-0')}>Use the unique Journal Finder to explore a wide range of journals and articles to help you find the best match for your paper.</p>
							</div>
							
							<div className='d-flex flex-row justify-content-center pb-3 my-5 container-xl'> {/* Button */}
								<button className={styles.carouselButton} onClick={() => {history.push("/search")}}>FIND A JOURNAL</button>
							</div>

							<div className='d-sm-block d-md-none container-xl p-0 px-0 mt-5'>
								<img alt="Homepage Find Publication logo" src={require('./assets/homepage\ background\ -\ find\ publication\ -\ mobile.png')} width="100%"/>
							</div>
							
							<Row className={classNames(styles.JustifyContentEvenly, 'pt-0 pt-md-5 mx-5 container-xl')}> {/* Cards */}
								<Col lg={5} md={5} sm={12} className={classNames(styles.roundedCorner, 'shadow bg-white py-3 mt-5')}>
									<Row className='text-center align-items-start p-2 mb-2'>
										<Col lg={4} md={12} sm={12} className='align-self-center'>
											<img alt="Homepage Journal finder logo" className={styles.btnImgMaxHeight} src={journalFinderButton}/>
										</Col>
										<Col lg={8} md={12} sm={12} className='d-flex flex-column'>
											<h3 className={classNames("my-3",styles.hightlightText)}>Journal finder</h3>
											<span className={classNames(styles.carouselText, 'my-2 pb-3')}>Find the right publication for your work <br/> using the <b>FREE</b> journal finder.</span>
											<div className='my-4'><button className={styles.cardButton} onClick={() => {history.push("/search")}}>FIND A JOURNAL</button></div>
										</Col>
									</Row>
								</Col>
								<Col lg={5} md={5} sm={12} className={classNames(styles.roundedCorner, 'shadow bg-white py-3 mt-5')}>
									<Row className='ml-md-2 text-center flex-row align-items-start p-2 mb-2'>
										<Col lg={4} md={12} sm={12} className='align-self-center'>
											<img alt="Homepage Format Manuscript logo" className={styles.btnImgMaxHeight} src={formatManuscriptButton}/>
										</Col>
										<Col lg={8} md={12} sm={12} className='d-flex flex-column'>
											<h3 className={classNames("my-3",styles.hightlightText)}>Format my manuscript</h3>
											<span className={classNames(styles.carouselText, 'my-2')}>Purchase your credits and the intuitive formatting tool will help you prepare your paper for submission.</span>
											<div className='my-3'><button className={styles.cardButton} onClick={() => {history.push("/select-journal?sort=journal_title_asc")}}>ADD A MANUSCRIPT</button></div>
										</Col>
									</Row>
								</Col>
							</Row>
						
						</div>
					</Carousel.Item>
				</Carousel>
			</Col>
			<Col className={classNames(styles.journyDiv, styles.fontLato, "align-content-center flex-wrap d-flex flex-column")}>
				<Row className='mb-3 container-xl d-none d-md-block'>
					<h1 className='text-center w-100'>How to use OrthoPublish</h1>
				</Row>
				<Row className='my-5 container-xl d-sm-block d-md-none'>
					<h1 className='text-center w-100'>How to use OrthoPublish</h1>
				</Row>
				<Row className='container-xl'>
					<Col lg={3} md={6} xs={12}>
						{/* <Row className='py-3'><span className={styles.nameBadge}>1</span></Row> */}
						<Row className='pt-5 d-flex justify-content-center'><Col md={8} className='d-flex justify-content-center'><img alt="How to use orthoPublish step 1" className={styles.journyDivIcons} src={step1Icon}/></Col></Row>
						<Row className={styles.journyDivText}><span>Use the FREE Journal Finder</span><span>to find the best journal for</span> <span>your manuscript</span></Row>
					</Col>
					<Col lg={3} md={6} xs={12}>
						{/* <Row className='py-3'><span className={styles.nameBadge}>2</span></Row> */}
						<Row className='pt-5 d-flex justify-content-center'><Col md={8} className='d-flex justify-content-center'><img alt="How to use orthoPublish step 2" className={styles.journyDivIcons} src={step2Icon}/></Col></Row>
						<Row className={styles.journyDivText}><span>Select a template for your</span><span>chosen publication</span></Row>
					</Col>
					<Col lg={3} md={6} xs={12}>
						{/* <Row className='py-3'><span className={styles.nameBadge}>3</span></Row> */}
						<Row className='pt-5 d-flex justify-content-center'><Col md={8} className='d-flex justify-content-center'><img alt="How to use orthoPublish step 3" className={styles.journyDivIcons} src={step3Icon}/></Col></Row>
						<Row className={styles.journyDivText}><span>Let OrthoPublish help you</span><span>through formatting your</span> <span>manuscript</span></Row>
					</Col>
					<Col lg={3} md={6} xs={12}>
						{/* <Row className='py-3'><span className={styles.nameBadge}>4</span></Row> */}
						<Row className='pt-5 d-flex justify-content-center'><Col md={8} className='d-flex justify-content-center'><img alt="How to use orthoPublish step 4" className={styles.journyDivIcons} src={step4Icon}/></Col></Row>
						<Row className={styles.journyDivText}><span>Access your personal</span><span>dashboard to update your</span> <span>paper's journey or select a</span> <span>new template for another</span> <span>publication</span></Row>
					</Col>
				</Row>
				<Row className='justify-content-center mt-5 mb-5 container-xl'>
					<button className={styles.journeyDivButton} onClick={() => {history.push("/select-journal?sort=journal_title_asc")}}>GET STARTED NOW</button>
				</Row>
			</Col>
			<Col className={classNames(styles.fontLato, 'm-0 py-5 px-0', 'align-content-center flex-wrap d-flex flex-column')}>
				<Row className='d-flex flex-row flex-wrap container-xl'>
					<Col lg={7} sm={12} className={classNames(styles.fontLato, 'flex-column flex-wrap mt-5 pl-5 d-none d-md-block')}> {/*Large Screen */}
						<h1 className='w-75 mb-4'>A revolutionary formatting tool from a global leader in orthopaedics</h1>
						<div className={classNames(styles.quoteTextLineHeight, 'w-75 mb-4')}>OrthoPublish is brought to you by Bone & Joint, an imprint of The British Editorial Society of Bone & Joint Surgery, formed in 1953 to improve and advance education in orthopaedic surgery.</div>
					</Col>
					<Col lg={5} sm={12} className={classNames(styles.quoteDivText, "mt-5 flex-column flex-wrap pl-5 d-none d-md-block")}> {/*Large Screen */}
						<h1 className=' font-italic font-weight-light'>
							<b>‘A tool that will revolutionise the journey to publication and save so much time’</b>
						</h1>
						<div className={classNames(styles.quoteTextLineHeight, 'pt-4')}>
							<span>Professor Fares S Haddad</span><br/>
							<span>University College London Hospitals, UK.</span>
						</div>
					</Col>

					<Col lg={7} sm={12} className={classNames(styles.fontLato, 'd-flex flex-column flex-wrap mt-5 d-sm-block d-md-none')}> {/*Small Screen */}
						<h2 className='w-75 mb-4'>A revolutionary formatting tool from a global leader in orthopaedics</h2>
						<div className={classNames(styles.quoteTextLineHeight, 'w-75 mb-4')}>OrthoPublish is brought to you by Bone & Joint, an imprint of The British Editorial Society of Bone & Joint Surgery, formed in 1953 to improve and advance education in orthopaedic surgery.</div>
					</Col>
					<Col lg={5} sm={12} className={classNames(styles.quoteDivText, "mt-5 d-flex flex-column flex-wrap d-sm-block d-md-none")}> {/*Small Screen */}
						<h2 className=' font-italic font-weight-light'>
							<b>‘A tool that will revolutionise the journey to publication and save so much time’</b>
						</h2>
						<div className={classNames(styles.quoteTextLineHeight, 'pt-4')}>
							<span>Professor Fares S Haddad</span><br/>
							<span>University College London Hospitals, UK.</span>
						</div>
					</Col>
					<Col lg={6} className='d-flex flex-column flex-wrap mt-5 pl-5 pb-5'>
						<div className='w-100'><button className={styles.homePageButton} onClick={() => {history.push("/about-orthopublish")}}>FIND OUT MORE</button></div>
					</Col>
				</Row>
			</Col>
			<Modal show={loggedInRegistermodal} dialogClassName={classNames(styles.fontLato, 'w-25')} onHide={() => {setLoggedInRegisterModal(false)}}>
				<Modal.Header closeButton className="h6 d-flex align-items-center">Register new user</Modal.Header>
				<Modal.Body>
					<p>You are already logged in. Are you sure you want to create a new account?</p>
					<p>Please note that on proceeding with new account, current user will be logged out.</p>
					<button className='w-100 btn-outline' disabled={logoutModalLoader} onClick={() => {setLoggedInRegisterModal(false)}}>No</button>
					<button className='w-100 mt-2' disabled={logoutModalLoader} onClick={() => {loggedInRegisterAction()}}>
						{logoutModalLoader ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : "Yes"}
					</button>
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default Home1;