import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import styles from './Filter.module.scss'
import { CircleLoader } from '../Loader/CommonLoader';
import { toast } from 'react-toastify';

const Filter =({ getAppliedFilter ,getFilters, loader, filters, filterType, isLoading, appliedFilter, setAppliedFilter, status, isStatusChange, sortValue, isDeleteAllLoading}) => {
    const [checkedVal, setCheckedVal] = useState(appliedFilter ? appliedFilter : []);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function isChecked(val) {
        return checkedVal?.includes(val) ? true : false;
    }

    function onChange(event) {
        const item = event.target.value;
        const localIsChecked = event.target.checked;
        if (localIsChecked) {
            setCheckedVal([...checkedVal, item]);
        } else {
            setCheckedVal(checkedVal.filter((each) => each !== item));
        }
    }

    function applyFilters(){
        if(checkedVal.length > 0) {
            setAppliedFilter([checkedVal])
            getAppliedFilter(checkedVal);
        } else {
            toast.error("Please select the status to filter the manuscripts.")
        }
        handleClose();
    }

    function resetFacets(){
        setCheckedVal([]);
        setAppliedFilter([])
        getAppliedFilter([], true);
        handleClose();
    }
    useEffect(()=>{
        if(checkedVal){
        getAppliedFilter(checkedVal)
        }
    },[sortValue])
    
    useEffect(()=>{
        if(loader.isReset|| loader.isSearch || (isDeleteAllLoading && status==="Completed")){
            setCheckedVal([])
            if(!isDeleteAllLoading) {
                getAppliedFilter([])
            }
        }
     },[ loader.isReset, loader.isSearch, isDeleteAllLoading, status])

    
    useEffect(()=>{
        if(isStatusChange){
            setCheckedVal([])
            getAppliedFilter([])
            getFilters([])
        }
    },[isStatusChange])
    
    return(
        <div className={styles.filterSection}>
            <Card >
                <Card.Body className={styles.windowFilter}>
                    {
                        isLoading ? 
                            <div className='d-flex justify-content-center align-items-center'>
                            <CircleLoader />
                            </div>
                        :   status === "Completed" ? <div>
                                <div className='mb-4 d-flex justify-content-between align-items-center'>
                                    <h6>
                                        <FontAwesomeIcon icon={faSliders} rotation={270} ></FontAwesomeIcon> Filters
                                    </h6>
                                    <button onClick={() => resetFacets()} className={styles.customLink}>Reset</button>
                                </div>
                                <div className='mb-3'>
                                    <h6 className={styles.customTitle}>{filterType}</h6>
                                    {filters.length ? filters.map((facet, i) => (
                                        <div key={i} className="form-check mt-2">
                                            <input className="form-check-input" type="checkbox"
                                                checked={isChecked(facet.key)} onChange={e => onChange(e)} value={facet.key} id={facet.key + facet.doc_count + i} />
                                            <label className="form-check-label d-flex align-items-center justify-content-between" htmlFor={facet.key + facet.doc_count + i}>
                                                {facet.key}
                                                <span className={classNames("form-check-label", styles.labelColor)} htmlFor={facet.key}> ({facet.doc_count})</span>
                                            </label>
                                        </div>
                                    )) : null}
                                </div>
                                <div className='mt-3'>
                                    <button className={styles.customButton} onClick={() => applyFilters()}>Apply Filters</button>
                                </div>
                            </div>
                            :<div className='mb-4'>
                                <h6>
                                    <FontAwesomeIcon icon={faSliders} rotation={270} ></FontAwesomeIcon> Filters
                                </h6>
                                <p className='mt-3'>Not available</p>
                            </div>  
                    }                
                    
                </Card.Body>
            </Card>

            <div className={styles.mobileFilter}>
                <button variant="primary" onClick={handleShow} className={styles.floatButton}>
                    <FontAwesomeIcon icon={faSliders} rotation={90}></FontAwesomeIcon>
                </button>

                <Modal show={show} onHide={handleClose} dialogClassName
                ={styles.customDialog} contentClassName={styles.customModalContent} >
                    <Modal.Header bsPrefix={styles.customModalHeader} closeButton>
                        <Modal.Title as="h5">
                            <FontAwesomeIcon className='mx-2' icon={faSliders} rotation={270} ></FontAwesomeIcon>
                            Filters
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {filters.length ? 
                            <>
                                <h6 className={styles.customTitle}>ACCESS TYPE</h6>
                                {filters.length ? filters.map((facet, i) => (
                                    <div key={i} className="form-check mt-2">
                                        <input className="form-check-input" type="checkbox"
                                            checked={isChecked(facet.key)} onChange={e => onChange(e)} value={facet.key} id={facet.key + facet.doc_count + i} />
                                        <label className="form-check-label d-flex align-items-center justify-content-between" htmlFor={facet.key + facet.doc_count + i}>
                                            {facet.key}
                                            <span className={classNames("form-check-label", styles.labelColor)} htmlFor={facet.key}>&nbsp;({facet.doc_count})</span>
                                        </label>
                                    </div>
                                )) : null}
                                <div className='mt-3'>
                                    <button className={styles.customButton} onClick={() => applyFilters()}>Apply Filters</button>
                                    <button onClick={() => resetFacets()} className={classNames(styles.customButton, "mt-2")}>Reset</button>
                                </div>
                            </> 
                        : 
                            <>
                                <div className='mb-4'>
                                    <p className='mt-3 text-center'>Not available</p>
                                </div>
                            </>
                        }
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default Filter;