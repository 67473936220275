import {SET_RECORDS_PER_PAGE, SET_SORT_VALUE, SET_FILTER_VALUE, SET_SEARCH_FILTER} from '../actions/SortFilterAction';

const initialState = {
    records_per_page: 10,
    selected_sort: '',
    selected_filter: {journal_title: [], specialty: [], from_date: [1970], to_date: [2020]},
    search_filter: [{ nameKey: (new Date()).getTime(), field: 'anywhere', values: [], operation: 'and' }]
}

const sortFilterReducer = (state = initialState, action) => {
    switch(action.type){
        case SET_SORT_VALUE:
            return {...state, selected_sort: action.payload}
        case SET_FILTER_VALUE:
            return {...state, selected_filter: action.payload}
        case SET_RECORDS_PER_PAGE:
            return {...state, records_per_page: action.payload}
        case SET_SEARCH_FILTER:
            return {...state, search_filter: action.payload}
        default:
            return state;
    }
}

export default sortFilterReducer;