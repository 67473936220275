import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './ScrollToTop.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

function ScrollToTop(){

    const [showScroll, setShowScroll] = useState(false);

    const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const checkScrollTop = () => {
        if (window.pageYOffset > 400){
            setShowScroll(true)
        } else if (window.pageYOffset <= 400){
            setShowScroll(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
    }, [])

    return(
        showScroll ? 
        <button className={styles.scrollTop} onClick={() => scrollTop()}>
            <FontAwesomeIcon icon={faArrowUp}></FontAwesomeIcon>
        </button> : null
    )
}

export default ScrollToTop;