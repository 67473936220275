import React, { useEffect } from 'react';
import {PageView} from '../../tracking';
import {Row, Col} from 'react-bootstrap';
import classNames from 'classnames';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

function FAQs(){

    useEffect(() => {
        PageView();
    }, [])

    return(
        <div className="container mt-5">
            <h5 className="">Frequently Asked Questions</h5><hr />

            {/* General Section */}
            <div className="col-md-12 mb-5">
                <h6 className='mb-3'>General</h6>
                <Accordion>
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            What is a generic template? What could I use this for?
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                        <Card.Body className='text-justify'>
                            If OrthoPublish does not have a template for the specific journal for which you wish to prepare a manuscript, you can use a generic template to check tables, figures, references and to add supplementary files, keywords, statements, and author details, among others. This will allow you to prepare your manuscript and ensure that these key elements are present and correct. You can then format your manuscript according to your journal guidelines and store it in the Dashboard to track your journey through to acceptance.
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                            Will my paper get accepted if I use OrthoPublish?
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                        <Card.Body className='text-justify'>
                            We cannot guarantee that your paper will be accepted but we can help you to format it for your chosen journal.
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                            What if I don't see a template for the journal I want to use?
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                        <Card.Body className='text-justify'>
                            If your chosen journal is listed in OrthoPublish but we do not yet have a specific template for it, please click ‘Request template’. Clicking ‘Request template’ does not mean we will be able to provide a template immediately but will help us to prioritise our development schedule. In the meantime, using a generic template will help you to format and check your tables, figures and references, and add your keywords, authors, supplementary information and statements. You can then manage your manuscript through the Dashboard.
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="3">
                            How can I use OrthoPublish to find the best publication for my article?
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                        <Card.Body className='text-justify'>
                            The Journal Finder will help you identify the most appropriate journal for your article. You can search in three ways:
                            <ul>
                            <li>By Journal - enter keywords to find a journal with a scope that suits your paper.</li>
                            <li>By Similar Articles - enter your title and abstract and our algorithm will suggest suitable journals based on the context of the content, matching this to previously published similar articles.</li>
                            <li>By Article - find out where your community is publishing by entering an author name, keywords, article title, institution name or PMID/DOI.</li>
                            </ul>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                I don't know my/my authors’ ORCID/Email?
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body className='text-justify'>
                                You do not have to include ORCID IDs for all authors. More information on ORCID IDs can be found at <a href="https://orcid.org">orcid.org</a>. To add an author in Step Five you will need to include their email address.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="5">
                                I've received an error message while formatting my manuscript, what can I do?
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="5">
                            <Card.Body className='text-justify'>
                                Please contact <a href="mailto:orthopublish@boneandjoint.org.uk">orthopublish@boneandjoint.org.uk</a> with a detailed description of your error and screenshots if possible.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="6">
                                Which one of my author team should format the manuscript?
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="6">
                            <Card.Body>
                                Any member of the author team can format the manuscript. Please remember this user will have control of the Dashboard. Author roles (e.g., Corresponding Author) can be added in Step Five.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="7">
                                Why do I need to buy credits?
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="7">
                            <Card.Body className='text-justify'>
                                OrthoPublish uses a credits system to simplify the template purchase process and to provide discounts for bulk purchases. A journal-specific template will use 2 credits and a generic template will use 1 credit. View our pricing information <Link to="/pricing-info" className="h6">here</Link> and click here to buy <Link to="/profile?tab=credits" className="h6">credits</Link>. Credits are valid for 12 months from the date of purchase and must be used during that time. Credits obtained using a promotional code will expire after six months.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="8">
                                Can I be refunded for unused credits?
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="8">
                            <Card.Body className='text-justify'>
                                Credits are valid for 12 months from the date of purchase and must be used during that time. No refunds will be allowed for any unused credits after 12 months.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="9">
                                Can I submit my manuscript straight from OrthoPublish?
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="9">
                            <Card.Body className='text-justify'>
                                From the Dashboard you can select ‘Go to the journal’s submission system’ from the Actions drop-down. You will need to sign into the appropriate journal submission system and upload your manuscript and associated files/information in the usual way.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="10">
                                What changes does OrthoPublish make?
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="10">
                            <Card.Body className='text-justify'>
                                OrthoPublish will automatically: make the manuscript title bold; correct abstract headings and make them bold; correct journal titles in references to standardized journal title abbreviations; and remove any text which has been deleted using a tracked changes function and accept any new additions. Additionally, OrthoPublish will: make sure your title is present and in bold; make sure abstract headings are present and in bold, and that the abstract does not exceed the journal’s word count; highlight if the abstract or manuscript exceed the journal’s word count requirements; make sure all your tables have captions and are cited in the text in numerical order, and that the total number does not exceed the journal’s limit; make sure figures are cited in the text in numerical order and that the total number does not exceed the journal’s limit, as well as removing figure captions, which will be dealt with in Step Three); make sure your references are correct and in an accepted format, are cited in both the text and reference list in numerical order, are no older than the journal allows, and that the total number does not exceed the journal’s limit; make sure any sections required specific to the journal are present; blind any Ringgold-listed institutions; make sure an NCT number is present, if required; and ensure any journal-specific criteria are fulfilled.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="11">
                                I can’t open my downloaded files?
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="11">
                            <Card.Body className='text-justify'>
                                Our export module is compatible with Microsoft Word versions from 2016 onwards. If you are using an older version, such as Word 2007, please follow these steps:
                                <ul> 
                                    <li>Verify Compatibility: Ensure that you are using the latest version of Microsoft Word. If so, you should be able to open the files without any issues.</li>
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </div>

            {/* Dashboard Section */}
            <div className="col-md-12 mb-5">
                <h6 className='mb-3'>Dashboard</h6>
                <Accordion>

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                What can I do from the Dashboard?
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className='text-justify'>
                                You can use the Dashboard to manage, review and update your manuscripts. You can view manuscripts that have been formatted, are in draft version and that you have chosen to archive. You can update the status of your manuscript and access or download your manuscript to edit or format for a different journal. You can also format new manuscripts and purchase credits from the Dashboard.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                How do I find my manuscript?
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body className='text-justify'>
                                Your manuscript can be found under one of the three tabs on the Dashboard. If you have clicked the ‘Finish formatting’ button it will be listed under ‘Formatted manuscripts’, if you have not finished working on your manuscript it will be listed under ‘Drafts’, if you have selected ‘Archive’ from the Actions drop-down menu your manuscript can be found under ‘Archived’.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                How can I filter/order my manuscripts?
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body className='text-justify'>
                                You can filter manuscripts in your Dashboard by the status you have applied (Submitted, Revised, Formatted, Rejected, Published). You can order manuscripts by date, title or journal.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                What happens when I delete a manuscript from the Dashboard?
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body className='text-justify'>
                                Deleting a manuscript will permanently remove the manuscript and all associated files. You will not be able to retrieve it after deletion. If you think you may want to revisit a paper, it is better to assign it to the Archive.
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    {/* <Card> 
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                I have formatted my paper and now want to import it again into the same template?
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body className='text-justify'>
                                If you need to reimport your paper, please
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card> */}

                </Accordion>
            </div>
        </div>
    )
}

export default FAQs;