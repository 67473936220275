import React from "react";
import { Modal } from "react-bootstrap";
import { FieldLoader } from "../../../components/Loader/CommonLoader";

const ConfirmationModal = ({show, setShow, manuscriptId = "", loader, setButtonLoader, handleClone, createDefaultManuscript, genericTemplate, journalTitle}) => {

	return(
		<Modal show={show.isConfirmationCheck} onHide={()=> {
			if (!loader.isDefault && !loader.isClone) {
				setShow({isConfirmationCheck: false})
				setButtonLoader({...loader, isClone:false, isDefault:false})
			}
		}}>
		<Modal.Header closeButton className='border-bottom-0'>
			<Modal.Title className='h5'>{!manuscriptId? "Format manuscript?": "Format manuscript for another journal?"}</Modal.Title>
		</Modal.Header>
		<Modal.Body className="pt-0">
			<div>
				{ genericTemplate ? 
					<p className={'message'}>You will be redirected to checkout page before you start formatting manuscript for <strong>{journalTitle}</strong>. Are you sure you want to proceed?</p> 
				: 
					<p className={'message'}>You will be formatting {manuscriptId? "an existing": "a new"} manuscript for <strong>{journalTitle}</strong>.</p>
				}
				<section>
					{ manuscriptId ?
						loader.isClone ? 
							<FieldLoader className="w-100 mb-2" />
						:
							<button className="w-100 mb-2" onClick={handleClone}>Start now</button>
					:	
						loader.isDefault ? 
							<FieldLoader className="w-100 mb-2" />
						:
							<button className="w-100 mb-2" onClick={createDefaultManuscript}>Start now</button>
					}
					<button className={'w-100 mb-2 customButton'} disabled={loader.isClone || loader.isDefault} onClick={()=> setShow({...show, isConfirmationCheck: false})}>Select a different journal</button>
				</section>
			</div>
		</Modal.Body>
		</Modal>
	)
}

export default ConfirmationModal;