import { HAS_RELEVANCE_SORT, HAS_LATEST_SORT, HAS_OA_SORT, HAS_JOURNAL_SORT, HAS_CITATION_SCORE_SORT, 
    HAS_NO_RELEVANCE_SORT, HAS_NO_LATEST_SORT, HAS_NO_OA_SORT, HAS_NO_JOURNAL_SORT, HAS_NO_CITATION_SCORE_SORT } from '../actions/SortConfigAction';

const initialState = {
    relevance_sort: false,
    latest_sort: false,
    oa_sort: false,
    journal_sort: false,
    citation_sort: false,
}

const sortConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        case HAS_RELEVANCE_SORT:
            return { ...state, relevance_sort: action.payload }
        case HAS_LATEST_SORT:
            return { ...state, latest_sort: action.payload }
        case HAS_OA_SORT:
            return { ...state, oa_sort: action.payload }
        case HAS_JOURNAL_SORT:
            return { ...state, journal_sort: action.payload }
        case HAS_CITATION_SCORE_SORT: 
            return { ...state, citation_sort: action.payload}
        case HAS_NO_RELEVANCE_SORT:
            return { ...state, relevance_sort: action.payload }
        case HAS_NO_LATEST_SORT:
            return { ...state, latest_sort: action.payload }
        case HAS_NO_OA_SORT:
            return { ...state, oa_sort: action.payload }
        case HAS_NO_JOURNAL_SORT:
            return { ...state, journal_sort: action.payload }
        case HAS_NO_CITATION_SCORE_SORT:
            return { ...state, citation_sort: action.payload }
        default:
            return state;
    }
}

export default sortConfigReducer;