import React from 'react';
import { OverlayTrigger, Popover, Badge } from 'react-bootstrap';

export const renderIFLogo = (impactfactor) => {
    const popover = <Popover id={`popover-positioned-` + impactfactor}>
                        <Popover.Title as="h3">{`Range scale`}</Popover.Title>
                        <Popover.Content>
                            <ul style={{ listStyleType: "none", padding: "0px", marginBottom: "0px" }}>
                                <li><div style={{ width: "15px", height: "15px", backgroundColor: "#28a745", display: "inline-block", marginRight: "2px" }}></div> 10 and above</li>
                                <li><div style={{ width: "15px", height: "15px", backgroundColor: "#9ACD32", display: "inline-block", marginRight: "2px" }}></div> 4 to 9.9</li>
                                <li><div style={{ width: "15px", height: "15px", backgroundColor: "#FFD700", display: "inline-block", marginRight: "2px" }}></div> 3 to 3.9</li>
                                <li><div style={{ width: "15px", height: "15px", backgroundColor: "#ffc107", display: "inline-block", marginRight: "2px" }}></div> 1 to 2.9</li>
                                <li><div style={{ width: "15px", height: "15px", backgroundColor: "#dc3545", display: "inline-block", marginRight: "2px" }}></div> less than 1</li>
                                <li className="text-center"><a href="https://en.wikipedia.org/wiki/Impact_factor" target="_blank" rel="noopener noreferrer">More details</a></li>
                            </ul>
                        </Popover.Content>
                    </Popover>
                    
        if(impactfactor >=1 && impactfactor <3){
            return <OverlayTrigger
                trigger="click"
                placement="top"
                rootClose={true}
                overlay={
                    popover
                }
            >
                <button className="btn font-weight-bold text-dark border border-dark rounded-0 px-1 mr-2 py-0" style={{ fontSize: "12px", backgroundColor:"#ffc107" }}>IF <Badge variant="dark">{impactfactor}</Badge></button>
            </OverlayTrigger>}
        else if(impactfactor >=3 && impactfactor < 4){         return <OverlayTrigger
                trigger="click"
                placement="top"
                rootClose={true}
                overlay={
                    popover
                }
            >
                <button className="btn font-weight-bold text-dark border border-dark rounded-0 px-1 mr-2 py-0" style={{ backgroundColor: '#FFD700', fontSize: "12px" }}>IF <Badge variant="dark">{impactfactor}</Badge></button>
            </OverlayTrigger>}
        else if(impactfactor >=4 && impactfactor <10){
            return <OverlayTrigger
                trigger="click"
                placement="top"
                rootClose={true}
                overlay={
                    popover
                }
            >
                <button className="btn font-weight-bold text-dark border border-dark rounded-0 px-1 mr-2 py-0" style={{ backgroundColor: '#9ACD32', fontSize: "12px" }}>IF <Badge variant="dark">{impactfactor}</Badge></button>
            </OverlayTrigger>}
        else if(impactfactor >10){
            return <OverlayTrigger
                trigger="click"
                placement="top"
                rootClose={true}
                overlay={
                    popover
                }
            >
                <button className="btn font-weight-bold text-dark border border-dark rounded-0 px-1 mr-2 py-0" style={{ fontSize: "12px", backgroundColor:"#28a745" }}>IF <Badge variant="dark">{impactfactor}</Badge></button>
            </OverlayTrigger>}
        else {return <OverlayTrigger
            trigger="click"
            placement="top"
            rootClose={true}
            overlay={
                popover
            }
        >
            <button className="btn font-weight-bold text-dark border border-dark rounded-0 px-1 mr-2 py-0" style={{ fontSize: "12px", backgroundColor:"#dc3545" }}>IF <Badge variant="dark">{impactfactor}</Badge></button>
        </OverlayTrigger> }
       
}