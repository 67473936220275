import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card, ProgressBar, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { renderIFLogo } from '../IFLogo/IFLogo';
import TooltipButton from '../TooltipButton/TooltipButton';
import styles from './ArticleList.module.scss'
import imag from "../../accets/Hybrid_Access_logo.png"
import AxiosService from '../../utils/AxiosService';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { FieldLoader } from '../Loader/CommonLoader';
import { useHistory} from 'react-router-dom';
import PaymentModals from '../../pages/PaymentPages/paymentModal';


function ArticleList(props){

    const article = props.info
    const onClickChange = props.onClickChange
    const apiService = new AxiosService()
    const [loading, setLoading]= useState(false)
    const [creditModalControl, setCreditModalControl] = useState({type: "generic", sufficientCreditModal: false, insufficientCreditModal: false})
    const [userCreditDetails, setUserCreditDetails] = useState({})
    const [genericLoading, setGenericLoading] = useState(false)
    const history = useHistory(); 

	const handleRequestTemaplate =() => {
        setLoading(true);
		apiService.requestTemplate(article.lexicon_id).then(res=> {
            setLoading(false)
			toast.success(res.message,  {
                autoClose: 3000,
            })
		}).catch(err=> {
            setLoading(false)
            toast.error(err.response.data.message,  {
                autoClose: 3000,
            })
		})
	}

    const genericTemplateClick = () => {
		setGenericLoading(true)
		history.push('/details/' + article.lexicon_id + `?creditModal=generic`)
		setGenericLoading(false)
		// apiService.userPaymentProfile().then((res) => {
		// 	setUserCreditDetails(res)
		// 	setGenericLoading(false)
		// 	if(res.credit >= 1 ) {
		// 		setCreditModalControl({...creditModalControl, type: "Generic", sufficientCreditModal: true})
		// 	} else {
		// 		setCreditModalControl({...creditModalControl, type: "Generic", insufficientCreditModal: true})
		// 	}
		// }).catch((err) => {
		// 	toast.error(err.response.data.message, {autoClose: 3000})
		// 	setGenericLoading(false)
		// })
	} 
 
	const specificTemplateClick = () => { 
		setLoading(true)
		history.push('/details/' + article.lexicon_id + `?creditModal=specific`)
		setLoading(false)
		// apiService.userPaymentProfile().then((res) => {
		// 	setUserCreditDetails(res)
		// 	setLoading(false)
		// 	if(res.credit >= 2 ) {
		// 		setCreditModalControl({...creditModalControl, type: "Specific", sufficientCreditModal: true})
		// 	} else {
		// 		setCreditModalControl({...creditModalControl, type: "Specific", insufficientCreditModal: true})
		// 	}
		// }).catch((err) => {
		// 	setLoading(false)
		// 	toast.error(err.response.data.message, {autoClose: 3000})
		// })
	}

    return(
        <Card id="journalInfo" className='mb-3'>
            <Card.Body>
                {props.isloading ?
                        <>
                            <ProgressBar animated now={100} variant="secondary" className='mb-2'/>
                            <ProgressBar animated now={100} variant="secondary" className='mb-2'/>
                            <ProgressBar animated now={100} variant="secondary" />
                        </>
                    :   <>
                        <div>
                            {/* <h5 id='title' className='mb-2'>{article.journal_title}</h5> */}
                            <h4>
                                <Link to={'/details/' + article.lexicon_id} className={styles.secondaryLink}>{article.journal_title}</Link>
                                { article.openAccess ==="Open access" || article.open_acess ==="Open access" ?
                                    <>
                                        <TooltipButton key="Open access" placement="top" tooltipMessage="Open access">
                                            <img width="15px" height="20px" className='mx-2 h6 text-muted' src={process.env.PUBLIC_URL + '/logos/open_access_logo.svg'} alt={article.openAccess || article.open_acess} />
                                        </TooltipButton>
                                        <span>
                                            { article.formatting ==="1" ? 
                                                <button className='btn-sm border-0' onClick={()=>{specificTemplateClick()}}>
                                                    Format Manuscript 
                                                    <FontAwesomeIcon icon={faAngleRight} /> 
                                                </button>
                                            : 
                                                <>
                                                    <TooltipButton tooltipMessage="Our generic template will help you meet common requirements for submission from reference, figure and table citations to figure quality and formatting errors.">
                                                        <button className='btn-sm border-0 mr-2' onClick={()=> {genericTemplateClick()}}>
                                                            {genericLoading ? 
                                                                <>
                                                                    Loading
                                                                    <Spinner animation='grow' size='sm'/>
                                                                    <Spinner animation='grow' size='sm'/>
                                                                    <Spinner animation='grow' size='sm'/>
                                                                </>  
                                                            : 
                                                                "Use generic template"
                                                            }
                                                        </button>
                                                    </TooltipButton>
                                                    { loading ? 
                                                        <FieldLoader isLoading={loading} className=" btn-outline-info btn btn-sm mr-2" />
                                                        :
                                                        <>
                                                            <TooltipButton tooltipMessage="There is no template available for this journal yet. Please click to here to notify our team so we can prioritise future development.">
                                                                <button className='btn-outline-info btn btn-sm mr-2' onClick={()=> handleRequestTemaplate()}>
                                                                    Request template
                                                                </button>
                                                            </TooltipButton>
                                                        </>
                                                    }
                                                </>
                                            }
                                        </span>
                                    </>
                                : article.openAccess ==="Hybrid" || article.open_acess==="Hybrid" ? 
                                    <>
                                        <TooltipButton key="Hybrid" placement="top" tooltipMessage="Hybrid">
                                            <img width="15px" height="20px" className='mx-2 h6 text-muted' src={imag} alt={article.openAccess || article.openAccess} />
                                        </TooltipButton>
                                        <span>
                                            {article.formatting ==="1" ? 
                                                <button className='btn-sm border-0' onClick={()=>{specificTemplateClick()}}>
                                                    Format Manuscript 
                                                    <FontAwesomeIcon icon={faAngleRight} /> 
                                                </button>
                                            : 
                                                <>
                                                    <TooltipButton tooltipMessage="Our generic template will help you meet common requirements for submission from reference, figure and table citations to figure quality and formatting errors.">
                                                        <button className='btn-sm border-0 mr-2' onClick={()=> {genericTemplateClick()}}>
                                                            {genericLoading ? 
                                                                <>
                                                                    Loading
                                                                    <Spinner animation='grow' size='sm'/>
                                                                    <Spinner animation='grow' size='sm'/>
                                                                    <Spinner animation='grow' size='sm'/>
                                                                </>  
                                                            : 
                                                                "Use generic template"
                                                            }
                                                        </button>
                                                    </TooltipButton>
                                                    { loading ? 
                                                        <FieldLoader isLoading={loading} className=" btn-outline-info btn btn-sm mr-2" />
                                                        :
                                                        <>
                                                            <TooltipButton tooltipMessage="There is no template available for this journal yet. Please click to here to notify our team so we can prioritise future development.">
                                                                <button className='btn-outline-info btn btn-sm mr-2' onClick={()=> handleRequestTemaplate()}>
                                                                    Request template
                                                                </button>
                                                            </TooltipButton>
                                                        </>
                                                    }
                                                </>
                                            }
                                        </span>
                                    </>
                                :
                                    null
                                }
                            </h4>
                        </div>
                        <div className='mb-2'><TooltipButton placement="top" tooltipMessage="View abstract in OrthoSearch"><a className={styles.customLink} href={article.doi ? `https://www.orthosearch.org.uk/Articles?query=${article.doi}&author=` :`https://www.orthosearch.org.uk/Articles?query=${article.pmid}&author=`} target="_blank">{article.article_title}</a></TooltipButton></div>
                        {article.authors.length>0 && <div><p className='mb-2'><strong className='text-muted'>Author(s): </strong>{article.authors.join(", ")}</p></div>}
                        <div>
                            <p><strong className='text-muted'>Keyword(s):</strong> {article.keywords.slice(0,9).map(key=> key).join(", ")}</p>
                        </div>
                        <div className='mb-2'>
                            {article.publication_date ? 
                                <><span>{article.publication_date}</span><span className='mx-2'>&bull;</span></> : null}
                            <span>PMID: <a className="font-weight-normal" href={article.pmid? `https://pubmed.ncbi.nlm.nih.gov/${article.pmid}`: `https://www.orthosearch.org.uk/Articles?query=${article.doi}`} target="_blank" rel="noopener noreferrer">{article.pmid || "NA"}</a></span><span className='mx-2'>&bull;</span>
                            <span>DOI: <a className="font-weight-normal" href={article.doi?`https://doi.org/${article.doi}`: `https://www.orthosearch.org.uk/Articles?query=${article.pmid}`} target="_blank" rel="noopener noreferrer">{article.doi || "NA"}</a></span>
                            {article.citeScore !=="null" && article.citeScore !== "0" ? <span><span className='mx-2'>&bull;</span>CiteScore: {article.citeScore}</span> : null}
                        </div>
                        <div>{article.impact_factor !== "0" && article.impact_factor !== 0 && article.impact_factor !=="" ? renderIFLogo(article.impact_factor) : null}</div>
                        {/* <div>
							{article.formatting ==="1" ? <button className='float-right btnOutline border-0 p-0' onClick={()=>onClickChange(article)}>Format Manuscript <FontAwesomeIcon icon={faAngleRight} /> </button>: 
                            loading ? <FieldLoader isLoading={loading} className="float-right btnOutline border-0 p-0" />:<button className='float-right btnOutline border-0 p-0' onClick={()=> handleRequestTemaplate()}>Request template</button>}
                        </div> */}
                    </> 
                }
            </Card.Body>
            <PaymentModals
                clone={false}
                journalInfo={article}
				creditModalControl={creditModalControl}
				setCreditModalControl={setCreditModalControl}
				userCreditDetails={userCreditDetails}
            />
        </Card>
    )
}

export default ArticleList;