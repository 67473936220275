import { faAngleDoubleDown, faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useState } from "react"
import { Collapse } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { FieldLoader } from "../../../../../components/Loader/CommonLoader"
import AxiosService from "../../../../../utils/AxiosService"
import { GoToErrorSection } from "./ValidationErrorTable"

const ErrorItem = ({err, index, setErrorMessage, fetchData, isSaveValidateLoading, header, gotoError, children, errorWindowControl, setErrorWindowControl}) => {
    const [view, setView] = useState(false)
    const [loading, setLoading] = useState({isAutoCorrect: false})
    const apiService = new AxiosService()
	const {manuscriptId} = useParams()
    const [showSystemMessage, setShowSystemMessage] = useState(false)

    const handleViewMore = ()=> {
       setView(!view)
    }

    function updateReferences() {
		apiService.validateData(manuscriptId, "Reference")
		.then(res => {
			setLoading({...loading, isAutoCorrect: false})
			setErrorMessage()
		}).catch(err => {
			setLoading({...loading, isAutoCorrect: false})
			setErrorMessage(err.response.data.message)
		})
	}
    function handleAutoCorrect(err){
		setLoading({...loading, isAutoCorrect: true})
		apiService.autoCorrect(manuscriptId , {
				"field": err.field,
				"errorKey": err.errorKey,
                "references": err.convertableList
		}).then(res=> {
		    setLoading({...loading, isAutoCorrect: false})
			fetchData()
			updateReferences()
			toast.success(res.result, {
                position: "top-center",
                autoClose: 3000
            })
		}).catch(err=> {
		    setLoading({...loading, isAutoCorrect: false})
			toast.error(err?.response?.data?.message, {autoClose: 3000})
		})
	}

    function systemMessageClick(msg, e) {
        if(showSystemMessage) {
            setShowSystemMessage(false) 
        } else {
            setShowSystemMessage(true)
        }
    } 

    return(
        <>
            <div>
                <p className="mb-0">
                    <span className= {err.type=== "error" ? "text-danger text-capitalize font-weight-bold" : "text-warning text-capitalize font-weight-bold" }>
                        {err.type}:
                    </span>
                    <span>
                        {" "+err.description}
                    </span>
                </p>
                { err?.errorKey !== "blindOriganization" &&
                    <p className="my-2 linkText cursor-pointer p-0" onClick={()=>handleViewMore()} >View details <FontAwesomeIcon  icon={!view? faAngleDoubleDown: faAngleDoubleUp} /></p>
                }
            </div>
            <Collapse key={index} in={view} className="multi-collapse">
                <div id={index} key={index}>
                    { err?.details && (err.errorKey !=="referenceConvert" && err?.errorKey !== "blindOriganization") && 
                        <ul className="p-0">
                            {err?.details?.map((detail, index) => 
                                <li key={index}> 
                                    { typeof detail === "object" && !Array.isArray(detail) && detail !== null ?
                                        <div className="mb-3">
                                            Ref {detail.referenceNo}: {detail.reference} 
                                            <span className="text-danger">
                                                ({detail?.error}) <br/>
                                            </span>
                                        </div>
                                    :
                                        null
                                    }
                                    { typeof detail === "string" ? 
                                        <>
                                            <span className="d-flex flex-row align-items-center justify-content-between">
                                                <span dangerouslySetInnerHTML={{__html: detail}}></span>
                                                {/* {err.errorKey === "referencesNotReferred" || err.errorKey === "referenceOlderYearCheck" || err.errorKey === "referenceFutureYearCheck" || err.errorKey === "referenceNotFoundForCitations" || err.errorKey === "tablesMissingForCitation" ?  */}
                                                { err?.updatedErrorDetails?.length > 0 ?
                                                    <GoToErrorSection err={err.updatedErrorDetails[index]} isSaveValidateLoading={isSaveValidateLoading} text={"Go to " + detail} errorWindowControl={errorWindowControl} setErrorWindowControl={setErrorWindowControl}/>
                                                :
                                                    ""
                                                }
                                                {/* {err.errorKey === "referenceConvert" ? 
                                                    <GoToErrorSection err={err.nonConvertableList[index]} isSaveValidateLoading={isSaveValidateLoading} text={"Go to " + detail}/>
                                                :
                                                    ""
                                                } */}
                                            </span> <br/>
                                        </>
                                    :
                                        null
                                    }
                                    { typeof detail === "number" ? 
                                        <>
                                            {detail}
                                        </>
                                    :
                                        null
                                    }
                                    {/* { err?.autoCorrect ? 
                                            loading?.isAutoCorrect ? 
                                                <FieldLoader className="btn-sm w-auto mt-2 btnOutline" isLoading={loading.isAutoCorrect} /> 
                                            :
                                                <button className="btn-sm btnOutline w-auto mt-2" onClick={()=> handleAutoCorrect(err, err?.header)} disabled={isSaveValidateLoading}>
                                                    Auto correct
                                                </button> 
                                        :
                                            ""
                                        } */}
                                </li>
                            )}
                        </ul>
                    }
                    
                    { err?.convertableList?.length > 0 && 
                        <ul className="p-0"> 
                            <h6 className="">Auto-correctable references: </h6>
                            { err?.convertableList?.map ( ref => ( 
                                <li className="mb-2">
                                    Ref {ref.referenceNo} : {ref.reference} - <strong className="text-warning">[{ref.referenceFormat}]</strong>
                                    { err?.id ?
                                        children
                                    : 
                                        ""
                                    }
                                </li>
                            ))}
                            { loading?.isAutoCorrect ? 
                                <FieldLoader className="btn-sm w-auto mt-2 btnOutline" isLoading={loading.isAutoCorrect} /> 
                            :
                                <button className="btn-sm btnOutline w-auto mt-2 mb-2" onClick={()=> handleAutoCorrect(err)} disabled={isSaveValidateLoading}>Auto correct error/s</button>
                            }
                        </ul>
                    }

                    {err?.nonConvertableList?.length > 0 && 
                        <ul className="p-0"> 
                            <h6 className="">Non auto-correctable references: </h6>
                            {err?.nonConvertableList?.map(ref => 
                                <li className="mb-3">
                                    Ref {ref.referenceNo}: {ref.reference}
                                    <span className="text-danger">
                                        ({ref?.error}) <br/>
                                        {/* { ref.systemMessage ? 
                                            <SystemMessage refItem={ref}/> 
                                            : 
                                            ""
                                        } */}
                                        <div className="d-flex justify-content-end">
                                            {ref.referenceNo && ref.referenceNo !== null ?
                                                <GoToErrorSection err={ref} isSaveValidateLoading={isSaveValidateLoading} text={"Go to " + ref.errorValue} errorWindowControl={errorWindowControl} setErrorWindowControl={setErrorWindowControl}/>
                                            :
                                                <>
                                                    <br/>
                                                    <br/>
                                                </>
                                            }
                                        </div> 
                                    </span>
                                </li>
                            )}
                        </ul>
                    }
                </div>
            </Collapse>	
            
        </>
    )
}

export default ErrorItem

const SystemMessage = ({refItem}) => {
    const [showSystemMessage, setShowSystemMessage] = useState(false)

    function systemMessageClick(msg, e) {
        if(showSystemMessage) {
            setShowSystemMessage(false) 
        } else {
            setShowSystemMessage(true)
        }
    } 

    return (
        <>
            {showSystemMessage ? <p className="text-info p-0 m-0">{refItem?.systemMessage}</p> : ""}
            {refItem?.systemMessage ? 
                <span
                    className="text-secondary btn p-0 m-0" 
                    onClick={(e) => {
                        systemMessageClick(refItem?.systemMessage, e)
                    }}
                >
                    {showSystemMessage ? "Hide" : "Show more"}
                </span> 
            : 
                ""
            }
        </>
    )
}