import { faCaretLeft, faCaretRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCommands, useHelpers } from '@remirror/react';
import React, { useCallback, useEffect, useState} from 'react';


function useFindReplace() {
    const initialState ={
      query: '',
      replacement: '',
      activeIndex: null,
      total: 0,
      caseSensitive: false
    }
    const helpers = useHelpers();
    const commands = useCommands();
    const [state, setState] = useState(initialState);
  
    const find = useCallback(
      (indexDiff = 0) => {
        setState((state) => {
          // console.log("called===", state)
          const { query, caseSensitive, activeIndex } = state;
          const result = helpers.findRanges({
            query,
            caseSensitive,
            activeIndex: activeIndex == null ? 0 : activeIndex + indexDiff,
          });
          return { ...state, total: result.ranges.length, activeIndex: result.activeIndex ?? 0 };
        });
      },
      [helpers],
    );
  
    const findNext = useCallback(() => find(+1), [find]);
    const findPrev = useCallback(() => find(-1), [find]);
  
    const stopFind = useCallback(() => {
      setState(initialState);
      commands.stopFind();
    }, [commands]);
  
    const replace = useCallback(()=> {
      const { query, replacement, caseSensitive, activeIndex } = state;
      commands.findAndReplace({ query, replacement, caseSensitive, index: activeIndex });
      find();
    }, [commands, state, find]);
  
    const replaceAll = useCallback(() => {
      const { query, replacement, caseSensitive } = state;
      commands.findAndReplaceAll({ query, replacement, caseSensitive });
      find();
    }, [commands, state, find]);
  
    const toggleCaseSensitive = useCallback(() => {
      setState((state) => {
        return { ...state, caseSensitive: !state.caseSensitive };
      });
    }, []);
    const setQuery = useCallback((query) => {
      setState((state) => ({ ...state, query }));
    }, []);
    const setReplacement = useCallback((replacement) => {
      setState((state) => ({ ...state, replacement }));
    }, []);
  
    useEffect(() => {
      find();
    }, [find, state.query, state.caseSensitive]);
  
    return {
      ...state,
      findNext,
      findPrev,
      stopFind,
      replace,
      replaceAll,
  
      toggleCaseSensitive,
      setQuery,
      setReplacement,
    };
  }
  
  
  
  const FindReplace = () => {
    const {
      query,
      setQuery,
      findNext,
      findPrev,
      stopFind,
      total,
      activeIndex,
      caseSensitive,
      toggleCaseSensitive,
      replace,
      replaceAll,
      replacement,
      setReplacement,
    } = useFindReplace();
  
    return (
      <div>
        <div className='d-flex align-items-start'>
            <div className="input-group mb-3 w-70 border mr-2 rounded">
                <input
                className="form-control bg-transparent border-0"
                placeholder='Find'
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                 />
                <span className="input-group-text bg-transparent border-0">
                  <button onMouseDown={(event) => event.preventDefault()} onClick={stopFind} className='btn py-0 text-muted' title="Clear">
                  <FontAwesomeIcon icon={faXmark} />
                  </button>
                  {total && activeIndex != null ? activeIndex + 1 : 0} of {total}
                </span>
            </div>
          <div className='d-flex flex-row'>
            <div class="btn-group mr-2" role="group">
                <button onMouseDown={(event) => event.preventDefault()} onClick={findPrev} className='btn btn-outline-light text-muted border' title='Previous'>
                <FontAwesomeIcon icon={faCaretLeft} />
                </button>
                <button onMouseDown={(event) => event.preventDefault()} onClick={findNext} className='btn btn-outline-light text-muted border' title="Next">
                  <FontAwesomeIcon icon={faCaretRight} />
                </button>
            </div>
            <span className='mx-1 align-items-center d-flex flex-row'>
              <input
                type='checkbox'
                id='case-sensitive-checkbox'
                checked={caseSensitive}
                onMouseDown={(event) => event.preventDefault()}
                onClick={toggleCaseSensitive}
              />
              <label htmlFor='case-sensitive-checkbox' className='ml-1 mb-0'>Match case</label>
            </span>
          </div> 
        </div>

        <div className='d-flex align-items-center'>
          <input
            className='form-control w-70'
            placeholder='Replace'
            value={replacement}
            onChange={(event) => setReplacement(event.target.value)}
          />
            <div class="btn-group ml-2" role="group">
              <button onMouseDown={(event) => event.preventDefault()} onClick={replace} className='btn border btn-outline-light text-muted'>
                Replace
              </button>
              <button onMouseDown={(event) => event.preventDefault()} onClick={replaceAll} className='btn border btn-outline-light text-muted'>
              All
              </button>
          </div>
        </div>

      </div>
    );
  };

  export default FindReplace;
  
  