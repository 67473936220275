export const HAS_SUBJECT_FILTER = "HAS_SUBJECT_FILTER";
export const HAS_JOURNAL_FILTER = "HAS_JOURNAL_FILTER";
export const HAS_PUBLICATION_YEAR_FILTER = "HAS_PUBLICATION_YEAR_FILTER";
export const HAS_OA_FILTER = "HAS_OA_FILTER";
export const HAS_BJ_FILTER = "HAS_BJ_FILTER";
export const HAS_PUBMED_FILTER = "HAS_PUBMED_FILTER";
export const HAS_NO_SUBJECT_FILTER = "HAS_NO_SUBJECT_FILTER";
export const HAS_NO_JOURNAL_FILTER = "HAS_NO_JOURNAL_FILTER";
export const HAS_NO_PUBLICATION_YEAR_FILTER = "HAS_NO_PUBLICATION_YEAR_FILTER";
export const HAS_NO_OA_FILTER = "HAS_NO_OA_FILTER";
export const HAS_NO_BJ_FILTER = "HAS_NO_BJ_FILTER";
export const HAS_NO_PUBMED_FILTER = "HAS_NO_PUBMED_FILTER"

export function hasSubjectFilter(){
    return{
        type: HAS_SUBJECT_FILTER,
        payload: true
    }
}


export function hasJournalFilter(){
    return {
        type: HAS_JOURNAL_FILTER,
        payload: true
    }
}

export function hasPublicationYearFilter(){
    return {
        type: HAS_PUBLICATION_YEAR_FILTER,
        payload: true
    }
}

export function hasOAFilter(){
    return {
        type: HAS_OA_FILTER,
        payload: true
    }
}

export function hasBJFilter(){
    return {
        type: HAS_BJ_FILTER,
        payload: true
    }
}

export function hasPubmedFilter() {
    return{
        type: HAS_PUBMED_FILTER,
        payload: true
    }
}

export function hasNoSubjectFilter(){
    return {
        type: HAS_NO_SUBJECT_FILTER,
        payload: false
    }
}

export function hasNoJournalFilter(){
    return {
        type: HAS_NO_JOURNAL_FILTER,
        payload: false
    }
}

export function hasNoPublicationYearFilter(){
    return {
        type: HAS_NO_PUBLICATION_YEAR_FILTER,
        payload: false
    }
}

export function hasNoOAFilter(){
    return {
        type: HAS_NO_OA_FILTER,
        payload: false
    }
}

export function hasNoBJFilter(){
    return {
        type: HAS_NO_BJ_FILTER,
        payload: false
    }
}

export function hasNoPubmedFilter(){
    return{
        type: HAS_NO_PUBMED_FILTER,
        payload: false
    }
}