import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import AxiosService from '../../../../../utils/AxiosService';
import RemirrorEditor, { editorRef } from '../../../../../components/RemirrorEditor/RemirrorEditor'
import { CircleLoader, FieldLoader, FullLoader } from '../../../../../components/Loader/CommonLoader';
import { Modal, Spinner } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Card from 'react-bootstrap/Card';
import { faSort, faUpload, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './RemirrorEditorStep.scss'
import EditingSuggestion from '../../../../../components/TooltipButton/EditingSuggestion';
import RemirrorTextEditor from './remirror';
import fileDownload from 'js-file-download';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export function CustomToggle({ children, eventKey }) {
	const decoratedOnClick = useAccordionToggle(eventKey);

	return (
		<div
			onClick={decoratedOnClick}
		>
			{children}
		</div>
	);
}

const RemirrorEditorStep = ({ 
	manuscriptId, resetError, isDisabled, setIsDisabled,
	journalId, loading, setLoading, editorData, manuscriptStatus,
	setEditorData, fetchData, showModal, hideModal,
	setApiCount, showToolbar, setShowToolbar, loader,
	setUpdating, saveManuscript, saveAndContinue,
	templateType, editorChanged, setEditorChanged, blindOrganizationClick,
	errorModal, setErrorModal, currentTab
}) => {

	const apiService = new AxiosService();
	const [articleType, setArticleType] = useState("")
	const [showPopulate, setShowPopulate] = useState(false)

	const [file, setFile] = useState();
	const inputFile = useRef(null);
	const [language, setLanguage] = useState("")
	const [fileNameVisible, setFileNameVisible] = useState(false)
	const [instructionModal, setInstructionModal] = useState(false)
	const [downloadErrorFileModal, setDownloadErrorFileModal] = useState(false)
	const search = useLocation().search;
	const tabIndex = Number(new URLSearchParams(search).get("step"))

	// const mergeLists = (docString) => {
	// 	let refrenceSection = docString.split("<p>References</p>")[1].split(/<p>[\s|\S]Table/)[0]
	// 	let refrenceList = refrenceSection.split(/<p><\/p>/).join("").split(/<ol>/).join("").split(/<\/ol>/).join("")
	// 	let refrence = "<ol>"+refrenceList+"</ol>"
	// 	let docArr = docString.split("<p>References</p>")
	// 	let refArr = docArr[1].split(/<p>[\s|\S]Table/)
	// 	refArr[0] = refrence
	// 	let refStr = refArr.join("<p>Table ")
	// 	docArr[1] = refStr
	// 	let docStr = docArr.join("<p>References</p>")
	// 	return docStr
	// }

	const uploadDocument = (file) => {
		const formData = new FormData();
		formData.append("file", file);
		setLoading({ ...loading, isFileLoading: true });
		setIsDisabled({ ...isDisabled, isSaveAndValidate: true })
		resetError()
		setApiCount(1)
		setUpdating(true)
		apiService.uploadManuscriptDocument(manuscriptId, formData).then(res => {
			setUpdating(false)
			// let manuscriptDocumentArr = res.split(/<p><\/?p>+/)
			// let document = mergeLists(res)
			// let manuscriptDocumentArr = document.split(/<p><\/?p>+/)
			// let trimStartFound = false
			// for(let i = 0; i < manuscriptDocumentArr.length; i++) {
			// 	if(manuscriptDocumentArr[i].length > 0 && trimStartFound === false) {
			// 		trimStartFound = true
			// 	}
			// 	if(manuscriptDocumentArr[i].length === 0 && trimStartFound === true ) {
			// 		manuscriptDocumentArr[i] = "<p></p>"
			// 	}
			// }
			// editorRef.current.setContent(manuscriptDocumentArr.join(""))
			editorRef.current.setContent(res)
			setLoading({ ...loading, isFileLoading: false });
			setIsDisabled({ ...isDisabled, isSaveAndValidate: false })
			toast.success("Document uploaded successfully.", {position: "top-center", autoClose: 3000});

		}).catch(err => {
			setUpdating(false)
			setLoading({ ...loading, isFileLoading: false });
			setIsDisabled({ ...isDisabled, isSaveAndValidate: false })
			if (err.response) {
				toast.error(err.response.data.message, {autoClose: 3000})
			}
			editorRef.current.setContent({content: [],type: "doc"})

		})
	}

	const handleInputChange = (e) => {
		// if(manuscriptStatus === "Formatted") {
		// 	toast.warning("You're only allowed to edit the formatted manuscript. Importing new file is not allowed.", {autoClose: 3000})
		// 	return
		// }
		e.preventDefault()
		let file = e.target?.files[0]
		setFile(e.target?.files[0]);
		const fileFormat = file.name.split(".").pop()
		const editorContent = editorRef?.current?.helpers?.getJSON()
		if (file?.size >0  && fileFormat === "docx" && file!==undefined && editorContent !=undefined) {
			uploadDocument(file)
		} else {
			if(file?.size ===0 ) toast.error("Empty file found!", {autoClose: 3000})
			else if (editorContent === undefined) editorRef.current.setContent({content: [],type: "doc"})
			else toast.error("Please upload your mansucript as a .docx file.", {autoClose: 3000})
		}
		setFileNameVisible(true)
		e.target.value=null
	}

	const populateTemplate = (e) => {
		e.preventDefault()
		setLoading({ ...loading, isPopulateTemplateLoading: true });
		setIsDisabled({ ...isDisabled, isSaveAndValidate: true })
		// setEditorData("")
		setShowPopulate(false)
		inputFile.current.value = ""
		setFile(inputFile.current.files[0])
		setApiCount(1)
		resetError()
		setUpdating(true)
		apiService.populateTemplate(journalId, articleType, manuscriptId).then(response => {
			setUpdating(false)
			setLoading({ ...loading, isPopulateTemplateLoading: false });
			setIsDisabled({ ...isDisabled, isSaveAndValidate: false })
			resetError()
			editorRef.current.setContent(response?.result)
			toast.success("Template populated successfully.", {position: "top-center", autoClose: 3000})
		}).catch(error => {
			setUpdating(false)
			setLoading({ ...loading, isPopulateTemplateLoading: false });
			setIsDisabled({ ...isDisabled, isSaveAndValidate: false })
			toast.error(error, {autoClose: 3000})
		})

	}

	const getData = () => {
		apiService.getStepOneDetails(manuscriptId).then(
			res => {
				const data = res.result;
				setArticleType(data?.manuscriptType)
				setLanguage(data?.language)
			}).catch(e => {
				toast.error(e?.response?.data?.message, {autoClose: 3000})
			})
	}

	function handleDeleteManuscript() {
		resetError()
		setLoading({ ...loading, isDeleteManuscript: true })
		apiService.deleteStepTwoManuscript(manuscriptId).then(res => {
			toast.success(res.result, {autoClose: 3000})
			fetchData()
			setFile()
			setLoading({ ...loading, isDeleteManuscript: false })
			hideModal({ ...showModal, isDeleteManuscript: false })
		}).catch(err => {
			setLoading({ ...loading, isDeleteManuscript: false })
			toast.error(err?.response?.data?.message, {autoClose: 3000})
		})
	}

	function getSuggestion() {
		let obj = []
		obj = window.WEBSPELLCHECKER.getInstances()
		if (obj[0]) {
			obj[0].openDialog()
		} else {
			toast.error(`Please select text area!`, {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 3000
			});
		}
	}

	useEffect(() => {
		if(tabIndex === 2) {
			fetchData()
		}
		getData()
	
	}, [tabIndex])

	useEffect(()=> {
		if(language.length > 0) {

			window?.WEBSPELLCHECKER?.init({
				container: document?.querySelector('.ProseMirror.remirror-editor'),
				lang: language
			});
			
			window?.WEBSPELLCHECKER?.getInstances()[1]?.setLang(language)
		}

	},[language])

	const handlePopulate = (e) => {
		let showPopUp = false
		editorRef?.current?.helpers?.getJSON()?.content?.map((item) => {
			if(item.content != undefined) {
				showPopUp = true
				return
			}
		})
		if(showPopUp) {
			setShowPopulate(true)
		} else {
			populateTemplate(e)
		}
			
	}

	function showInstructionsModal () {
		setInstructionModal(!instructionModal)
	}

	const updateSuggestedKeywords = () => {
		apiService.repopulateKeywords(manuscriptId, {force: true}).then(res => {
			
		}).catch(err => {
			toast.error(err?.response?.data?.message, {autoClose: 3000})
		})
	}

	function downloadErrorFile() {
		setLoading({...loading, isDownloadError: true})
		saveAndContinue().then((res) => {
			toast.success(res.result, {autoClose: 3000})
			updateSuggestedKeywords()
			setEditorChanged(false)
			setDownloadErrorFileModal(true)
			apiService.getExportErrorFile(manuscriptId, 2).then((result) => {
				toast.success("Error list downloaded successfully.", {autoClose: 3000})
				fileDownload(result, manuscriptId+"_Manuscript_Errors.xlsx")
				setLoading({...loading, isDownloadError: false})
			}).catch((err) => {
				toast.error(err.response.data.error.code, {autoClose: 3000})
				setLoading({...loading, isDownloadError: false})
			})
		}).catch((err) => {
			console.log("Err: ", err)
			if (err.response.data.message) {
				setErrorModal({...errorModal, message: err.response.data.message, visible: true})
			} else {
				setErrorModal({...errorModal, message: "Unable to save, please try again!", visible: true})
			}
			setLoading({...loading, isDownloadError: false})
		})
	}

	return (
		<div className='pb-3 stepTwoPage'>
			<div className='d-flex flex-row justify-content-between align-items-center'>
				<h5>Upload your manuscript to OrthoPublish</h5>
			</div>
			<p>Choose from the following options to upload your manuscript:</p>
			<ul className="">
				{/* <li>Copy and paste your manuscript into the box below </li> */}
				<li>Upload your manuscript using the 'Import file' button</li>
				<li>Build your manuscript by selecting the 'Populate template' button and adding your text under the journal's mandatory headings</li>
			</ul>
			<p className="">When you have uploaded your manuscript, ensure the title and mandatory journal headings are present, then press the ‘Check manuscript’ button to apply journal style and check for errors. </p>
			<p className="">Please DO NOT INCLUDE figures, figure captions/legends, numbered headings and title pages. Please DO INCLUDE figure citations and tables.</p>
			<p className="">OrthoPublish will not import tracked changes. Please accept changes before uploading.</p>
			<p className="">Please note: in the final export files, the abstract will be included in the main document. Please check the guidelines for your chosen journal to ensure it is submitted in line with their instructions.</p>
			<p className="">You will then be given a list of errors/guidance to help you ensure your manuscript meets your chosen journal's requirements. Please make the changes directly into the manuscript field on the left or edit your original document and reupload.  You can download the corrections needed by clicking on ‘Download list of errors’.</p>
			<p className="">Use the “Blind Organisations” button to blind all identifying information – this can be included on the separate title page.</p>
			<div className='mt-4 mb-3'>
				<div className='w-100 border rounded p-2'>
					<div className='d-flex flex-column align-items-center'>
						<div className='w-100 d-flex flex-row justify-content-between align-items-center'>
							<span><strong>Import a manuscript file (in .docx format)</strong></span>
							<button
								onClick={() => inputFile.current.click()}
								className="wide-bordered-button-secondary"
								disabled={loading.isFileLoading || loading.isPopulateTemplateLoading}
							>
								Import file
							</button>
							<input type="file" onChange={handleInputChange} ref={inputFile} />
						</div>
						<div className='w-100 d-flex flex-row justify-content-between align-items-center'>{fileNameVisible && <span>{file?.name}</span>}</div>
						<div className='w-100 d-flex flex-row justify-content-between align-items-center'>
							<h5 className='line-label text-secondary'><span className='text-secondary'>or</span></h5>
						</div>
						<div className='w-100 d-flex flex-row justify-content-between align-items-center'>
							<span><strong>Paste your manuscript into the box below or add your journal's mandatory headings</strong></span>
							{loading.isPopulateTemplateLoading ?
								<FieldLoader className="wide-bordered-button-secondary" isLoading={loading.isPopulateTemplateLoading} />
								:
								<button
									className='wide-bordered-button-secondary'
									onClick={(e) => handlePopulate(e)}
									disabled={loading.isFileLoading || loading.isUpload || templateType==="Generic"}
								>
									Populate template
								</button>
							}
						</div>
					</div>
				</div>
			</div>
			{editorData ?
			<>
				<RemirrorEditor 
					data={editorData} 
					language={language} 
					getSuggestion={getSuggestion} 
					showToolbar={showToolbar} 
					setShowToolbar={setShowToolbar} 
					loader={loader} 
					loading={loading} 
					handlePopulate ={handlePopulate}
					showModal = {showModal}
					hideModal = {hideModal}
					saveManuscript = {saveManuscript}
					downloadErrorFile = {downloadErrorFile}
					editorChanged={editorChanged} 
					setEditorChanged={setEditorChanged}
					manuscriptStatus = {manuscriptStatus}
					blindOrganizationClick = {blindOrganizationClick}
					templateType={templateType}
				/> 
			 </>
			 : (loading.isFileLoading || loading.isPopulateTemplateLoading) ?
				<p className='text-center'><CircleLoader /></p>
				: ''}
			{/* Clear all changes warning popup  */}
			<Modal show={showModal.isClearAll} onHide={() => hideModal({ ...showModal, isClearAll: false })} dialogClassName="">
				<Modal.Header className="h6">Warning!</Modal.Header>
				<Modal.Body className="pt-0">
					<p>Clearing all changes will remove all edits you have made. These cannot be retrieved.</p>
					<button className="btnOutline w-100" onClick={() => hideModal({ ...showModal, isClearAll: false })}>Cancel</button>
					{loading.isClearAll ? <FieldLoader className="w-100 mt-2" isLoading={loading.isClearAll} /> : <button onClick={fetchData} className="w-100 mt-2">Clear all changes</button>}
				</Modal.Body>
			</Modal>

			{/* Delete manuscript warning popup */}
			<Modal show={showModal.isDeleteManuscript} onHide={() => hideModal({ ...showModal, isDeleteManuscript: false })} dialogClassName="">
				<Modal.Header className="h6">Warning!</Modal.Header>
				<Modal.Body className="pt-0">
					<p>Deleting the manuscript will remove all edits you have made. These cannot be retrieved.</p>
					<button className="btnOutline w-100" onClick={() => hideModal({ ...showModal, isDeleteManuscript: false })}>Cancel</button>
					{loading.isDeleteManuscript ? <FieldLoader className="w-100 mt-2" isLoading={loading.isDeleteManuscript} /> : <button onClick={(e) => handleDeleteManuscript()} className="w-100 mt-2">Delete manuscript</button>}
				</Modal.Body>
			</Modal>

			{/* Populate template warning popup */}
			<Modal show={showPopulate} onHide={() => setShowPopulate(false)} dialogClassName="">
				<Modal.Header className="h6">Warning!</Modal.Header>
				<Modal.Body className="pt-0">
					<p>Are you sure you want to populate the template for the existing manuscript? Clicking on 'Populate template' will permanently remove all data.</p>
					<button className="btnOutline w-100" onClick={() => setShowPopulate(false)}>Cancel</button>
					{loading.isPopulateTemplateLoading ? <FieldLoader className="w-100 btn-info btn mt-2" isLoading={loading.isPopulateTemplateLoading} /> : <button onClick={(e) => populateTemplate(e)} className="w-100 mt-2">Populate template</button>}
				</Modal.Body>
			</Modal>

			{/* Journal detailed instruction modal */}
			<Modal show={instructionModal} onHide={() => showInstructionsModal()}>
				<Modal.Header>
					<div className='d-flex flex-column'>
						<h6>Upload / Copy - Paste your manuscript</h6>
						<p className='m-0 text-secondary'>detailed instruction</p>
					</div>
					<div>
						<button className='secondary-text-button'>
							<FontAwesomeIcon icon={faXmark} onClick={() => showInstructionsModal()}/>
						</button>
					</div>
				</Modal.Header>
				<Modal.Body className='modal-body-height'>
					<p>When you have uploaded your manuscript press the 'Save and Proceed' button to apply journal style.</p>
					<p>Please DO NOT INCLUDE figures or figure captions/legends but DO INCLUDE figure citations (e.g. see Figure 1).</p>
					<p>OrthoPublish will not import tracked changes. Please accept changes before uploading.</p>
					<p>Please note: in the final export files, the abstract will be included in the main document. Please check the guidelines for your chosen journal to ensure it is submitted in line with their instructions.</p>
					<p>You will then be given a list of errors/guidance to help you ensure your manuscript meets your chosen journal's requirements. Please make the changes directly into the manuscript field on the left or edit your original document and reupload. You can download the corrections needed by clicking on ‘Download list of errors’.</p>
				</Modal.Body>
			</Modal>

			{/* Download error file notice modal */}
			<Modal show={downloadErrorFileModal} onHide={() => {setDownloadErrorFileModal(false)}} size='lg'>
				<Modal.Header className='h6' closeButton>
					Steps after downloading error file
				</Modal.Header>
				<Modal.Body>
					<p>Use your downloaded error list to work on your paper on screen in OrthoPublish or to make changes to your document offline. If you choose to use the downloaded error list to work on your paper outside OrthoPublish, follow these steps to reupload your manuscript:</p>
					<ul className='list-Style-Disc'>
						<li>Go to Dashboard/Drafts</li>
						<li>Select the paper you were working on</li>
						<li>Ensure the new document has the same paper title as the original file</li>
						<li>Click on ‘Import file’ (note this will overwrite the file you originally imported and any changes you have made to it)</li>
					</ul>
					<div className='d-flex flex-row-reverse'>
						<button className='mt-3 ml-auto' disabled={loading.isDownloadError} onClick={() => {setDownloadErrorFileModal(false)}}>
							{loading.isDownloadError ? 
								<>
									<Spinner animation='grow' size='sm' className='mx-1'/>
									<Spinner animation='grow' size='sm' className='mx-1'/>
									<Spinner animation='grow' size='sm' className='mx-1'/>
								</>
							:
								"Continue"
							}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			{loader ? <FullLoader /> : null}

		</div>

		// <RemirrorTextEditor />
	)
}

export default RemirrorEditorStep
