import React from "react";
import AxiosService from "../../utils/AxiosService";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Pagination from "../../components/PaginationComponent/Pagination";
import { Spinner } from "react-bootstrap";
import styles from "./AdminDashboard.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faExclamationCircle, faSearch, faSort, faSortAmountAsc, faSortAmountDesc, faSortNumericDesc, faX } from "@fortawesome/free-solid-svg-icons";
import LocalStorageService from "../../utils/auth_service/AuthServiceStorage";
import classNames from "classnames";
import history from "../../history";
import TooltipButton from "../../components/TooltipButton/TooltipButton";

const AdminUserDetails = ({activeTab, location}) => {
    const apiService = new AxiosService()
    const localStorageService = LocalStorageService.getService();
    const authDetail = JSON.parse(localStorageService.getAuthDetails());
    const [userList, setUserList] = useState([])
    const [limit, setLimit] = useState(10)
    const [sortField, setSortField] = useState("createDate")
    const [transactionStatus, setTransactionStatus] = useState("none")
    const [orderBy, setOrderBy] = useState("desc")
    const [current, setCurrent] = useState(1)
    const [page, setPage] = useState(1)
    const [templateType, setTemplateType] = useState("none")
    const [totalRecords, setTotalRecords] = useState(0)
    const [loaderControl, setLoaderControl] = useState({tableLoader: false})
    const [searchTerm, setSearchTerm] = useState("")
    const [sortDropDownValue, setSortDropDownValue] = useState("mostRecentFirst")
    const [userCredits, setUserCredits] = useState(0)
    const [resetSearch, setResetSearch] = useState(0)
    const [fetchUserTransBody, setFetchUserTransBody] = useState("")
    const [fetchUserManuscripts, setFetchUserManuscripts] = useState(false)

    const user = location?.state?.user

    const currencySymbols = {
        'USD': '$', // US Dollar
        'EUR': '€', // Euro
        'CRC': '₡', // Costa Rican Colón
        'GBP': '£', // British Pound Sterling
        'ILS': '₪', // Israeli New Sheqel
        'INR': '₹', // Indian Rupee
        'JPY': '¥', // Japanese Yen
        'KRW': '₩', // South Korean Won
        'NGN': '₦', // Nigerian Naira
        'PHP': '₱', // Philippine Peso
        'PLN': 'zł', // Polish Zloty
        'PYG': '₲', // Paraguayan Guarani
        'THB': '฿', // Thai Baht
        'UAH': '₴', // Ukrainian Hryvnia
        'VND': '₫', // Vietnamese Dong
    };

    const fetchUserManuscript = (searchClicked, resetSearch) => {
        const queryParams = {
            start: resetSearch ? 0 : (current-1)*limit,
            limit: limit,
            sortField: sortField !== "none" ? sortField : "",
            orderBy: orderBy !== "none" ? orderBy : ""
        }
        if(resetSearch && current !== 1) {
            setCurrent(1)
            setPage(1)
            return
        }
        let body = {
            userId: user.id,
            title: ""
        }
        if(searchClicked) {
            body = {
                userId: user.id,
                title: searchTerm
            }
            setFetchUserTransBody(body.title)
        } else {
            if(resetSearch === true) {
                body = {
                    userId: user.id,
                    title: ""
                }
            } else {
                body = {
                    userId: user.id,
                    title: fetchUserTransBody
                }
            }
            setSearchTerm(body.title)
        }
        setLoaderControl({...loaderControl, tableLoader: true})
        apiService.fetchUserDetails(body, queryParams).then((res) => {
            setUserList(res.result)
            setTotalRecords(res.total)
            setUserCredits(res.totalCredit)
            setLoaderControl({...loaderControl, tableLoader: false})
            setFetchUserManuscripts(true)
        }).catch((err) => {
            toast.error(err.response.data.message, {autoClose: 3000})
            setLoaderControl({...loaderControl, tableLoader: false})
            setFetchUserManuscripts(true)
        })       
    }

    const getDate = (dateString) => {
        let date = new Date(dateString)
        return date.toLocaleDateString("en-UK")
    }
    
    const getPurchaseType = (typeString) => {
        if(typeString === "perFormat" || typeString === "perFormatGeneric") {
            return "One time purchase 1 credit"
        } else if (typeString ==="couponCode") {
            return "Coupon code"
        } else if (typeString === "bulk6") {
            return "Bulk 6 credits"
        } else if (typeString === "bulk12") {
            return "Bulk 12 credits"
        }else if (typeString === "bulk24") {
            return "Bulk 24 credits"
        } else if (typeString === "perFormatSpecific") {
            return "One time purchase 2 credits"
        } else {
            return "N/A"
        }
    }

    const handleCurrentPage = (page) => {
        setCurrent(page)
        setPage(page)
    } 

    const handleGoToPagination = () => {
        let totalPage= Math.ceil(totalRecords / limit)
		if(page>0 && page<=totalPage){
            setPage(parseInt(page))
			setCurrent(parseInt(page))
		}
		else {
			toast.error("Please enter valid page number!", {autoClose: 3000})
		}
    }

    const getAmount = (item) => {
        if (item.amount) {
            if(item.currencyType) {
                return currencySymbols[item.currencyType.toUpperCase()] + item.amount
            } else {
                return currencySymbols["GBP"] + item.amount
            }
        } else {
            return false
        }
    }

    const setSortValue = (e) => {
        if(e.target.value === "mostRecentFirst") {
            setSortField("createDate")
            setOrderBy("desc")
            setSortDropDownValue(e.target.value)
        } else if (e.target.value === "mostRecentLast") {
            setSortField("createDate")
            setOrderBy("asc")
            setSortDropDownValue(e.target.value)
        } else if (e.target.value === "manuscript-a-z") {
            setSortField("manuscriptTitle")
            setOrderBy("asc")
            setSortDropDownValue(e.target.value)
        } else if (e.target.value === "manuscript-z-a") {
            setSortField("manuscriptTitle")
            setOrderBy("desc")
            setSortDropDownValue(e.target.value)
        } else if (e.target.value === "journal-a-z") {
            setSortField("journalTitle")
            setOrderBy("asc")
            setSortDropDownValue(e.target.value)
        } else if (e.target.value === "journal-z-a") {
            setSortField("journalTitle")
            setOrderBy("desc")
            setSortDropDownValue(e.target.value)
        }else {
            setSortField("createDate")
            setOrderBy("desc")
            setSortDropDownValue("mostRecentFirst")
        }
    }

    const resetSearchTerm = (e) => {
        setSearchTerm("")
        setResetSearch(resetSearch+1)
    }

    useEffect(() => {
        if(user !== undefined && fetchUserManuscripts) {
            fetchUserManuscript(false, true)
        }
    }, [templateType, sortField, orderBy, transactionStatus, resetSearch])

    useEffect(() => {
        if(user !== undefined) {
            fetchUserManuscript()
        }
    }, [current])

    useEffect(() => {
        if(user === undefined) {
            toast.error("User's details are missing. Please select user from Admin Dashboard.")
            setTimeout(() => {
                history.push("/admin-dashboard")
            }, 1000);
        }
    }, [])

    return (
        user ?
        <div>
            <div className="d-flex flex-row my-3 mx-2 align-items-center">
                <span className={classNames(styles.secondaryTextColor, "cursor-pointer")} onClick={() => {history.push("/admin-dashboard")}}>Admin Dashboard</span>
                <span className="border-left border-dark pl-2 ml-2 text-secondary">{user ? user.metadata.first_name + " " + user.metadata.last_name : <Spinner animation="grow" size="sm"/>}</span>
            </div>
            <div className="d-flex flex-column my-3 mx-2 justify-content-center">
                <h2>{user ? user.metadata.first_name + " " + user.metadata.last_name : <Spinner animation="grow" size="sm"/>}</h2>
                <span className="text-secondary justify-content-center text-truncate">
                    {user.email ? <span>{user.email}</span> : ""}
                    {user.metadata.phone ? <span className="border-left border-dark pl-2 ml-2">{user.metadata.phone}</span> : ""}
                    {user.metadata.affiliation ? <span className="border-left border-dark pl-2 ml-2">{user.metadata.affiliation}</span> : ""}
                    {user.metadata.designation ? <span className="border-left border-dark pl-2 ml-2">{user.metadata.designation}</span> : ""}
                    {user.metadata.orcid ? <span className="border-left border-dark pl-2 ml-2">{user.metadata.orcid}</span> : ""}
                    {user.metadata.country ? <span className="border-left border-dark pl-2 ml-2">{user.metadata.country}</span> : ""}
                </span>
            </div>
            <div className="card shadow">
                <div className="d-flex flex-row justify-content-between my-3 mx-3 row">
                    <div className="d-flex flex-row align-items-center col-md-2 col-sm-12 mt-2">
                        <div className="d-flex flex-column">
                            <h3 className="m-0 p-0"><span className="text-secondary">Total: </span><span>{totalRecords}</span></h3>
                            {/* <h5 className="my-2"><span className="text-secondary">Available credits: </span><span>{userCredits}</span></h5> */}
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-end col-md-10 col-sm-12 row mt-1">
                            <div className="d-flex flex-row col-md-8 col-sm-12 mt-2">
                                <div class="input-group">
                                    <input type="text" class="form-control"  placeholder="Search Journal Title/Manuscript Title" aria-describedby="inputGroupPrepend2" required
                                        value={searchTerm}
                                        onChange={(e) => {setSearchTerm(e.target.value)}}
                                        onKeyDown={(e) => {
                                            if(e.code === "Enter") {
                                                fetchUserManuscript(true)
                                            }
                                        }}
                                    />
                                    <div class="input-group-append">
                                        <TooltipButton tooltipMessage="Clear search">
                                            <button class="btn btn-outline-secondary" type="button" disabled={searchTerm.length < 1} onClick={(e) => {resetSearchTerm()}}>
                                                <FontAwesomeIcon icon={faX} size="sm"/>
                                            </button>
                                        </TooltipButton>
                                    </div>
                                </div>
                                <div>
                                    <button class="ml-2 text-nowrap" type="button" onClick={(e) => {fetchUserManuscript(true)}}>
                                        <span>Search</span> <FontAwesomeIcon className="ml-2" icon={faSearch}/>
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12 mt-2">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="inputGroupPrepend2">
                                            <FontAwesomeIcon icon={faSortAmountAsc}/>
                                        </span>
                                    </div>
                                    <select className="form-control" value={sortDropDownValue} disabled={loaderControl.tableLoader} onChange={(e)=> {setSortValue(e)}}>
                                        <option value={"none"}>Select Order</option>
                                        <option value={"mostRecentFirst"}>Most recent first</option>
                                        <option value={"mostRecentLast"}>Most recent last</option>
                                        <option value={"journal-a-z"}>Journal title (A-Z)</option>
                                        <option value={"journal-z-a"}>Journal title (Z-A)</option>
                                        <option value={"manuscript-a-z"}>Manuscript title (A-Z)</option>
                                        <option value={"manuscript-z-a"}>Manuscript title (Z-A)</option>
                                    </select>
                                </div>
                            </div>
                    </div>
                </div>
                <div className="d-none d-md-block">
                    { !loaderControl.tableLoader ?
                        <>
                            {totalRecords > 0 ? 
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr className="text-secondary">
                                            <th width="50%">Manuscript Title</th>
                                            <th width="11%">Created Date</th>
                                            <th width="20%">Journal Title</th>
                                            <th width="9%">Status</th>
                                        </tr>
                                    </thead>
                                    {userList.map((item) => {
                                        return (
                                            <UserTableRow type="table" user={item} country={user.metadata.country ? user.metadata.country : "-"}/>
                                        )
                                    })}
                                </table>
                            :
                                <p className="w-100 text-center h2 mt-3 font-weight-light mb-5">No records found</p>
                            }
                        </>
                    :
                        <div className="d-flex flex-row justify-content-center mt-5 mb-5">
                            <Spinner animation="grow" className={classNames(styles.secondaryColor, "mr-2")}/>
                            <Spinner animation="grow" className={classNames(styles.secondaryColor, "mr-2")}/>
                            <Spinner animation="grow" className={styles.secondaryColor}/>
                        </div>
                    }
                </div>
                <div className="d-sm-block d-md-none px-3">
                    { !loaderControl.tableLoader ?
                        <>
                            {totalRecords > 0 ? 
                                    userList.map((item) => {
                                        return (
                                            <UserTableRow type="card" user={item} country={user.metadata.country ? user.metadata.country : "-"}/>
                                        )
                                    })
                            :
                                <p className="w-100 text-center h2 mt-3 font-weight-light mb-5">No records found</p>
                            }
                        </>
                    :
                        <div className="d-flex flex-row justify-content-center mt-5 mb-5">
                            <Spinner animation="grow" className={classNames(styles.secondaryColor, "mr-2")}/>
                            <Spinner animation="grow" className={classNames(styles.secondaryColor, "mr-2")}/>
                            <Spinner animation="grow" className={styles.secondaryColor}/>
                        </div>
                    }
                </div>
                <Pagination
                    className="p-2 mb-0 my-2"
                    currentPage={current}
                    totalCount={totalRecords}
                    pageSize={limit}
                    onPageChange={(page) => handleCurrentPage(page)}
                    handleGoToPagination={handleGoToPagination}
                    pageNo={page}
                    setPageNo={setPage}
                />
            </div>
        </div>
        :

        <div className="d-flex flex-row justify-content-center mt-5 mb-5">
            <Spinner animation="grow" className={styles.secondaryTextColor}/>
        </div>
    )
}

const UserTableRow = ({user, country, type}) => {
    const [loaderControl, setLoaderControl] = useState({tableButtonLoader: false})
    const getDate = (dateString) => {
        let date = new Date(dateString)
        return date.toLocaleDateString("en-UK")
    }
    if(type === "table") {
        return (
            <tr className="small">
                <td>{user.manuscriptTitle ? user.manuscriptTitle : "-"}</td>
                <td>{user.createDate ? getDate(user.createDate) : "-"}</td>
                <td>{user.journalTitle ? user.journalTitle : "-"}</td>
                <td>{user.manuscriptStatus ? user.manuscriptStatus : "-"}</td>
            </tr>
        )
    } else if (type === "card") {
        return (
            <div className="border rounded align-items-start my-2">
                <table className="table">
                    <tr className="small">
                        <td width="35%" className="text-break text-secondary">Manuscript Title </td>
                        <td className="text-break">{user.manuscriptTitle ? user.manuscriptTitle : "-"}</td>
                    </tr>
                    <tr className="small">
                        <td width="30%" className="text-break text-secondary">Created Date</td>
                        <td className="text-break">{user.createDate ? user.createDate : "-"}</td>
                    </tr>
                    <tr className="small">
                        <td width="30%" className="text-break text-secondary">Journal Title</td>
                        <td className="text-break">{user.journalTitle ? user.journalTitle : "-"}</td>
                    </tr>
                    <tr className="small">
                        <td width="30%" className="text-break text-secondary">Status</td>
                        <td className="text-break">{user.manuscriptStatus ? user.manuscriptStatus : "-"}</td>
                    </tr>
                </table>
            </div>
        )
    }
}

export default AdminUserDetails