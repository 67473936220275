import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import { Card, Modal, ProgressBar } from 'react-bootstrap';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import styles from './Facets.module.scss'

function Facets(props){
    
    const facets = props.oaFacets;
    const queryParams = new URLSearchParams(window.location.search);
    const appliedFacets = queryParams.get('facets') ? JSON.parse(queryParams.get('facets')) : [];
    const [checkedVal, setCheckedVal] = useState(appliedFacets);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function isChecked(val) {
        return checkedVal.includes(val) ? true : false;
    }

    function onChange(event) {
        const item = event.target.value;
        const localIsChecked = event.target.checked;
        if (localIsChecked) {
            setCheckedVal([...checkedVal, item]);
        } else {
            setCheckedVal(checkedVal.filter((each) => each !== item));
        }
    }

    function applyFilters(){
        props.filtersApplied(checkedVal);
        handleClose();
    }

    function resetFacets(){
        setCheckedVal([]);
        props.filtersApplied([]);
        handleClose()
        
    }

    useEffect(() => {
        if(props.isRefreshed){
            setCheckedVal([]);
        }
    }, [props.isRefreshed])
    
    return(
        <div className={styles.filterSection}>
            <Card >
                <Card.Body className={styles.windowFilter}>
                    {
                        props.isloading ? 
                            <>
                                <ProgressBar animated now={100} variant="secondary" className='mb-2'/>
                                <ProgressBar animated now={100} variant="secondary" className='mb-2'/>
                                <ProgressBar animated now={100} variant="secondary" />
                            </>
                        :   <>
                                <div className='mb-4 d-flex justify-content-between align-items-center'>
                                    <h6>
                                        <FontAwesomeIcon icon={faSliders} rotation={270} ></FontAwesomeIcon> Filters
                                    </h6>
                                    <button onClick={() => resetFacets()} className={styles.customLink}>Reset</button>
                                </div>
                                <div className='mb-3'>
                                    <h6 className={styles.customTitle}>{props.filterType}</h6>
                                    {facets && facets.map((facet, i) => (
                                        <div key={i} className="form-check mt-2">
                                            <input className="form-check-input" type="checkbox"
                                                checked={isChecked(facet.key)} onChange={e => onChange(e)} value={facet.key} id={facet.key + facet.doc_count + i} />
                                            <label className="form-check-label d-flex align-items-center justify-content-between" htmlFor={facet.key + facet.doc_count + i}>
                                                {facet.key}
                                                <span className={classNames("form-check-label", styles.labelColor)} htmlFor={facet.key}> ({facet.doc_count})</span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <div className='mt-3'>
                                    <button className={styles.customButton} onClick={() => applyFilters()}>Apply Filters</button>
                                </div>
                            </>
                    }                
                    
                </Card.Body>
            </Card>

            <div className={styles.mobileFilter}>
                <button variant="primary" onClick={handleShow} className={styles.floatButton}>
                    <FontAwesomeIcon icon={faSliders} rotation={90}></FontAwesomeIcon>
                </button>

                <Modal show={show} onHide={handleClose} dialogClassName
                ={styles.customDialog} contentClassName={styles.customModalContent} >
                    <Modal.Header bsPrefix={styles.customModalHeader} closeButton>
                        <Modal.Title as="h5">
                            <FontAwesomeIcon className='mx-2' icon={faSliders} rotation={270} ></FontAwesomeIcon>
                            Filters
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h6 className={styles.customTitle}>ACCESS TYPE</h6>
                        {facets && facets.map((facet, i) => (
                            <div key={i} className="form-check mt-2">
                                <input className="form-check-input" type="checkbox"
                                    checked={isChecked(facet.key)} onChange={e => onChange(e)} value={facet.key} id={facet.key + facet.doc_count + i} />
                                <label className="form-check-label d-flex align-items-center justify-content-between" htmlFor={facet.key + facet.doc_count + i}>
                                    {facet.key}
                                    <span className={classNames("form-check-label", styles.labelColor)} htmlFor={facet.key}>&nbsp;({facet.doc_count})</span>
                                </label>
                            </div>
                        ))}
                        <div className='mt-3'>
                            <button className="w-100" onClick={() => applyFilters()}>Apply Filters</button>
                            <button className={classNames(styles.customButton, "mt-2")} onClick={() => resetFacets()}>Reset</button>

                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default Facets;