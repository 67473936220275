import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import {Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FieldLoader } from "../../components/Loader/CommonLoader";
import AxiosService from "../../utils/AxiosService";
import StepFiveForm from "../Menuscript/ManuscriptSubmission/SubmissionProcessPages/StepFiveForm/StepFiveForm";
import style from "./Preview.module.scss"

const PreviewPage = ()=>{
	const {manuscriptId} = useParams();
    const queryParams = new URLSearchParams(window.location.search);
	const stepStatus = JSON.parse(queryParams.get('stepStatus')) || 1;
    const status = queryParams.get('status') || "Completed"
    const journalTitle = queryParams.get('journal')||"";
    const history = useHistory()
    const apiService = new AxiosService()
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false);
    const [editorChanged, setEditorChanged] = useState(false)

    const [tabIndex,setTabIndex] = useState(1)

    const handleShow= (step) => {
        setShow(true)
        setTabIndex(step)
    }

    const handleHide= () => setShow(false)

    const handleSelect = ()=> {
        if(show){
            setLoading(true)
            apiService.editManuscript({
                "id": manuscriptId,
                "status": "Draft"
            }).then(res=>{
                setLoading(false)
                history.push(`/manuscript-submission/${manuscriptId}?type=edit&step=${tabIndex}`)
            }).catch(err=>{
                toast.error(err?.response?.data?.error, {autoClose: 3000})
                // setShow(false)
                setLoading(false)
            })
        }
	};

    return(            
            <div className="bg-white my-3 p-3 rounded">
                <StepFiveForm manuscriptId={manuscriptId} handleEdit={handleShow} stepStatus={stepStatus} journalTitle={journalTitle} editorChanged={editorChanged} setEditorChanged={setEditorChanged}/>
                <Modal show={show} onHide={handleHide} size="sm">
                    <Modal.Header  className="h5">Edit manuscript</Modal.Header>
                    <Modal.Body className="pt-1">
                        <p>The selected manuscript will be moved to Draft status. <br />
                            Are you sure you wanted to edit this manuscript?
                        </p>
                            <button className="btnOutline w-100" onClick={handleHide}>Cancel</button>
                        {loading?<FieldLoader isLoading={loading} className="w-100 mt-2" /> :
                        <button className="w-100 mt-2" onClick={handleSelect}>
                        <FontAwesomeIcon icon={faEdit} /> Edit manuscript</button>
                        }
                    </Modal.Body>
                </Modal>
            </div>        
    )

}

export default PreviewPage