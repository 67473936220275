import React from "react";
import { ProgressBar, Spinner } from "react-bootstrap";
import styles from './CommonLoader.module.scss';

export const FieldLoader = ({isLoading, className, message="Loading"}) => {
	return(
		<button className={className} disabled={isLoading}>
			<span>
				<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
				<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
				<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
			</span> 
		</button>
	)
}

export const CircleLoader = ({size}) => <Spinner animation="grow" size={size} />

export const ProgressLoader = () => <ProgressBar animated now={100} variant="secondary" className='mb-2'/>

export const FullLoader = () => {
	return(
		<div className={styles.loading}>
			<Spinner animation="grow"/>
			<Spinner animation="grow"/>
			<Spinner animation="grow"/>
		</div>
	)
}