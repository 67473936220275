import React from "react";
import AxiosService from "../../utils/AxiosService";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Pagination from "../../components/PaginationComponent/Pagination";
import { Col, Dropdown, Modal, Row, Spinner } from "react-bootstrap";
import styles from "./AdminDashboard.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCross, faEllipsisV, faExclamationCircle, faMoneyBill, faSearch, faSort, faSortAmountAsc, faSortAmountDesc, faSortNumericDesc, faTimesCircle, faX } from "@fortawesome/free-solid-svg-icons";
import LocalStorageService from "../../utils/auth_service/AuthServiceStorage";
import { faCreditCard, faEye } from "@fortawesome/free-regular-svg-icons";
import classNames from "classnames";
import history from "../../history";
import TooltipButton from '../../components/TooltipButton/TooltipButton'
import Datetime from 'react-datetime'
import "react-datetime/css/react-datetime.css";
import fileDownload from "js-file-download";
import { FieldLoader } from "../../components/Loader/CommonLoader";

const AdminDashboard = ({activeTab}) => {
    const apiService = new AxiosService()
    const localStorageService = LocalStorageService.getService();
    const authDetail = JSON.parse(localStorageService.getAuthDetails());
    const [userList, setUserList] = useState([])
    const [limit, setLimit] = useState(10)
    const [sortField, setSortField] = useState("creationDate")
    const [transactionStatus, setTransactionStatus] = useState("none")
    const [orderBy, setOrderBy] = useState("desc")
    const [current, setCurrent] = useState(1)
    const [page, setPage] = useState(1)
    const [templateType, setTemplateType] = useState("none")
    const [totalRecords, setTotalRecords] = useState(0)
    const [loaderControl, setLoaderControl] = useState({tableLoader: false})
    const [searchUsername, setSearchUsername] = useState({lastName: "", firstName: ""})
    const [resetSearch, setResetSearch] = useState(0)
    const [reportModalController, setReportModalController] = useState({visible: false, modalLoader: false, reportType: "none", fromDate: "", toDate: ""})
    const [sortBy, setSortBy] = useState("asc")
    const [fetchUserTransBody, setFetchUserTransBody] = useState({lastName: "", firstName: ""})
    const [fetchUsers, setFetchUsers] = useState(false)
    const validEmailRegex = RegExp(
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    );
    const currencySymbols = {
        'USD': '$', // US Dollar
        'EUR': '€', // Euro
        'CRC': '₡', // Costa Rican Colón
        'GBP': '£', // British Pound Sterling
        'ILS': '₪', // Israeli New Sheqel
        'INR': '₹', // Indian Rupee
        'JPY': '¥', // Japanese Yen
        'KRW': '₩', // South Korean Won
        'NGN': '₦', // Nigerian Naira
        'PHP': '₱', // Philippine Peso
        'PLN': 'zł', // Polish Zloty
        'PYG': '₲', // Paraguayan Guarani
        'THB': '฿', // Thai Baht
        'UAH': '₴', // Ukrainian Hryvnia
        'VND': '₫', // Vietnamese Dong
    };

    const fetchUser = (resetPagination, searchClicked) => {
        if((searchUsername?.lastName?.length === 0 && searchUsername?.firstName?.length === 0) || searchUsername?.lastName?.trim().length !== 0 || searchUsername?.firstName?.trim().length !== 0) {
            const queryParams = {
                start: resetPagination ? 0 : (current-1)*limit,
                limit: limit,
                sortBy: sortField !== "none" ? sortField : "",
                sortOrder: orderBy !== "none" ? orderBy : ""
            }
            let body = {lastName: "", firstName: ""}
            if (resetPagination && current !== 1) {
                setCurrent(1)
                setPage(1)
                return
            }
            if(searchClicked) {
                body = {
                    firstName: searchUsername.firstName?.trim(),
                    lastName: searchUsername.lastName?.trim()
                }
                setFetchUserTransBody({...body})
            } else {
                body = {
                    firstName: fetchUserTransBody.firstName?.trim(),
                    lastName: fetchUserTransBody.lastName?.trim()
                }
                setSearchUsername({...body})
            }
            setLoaderControl({...loaderControl, tableLoader: true})
            // {start: (current-1)*limit, limit: limit}
            apiService.fetchUsers(body, queryParams).then((res) => {
                setUserList(res.result)
                setTotalRecords(res.total)
                setLoaderControl({...loaderControl, tableLoader: false})
                setFetchUsers(true)
            }).catch((err) => {
                toast.error(err.response.data.message, {autoClose: 3000})
                setLoaderControl({...loaderControl, tableLoader: false})
                setFetchUsers(true)
            })
        } else {
            toast.error("Please enter valid first name or last name!", {autoClose: 3000})
            setSearchUsername({lastName: "", firstName: ""})
        }
    }

    const getDate = (dateString) => {
        let date = new Date(dateString)
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
        
        return `${year}-${month}-${day}`
    }

    const handleCurrentPage = (page) => {
        setCurrent(page)
        setPage(page)
    } 

    const handleGoToPagination = () => {
        let totalPage= Math.ceil(totalRecords / limit)
		if(page>0 && page<=totalPage){
            setPage(parseInt(page))
			setCurrent(parseInt(page))
		}
		else {
			toast.error("Please enter valid page number!", {autoClose: 3000})
		}
    }

    const downloadReport = () => {
        let fromDate = new Date(reportModalController.fromDate)
        let toDate = new Date(reportModalController.toDate)
        let today = new Date() 

        if(reportModalController.fromDate.length !== 10 || fromDate == "Invalid Date") {
            toast.warn("Please select a valid start date for the report.")
            return
        }
        if(reportModalController.toDate.length !== 10 || toDate == "Invalid Date") {
            toast.warn("Please select a valid end date for the report.")
            return
        }
        if (fromDate > toDate) {
            toast.warning("Starting date cannot exceed ending date.")
            return
        }
        if (toDate < fromDate) {
            toast.warning("Ending date must be later than starting date.")
            return
        }
        if(fromDate > today) {
            toast.warning("Starting date cannot be the future date.")
            return
        }
        if (toDate > today) {
            toast.warning("Ending date cannot be the future date.")
            return
        }
        let data = {
            fromDate: getDate(fromDate.toLocaleDateString("en-US")),
            toDate: getDate(toDate.toLocaleDateString("en-US"))
        }
        if(reportModalController.reportType === "marketingReport") {
            setReportModalController({...reportModalController, modalLoader: true})
            apiService.exportUsers(data).then((res) => {
                toast.success("Report download started.", {autoClose: 3000})
                fileDownload(res, "OrthoPublish User Report "+ `(${fromDate.toLocaleDateString("en-UK")}-${toDate.toLocaleDateString("en-UK")})` +".xlsx")
                setReportModalController({...reportModalController, modalLoader: false, visible: false, reportType: "", toDate: "", fromDate: ""})
            }).catch((error) => {
                toast.error(error?.response?.data?.message ? error?.response?.data?.message : error?.response?.status + " : " + error?.response?.statusText)
                setReportModalController({...reportModalController, modalLoader: false, visible: false, reportType: "", toDate: "", fromDate: ""})
            })
        } else if (reportModalController.reportType === "transactionReport") {
            setReportModalController({...reportModalController, modalLoader: true})
            apiService.exportUserTransctions(data).then((res) => {
                toast.success("Report download started.", {autoClose: 3000})
                fileDownload(res, "OrthoPublish Transactions Report "+ `(${fromDate.toLocaleDateString("en-UK")}-${toDate.toLocaleDateString("en-UK")})` +".xlsx")
                setReportModalController({...reportModalController, modalLoader: false, visible: false, reportType: "", toDate: "", fromDate: ""})
            }).catch((error) => {
                console.log("Error: ", error.response)
                toast.error(error?.response?.data?.message ? error?.response?.data?.message : error?.response?.status + " : " + error?.response?.statusText)
                setReportModalController({...reportModalController, modalLoader: false, visible: false, reportType: "", toDate: "", fromDate: ""})
            })
        } else (
            toast.warning("Please select report type to download a report.")
        )
    }

    const getDateString = (e) => {
        if(typeof e === "string") {
            return e
        } else {
            let date = new Date(e.toDate())
            let day = date.getDate()
            let month = date.getMonth() + 1
            let year = date.getFullYear()
            
            day = day < 10 ? '0' + day : day;
            month = month < 10 ? '0' + month : month;
            
            return `${year}-${month}-${day}`
        }
    }

    const resetSearchUsername = (e) => {
        setSearchUsername({lastName: "", firstName: ""})
        setFetchUserTransBody({lastName: "", firstName: ""})
        setResetSearch(resetSearch+1)
    }

    const handleSort = (selectedSort) => {
        setSortBy(selectedSort)
        switch(selectedSort) {
            case "creationDate" :
                setOrderBy("desc")
                setSortField(selectedSort)
                break
            case "creationDateDesc" :
                setOrderBy("asc")
                setSortField("creationDate")
                break
            case "name" :
                setOrderBy("asc")
                setSortField(selectedSort)
                break
            case "nameDesc" :
                setOrderBy("desc")
                setSortField("name")
                break
            default :
                setOrderBy("asc")
                setSortField("creationDate")
                break
        }
    }

    useEffect(() => {
        if(fetchUsers) {
            fetchUser(true)
        }
    }, [sortField, orderBy, transactionStatus, resetSearch])

    useEffect(() => {
        fetchUser()
    }, [current])

    useEffect(() => {
        if(authDetail?.authorities[0]?.authority !== "Admin") {
            history.push("/home")
        }
    }, [])
    return (
        <div>
            <div className="d-flex flex-column my-3 mx-2">
                <h2>Admin Dashboard</h2>
                <div className="d-flex flex-row align-content-center flex-wrap">
                    <span className="text-secondary"> List of all the users</span>
                    <span className="mx-2">|</span>
                    <span className={classNames(styles.secondaryTextColor, "cursor-pointer")}  
                        onClick={() => {
                            setReportModalController({...reportModalController, visible: true})
                        }} 
                    >
                        {/* <button
                            className={classNames(styles.secondaryTextColor, "border-0 p-0 bg-white btn btn-outline-secondary")}
                            onClick={() => {
                                setReportModalController({...reportModalController, visible: true})
                            }} 
                        > */}
                            Download Reports
                        {/* </button> */}
                    </span>
                </div>
            </div>
            <div className="card shadow">
                <div className="d-flex flex-row justify-content-between mx-1 row">
                    <div className="d-flex flex-row align-items-center mt-3 col-md-6 col-sm-12">
                        <div className="d-flex flex-column">
                            <h3 className="m-0 p-0"><span className="text-secondary">Total: </span><span>{totalRecords}</span></h3>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between my-3 mx-1 row">
                    <div className="d-flex flex-row align-items-center col-md-6">
                        <div className="d-flex flex-row mr-2">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="First Name" aria-describedby="username-search-box"
                                    value={searchUsername?.firstName}
                                    onChange={(e) => {setSearchUsername({...searchUsername, firstName: e.target.value})}}
                                    disabled={loaderControl.tableLoader}
                                    onKeyDown={(e) => {
                                        if(e.code === "Enter") {
                                            fetchUser(true, true)
                                        }
                                    }}
                                />
                                {/* <div class="input-group-append">
                                    <TooltipButton tooltipMessage="Clear search">
                                        <button class="btn btn-outline-secondary" type="button" disabled={searchUsername.length < 1} onClick={(e) => {resetSearchUsername()}}>
                                            <FontAwesomeIcon icon={faX} size="sm"/>
                                        </button>
                                    </TooltipButton>
                                </div> */}
                            </div>
                            <div class="input-group">
                                <input type="text" class="form-control ml-2" placeholder="Last Name" aria-describedby="username-search-box"
                                    value={searchUsername?.lastName}
                                    onChange={(e) => {setSearchUsername({...searchUsername, lastName: e.target.value})}}
                                    disabled={loaderControl.tableLoader}
                                    onKeyDown={(e) => {
                                        if(e.code === "Enter") {
                                            fetchUser(true, true)
                                        }
                                    }}
                                />
                                {/* <div class="input-group-append">
                                    <TooltipButton tooltipMessage="Clear search">
                                        <button class="btn btn-outline-secondary" type="button" disabled={searchUsername.length < 1} onClick={(e) => {resetSearchUsername()}}>
                                            <FontAwesomeIcon icon={faX} size="sm"/>
                                        </button>
                                    </TooltipButton>
                                </div> */}
                            </div>
                            <div className="d-flex flex-row ml-2">
                                <button className="text-nowrap mr-2" type="button" onClick={(e) => {fetchUser(true, true)}}>
                                   Search <FontAwesomeIcon className="ml-2" icon={faSearch}/>
                                </button>
                                <button className="text-nowrap btn btn-outline-info" type="button" disabled={searchUsername?.lastName?.length < 1 && searchUsername?.firstName?.length < 1} onClick={(e) => {resetSearchUsername()}}>
                                    Clear <FontAwesomeIcon className="ml-2" icon={faX} size="sm"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center col-md-6 justify-content-end">
                        <div class="input-group w-50">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend2">
                                    <FontAwesomeIcon icon={faSortAmountAsc}/>
                                </span>
                            </div>
                            <select className="form-control" value={sortBy ? sortBy : "creationDate"} onChange={(e)=> handleSort(e.target.value)} >
                                {/* <option value="none">Please select</option> */}
                                <option value="creationDate">Most recent first</option>
                                <option value="creationDateDesc">Most recent last</option>
                                <option value="name">Name (A-Z)</option>
                                <option value="nameDesc">Name (Z-A)</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="d-none d-md-block">
                    { !loaderControl.tableLoader ?
                        <> 
                            {totalRecords > 0 ?  
                                <table className="table">
                                    <thead className="thead-light">
                                        <tr className="text-secondary">
                                            <th width="13%">Name</th>
                                            <th width="28%">Email</th>
                                            <th width="9%">Register Date</th>
                                            <th width="9%">Manuscripts</th>
                                            <th width="10%">Country</th>
                                            <th width="11%">Bone & Joint Opt-in</th>
                                            <th width="11%">OrthoPublish Opt-in</th>
                                            <th width="14%">Remaining Credits</th>
                                            <th width="9%">More Action</th>
                                        </tr>
                                    </thead>
                                    {userList.map((item) => {
                                        return (
                                            <UserTableRow user={item} type="table"/>
                                        )
                                    })}
                                </table>
                            :
                                <p className="w-100 text-center h2 mt-3 font-weight-light mb-5">No records found</p>
                            }
                        </>
                    :
                        <div className="d-flex flex-row justify-content-center mt-5 mb-5">
                            <Spinner animation="grow" className="mr-2"/>
                            <Spinner animation="grow" className="mr-2"/>
                            <Spinner animation="grow"/>
                        </div>
                    }
                </div>

                <div className="d-sm-block d-md-none px-3">
                    { !loaderControl.tableLoader ?
                            <> 
                                {totalRecords > 0 ?  
                                    userList.map((item) => {
                                        return (
                                            <UserTableRow user={item} type="card"/>
                                        )
                                    })
                                :
                                    <p className="w-100 text-center h2 mt-3 font-weight-light mb-5">No records found</p>
                                }
                            </>
                        :
                            <div className="d-flex flex-row justify-content-center mt-5 mb-5">
                                <Spinner animation="grow" className="mr-2"/>
                                <Spinner animation="grow" className="mr-2"/>
                                <Spinner animation="grow"/>
                            </div>
                    }
                </div>
                <Pagination
                    className="p-2 mb-0 my-2"
                    currentPage={current}
                    totalCount={totalRecords}
                    pageSize={limit}
                    onPageChange={(page) => handleCurrentPage(page)}
                    handleGoToPagination={handleGoToPagination}
                    pageNo={page}
                    setPageNo={setPage}
                />
            </div>
            <Modal show={reportModalController.visible} onHide={() => {setReportModalController({...reportModalController, visible: false, reportType: "", fromDate: "", toDate: "", modalLoader: false})}}>
                <Modal.Header className="h6" closeButton>Download User Reports</Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}> {/* Report type */}
                            <label>Select Report type</label>
                            <select className="form-control" value={reportModalController.reportType} disabled={reportModalController.modalLoader} onChange={(e)=> {setReportModalController({...reportModalController, reportType: e.target.value})}}>
                                <option value={"none"}>Select Report</option>
                                <option value={"marketingReport"}>Marketing Report</option>
                                <option value={"transactionReport"}>Transaction Report</option>
                            </select>
                        </Col> 
                        <Col md={6} sm={12} xs={12} className="mt-3"> {/* From date */}
                            <label>From</label>
                            <Datetime 
                                timeFormat={false} 
                                closeOnSelect={true}
                                inputProps={{placeholder: 'MM/DD/YYYY', disabled: reportModalController.modalLoader}} 
                                onChange={(e) => {setReportModalController({...reportModalController, fromDate: getDateString(e)})}}
                            />
                            {/* <input className="form-control" placeholder="DD/MM/YYYY" onChange={(e)=> {setReportModalController({...reportModalController, fromDate: e.target.value})}}/> */}
                        </Col> 
                        <Col md={6} sm={12} xs={12} className="mt-3"> {/* To date */}
                            <label>To</label>
                            <Datetime 
                                timeFormat={false} 
                                closeOnSelect={true} 
                                inputProps={{placeholder: 'MM/DD/YYYY', disabled: reportModalController.modalLoader}}
                                onChange={(e) => {setReportModalController({...reportModalController, toDate: getDateString(e)})}}
                            />
                            {/* <input className="form-control" placeholder="DD/MM/YYYY" onChange={(e)=> {setReportModalController({...reportModalController, toDate: e.target.value})}}/> */}
                        </Col>
                        <Col className="mt-4 d-flex justify-content-end">
                            <button onClick={() => {downloadReport()}} disabled={reportModalController.modalLoader}>
                                {reportModalController.modalLoader ? <FieldLoader/> :"Download Report"}
                            </button>
                        </Col> 
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const UserTableRow = ({user, type}) => {
    const [loaderControl, setLoaderControl] = useState({tableButtonLoader: false})
    const userObj = {
        email: user.email,
        username: user.username,
        metadata: user.metaData,
        id: user.userId
    }

    const getDate = (dateString) => {
        let date = new Date(dateString)
        return date.toLocaleDateString("en-UK")
    }

    if(type === "table") {
        return (
            <tr className="small">
                <td className="text-break">{user?.metaData ? user?.metaData?.first_name + " " + user?.metaData?.last_name: "-"}</td>
                <td className="text-break">{user?.email ? user?.email : "-"}</td>
                <td className="text-break">{user?.creationDate ? getDate(user?.creationDate) : "-"}</td>
                <td className="text-break">{user?.totalManuscripts ? user?.totalManuscripts : "0"}</td>
                <td className="text-break">{user?.metaData?.country ? user?.metaData?.country : "-"}</td>
                <td className="text-break">
                    {
                        user?.metaData?.receive_news_and_offers_from_bnj === "Yes" ? 
                            <span className="text-success"><FontAwesomeIcon className="mr-2" icon={faCircleCheck}/>  Subscribed</span>
                        : 
                            <span className="text-danger"><FontAwesomeIcon className="mr-2" icon={faTimesCircle}/> Unsubscribed</span> 
                    }
                </td>
                <td className="text-break">
                    {
                        user?.metaData?.receive_news_on_orthopublish === "Yes" ? 
                            <span className="text-success"><FontAwesomeIcon className="mr-2" icon={faCircleCheck}/>  Subscribed</span>
                        : 
                            <span className="text-danger"><FontAwesomeIcon className="mr-2" icon={faTimesCircle}/> Unsubscribed</span>  
                    }
                </td>
                <td>{user?.remainingCredits ? user?.remainingCredits : "-"}</td>
                <td className="text-break ">
                    <ActionsDropDown user={userObj}/>
                </td>
            </tr>
        )
    } else if (type === "card") {
        return (
            <div className="border rounded align-items-start my-2">
                <div className="d-flex flex-row justify-content-between aling-items-center my-2">
                    <h5 className="text-secondary ml-3 mr-3">
                        {user.metaData ? 
                            user?.metaData?.first_name?.length ? 
                                user?.metaData?.last_name?.length ? 
                                    user?.metaData?.first_name + " " + user?.metaData?.last_name 
                                : 
                                    user?.metaData?.first_name 
                            : 
                                user?.metaData?.last_name?.length ? 
                                    user?.metaData?.last_name 
                                : 
                                    "N/A"
                            :
                                "-"
                        }
                    </h5>
                    <h5 className="text-secondary ml-auto mr-3">
                        <ActionsDropDown user={userObj}/>
                    </h5>
                </div>
                <table className="table">
                    <tr className="small">
                        <td width="40%" className="text-break">Email </td>
                        <td className="text-break">{user?.email ? user?.email : "-"}</td>
                    </tr>
                    <tr className="small">
                        <td width="40%" className="text-break">Register Date</td>
                        <td className="text-break">{user?.creationDate ? getDate(user?.creationDate) : "-"} </td>
                    </tr>
                    <tr className="small">
                        <td width="40%" className="text-break">Manuscripts</td>
                        <td className="text-break">{user?.totalManuscripts ? user?.totalManuscripts : "0"}</td>
                    </tr>
                    {/* <tr className="small">
                        <td width="40%" className="text-break">ORCID Id</td>
                        <td className="text-break">{user?.metaData?.orcid ? user?.metaData?.orcid : "-"}</td>
                    </tr> */}
                    <tr className="small">
                        <td width="40%" className="text-break">Country</td>
                        <td className="text-break">{user?.metaData?.country ? user?.metaData?.country : "-"}</td>
                    </tr>
                    <tr className="small">
                        <td width="40%" className="text-break">Bone & Joint Updates</td>
                        <td className="text-break">{user?.metaData?.receive_news_and_offers_from_bnj === "Yes" ? <div className="text-success"><FontAwesomeIcon className="text-success" icon={faCircleCheck}/> Subscribed</div> : <div className="text-danger"><FontAwesomeIcon className="text-danger" icon={faTimesCircle}/> Unsubscribed</div>}</td>
                    </tr>
                    <tr className="small">
                        <td width="40%" className="text-break">OrthoPublish Updates</td>
                        <td className="text-break">{user?.metaData?.receive_news_on_orthopublish === "Yes" ? <div className="text-success"><FontAwesomeIcon className="text-success" icon={faCircleCheck}/> Subscribed</div> : <div className="text-danger"><FontAwesomeIcon className="text-danger" icon={faTimesCircle}/> Unsubscribed</div>}</td>
                    </tr>
                    <tr>
                        <td width="40%" className="text-break"> Remaining Credits</td>
                        <td className="text-break">{user?.remainingCredits ? user?.remainingCredits : "-"}</td>
                    </tr>
                </table>
            </div>
        )
    }
}

const ActionsDropDown = ({user}) => {
    const [dropDownToggle, setDropdownToggle] = useState(false)
    const [loaderControl, setLoaderControl] = useState({detailsButtonLoader: false, purchaseButtonLoader: false, spentButtonLoader: false})
    const detailButtonClick = (user) => {
        if(user === undefined || user === null) {
            toast.error("Username not found!", {autoClose: 3000})
        } else {
            history.push(`/admin-user-details`, {user})
        }
    }

    const purchaseHistoryClick = (user) => {
        console.log("user date: ", user)
        if(user === undefined || user === null) {
            toast.error("Username not found!", {autoClose: 3000})
        } else {
            history.push(`/admin-user-purchase`, {user})
        }
    }

    const usageHistoryClick = (user) => {
        if(user === undefined || user === null) {
            toast.error("Username not found!", {autoClose: 3000})
        } else {
            history.push(`/admin-user-usage`, {user})
        }
    }

    return (
        <Dropdown autoClose="outside" show={dropDownToggle}>
            <TooltipButton tooltipMessage="See more options">
                <Dropdown.Toggle className="btn-outline-light bg-white text-dark border-0" onClick={() => {setDropdownToggle(!dropDownToggle)}}>
                    <FontAwesomeIcon icon={faEllipsisV}/>
                </Dropdown.Toggle>
            </TooltipButton>
            <Dropdown.Menu>
                <Dropdown.Item className={styles.secondaryTextColor} onClick={(e) => {detailButtonClick(user)}}>
                    { loaderControl.detailsButtonLoader ? 
                        <>
                            <Spinner animation="grow"/>
                        </>
                    :
                        <TooltipButton tooltipMessage="View user's manuscripts">
                            <span><FontAwesomeIcon icon={faEye}/> User's Manuscripts</span>
                        </TooltipButton>
                    }
                </Dropdown.Item>
                <Dropdown.Item className={styles.secondaryTextColor} onClick={(e) => {purchaseHistoryClick(user)}}>
                    { loaderControl.purchaseButtonLoader ? 
                        <>
                            <Spinner animation="grow"/>
                        </>
                    :
                        <TooltipButton tooltipMessage="View user's credit purchase history">
                            <span><FontAwesomeIcon icon={faCreditCard}/> User's Credit Purchase History</span>
                        </TooltipButton>
                    }
                </Dropdown.Item>
                <Dropdown.Item className={styles.secondaryTextColor} onClick={() => {usageHistoryClick(user)}}>
                    { loaderControl.spentButtonLoader ? 
                        <>
                            <Spinner animation="grow"/>
                        </>
                    :
                        <TooltipButton tooltipMessage="View user's credit usage history">
                            <span><FontAwesomeIcon icon={faMoneyBill}/> User's Credit Usage History</span>
                        </TooltipButton>
                    }
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default AdminDashboard