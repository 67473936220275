import {SET_LOADER} from '../actions/LoaderAction';

const initialState = {
    search_loader: false
}

const loaderReducer = (state=initialState, action) => {
    switch(action.type){
        case SET_LOADER:
            return {...state, search_loader: action.payload}
        default:
            return state;
    }
}

export default loaderReducer;