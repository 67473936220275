export const ALERT_MODAL_OPEN = "ALERT_MODAL_OPEN";
export const ALERT_MODAL_CLOSE = "ALERT_MODAL_CLOSE";
export const SIGN_UP_ALERT_MODAL_OPEN = "SIGN_UP_MODAL_OPEN";
export const SIGN_UP_ALERT_MODAL_CLOSE = "SIGN_UP_MODAL_CLOSE";

export function openAlertModal(query){
    return {
        type: ALERT_MODAL_OPEN,
        keyword: query,
        payload: true
    }
}

export function closeAlertModal(){
    return {
        type: ALERT_MODAL_CLOSE,
        payload: false
    }
}

export function openSignUpAlertModal(){
    return {
        type: SIGN_UP_ALERT_MODAL_OPEN,
        payload: true
    }
}

export function closeSignUpAlertModal(){
    return {
        type: SIGN_UP_ALERT_MODAL_CLOSE,
        payload: false
    }
}