import React, { useEffect, useState } from 'react'
import { Card, Modal, Tab, Tabs } from 'react-bootstrap'
import "./ActionView.scss"
import AxiosService from '../../../../../utils/AxiosService'
import { toast } from 'react-toastify'
import ValidationErrorTable from '../../SubmissionProcessPages/ValidationErrorTable/ValidationErrorTable'
import { CircleLoader, FieldLoader } from '../../../../../components/Loader/CommonLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faArrowRight, faDownload, faGear } from '@fortawesome/free-solid-svg-icons'
import fileDownload from "js-file-download";
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setDiscardFalse, setGateClose, setGateOpen } from '../../../../../store/actions/NavigateAwayAlertAction'
import { useHistory } from "react-router-dom";
import TooltipButton from '../../../../../components/TooltipButton/TooltipButton'


const ActionView = ({handleSave, handleValidate, handleSkipError,
  handleStepStatus, handleSkipStep, isSkiped,
  isDisabled, manuscriptId, step, exitFunction,
  errorMessage, loader, journalTitle, 
  fetchData, setErrorMessage, apiCount, 
  setApiCount, visibleSkipErrorBtn, skipStepTwoError, 
  setSkipStepTwoError,  type2Error, type3Error, 
  type4Error, type5Error, type6Error ,
  skipButtonVisible, errorType, setErrorType, 
  skipAndContinue, setType2Error, setType3Error, 
  setType4Error, setType5Error, setType6Error, setLoader,
  typeAutoCorrect, setTypeAutoCorrect, showToolbar, 
  setShowToolbar, setShouldAllowNavigation, shouldAllowNavigation,
  editorChanged, setEditorChanged, setIgnoreOrganizationControl,
  setIgnoreErrorControl
}) => {
  const apiService = new AxiosService();
	const [isLoader, setIsLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState({isFinishBtn: false})
  const [finishedFormating, setFinishedFormatting] = useState(false)
  const [ignoreErrorModalVisible, setIgnoreErrorModalVisible] = useState(false)
  const [fetchIgnoredErrorLoading, setFetchIgnoredErrorLoading] = useState(false)
  const [ignoredErrorList, setIgnoredErrorList] = useState([])
  const [restoreErrorList, setRestoreErrorList] = useState([])
  const [ignoreErrorChanged, setIgnoreErrorChanged] = useState(0)
  const [ignoredCaptionList, setIgnoredCaptionList] = useState([])
  const [restoreCaptionList, setRestoreCaptionList] = useState([])
  const [ignoreCaptionChanged, setIgnoreCaptionChanged] = useState(0)
  const [ignoreOrganizationNameChanged, setIgnoreOrganizationNameChanged] = useState(0)
  const [ignoreOrganizationNameList, setIgnoreOrganizationNameList] = useState([])
  const [restoreOrganizationnameList, setRestoreOrganizationNameList] = useState([])
  const [ignoreErrorModalActiveTab, setIgnoreErrorModalActiveTab] = useState("ignoredErrors")
  const [undoErrorControl, setUndoErrorControl] = useState({errors: {}, visible: false})
  const [undoModalControl, steUndoModalControl] = useState({loading: false, visible: false})
  const [disableFinishButton, setDisableFinishButton] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleFinish = () => {
		setBtnLoader({ ...btnLoader, isFinishBtn: true })
		apiService.completeManuscript(manuscriptId).then(res => {
      dispatch(setGateOpen())
      setFinishedFormatting(true)
      setDisableFinishButton(true)
      setBtnLoader({ ...btnLoader, isFinishBtn: false })
			toast.success(res.result, {
				autoClose: 3000,
			});
			handleStepStatus()
		}).catch(err => {
      dispatch(setGateClose())
      setBtnLoader({ ...btnLoader, isFinishBtn: false })
			toast.error(err.response.data.message, {
				autoClose: 3000,
			})
      if(err.response.status === 500  && err.response.data.message === undefined) {
        toast.error("Something went wrong!", {autoClose: 3000})
      }
		})
	}

	const handleDownloadManuscript = async () => {
    if(finishedFormating) {
      setIsLoader(true);
      apiService.fetchPreviewManuscript(manuscriptId).then(res => {
        let manuscriptName = res.title ? res?.title.split(" ",4).join("_"): ""
        let fileName = journalTitle.split(" ").join("_") + "_"+ manuscriptName + ".zip";
        apiService.exportManuscript(manuscriptId).then(res=> {
          toast.success("Manuscript has been downloaded successfully.",  {
            autoClose: 3000,
          })
          setIsLoader(false);
          fileDownload(res, fileName);
        }).catch(async err=> {
          setIsLoader(false);	
          let data = JSON.parse(await err?.response?.data.text())
          toast.error(data?.message,  {
            autoClose: 3000,
          })	
        })
      }).catch(err => {         
        setIsLoader(false);   
        toast.error(err?.response?.data?.message, {autoClose: 3000})
      })	
    } else {
      toast.error("Click on finish formatting before downloading manuscript", {autoClose: 3000})
    }
  } 
    
  function checkErrors () {
    if(errorMessage?.details?.length) {
      return true
    } else if (type2Error?.details?.length){
      return true
    } else if (type3Error?.details?.length){
      return true
    } else if (type4Error?.details?.length){
      return true
    } else if (type5Error?.details?.length){
      return true
    } else if (type6Error?.details?.length){
      return true
    } else if (typeAutoCorrect) {
      return true
    } else {
      return false
    }
  }

  function renderSaveAndValidateButtonText (step) {
    if(errorMessage || type2Error || type3Error || type4Error || type5Error || type6Error || typeAutoCorrect) {
      switch (step) {
        case 2:
          return "Save and check"
        case 3:
          return "Save and check"
        default:
          return "Save and check"
      }
    } else {
      switch (step) {
        case 1:
          return "Save and Proceed to Step Two"
        case 2:
          return "Check manuscript"
        case 3:
          return "Check files"
        case 4:
          return "Save and Proceed to Step Five"
        case 5:
          return "Save and Proceed to Step Six"
        default:
          return "Save and Proceed"
      }
    }
  }

  function renderSkipErrorButtonText (step) {
    switch (step) {
      case 2:
        return "Skip Errors and Proceed to Step Three"
      case 3:
        return "Skip Errors and Proceed to Step Four"
      case 4:
        return "Skip Errors and Proceed to Step Five"
      case 5:
        return "Skip Errors and Proceed to Step Six"
      default:
        return "Skip Errors and Proceed"
    }
  }

  function selectAllIgnoredErrors(e) {
    if(e.target.checked) {
      setRestoreErrorList(() => {
        let temp = []
        ignoredErrorList.map((item) => {
          temp.push(item.errorKey)
        })
        return temp
      })
    } else {
      setRestoreErrorList([])
    }
  }

  function selectAllIgnoredCaptions(e) {
    if(e.target.checked) {
      setRestoreCaptionList(() => {
        let temp = []
        ignoredCaptionList.map((item) => {
          temp.push(item)
        })
        return temp
      })
    } else {
      setRestoreCaptionList([])
    }
  }

  function selectAllIgnoredOrganizationNames(e) {
    if(e.target.checked) {
      setRestoreOrganizationNameList(() => {
        let temp = []
        ignoreOrganizationNameList.map((item) => {
          temp.push(item)
        })
        return temp
      })
    } else {
      setRestoreOrganizationNameList([])
    }
  }

  function errorSelectAllChecked () {
    if(restoreErrorList?.length === ignoredErrorList?.length) {
      return true
    } else {
      return false
    }
  }

  function captionSelectAllChecked () {
    if(restoreCaptionList?.length === ignoredCaptionList?.length) {
      return true
    } else {
      return false
    }
  }

  function organizationNameSelectAllChecked () {
    if(restoreOrganizationnameList?.length === ignoreOrganizationNameList?.length) {
      return true
    } else {
      return false
    }
  }

  function ignoredErrorCheckboxChanged (e) {
    if(e.target.checked) {
      setRestoreErrorList([...restoreErrorList, e.target.value])
    } else {
      setRestoreErrorList(restoreErrorList.filter((each) => each !== e.target.value))
    }
  }

  function ignoredCaptionCheckboxChanged (e) {
    if(e.target.checked) {
      setRestoreCaptionList([...restoreCaptionList, e.target.value])
    } else {
      setRestoreCaptionList(restoreCaptionList.filter((each) => each !== e.target.value))
    }
  }

  function ignoredOrganizationNameCheckboxChanged (e) {
    if(e.target.checked) {
      setRestoreOrganizationNameList([...restoreOrganizationnameList, e.target.value])
    } else {
      setRestoreOrganizationNameList(restoreOrganizationnameList.filter((each) => each !== e.target.value))
    }
  }

  function isIgnoredErrorCheckboxChecked(value) {
    if (restoreErrorList.includes(value)) {
      return true
    } else {
      return false
    }
  }

  function isIgnoredCaptionCheckboxChecked(value) {
    if (restoreCaptionList.includes(value)) {
      return true
    } else {
      return false
    }
  }

  function isIgnoredOrganizationNameCheckboxChecked(value) {
    if (restoreOrganizationnameList.includes(value)) {
      return true
    } else {
      return false
    }
  }

  function restoreErrors () {
    const body = {errorKeys: restoreErrorList}
    if(restoreErrorList?.length <= 0) {
      toast.error("Please select at least one error to restore.", {autoClose: 3000})
    } else {
      setFetchIgnoredErrorLoading(true)
      apiService.deleteIgnoredError(manuscriptId, body).then((res) => {
        toast.success(res.result, {autoClose: 3000})
        setFetchIgnoredErrorLoading(false)
        setIgnoreErrorModalVisible(false)
        setIgnoreErrorChanged(ignoreErrorChanged+1)
        handleSave(step)
        setRestoreErrorList([])
        setRestoreCaptionList([])
        setRestoreOrganizationNameList([])
      }, (err) => {
        console.log("Error in delete ignore error: ", err)
        toast.error(err?.response?.data?.message, {autoClose: 3000})
        setFetchIgnoredErrorLoading(false)
        setIgnoreErrorModalVisible(false)
        setIgnoreErrorChanged(ignoreErrorChanged+1)
        setRestoreErrorList([])
        setRestoreCaptionList([])
        setRestoreOrganizationNameList([])
      })
    }
  }

  function restoreCaptions () {
    const body = {figureCaptions: restoreCaptionList}
    if(restoreCaptionList?.length <= 0) {
      toast.error("Please select at least one Caption to restore.", {autoClose: 3000})
    } else {
      setFetchIgnoredErrorLoading(true)
      apiService.deleteIgnoredCaption(manuscriptId, body).then((res) => {
        toast.success(res.result, {autoClose: 3000})
        setFetchIgnoredErrorLoading(false)
        setIgnoreErrorModalVisible(false)
        setIgnoreCaptionChanged(ignoreCaptionChanged+1)
        handleSave(step)
        setRestoreErrorList([])
        setRestoreCaptionList([])
        setRestoreOrganizationNameList([])
      }, (err) => {
        console.log("Error in delete ignore caption: ", err)
        toast.error(err?.response?.data?.message, {autoClose: 3000})
        setFetchIgnoredErrorLoading(false)
        setIgnoreErrorModalVisible(false)
        setIgnoreCaptionChanged(ignoreCaptionChanged+1)
        setRestoreErrorList([])
        setRestoreCaptionList([])
        setRestoreOrganizationNameList([])
      })
    }
  }

  function restoreOrganizationName () {
    const body = {organizations: restoreOrganizationnameList}
    if(restoreOrganizationnameList?.length <= 0) {
      toast.error("Please select at least one name to restore.", {autoClose: 3000})
    } else {
      setFetchIgnoredErrorLoading(true)
      apiService.deleteIgnoredOrganizationName(manuscriptId, body).then((res) => {
        toast.success(res.result, {autoClose: 3000})
        setFetchIgnoredErrorLoading(false)
        setIgnoreErrorModalVisible(false)
        setIgnoreOrganizationNameChanged(ignoreOrganizationNameChanged+1)
        handleSave(step)
        setRestoreErrorList([])
        setRestoreCaptionList([])
        setRestoreOrganizationNameList([])
      }, (err) => {
        console.log("Error in delete ignore caption: ", err)
        toast.error(err?.response?.data?.message, {autoClose: 3000})
        setFetchIgnoredErrorLoading(false)
        setIgnoreErrorModalVisible(false)
        setIgnoreOrganizationNameChanged(ignoreOrganizationNameChanged+1)
        setRestoreErrorList([])
        setRestoreCaptionList([])
        setRestoreOrganizationNameList([])
      })
    }
  }

  function handleUndoError() {
		steUndoModalControl({...undoModalControl, loading: true})
    
		apiService.undoCorrectedChanges(manuscriptId).then(res => {
			steUndoModalControl({visible: false, loading: false})
			setErrorMessage()
			fetchData(true)
			toast.success(res?.result , {position: "top-center", autoClose: 3000})
			// handleSave(selectedTab)
			return res
		}).catch(err => {
			steUndoModalControl({visible: false, loading: false})
			setErrorMessage(err.response.data.message)
			fetchData()
			return err.response
		})
  }

  const handleSaveAndValidateButton = (step) => {
    handleSave(step)
    document.querySelector(".sticky-div-1").scrollIntoView(true)
  }

  useEffect(() => {
    if(step === 2 || step === 3) {
      setFetchIgnoredErrorLoading(true)
      setIgnoredErrorList([])
      apiService.fetchAllIgnoredError(manuscriptId).then((res) => {
        setIgnoredErrorList(res.result)
        setFetchIgnoredErrorLoading(false)
      }, (err) => {
        console.log("Error in fetch all ignored errors: ", err)
        setFetchIgnoredErrorLoading(false)
      })
    }
  },[ignoreErrorChanged, step])

  useEffect(() => {
    if(step === 2 || step === 3) {
      setFetchIgnoredErrorLoading(true)
      setIgnoredCaptionList([])
      apiService.fetchAllIgnoredCaptions(manuscriptId).then((res) => {
        setIgnoredCaptionList(res.result)
        setFetchIgnoredErrorLoading(false)
      }, (err) => {
        setFetchIgnoredErrorLoading(false)
      })
    }
  }, [ignoreCaptionChanged, step])

  useEffect(() => {
    if(step === 2 || step === 3) {
      setFetchIgnoredErrorLoading(true)
      setIgnoreOrganizationNameList([])
      apiService.fetchAllIgnoredOrganization(manuscriptId).then((res) => {
        setIgnoreOrganizationNameList(res.result)
        setFetchIgnoredErrorLoading(false)
      }, (err) => {
        setFetchIgnoredErrorLoading(false)
      })
    }
  }, [ignoreOrganizationNameChanged, step])

  useEffect(() => {
    setIgnoreOrganizationControl({count: ignoreOrganizationNameChanged, update: setIgnoreOrganizationNameChanged})
    setIgnoreErrorControl({count: ignoreErrorChanged, update: setIgnoreErrorChanged})
  }, [])

  useEffect(() => {
    if(disableFinishButton) {
      setDisableFinishButton(!disableFinishButton)
      setFinishedFormatting(!finishedFormating)
    }
  }, [step])
    
  return (
    <div className="action-div p-2 my-2">

        <div className='action-div'>
            <div className='d-flex flex-row align-items-center justify-content-between'> 
              {/* { checkErrors() && */}
                <h5 className=''>
                  {step === 6 ? "Manuscript Formatted" : "Errors and Warnings"}
                </h5>
              {/* } */}
              {(step === 2 || step === 3) && (ignoredErrorList?.length > 0 || ignoredCaptionList?.length > 0 || ignoreOrganizationNameList?.length > 0) ?
                <TooltipButton tooltipMessage="Restore ignored errors/captions/Organisation names">
                  <button className='small btn btn-outline-secondary btn-sm width-auto' onClick={() => {setIgnoreErrorModalVisible(true)}}>
                    Restore ignored errors
                  </button>
                </TooltipButton>
              :
                ""
              }
            </div>
          { !checkErrors() && 
            <p className={step === 6 ? "text-secondary mt-2 small" : 'text-secondary mt-2 small'}>
              {step === 6 ? "Click the ‘Finish Formatting’ button to save your manuscript." : "Click ‘" + renderSaveAndValidateButtonText(step) + "’ to check if there are any errors."}
            </p>
          }
          {step === 6 ?
            <div className='stepSixActionBtn'>
              {/* <p>Select 'Finish Formatting' to save your paper or 'Download Manuscript' to see the export folder. You can view your paper and make changes to it from 'My Dashboard'</p> */}
              {btnLoader.isFinishBtn ?
                <FieldLoader isLoading={btnLoader.isFinishBtn} className="finishBtn" />
              :
                <button className="finishBtn" disabled={disableFinishButton || isLoader} onClick={()=>handleFinish(step)}>Finish Formatting</button> 
              }
              <p className='text-secondary mt-3'>You can now either download your manuscript to view it offline or visit your Dashboard to make changes or add/edit another manuscript</p>
              {isLoader ? 
              <FieldLoader isLoading={isLoader}  message="Downloading" className={finishedFormating ? "btnOutline" :"downloadButton"}  />
              :
                <>
                  {finishedFormating ? 
                    <button  onClick={handleDownloadManuscript} disabled={!finishedFormating || isLoader} className={finishedFormating ? "btnOutline" :"downloadButton"}>
                      Download Manuscript <FontAwesomeIcon icon={faDownload} />
                    </button>
                  :
                    <TooltipButton tooltipMessage="Click on finish formatting before downloading manuscript" >
                      <button  onClick={handleDownloadManuscript} disabled={!finishedFormating || isLoader} className={finishedFormating ? "btnOutline" :"downloadButton"}>
                        Download Manuscript <FontAwesomeIcon icon={faDownload} />
                      </button>
                    </TooltipButton>
                  }
                </>
              }
              <Link to="/my-dashboard" className="no-underline" ><p className="text-center linkText no-underline">Go to My Dashboard</p></Link>
            </div>
          :
            null
          }
          <section className='error-description'>
            {(checkErrors()) && <ValidationErrorTable 
              errors={errorMessage} 
              manuscriptId={manuscriptId} 
              step={step ===2 ? 2 : 0} 
              selectedTab={step} 
              loader={loader} 
              handleSkipStep={handleSkipError}
              setErrorMessage={setErrorMessage} 
              type2Error={type2Error} 
              type3Error={type3Error} 
              type4Error={type4Error}
              type5Error={type5Error}
              type6Error={type6Error}
              fetchData={fetchData}
              apiCount={apiCount}
              setApiCount={setApiCount}
              handleValidate={handleValidate}
              skipStepTwoError={skipStepTwoError}
              setSkipStepTwoError={setSkipStepTwoError}
              skipAndContinue={skipAndContinue}
              setType2Error={setType2Error}
              setType3Error={setType3Error}
              setType4Error={setType4Error}
              setType5Error={setType5Error}
              setType6Error={setType6Error}
              typeAutoCorrect={typeAutoCorrect}
              setTypeAutoCorrect={setTypeAutoCorrect}
              setShowToolbar={setShowToolbar}
              showToolbar={showToolbar}
              renderSaveAndValidateButtonText = {renderSaveAndValidateButtonText}
              renderSkipErrorButtonText = {renderSkipErrorButtonText}
              setIgnoreErrorChanged = {setIgnoreErrorChanged}
              ignoreErrorChanged = {ignoreErrorChanged}
              ignoredErrorList = {ignoredErrorList}
              ignoreCaptionChanged = {ignoreCaptionChanged}
              setIgnoreCaptionChanged = {setIgnoreCaptionChanged}
              ignoredCaptionList = {ignoredCaptionList}
              ignoreOrganizationNameChanged = {ignoreOrganizationNameChanged}
              setIgnoreOrganizationNameChanged = {setIgnoreOrganizationNameChanged}
              ignoreOrganizationNameList = {ignoreOrganizationNameList}
              setLoader = {setLoader}
              editorChanged={editorChanged} 
              setEditorChanged={setEditorChanged}
              handleSave={handleSave}
              undoErrorControl = {undoErrorControl}
              setUndoErrorControl = {setUndoErrorControl}
              // errorType={errorType}
              // setErrorType={setErrorType}
            />
          }
          </section>
        </div>
        <section className=' mt-3'>
          {step < 6 ? 
            loader.nextBtnLoader? 
              <div className='d-flex flex-row flex-grow'>
                <FieldLoader isLoading={loader.nextBtnLoader} className="w-100" />
              </div>
            : 
              <>
                <div className='d-flex flex-row'>
                  <button className='w-100' onClick={(e)=>handleSaveAndValidateButton(step)} disabled={isDisabled}> 
                    {renderSaveAndValidateButtonText(step)}
                  </button>
                </div>
                {!checkErrors() && step === 3 ? 
                  <div className='d-flex flex-row  mt-1'>
                    <button className='skip-Button w-100' onClick={(e)=>handleValidate(step, true)} disabled={isDisabled}> 
                      {renderSkipErrorButtonText(step)}
                    </button>
                  </div>
                :
                  null
                }
                {checkErrors() ? 
                  <button className='skip-Button w-100 mt-1' onClick={() => handleSkipStep(step)}>
                    {loader.skipBtnLoader ? <CircleLoader/> : renderSkipErrorButtonText(step)}
                  </button>
                : 
                  null
                }
                { isDisabled ? 
                  <FieldLoader isLoading={loader.nextBtnLoader} className="skip-Button w-100" />
                :
                  <button className='skip-Button w-100 mt-2' onClick={(e)=>{
                    dispatch(setDiscardFalse())
                    history.push("/my-dashboard")
                  }} disabled={isDisabled}> 
                    Save and Exit
                  </button>
                }
                {/* <p className='mb-0'>{ (errorMessage || type2Error || type3Error || type4Error || type5Error || type6Error || typeAutoCorrect) ? "Please make the changes directly into the manuscript field on the left then click 'Resave and Validate' or edit your original document and reupload." : "" }</p> */}
              </>
          : 
            null
          }
        </section>
      <Modal show={ignoreErrorModalVisible} size='lg' onHide={() => {
        setIgnoreErrorModalVisible(false)
        setRestoreErrorList([])
        setRestoreCaptionList([])
        setRestoreOrganizationNameList([])
      }}>
        <Modal.Header className="h6" closeButton>Restore ignored errors/captions</Modal.Header>
        <Modal.Body>
          <Tabs id="ignoredErrorsTab" activeKey={ignoreErrorModalActiveTab} onSelect={(k) => {setIgnoreErrorModalActiveTab(k)}} className="mb-3 border-bottom">
            <Tab eventKey="ignoredErrors" title="Errors/Warnings">
              <p>These are the errors you have chosen to ignore. You can select the ones you want by clicking on the "Restore error/s" button.</p>
              <div className='mh-30'>
                <ul>
                  {ignoredErrorList?.length > 0 && 
                    <li className='mb-3' style={{listStyleType: "none"}}>
                      <input 
                        type="checkbox" 
                        className="mr-3"
                        onChange={(e) => {selectAllIgnoredErrors(e)}}
                        value="select all"
                        checked = {errorSelectAllChecked()}
                        /> 
                      Select All
                    </li>
                  }
                  {ignoredErrorList?.length > 0 ? 
                    ignoredErrorList.map((item, index) => {
                      if(item.description) {
                        return (
                          <li className='mb-3' style={{listStyleType: "none"}}>
                            <input 
                              type="checkbox" 
                              className="mr-3"
                              onChange={(e) => {ignoredErrorCheckboxChanged(e)}}
                              checked={isIgnoredErrorCheckboxChecked(item.errorKey)}
                              value={item.errorKey}
                            /> 
                            <span dangerouslySetInnerHTML={{__html: item.description}}></span>
                          </li>
                        )
                      } else {
                        return (
                          <li>
                            <input 
                              type="checkbox" 
                              className="mr-3"
                              onChange={(e) => {ignoredErrorCheckboxChanged(e)}}
                              checked={isIgnoredErrorCheckboxChecked(item.errorKey)}
                              value={item.errorKey}
                              />
                            {item.errorKey}
                          </li>
                        )
                      }
                    })
                    : 
                    <h6 className='text-secondary'>No ignored errors found</h6>
                  }
                </ul>
              </div>
              <div className='d-flex justify-content-end align-items-center gap1'>
                <button className="btnOutline mt-2 w-200" onClick={(e) => {
                  setIgnoreErrorModalVisible(false)
                  setRestoreErrorList([])
                  setRestoreCaptionList([])
                  setRestoreOrganizationNameList([])
                }}>
                  Cancel
                </button>
                {!fetchIgnoredErrorLoading ? 
                  <button className='mt-2 w-200' onClick={() => {restoreErrors()}}>
                    Restore error/s
                  </button>
                :
                  <FieldLoader className="btn-info btn mt-2 w-200" isLoading={fetchIgnoredErrorLoading}/>
                }
              </div>
            </Tab>
            <Tab eventKey="ignoredCaptions" title="Captions/Legends">
              <p>These are the captions/legends you have chosen to ignore. You can select the ones you want to restore by clicking on the "Restore caption/s" button</p>
              <div className='mh-30'>
                <ul>
                  {ignoredCaptionList?.length > 0 && 
                    <li className='mb-3' style={{listStyleType: "none"}}>
                      <input 
                        type="checkbox" 
                        className="mr-3"
                        onChange={(e) => {selectAllIgnoredCaptions(e)}}
                        value="select all"
                        checked = {captionSelectAllChecked()}
                      /> 
                      Select All
                    </li>
                  }
                  {ignoredCaptionList?.length > 0 ? 
                    ignoredCaptionList.map((item, index) => {
                      return (
                        <li className='mb-3' style={{listStyleType: "none"}}>
                          <input 
                            type="checkbox" 
                            className="mr-3"
                            onChange={(e) => {ignoredCaptionCheckboxChanged(e)}}
                            checked={isIgnoredCaptionCheckboxChecked(item)}
                            value={item}
                          />
                          <span dangerouslySetInnerHTML={{__html: item}}></span>
                        </li>
                      )
                    })
                    : 
                    <h6 className='text-secondary'>No ignored captions found</h6>
                  }
                </ul>
              </div>
              <div className='d-flex justify-content-end align-items-center gap1'>
                <button className="btnOutline mt-2 w-200" onClick={(e) => {
                  setIgnoreErrorModalVisible(false)
                  setRestoreErrorList([])
                  setRestoreCaptionList([])
                  setRestoreOrganizationNameList([])
                }}>
                  Cancel
                </button>
                {!fetchIgnoredErrorLoading ? 
                  <button className='mt-2 w-200' onClick={() => {restoreCaptions()}}>
                    Restore caption/s
                  </button>
                :
                  <FieldLoader className="btn-info btn mt-2 w-200" isLoading={fetchIgnoredErrorLoading}/>
                }
              </div>
            </Tab>
            <Tab eventKey="ignoreOrganization" title="Organisation names">
              <p>These are the organisation names you have chosen to ignore. You can select the ones you want to restore by clicking on the "Restore name/s" button</p>
              <div className='mh-30'>
                <ul>
                  {ignoreOrganizationNameList?.length > 0 && 
                    <li className='mb-3' style={{listStyleType: "none"}}>
                      <input 
                        type="checkbox" 
                        className="mr-3"
                        onChange={(e) => {selectAllIgnoredOrganizationNames(e)}}
                        value="select all"
                        checked = {organizationNameSelectAllChecked()}
                      /> 
                      Select All
                    </li>
                  }
                  {ignoreOrganizationNameList?.length > 0 ? 
                    ignoreOrganizationNameList.map((item, index) => {
                      return (
                        <li className='mb-3' style={{listStyleType: "none"}}>
                          <input 
                            type="checkbox" 
                            className="mr-3"
                            onChange={(e) => {ignoredOrganizationNameCheckboxChanged(e)}}
                            checked={isIgnoredOrganizationNameCheckboxChecked(item)}
                            value={item}
                          />
                          <span dangerouslySetInnerHTML={{__html: item}}></span>
                        </li>
                      )
                    })
                    : 
                    <h6 className='text-secondary'>No ignored organisation names found</h6>
                  }
                </ul>
              </div>
              <div className='d-flex justify-content-end align-items-center gap1'>
                <button className="btnOutline mt-2 w-200" onClick={(e) => {
                  setIgnoreErrorModalVisible(false)
                  setRestoreErrorList([])
                  setRestoreCaptionList([])
                  setRestoreOrganizationNameList([])
                }}>
                  Cancel
                </button>
                {!fetchIgnoredErrorLoading ? 
                  <button className='mt-2 w-200' onClick={() => {restoreOrganizationName()}}>
                    Restore name/s
                  </button>
                :
                  <FieldLoader className="btn-info btn mt-2 w-200" isLoading={fetchIgnoredErrorLoading}/>
                }
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>

      {/* Auto corrected list modal */}
      <Modal size="lg" show={undoErrorControl.visible} backdrop="static" keyboard={false} onHide={() => {setUndoErrorControl({...undoErrorControl, visible: false})}} dialogClassName="modal-dialog-scrollable">
        <Modal.Header closeButton className="h5">
          {undoErrorControl.errors?.header ? undoErrorControl.errors?.header: "Autocorrected errors and warnings"} 
          {undoErrorControl.errors?.details ? ` (${undoErrorControl.errors?.details?.length})`: ""}
        </Modal.Header>
        <Modal.Body>
          <div>
            <ValidationErrorTable 
              manuscriptId={manuscriptId} 
              step={step ===2 ? 2 : 0} 
              selectedTab={step} 
              loader={loader} 
              handleSkipStep={handleSkipError}
              fetchData={fetchData}
              apiCount={apiCount}
              setApiCount={setApiCount}
              handleValidate={handleValidate}
              skipStepTwoError={skipStepTwoError}
              setSkipStepTwoError={setSkipStepTwoError}
              skipAndContinue={skipAndContinue}
              typeAutoCorrect={undoErrorControl.errors}
              setTypeAutoCorrect={setTypeAutoCorrect}
              setShowToolbar={setShowToolbar}
              showToolbar={showToolbar}
              renderSaveAndValidateButtonText = {renderSaveAndValidateButtonText}
              renderSkipErrorButtonText = {renderSkipErrorButtonText}
              setIgnoreErrorChanged = {setIgnoreErrorChanged}
              ignoreErrorChanged = {ignoreErrorChanged}
              ignoredErrorList = {ignoredErrorList}
              ignoreCaptionChanged = {ignoreCaptionChanged}
              setIgnoreCaptionChanged = {setIgnoreCaptionChanged}
              ignoredCaptionList = {ignoredCaptionList}
              ignoreOrganizationNameChanged = {ignoreOrganizationNameChanged}
              setIgnoreOrganizationNameChanged = {setIgnoreOrganizationNameChanged}
              ignoreOrganizationNameList = {ignoreOrganizationNameList}
              setLoader = {setLoader}
              editorChanged={editorChanged} 
              setEditorChanged={setEditorChanged}
              handleSave={handleSave}
              modal = {true}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btnOutline' onClick={() => {setUndoErrorControl({...undoErrorControl, visible: false}); }}>Close</button>
          <button className='btnDefault' onClick={() => {steUndoModalControl({...undoModalControl, visible: true}); setUndoErrorControl({...undoErrorControl, visible: false})}}>Undo</button>
        </Modal.Footer>
      </Modal>

      <Modal show={undoModalControl.visible} onHide={() => {steUndoModalControl({...undoModalControl, visible: false}); setUndoErrorControl({...undoErrorControl, visible: true})}} dialogClassName="">
        <Modal.Header className="h6">Warning!</Modal.Header>
        <Modal.Body className="pt-0">
          <p>Automated corrections for journal style have been made. Click 'Undo' if you wish to remove these changes.</p>					
          <button className="btnOutline w-100" onClick={() => {steUndoModalControl({...undoModalControl, visible: false}); setUndoErrorControl({...undoErrorControl, visible: true})}}>Cancel</button>
          {undoModalControl.loading ? <FieldLoader isLoading={undoModalControl.loading} className="btnDefault mt-2 w-100" /> : <button className="w-100 btnDefault mt-2" onClick={()=>handleUndoError()}>Undo</button>}
        </Modal.Body>
      </Modal>
    </div>

  )
}

export default ActionView
