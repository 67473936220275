import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Col, Form, FormControl, Row } from "react-bootstrap";
import Select from 'react-select';
import { FieldLoader } from "../../../../../components/Loader/CommonLoader";
import { toast } from "react-toastify";
import AxiosService from "../../../../../utils/AxiosService";
import classNames from "classnames";
import style from "../StepFourForm/StepFourForm.module.scss"
import { useEffect } from "react";
import TooltipButton from "../../../../../components/TooltipButton/TooltipButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const AuthorForm = forwardRef(({
    authorContributionOptions, authorOptions, getAuthorList,
    authorLimitExceedModal, setAuthorLimitExceedModal, manuscriptId,
    mandatoryFieldSet, authorList, prefillData, setSubmitAuthor,
    submitButtonText, modal, closeModal, setExceedAuthor, isModalAddAuthorBtn,
    groupNameOption
}, ref) => {

    const apiService = new AxiosService();
    const [formValues, setFormValues] = useState({
        first_name: "",
        last_name: "",
        group_name: "",
        email: "",
        orcid: "",
        affiliation:"",
        authorType: [],
        phone: "",
        contribution: [],
        authorStatment: ""
    })

    const [formErrors, setFormErrors] = useState({
        first_name: "",
        last_name: "",
        group_name: "",
        email: "",
        orcid: "",
        affiliation:"",
        authorType: "",
        phone: "",
        contribution: [],
        authorStatment: "",
    })
    const [multivalue, setMultiValue] = useState(() => {
        let temp = []
        prefillData.contribution.map((item) => {
            temp.push({label: item, value: item})
        }) 
        return temp
    })
    const [authorOptins, setAuthorOptions] = useState(() => {
        let temp = []
        prefillData.authorType.map((item) => {
            temp.push({label: item, value: item})
        }) 
        return temp
    })
    const [isLoading, setIsLoading] = useState({btnAddAuthor: false, btnRemove: false});
    const [conflictOfInterest, setConflictOfInterest] = useState(false)	
    const [groupName, setGroupName] = useState(groupNameOption)

    const validEmailRegex = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)
    const validText = RegExp(/^([^\s\d]+)+(\s{1}[^\s\d]+)*$/)
	const validPhoneNo = RegExp(/\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/)
    const noChar = RegExp(/^[^a-zA-Z]*$/)

    const handleChange = (event) => {
        let { name, value } = event.target;
        if(name === "group_name1") {
            setGroupName(event.target.checked)
            if(event.target.checked === true) {
                setFormValues({...formValues, group_name: "", first_name: "", last_name: ""})
                setFormErrors({...formErrors, group_name: "", first_name: "", last_name: ""})
            } else {
                if(authorList.length < 1) {
                    setFormValues({...formValues, first_name: prefillData.first_name, last_name: prefillData.last_name, group_name: ""})
                } else {
                    setFormValues({...formValues, group_name: ""})
                }
                setFormErrors({...formErrors, first_name: "", last_name: "", group_name: ""})
            }
            return
        }
        event.preventDefault();
        setFormValues({...formValues, [name]: value})
    }

    function handleNumberChange(event){
        event.preventDefault();
        const {name, value} = event.target
        if(event.target.validity.valid){
            setFormValues({...formValues,[name]: value})
        }
        else if (value === '' || value === '-') {
            setFormValues({...formValues,[name]: value})
        }
    }

    const resetFormValues = () => {
        setFormValues({
            ...formValues, 
            first_name: "",
            last_name: "",
            group_name: "",
            email: "",
            orcid: "",
            affiliation:"",
            authorType: [],
            phone: "",
            contribution: [],
            authorStatment: ""
        })
        setMultiValue([])
        setAuthorOptions([])
    }

    const handleValidation = (event) => {
        const {name, value} = event.target;
        // typeof value === "string" ?
        //     setFormValues({...formValues, [name]: value.trim()})
        // :
        //     setFormValues({...formValues, [name]: value})
        switch (name) {
            case "email":
                setFormErrors({...formErrors,
                    email: !groupName ? value.length > 0 ? validEmailRegex.test(value) ? "" : "* Please enter a valid Email" : "* Email is required" : value.length > 0 ? validEmailRegex.test(value) ? "" : "* Please enter a valid Email" : "" 
                });
                break;
            case "authorType":
                setFormErrors({...formErrors,
                    authorType: value.length > 0 ? "" : "* Role is required"
                });
                break;
            case "first_name":
                setFormErrors({...formErrors,
                    first_name: formValues.last_name?.length <= 0 && value?.length <= 0 && formValues.group_name?.length > 1 && validText.test(formValues.group_name?.trim()) && formValues.group_name?.trim().length<=50 ? "" : value?.length ===0 ? "* First Name is required": value?.length > 0 && value?.length < 2 ?  "First Name must be min. 2 characters.": !validText.test(value.trim()) ? "Numericals or white space not accepted":value?.trim().length>15? "First Name must be max. 15 characters": "",
                    last_name: value?.length <= 0 && formValues.last_name?.length <= 0 && formValues.group_name?.length > 1 && validText.test(formValues.group_name?.trim()) && formValues.group_name?.trim().length<=50 ? "" : formValues.last_name?.length === 0 ? "* Last Name is required": !validText.test(formValues.last_name.trim()) ? "Numericals or white space not accepted": formValues.last_name?.trim().length>15? "Last Name must be max. 15 characters": "",
                    group_name: formValues.group_name?.length > 1 && validText.test(formValues.group_name?.trim()) && formValues.group_name?.trim().length < 15 ? "" : value?.length === 0 && formValues.last_name?.length === 0 && formValues.group_name?.length ===0 ? "* Group Name is required": formValues.group_name?.length > 0 && formValues.group_name?.length < 2 ?  "Group Name must be min. 2 characters.": formValues.group_name?.length > 0 && !validText.test(formValues.group_name?.trim()) ? "Numericals or white space not accepted" : formValues.group_name?.trim().length>50? "Group Name must be max. 50 characters": ""
                });
                break;
            case "last_name":
                setFormErrors({...formErrors,
                    last_name: formValues.first_name?.length <= 0 && value?.length <= 0 && formValues.group_name?.length > 1 && validText.test(formValues.group_name?.trim()) && formValues.group_name?.trim().length<=50 ? "" : value?.length === 0 ? "* Last Name is required": !validText.test(value.trim()) ? "Numericals or white space not accepted": value?.trim().length>15? "Last Name must be max. 15 characters": "",
                    first_name: value?.length <= 0 && formValues.first_name?.length <= 0 && formValues.group_name?.length > 1 && validText.test(formValues.group_name?.trim()) && formValues.group_name?.trim().length<=50 ? "" : formValues.first_name?.length ===0 ? "* First Name is required": formValues.first_name?.length > 0 && formValues.first_name?.length < 2 ?  "First Name must be min. 2 characters.": !validText.test(formValues.first_name.trim()) ? "Numericals or white space not accepted":formValues.first_name?.trim().length>15? "First Name must be max. 15 characters": "",
                    group_name: formValues.group_name?.length > 1 && validText.test(formValues.group_name?.trim()) && formValues.group_name?.trim().length < 15 ? "" : formValues.first_name?.length === 0 && value?.length === 0 && formValues.group_name?.length ===0 ? "* Group Name is required": formValues.group_name?.length > 0 && formValues.group_name?.length < 2 ?  "Group Name must be min. 2 characters.":formValues.group_name?.length > 0 && !validText.test(formValues.group_name?.trim()) ? "Numericals or white space not accepted" : formValues.group_name?.trim().length>50? "Group Name must be max. 50 characters": ""
                });
                break;
            case "affiliation":
                setFormErrors({...formErrors,
                    affiliation: value?.trim().length > 0 ? value?.trim().length > 2 ? "" : "Company/Institution must be min. 3 characters." : "* Company/Institution is required"
                });
                break;
            case "phone":
                let trimedPhoneVal = formValues?.phone?.split('').filter(v=> v !="-" && v!="+").join('')
                setFormErrors({...formErrors,
                    phone: trimedPhoneVal.length>0 && trimedPhoneVal.length < 7 ?  "Phone number must be min. 7 digit." : formValues?.phone?.length > 0 ? !validPhoneNo.test(trimedPhoneVal) ? "Enter valid phone number" : !noChar.test(trimedPhoneVal) ? "Phone numbers must not include any alphabetical characters" : "" : ""
                });
                break;
            case "contribution":
                setFormErrors({...formErrors,
                    contribution: value.length <= 0 ? "* Contribution is required" : ""
                })
                break;
            case "orcid":
                if(mandatoryFieldSet?.orcid[0]?.mandatory) {
                    setFormErrors({...formErrors,
                        orcid: value.length <= 0 ? "* ORCID ID is required" : ""
                    }) 
                }
                break;
            case "group_name":
                setFormErrors({...formErrors,
                    group_name: formValues.first_name?.length === 0 && formValues.last_name?.length === 0 && value?.length ===0 ? "* Group Name is required": value?.length > 0 && value?.length < 2 ?  "Group Name must be min. 2 characters.": value?.length > 0 && !validText.test(value.trim()) ? "Numericals or white space not accepted" : value.trim().length>50? "Group Name must be max. 50 characters": "",
                    first_name: formValues.first_name?.length <= 0 && formValues.last_name?.length <= 0 && value?.length > 1 && validText.test(value.trim()) && value.trim().length<=50 ? "" : formValues.first_name?.length ===0 ? "* First Name is required": formValues.first_name?.length > 0 && formValues.first_name?.length < 2 ?  "First Name must be min. 2 characters.": !validText.test(formValues.first_name.trim()) ? "Numericals or white space not accepted":formValues.first_name?.trim().length>15? "First Name must be max. 15 characters": "",
                    last_name: formValues.last_name?.length <= 0 && formValues.first_name?.length <= 0 && value?.length > 1 && validText.test(value.trim()) && value.trim().length<=50 ? "" : formValues.last_name?.length === 0 ? "* Last Name is required": !validText.test(formValues.last_name.trim()) ? "Numericals or white space not accepted": formValues.last_name?.trim().length>15? "Last Name must be max. 15 characters": ""
                });
                break;
            case "authorStatment" :
                setFormErrors({...formErrors,
                    authorStatment: formValues.authorType.includes("Co-First author") || formValues.authorType.includes("Co-Senior author") ? formValues.authorStatment.length <= 0 ? "*Author statement is required" : formValues?.authorStatment?.trim().length <= 0 ? "White space not accepted" : "" : ""
                })
            default:
                break;
        }
    }

    const submitAuthor = (e) => {
        // window.alert("Form values > Author Type: " + formValues.authorType + " and Author Contribution: " + formValues?.contribution)
        e.preventDefault();
        if(formErrors.phone || formErrors.first_name || formErrors.last_name || (!groupName && formErrors.email) || formErrors.group_name) {
            toast.error("Form submission contains errors - please check and correct.", {autoClose: 3000})
        }else{
            if((groupName ? formValues?.group_name.trim().length > 1 : true) && (!groupName ? formValues.email?.trim() && validEmailRegex.test(formValues.email) : formValues.email?.trim() ? validEmailRegex.test(formValues.email) : true) && formValues.authorType.length > 0 && ((formValues.first_name?.trim().length >1 && formValues.last_name?.trim().length >0) || formValues.group_name?.trim().length > 0) && formValues.affiliation?.trim().length >2 && formValues.contribution.length > 0 && (formValues.authorType.includes("Co-First author") || formValues.authorType.includes("Co-Senior author") ? formValues?.authorStatment?.trim().length <= 0 ? false : true : true)) {
                if(mandatoryFieldSet?.authors[0]?.limit && authorList.length >= mandatoryFieldSet?.authors[0]?.limit && !modal && authorLimitExceedModal === false) {
                    setAuthorLimitExceedModal(true)
                    setExceedAuthor(formValues)
                    return
                } else if (mandatoryFieldSet?.orcid[0]?.mandatory && formValues.orcid.trim().length < 1 && groupName === false) {
                    if(!modal) {
                        window.scrollTo({top: 0, behavior: 'smooth'});
                        toast.error("Form submission contains errors - please check and correct.",  {
                            autoClose: 3000,
                        })
                    }
                    setFormErrors({
                        ...formErrors,
                        email: !groupName ? formValues.email.length ? validEmailRegex.test(formValues.email) ? '' : '*Enter a valid email' : '* Email is required' : formValues.email.length ? validEmailRegex.test(formValues.email) ? '' : '*Enter a valid email' : '',
                        first_name: formValues.last_name?.length > 0 || formValues.first_name?.length > 0 || formValues.group_name?.length <= 1 || !validText.test(formValues.group_name?.trim()) || formValues.group_name?.trim().length>50 ? formValues.first_name?.length ===0 ? '* First Name is required': formValues.first_name?.length > 0 && formValues.first_name?.length < 2 ?  "First Name must be min. 2 characters.":  !validText.test(formValues.first_name) ? "Numericals or white space not accepted":"":"",
                        last_name: formValues.first_name?.length > 0 || formValues.last_name?.length > 0 || formValues.group_name?.length <= 1 || !validText.test(formValues.group_name?.trim()) || formValues.group_name?.trim().length>50 ? formValues.last_name?.length === 0 ?  '* Last Name is required': !validText.test(formValues.last_name) ? "Numericals or white space not accepted": "":"",
                        affiliation: formValues.affiliation?.trim()?.length > 0 ? formValues.affiliation?.trim()?.length > 2 ? "" : "Company/Institution must be min. 3 characters." : "* Company/Institution is required" , 
                        authorType: formValues.authorType?.length ===0 ?  '* Role is required': "",
                        contribution: formValues.contribution?.length <= 0 ? "* Contribution is required": "",
                        orcid: formValues.orcid.trim().length < 1 ? "* ORCID ID is required" : "",
                        group_name: formValues.first_name?.length > 2 && validText.test(formValues.first_name.trim()) && formValues.first_name?.trim().length < 15 && formValues.last_name?.length > 0 && validText.test(formValues.last_name?.trim()) ? "" : formValues.group_name?.length ===0 ? "* Group Name is required": formValues.group_name?.length > 0 && formValues.group_name?.length < 2 ?  "Group Name must be min. 2 characters." : formValues.group_name?.length > 0 && !validText.test(formValues.group_name.trim()) ? "Numericals or white space not accepted" : "",
                        authorStatment: formValues.authorType.includes("Co-First author") || formValues.authorType.includes("Co-Senior author") ? formValues?.authorStatment?.length <= 0 ? "* Author statement is required" : formValues?.authorStatment?.trim().length <= 0 ? "white space not accepted" : "" : "" 
                    })	
                } else {
                    setIsLoading({...isLoading, btnAddAuthor :true})
                    let trimedFormaValue = {
                        "affilliation": formValues.affiliation?.trim(),
                        "authorType": formValues.authorType,
                        "emailId": formValues.email?.trim().toLowerCase(),
                        "firstName": groupName ? "" : formValues.first_name?.trim(),
                        "lastName": groupName ? "" : formValues.last_name?.trim(),
                        "orcid": formValues.orcid?.trim(),
                        "phone": formValues.phone?.trim()? formValues.phone?.trim() : "",
                        "conflictOfInterest": conflictOfInterest,
                        "contribution": formValues?.contribution ? formValues?.contribution: "",
                        "groupName": groupName ? formValues.group_name?.trim() : "",
                        "authorStatment" : formValues.authorType.includes("Co-First author") || formValues.authorType.includes("Co-Senior author") ? formValues.authorStatment.trim() : ""
                    }
                    if(prefillData.id) {
                        apiService.updateAuthor(manuscriptId, prefillData.id, trimedFormaValue).then(res => {
                            resetFormValues();
                            setConflictOfInterest(false)
                            setIsLoading({...isLoading, btnAddAuthor :false});
                            closeModal()
                            toast.success("Author information updated.",  {
                                autoClose: 3000,
                            })
                            getAuthorList();
                        }).catch(e => {
                            setIsLoading({...isLoading, btnAddAuthor :false});
                            if(e.response){
                                toast.error(e.response.data.message,  {
                                    autoClose: 3000,
                                })
                            }
                        })
                    } else {
                        apiService.addAuthor(manuscriptId, trimedFormaValue).then(res => {
                            resetFormValues();
                            setConflictOfInterest(false)
                            setIsLoading({...isLoading, btnAddAuthor :false});
                            toast.success("Saved successfully.",  {
                                autoClose: 3000,
                            })
                            getAuthorList();
                        }).catch(e=> {
                            setIsLoading({...isLoading, btnAddAuthor :false});
                            if(e.response){
                                toast.error(e.response.data.message,  {
                                    autoClose: 3000,
                                })
                            }
                        })
                    }
                }
            } else {
                if(!modal) {
                    window.scrollTo({top: 0, behavior: 'smooth'});
                    toast.error("Form submission contains errors - please check and correct.",  {
                        autoClose: 3000,
                    })
                }
                setFormErrors({
                    ...formErrors,
                    email: !groupName ? formValues.email.length ? validEmailRegex.test(formValues.email) ? '' : '*Enter a valid email' : '* Email is required' : formValues.email.length ? validEmailRegex.test(formValues.email) ? '' : '*Enter a valid email' : '',
                    first_name: formValues.last_name?.length > 0 || formValues.first_name?.length > 0 || formValues.group_name?.length <= 1 || !validText.test(formValues.group_name?.trim()) || formValues.group_name?.trim().length>50 ? formValues.first_name?.length ===0 ? '* First Name is required': formValues.first_name?.length > 0 && formValues.first_name?.length < 2 ?  "First Name must be min. 2 characters.":  !validText.test(formValues.first_name) ? "Numericals or white space not accepted":"":"",
                    last_name: formValues.first_name?.length > 0 || formValues.last_name?.length > 0 || formValues.group_name?.length <= 1 || !validText.test(formValues.group_name?.trim()) || formValues.group_name?.trim().length>50 ? formValues.last_name?.length === 0 ?  '* Last Name is required': !validText.test(formValues.last_name) ? "Numericals or white space not accepted": "":"",
                    affiliation: formValues.affiliation?.trim()?.length > 0 ? formValues.affiliation?.trim()?.length > 2 ? "" : "Company/Institution must be min. 3 characters." : "* Company/Institution is required" , 
                    authorType: formValues.authorType?.length ===0 ?  '* Role is required': "",
                    contribution: formValues.contribution?.length <= 0 ? "* Contribution is required": "",
                    orcid: mandatoryFieldSet?.orcid[0]?.mandatory && formValues.orcid.trim().length < 1 && groupName === false ? "* ORCID ID is required" : "",
                    group_name: formValues.first_name?.length > 2 && validText.test(formValues.first_name.trim()) && formValues.first_name?.trim().length < 15 && formValues.last_name?.length > 0 && validText.test(formValues.last_name?.trim()) ? "" : formValues.group_name?.length ===0 ? "* Group Name is required": formValues.group_name?.length > 0 && formValues.group_name?.length < 2 ?  "Group Name must be min. 2 characters." : formValues.group_name?.length > 0 && !validText.test(formValues.group_name.trim()) ? "Numericals or white space not accepted" : "",
                    authorStatment: formValues.authorType.includes("Co-First author") || formValues.authorType.includes("Co-Senior author") ? formValues?.authorStatment?.length <= 0 ? "* Author statement is required" : formValues?.authorStatment?.trim().length <= 0 ? "white space not accepted" : "" : ""
                })	
            }	
        }	
    }

    useEffect(() => {
        if(prefillData) {
            if(groupName) {
                setFormValues({...prefillData, first_name: "", last_name: ""})
            } else {
                setFormValues({...prefillData, group_name: ""})
            }
        } else {
            resetFormValues()
        }
    }, [prefillData])

    useImperativeHandle(ref, () => ({
        submitAuthor (e) {
            // window.alert("Form values > Author Type: " + formValues.authorType + " and Author Contribution: " + formValues?.contribution)
            e.preventDefault();
            if(formErrors.phone || formErrors.first_name || formErrors.last_name || formErrors.email || formErrors.group_name) {
                toast.error("Form submission contains errors - please check and correct.", {autoClose: 3000})
            }else{
                if((groupName ? formValues?.group_name.trim().length > 1 : true) && (!groupName ? formValues.email?.trim() && validEmailRegex.test(formValues.email) : formValues.email?.trim() ? validEmailRegex.test(formValues.email) : true) && formValues.authorType.length > 0 && ((formValues.first_name?.trim().length >1 && formValues.last_name?.trim().length >0) || formValues.group_name?.trim().length > 0) && formValues.affiliation?.trim().length >2 && formValues.contribution.length > 0 && (formValues.authorType.includes("Co-First author") || formValues.authorType.includes("Co-Senior author") ? formValues?.authorStatment?.trim().length <= 0 ? false : true : true)) {
                    if(mandatoryFieldSet?.authors[0]?.limit && authorList.length >= mandatoryFieldSet?.authors[0]?.limit && !modal && authorLimitExceedModal === false) {
                        setAuthorLimitExceedModal(true)
                        setExceedAuthor(formValues)
                        setIsLoading({...isLoading, btnAddAuthor :false})
                        return
                    } else if (mandatoryFieldSet?.orcid[0]?.mandatory && formValues.orcid.trim().length < 1 && groupName === false) {
                        if(!modal) {
                            window.scrollTo({top: 0, behavior: 'smooth'});
                            toast.error("Form submission contains errors - please check and correct.",  {
                                autoClose: 3000,
                            })
                        }
                        setFormErrors({
                            ...formErrors,
                            email: !groupName ? formValues.email.length ? validEmailRegex.test(formValues.email) ? '' : '*Enter a valid email' : '* Email is required' : formValues.email.length ? validEmailRegex.test(formValues.email) ? '' : '*Enter a valid email' : '',
                            first_name: formValues.last_name?.length > 0 || formValues.first_name?.length > 0 || formValues.group_name?.length <= 1 || !validText.test(formValues.group_name?.trim()) || formValues.group_name?.trim().length>50 ? formValues.first_name?.length ===0 ? '* First Name is required': formValues.first_name?.length > 0 && formValues.first_name?.length < 2 ?  "First Name must be min. 2 characters.":  !validText.test(formValues.first_name) ? "Numericals or white space not accepted":"":"",
                            last_name: formValues.first_name?.length > 0 || formValues.last_name?.length > 0 || formValues.group_name?.length <= 1 || !validText.test(formValues.group_name?.trim()) || formValues.group_name?.trim().length>50 ? formValues.last_name?.length === 0 ?  '* Last Name is required': !validText.test(formValues.last_name) ? "Numericals or white space not accepted": "":"",
                            affiliation: formValues.affiliation?.trim()?.length > 0 ? formValues.affiliation?.trim()?.length > 2 ? "" : "Company/Institution must be min. 3 characters." : "* Company/Institution is required" , 
                            authorType: formValues.authorType?.length ===0 ?  '* Role is required': "",
                            contribution: formValues.contribution?.length <= 0 ? "* Contribution is required": "",
                            orcid: formValues.orcid.trim().length < 1 ? "* ORCID ID is required" : "",
                            group_name: formValues.first_name?.length > 2 && validText.test(formValues.first_name.trim()) && formValues.first_name?.trim().length < 15 && formValues.last_name?.length > 0 && validText.test(formValues.last_name?.trim()) ? "" : formValues.group_name?.length ===0 ? "* Group Name is required": formValues.group_name?.length > 0 && formValues.group_name?.length < 2 ?  "Group Name must be min. 2 characters." : formValues.group_name?.length > 0 && !validText.test(formValues.group_name.trim()) ? "Numericals or white space not accepted" : "",
                            authorStatment: formValues.authorType.includes("Co-First author") || formValues.authorType.includes("Co-Senior author") ? formValues?.authorStatment?.length <= 0 ? "* Author statement is required" : formValues?.authorStatment?.trim().length <= 0 ? "white space not accepted" : "" : ""
                        })	
                    } else {
                        setIsLoading({...isLoading, btnAddAuthor :true})
                        let trimedFormaValue = {
                            "affilliation": formValues.affiliation?.trim(),
                            "authorType": formValues.authorType,
                            "emailId": formValues.email?.trim().toLowerCase(),
                            "firstName": formValues.first_name?.trim(),
                            "lastName": formValues.last_name?.trim(),
                            "orcid": formValues.orcid?.trim(),
                            "phone": formValues.phone?.trim()? formValues.phone?.trim() : "",
                            "conflictOfInterest": conflictOfInterest,
                            "contribution": formValues?.contribution ? formValues?.contribution: "",
                            "groupName": formValues.group_name?.trim()
                        }
                        if(!groupName) {
                            delete trimedFormaValue.groupName
                        } else {
                            delete trimedFormaValue.firstName
                            delete trimedFormaValue.lastName
                        }
                        if(prefillData.id) {
                            apiService.updateAuthor(manuscriptId, prefillData.id, trimedFormaValue).then(res => {
                                resetFormValues();
                                setConflictOfInterest(false)
                                setIsLoading({...isLoading, btnAddAuthor :false});
                                closeModal()
                                toast.success("Author information updated.",  {
                                    autoClose: 3000,
                                })
                                getAuthorList();
                            }).catch(e => {
                                setIsLoading({...isLoading, btnAddAuthor :false});
                                if(e.response){
                                    toast.error(e.response.data.message,  {
                                        autoClose: 3000,
                                    })
                                }
                            })
                        } else {
                            apiService.addAuthor(manuscriptId, trimedFormaValue).then(res => {
                                resetFormValues();
                                setConflictOfInterest(false)
                                setIsLoading({...isLoading, btnAddAuthor :false});
                                toast.success("Saved successfully.",  {
                                    autoClose: 3000,
                                })
                                getAuthorList();
                            }).catch(e=> {
                                setIsLoading({...isLoading, btnAddAuthor :false});
                                if(e.response){
                                    toast.error(e.response.data.message,  {
                                        autoClose: 3000,
                                    })
                                }
                            })
                        }
                    }
                } else {
                    if(!modal) {
                        window.scrollTo({top: 0, behavior: 'smooth'});
                        toast.error("Form submission contains errors - please check and correct.",  {
                            autoClose: 3000,
                        })
                    }
                    setFormErrors({
                        ...formErrors,
                        email: !groupName ? formValues.email.length ? validEmailRegex.test(formValues.email) ? '' : '*Enter a valid email' : '* Email is required' : formValues.email.length ? validEmailRegex.test(formValues.email) ? '' : '*Enter a valid email' : '',
                        first_name: formValues.last_name?.length > 0 || formValues.first_name?.length > 0 || formValues.group_name?.length <= 1 || !validText.test(formValues.group_name?.trim()) || formValues.group_name?.trim().length>50 ? formValues.first_name?.length ===0 ? '* First Name is required': formValues.first_name?.length > 0 && formValues.first_name?.length < 2 ?  "First Name must be min. 2 characters.":  !validText.test(formValues.first_name) ? "Numericals or white space not accepted":"":"",
                        last_name: formValues.first_name?.length > 0 || formValues.last_name?.length > 0 || formValues.group_name?.length <= 1 || !validText.test(formValues.group_name?.trim()) || formValues.group_name?.trim().length>50 ? formValues.last_name?.length === 0 ?  '* Last Name is required': !validText.test(formValues.last_name) ? "Numericals or white space not accepted": "":"",
                        affiliation: formValues.affiliation?.trim()?.length > 0 ? formValues.affiliation?.trim()?.length > 2 ? "" : "Company/Institution must be min. 3 characters." : "* Company/Institution is required" , 
                        authorType: formValues.authorType?.length ===0 ?  '* Role is required': "",
                        contribution: formValues.contribution?.length <= 0 ? "* Contribution is required": "",
                        orcid: mandatoryFieldSet?.orcid[0]?.mandatory && formValues.orcid.trim().length < 1 && groupName === false ? "* ORCID ID is required" : "",
                        group_name: formValues.first_name?.length > 2 && validText.test(formValues.first_name.trim()) && formValues.first_name?.trim().length < 15 && formValues.last_name?.length > 0 && validText.test(formValues.last_name?.trim()) ? "" : formValues.group_name?.length ===0 ? "* Group Name is required": formValues.group_name?.length > 0 && formValues.group_name?.length < 2 ?  "Group Name must be min. 2 characters." : formValues.group_name?.length > 0 && !validText.test(formValues.group_name.trim()) ? "Numericals or white space not accepted" : "",
                        authorStatment: formValues.authorType.includes("Co-First author") || formValues.authorType.includes("Co-Senior author") ? formValues?.authorStatment?.length <= 0 ? "* Author statement is required" : formValues?.authorStatment?.trim().length <= 0 ? "white space not accepted" : "" : ""
                    })	
                }	
            }	
        }
    }))


    return (
        <>
            <Form onSubmit={(e) => submitAuthor(e)}>
					<Row>
                        {!modal ? 
                            <Col md={12}>
                                <div className="mb-3 align-items-center">
                                    <Form.Check
                                        label="Group Name"
                                        type="switch"
                                        onChange={(e) => {handleChange(e)}}
                                        checked={groupName === true}
                                        value={groupName}
                                        name="group_name1"
                                        id="group_name1"
                                    />
                                    <span className="text-secondary small">{groupName ? "(Switch this off to add an author name.)" : "(Switch this on to add a group name.)"}</span>
                                </div>
                            </Col>
                        :
                            null
                        }
                        {modal ? 
                            <>
                                {!groupName ?
                                    <>
                                        <Col md={6}>
                                            <div>
                                                <label>First Name <span className="text-danger">*</span></label>
                                                <FormControl
                                                    type="text"
                                                    placeholder="Enter first name"
                                                    value={formValues.first_name}
                                                    onChange={(e)=> handleChange(e)}
                                                    onBlur={(e)=> handleValidation(e)}
                                                    area-label="First Name"
                                                    name="first_name"
                                                />
                                                {formErrors.first_name && <span className="text-danger">{formErrors.first_name}</span>}
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div>
                                                <label>Last Name <span className="text-danger">*</span></label>
                                                <FormControl
                                                    type="text"
                                                    placeholder="Enter last name"
                                                    value={formValues.last_name}
                                                    onChange={(e)=> handleChange(e)}
                                                    onBlur={(e)=> handleValidation(e)}
                                                    area-label="Last Name"
                                                    name="last_name"
                                                />
                                                {formErrors.last_name && <span className="text-danger">{formErrors.last_name}</span>}
                                            </div>
                                        </Col>
                                    </>
                                :
                                    null
                                }

                                {groupName ?
                                    <Col md={12}>
                                        <div>
                                            <label>Group Name <span className="text-danger">*</span></label>
                                            <FormControl
                                                type="text"
                                                placeholder="Enter group name"
                                                value={formValues?.group_name}
                                                onChange={(e)=> handleChange(e)}
                                                onBlur={(e)=> handleValidation(e)}
                                                area-label="Group Name"
                                                name="group_name"
                                            />
                                            {formErrors?.group_name && <span className="text-danger">{formErrors?.group_name}</span>}
                                        </div>
                                    </Col>
                                :
                                    null
                                }
                            </>
                        :
                            <>
                                {!groupName ? 
                                    <>
                                        <Col md={6}>
                                            <div>
                                                <label>First Name <span className="text-danger">*</span></label>
                                                <FormControl
                                                    type="text"
                                                    placeholder="Enter first name"
                                                    value={formValues.first_name}
                                                    onChange={(e)=> handleChange(e)}
                                                    onBlur={(e)=> handleValidation(e)}
                                                    area-label="First Name"
                                                    name="first_name"
                                                />
                                                {formErrors.first_name && <span className="text-danger">{formErrors.first_name}</span>}
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div>
                                                <label>Last Name <span className="text-danger">*</span></label>
                                                <FormControl
                                                    type="text"
                                                    placeholder="Enter last name"
                                                    value={formValues.last_name}
                                                    onChange={(e)=> handleChange(e)}
                                                    onBlur={(e)=> handleValidation(e)}
                                                    area-label="Last Name"
                                                    name="last_name"
                                                />
                                                {formErrors.last_name && <span className="text-danger">{formErrors.last_name}</span>}
                                            </div>
                                        </Col>
                                    </>
                                :
                                    <Col md={12}>
                                        <div>
                                            <label>Group Name <span className="text-danger">*</span></label>
                                            <FormControl
                                                type="text"
                                                placeholder="Enter group name"
                                                value={formValues?.group_name}
                                                onChange={(e)=> handleChange(e)}
                                                onBlur={(e)=> handleValidation(e)}
                                                area-label="Group Name"
                                                name="group_name"
                                            />
                                            {formErrors?.group_name && <span className="text-danger">{formErrors?.group_name}</span>}
                                        </div>
                                    </Col>
                                }
                            </>
                        }
                        <Col md={6}>
							<div className="my-3">
								<label>Email ID {!groupName && formValues?.group_name?.length < 1 ? <span className="text-danger">*</span> : null}</label>
								<FormControl
									type="email"
									placeholder="Enter email ID"
									value={formValues.email}
									onChange={(e)=> handleChange(e)}
									onBlur={(e)=> handleValidation(e)}
									area-label="Email ID"
									name="email"
								/>
								{formErrors.email && <span className="text-danger">{formErrors.email}</span>}
							</div>
							<div className={classNames(style.inputNumberStyle , "my-3")}>
								<label>Phone </label>
								<FormControl
									type="tel"
									pattern="^[0-9+-]*$"
									placeholder="Enter phone number"
									value={formValues.phone}
									onChange={(e)=> handleNumberChange(e)}
									onBlur={(e)=> handleValidation(e)}
									// onKeyDown={(event)=> (event.keyCode === 69 || event.keyCode === 190 ) && event.preventDefault()}
									area-label="phone"
									name="phone"
								/>
								{formErrors.phone && <span className="text-danger">{formErrors.phone}</span>}
							</div>
							<div className="my-3">
								<label className="d-flex flex-row justify-content-between">
                                    <div>
                                        <span className="">Contribution</span>
                                        <span className="text-danger mr-3">*</span>
                                    </div>
                                    <a className="text-right" href="https://credit.niso.org/" target={"_blank"}>View CRediT guidelines</a>
                                </label>
								<Select
									isMulti
									name="contribution"
									value={multivalue}
									unstyled
									options = {authorContributionOptions}
                                    formatOptionLabel={option => <div className="needsclick">{option.label}</div>}
									placeholder="Select author's contributions"
									classNames = {{
										control: () => (style.reactSelectControl),
										menu: () => (style.raectSelectMenu),
										menuList: () => (style.reactSelectMenuList),
										option: () => (style.reactSelectOption),
										indicatorsContainer: () => (style.reactSelectIndicator),
										multiValue: () => (style.reactSelectMultivalue),
										multiValueRemove: () =>(style.reactMultiValueRemove),
										container: () => (style.reactSelectContainer)
									}}
									onChange={(e) => {
										let value = []
										e.map((item) => {value.push(item.value)})
										e.target = {}
										e.preventDefault = () =>{return null}
										e.target.name = "contribution"
										e.target.value = value
										setMultiValue(e)
										handleChange(e)
                                        handleValidation(e)
									}}
									onBlur={(e) => {
										e.target = {}
										e.target.name = "contribution"
										e.target.value = formValues.contribution
									}}
								/>
								{formErrors.contribution && <span className="text-danger">{formErrors.contribution}</span>}
							</div>											
						</Col>
						<Col md={6}>
							<div className="my-3">
								<label>ORCID ID {mandatoryFieldSet?.orcid[0]?.mandatory && groupName === false ? <span className="text-danger">*</span> : null}</label>
								<FormControl
									type="text"
									placeholder="Enter ORCID ID"
									value={formValues.orcid}
									onChange={(e)=> handleChange(e)}
                                    onBlur={(e)=> handleValidation(e)}
									area-label="ORCID ID"
									name="orcid"
								/>
								{formErrors.orcid && <p className="text-danger">{formErrors.orcid}</p>}
							</div>
							<div className="my-3">
								<label>Role <span className="text-danger">*</span></label>
								{/* <select className="form-control" name="authorType" value={formValues.authorType} onChange={(e) => handleChange(e)} onBlur={(e)=> handleValidation(e)}>
									<option value="">Select type</option>
									{authorOptions && authorOptions.map(author => (
											<option key={author.id} value={author.name}>{author.name}</option>
									))}
								</select> */}
                                <Select
									isMulti
									name="authorType"
									value={authorOptins}
									unstyled
									options = {authorOptions}
                                    formatOptionLabel={option => <div className="needsclick">{option.label}</div>}
									placeholder="Select author's role"
									classNames = {{
										control: () => (style.reactSelectControl),
										menu: () => (style.raectSelectMenu),
										menuList: () => (style.reactSelectMenuList),
										option: () => (style.reactSelectOption),
										indicatorsContainer: () => (style.reactSelectIndicator),
										multiValue: () => (style.reactSelectMultivalue),
										multiValueRemove: () =>(style.reactMultiValueRemove),
										container: () => (style.reactSelectContainer)
									}}
									onChange={(e) => {
										let value = []
										e.map((item) => {value.push(item.value)})
										e.target = {}
										e.preventDefault = () =>{return null}
										e.target.name = "authorType"
										e.target.value = value
										setAuthorOptions(e)
										handleChange(e)
                                        handleValidation(e)
									}}
									onBlur={(e) => {
										e.target = {}
										e.target.name = "authorType"
										e.target.value = formValues.authorType
									}}
								/>
								{formErrors.authorType && <span className="text-danger">{formErrors.authorType}</span>}
							</div>
							<div className="my-3">
								<label>Company/Institution <span className="text-danger">*</span></label>
								<FormControl
									type="text"
									placeholder="Enter Company/Institution"
									value={formValues.affiliation}
									onChange={(e)=> handleChange(e)}
									onBlur={(e)=> handleValidation(e)}
									area-label="Affilliation"
									name="affiliation"
								/>
								{formErrors.affiliation && <span className="text-danger">{formErrors.affiliation}</span>}
							</div>
						</Col>
                        {formValues.authorType.includes("Co-First author") || formValues.authorType.includes("Co-Senior author") ? 
                            <Col md={12}>
                                <label>
                                    Author Statements <span className="text-danger">*</span>
                                    <TooltipButton tooltipMessage="Please copy and paste in mandatory author statements required by your chosen journal. These will be added to your title page. No changes will be made to these." placement="top">
                                        <FontAwesomeIcon icon={faInfoCircle} className={classNames(style.primaryFont, "mx-2 align-middle")} />
                                    </TooltipButton>
                                </label>
                                <FormControl
                                    as="textarea"
                                    value={formValues?.authorStatment}
                                    rows={5}
                                    placeholder="Please copy and paste in mandatory author statements required by your chosen journal. These will be added to your title page. No changes will be made to these."
                                    onChange={(e)=> handleChange(e)}
                                    onBlur={(e)=> handleValidation(e)}
                                    area-label="authorStatment"
                                    name="authorStatment"
                                />
                                {formErrors.authorStatment && <span className="text-danger">{formErrors.authorStatment}</span>}
                            </Col>
                        :
                            null
                        }
					</Row>
                    <div className={modal ? "w-100 d-flex justify-content-end" : "w-100 d-flex"}>
                        {isLoading.btnAddAuthor ? 
                            <FieldLoader className="mt-2 mb-4" isLoading={isLoading.btnAddAuthor} message={modal? "Updating..." : "Adding..."} />
                        :
                            <button type="submit" className="mt-2 mb-4 defaultBtnWidth">{submitButtonText ? submitButtonText : "Submit"}</button> 
                        }
                    </div>
			</Form>
        </>
    )
})

export default AuthorForm