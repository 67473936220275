import React, { useEffect, useState } from "react"
import { Col, Container, Modal, Row, Tab, Tabs} from "react-bootstrap"
import style from "./ManuscriptSubmission.module.scss"
import MultiStepTabView from "../SubComponents/MultiStepTabView/MultiStepTabView"
import { useHistory, useParams } from "react-router-dom";
import AxiosService from "../../../../utils/AxiosService";
import classNames from "classnames";
import { CircleLoader } from "../../../../components/Loader/CommonLoader";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setJournalConfig } from "../../../../store/actions/JournalConfigAction";



const ManuscriptSubmission= (props) => { 
	const {manuscriptId} = useParams();
	const [journal, setJournal] = useState();
	const apiService = new AxiosService();
	const history = useHistory();
	const [stepStatus, setStepStatus] = useState();
	const [show, setShow] = useState(false);
	const [journalloader, setJournalLoader] = useState(false)
	const [journalGuidelines, setJournalGuidelines] = useState();
	const [key, setKey] = useState(0)
	const dispatch = useDispatch()
	const journalConfig = useSelector(state => state.journalConfig.config)


	const getJournal = () => {
		apiService.getJournalTitle(manuscriptId).then(res=> {
			setJournal(res.result);
			setStepStatus(res.result.stepStatus.step);
		}).catch(e=> {
			if(e?.response?.status === 403) {
				history.push("/403?message="+e.response.data.message);
			}
		})
	}
	const handleGuidelines= () =>{
		setShow(true);
		setJournalLoader(true);
		apiService.getJournalGuidelines(journal.journalId, manuscriptId).then(res=>{
			setJournalLoader(false)
			let array = []
			Object.keys(res.result).forEach(key=>{
				if(res.result[key].length > 0){
				array.push({
					name: key,
					data: res.result[key]
				})
				}
			})
			setJournalGuidelines(array)
		}).catch(err=> {
			setJournalLoader(false)
			toast.error(err.response.data.message,  {
				autoClose: 3000,
			})
		})
	}

	const getJournalConfig = (journalId, manuscriptId, manuscriptType) => {
		apiService.getJournalConfig(journalId, manuscriptId, manuscriptType).then((res) => {
			dispatch(setJournalConfig(res.result))
		}).then((err) => {
			toast.error(err?.response?.data?.message)
		})
	}
	
	useEffect(()=> {
		getJournal()
	},[])

	return(
		<>
			<div className="my-2 py-2">
					<h3 className="">Manuscript Formatting Tool</h3>
					<p className="mb-0">OrthoPublish’s unique formatting tool guides you through the paper preparation process – add key information and upload your manuscript to receive guidance on meeting your chosen journal’s requirements such as abstract format, article length, reference formatting, blinding, keywords, figure and table requirements.</p>
			</div>
			<Modal show={show} onHide={()=> setShow(false)} size="lg">
				<Modal.Header closeButton>
                    <Modal.Title as="h5" className="">{journal?.templateType==="Generic"?'Generic Journal' :'Journal'} Guidelines</Modal.Title>
                </Modal.Header>
				<Modal.Body className={classNames(style.modalBody, "pt-2 px-1")}>
					{ journalloader ? 
						<div className="d-flex justify-content-center align-items-center h-75"><CircleLoader /></div>
					:
						journalGuidelines ? 
							<Tabs id={key} activeKey={key} onSelect={(e) => setKey(e)}>
								{journalGuidelines.map((key, index)=>
									<Tab eventKey={index} title={key.name}>
										<ul style={{listStyle: "none"}}>
											{key.data.map(listData => 
												<li className="my-2" dangerouslySetInnerHTML={{ __html: `${listData}` }}></li>
											)}
										</ul>
									</Tab>
								)}
							</Tabs>
					
						:
							<div className="d-flex justify-content-center align-items-center h-75 p-3">
								<p>Journal guidelines are not available for <strong className="px-2">{journal? journal.journalTitle : ""}</strong>!</p>
							</div> 
					}
				</Modal.Body>
			</Modal>
		{stepStatus && 
		<MultiStepTabView 
			manuscriptId={manuscriptId} 
			stepStatus={stepStatus? stepStatus : 1}
			journal={journal ? journal : "NA"}
			handleStepStatus={getJournal}
			handleGuidelines = {handleGuidelines}
			setExitFunction = {props.setExitFunction}
			setStepOneInfo={props.setStepOneInfo}
			getJournalConfig={getJournalConfig}
		/>}
		</>
	)
}

export default ManuscriptSubmission;