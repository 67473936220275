import React, { useEffect, useState } from "react";
import AxiosService from "../../utils/AxiosService";
import { Card, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";

const PricingInfo = () => {
    const [priceInfoModal, setPriceInfoTable] = useState({visible: false, loading: true})
    const [userPaymentProfile, setUserPaymentProfile] = useState([])

    const apiService = new AxiosService()
    const history = useHistory();
    const currencySymbols = {
        'USD': '$', // US Dollar
        'EUR': '€', // Euro
        'CRC': '₡', // Costa Rican Colón
        'GBP': '£', // British Pound Sterling
        'ILS': '₪', // Israeli New Sheqel
        'INR': '₹', // Indian Rupee
        'JPY': '¥', // Japanese Yen
        'KRW': '₩', // South Korean Won
        'NGN': '₦', // Nigerian Naira
        'PHP': '₱', // Philippine Peso
        'PLN': 'zł', // Polish Zloty
        'PYG': '₲', // Paraguayan Guarani
        'THB': '฿', // Thai Baht
        'UAH': '₴', // Ukrainian Hryvnia
        'VND': '₫', // Vietnamese Dong
    };

    const fetchUserPaymentProfile = () => {
        setPriceInfoTable({...priceInfoModal, loading: true})
        apiService.getPriceTable().then((res) => {
            setUserPaymentProfile(res.result)
            setPriceInfoTable({...priceInfoModal, loading: false})
        }).catch((err) => {
            toast.error(err.response.data.message, {autoClose: 3000})
            setPriceInfoTable({...priceInfoModal, loading: false, visible: false})
        })
    }
    
    useEffect(() => {
        fetchUserPaymentProfile()
    }, [])

    return (
        <>
            <div className="d-none d-md-block">
                <Card>
                    <Card.Body className='m-0 p-0'>
                        {priceInfoModal.loading ? 
                            <div className='d-flex flex-row justify-content-center'>
                                <Spinner animation='grow' className='my-3'/>
                                <Spinner animation='grow' className='my-3 mx-2'/>
                                <Spinner animation='grow' className='my-3'/>
                            </div>
                        :
                            <>
                                <div className="d-flex flex-row justify-content-between aling-items-center">
                                    <h5 className="text-secondary mt-3 ml-3">Cost of credits</h5>
                                    <div className="d-flex flex-column my-2 mx-3">
                                        <span className="text-secondary font-weight-bold ml-auto">Generic template = 1 credit</span>
                                        <span className="text-secondary font-weight-bold ml-auto mb-1">Journal-specific template = 2 credits</span>
                                    </div>
                                </div>
                                <table class="table">
                                    <tr>
                                        <th>Credits</th>
                                        {userPaymentProfile[0].prices.map((item) => {
                                            return (
                                                <>
                                                    <th>{item.currency} ({currencySymbols[item.currency.toUpperCase()]}{item?.currency === "GBP" ? " exc. VAT" : ""})</th>
                                                </>
                                            )
                                        })}
                                    </tr>
                                    {userPaymentProfile.map((priceDetail) => {
                                        return(
                                            <>
                                                { priceDetail.credit !== 2 ? 
                                                    <tr>
                                                        <td>
                                                            {priceDetail.credit} <br/>
                                                            {priceDetail?.discount ? <span className="small text-secondary">({priceDetail.discount}% disc)</span> : ""}
                                                        </td>
                                                        {priceDetail.prices.map((price) => {
                                                            return(
                                                                <>
                                                                    <td>
                                                                        {price.amount ? currencySymbols[price.currency.toUpperCase()]+price.amount : "N/A"} <br/>
                                                                        {price?.listPrice ? <span className="small text-secondary">(list price: {currencySymbols[price.currency.toUpperCase()]+price.listPrice})</span> : ""}
                                                                    </td>
                                                                </>
                                                            )
                                                        })}
                                                    </tr>
                                                :
                                                    <></>
                                                }
                                            </>
                                        )
                                    })}
                                </table>
                            </>
                        }
                    </Card.Body>
                </Card>
                <div className='d-flex flex-row justify-content-end'>
                    <button 
                        onClick={() => {
                            history.push("/profile?tab=credits")
                        }}
                        disabled={priceInfoModal.loading} 
                        className='btn-lg mt-3 col-md-3 col-sm-12'
                    >
                        Purchase now
                    </button>
                </div>
            </div>
            <div className="mt-2 d-sm-block d-md-none">
                {priceInfoModal.loading ? 
                        <div className='d-flex flex-row justify-content-center'>
                            <Spinner animation='grow' className='my-3'/>
                            <Spinner animation='grow' className='my-3 mx-2'/>
                            <Spinner animation='grow' className='my-3'/>
                        </div>
                    :
                    userPaymentProfile[0]?.prices.map((item) => {
                        return (
                            <>
                                <div className="border rounded align-items-start my-2">
                                    <div className="d-flex flex-row justify-content-between aling-items-center my-2">
                                        {/* <h5 className="text-secondary ml-3">Currency</h5> */}
                                        <h5 className="text-secondary ml-3 mr-3">{item.currency} ({currencySymbols[item.currency.toUpperCase()]} exc. VAT)</h5>
                                    </div>
                                    <table className="table">
                                        {userPaymentProfile.map((priceDetail) => {
                                            return (
                                                <>
                                                    {priceDetail.credit !== 2 ? 
                                                        <tr>
                                                            <td className="text-secondary">
                                                                {priceDetail.credit} {priceDetail.credit > 1 ? "credits" : "credit"} <br/>
                                                                {priceDetail?.discount ? <span className="small">({priceDetail.discount}% disc)</span> : ""}
                                                            </td>
                                                            {priceDetail.prices.map((price) => {
                                                                if(price?.currency === item.currency) {
                                                                    return (
                                                                        <td className="text-secondary">
                                                                            {price.amount ? currencySymbols[price.currency.toUpperCase()]+price.amount : "N/A"} <br/>
                                                                            {price?.listPrice ? <span className="small text-secondary">(list price: {currencySymbols[price.currency.toUpperCase()]+price.listPrice})</span> : ""} 
                                                                        </td>
                                                                    )
                                                                }
                                                            })}
                                                        </tr> 
                                                    : 
                                                        <></>
                                                    }
                                                </>
                                            )        
                                        })}
                                    </table>
                                </div>
                            </>
                        )
                    })
                }
            </div>
        </>
    )
}

export default PricingInfo