export const SET_SORT_VALUE = "SET_SORT_VALUE";
export const SET_FILTER_VALUE = "SET_FILTER_VALUE";
export const SET_SEARCH_FILTER = "SET_SEARCH_FILTER";
export const SET_RECORDS_PER_PAGE = "SET_RECORDS_PER_PAGE"

export function setRecordsPerPage(value){
    return{
        type: SET_RECORDS_PER_PAGE,
        payload: value
    }
}

export function setSortValue(value){
    return{
        type: SET_SORT_VALUE,
        payload: value
    }
}

export function setFilterValue(value){
    return{
        type: SET_FILTER_VALUE,
        payload: value
    }
}

export function setSearchFilter(value){
    return{
        type: SET_SEARCH_FILTER,
        payload: value
    }
}