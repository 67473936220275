import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory} from "react-router-dom";
import { FieldLoader } from "../../../components/Loader/CommonLoader";
import "./DuplicateCheckModal.scss";

const DuplicateCheckModal = ({pageUrl, show, handleShow, 
	lexiconId, force, handleForce, 
	journalTitle, getManuscriptData, manuscriptDetails, 
	setLoader, setDefaultBtnLoader, genericTemplate}) => {
	const [isBtnLoader, setButtonLoader] = useState({isDraftBtn: false, isCreateBtn: false});
	const history = useHistory();

	const forceToCreateManuscript = () => {
		handleForce(true);
		if(genericTemplate){
			setButtonLoader({...isBtnLoader, isCreateBtn: true});
			getManuscriptData(lexiconId, true, genericTemplate);
		} else {
			setButtonLoader({...isBtnLoader, isCreateBtn: true});
			getManuscriptData(lexiconId, true);
		}
	}

	const handleDrafts = () => {
		setButtonLoader({...isBtnLoader, isDraftBtn: true})
		history.push(`/my-dashboard?journalTitle=${encodeURIComponent(journalTitle)}&status=Draft`)
	}

	const handleClose = () => {
		if(pageUrl){
			handleForce(false)
			setLoader(false)
			setDefaultBtnLoader({isDefault:false});
			handleShow({isduplicateCheck:false})
		}else{
			handleShow({isduplicateCheck:false})

		}	
		setButtonLoader({isDraftBtn: false, isCreateBtn: false})
	}
	return(
		<Modal show={show} onHide={()=> handleClose()}>
			<Modal.Header closeButton className='border-bottom-0'>
				<Modal.Title className='h5'>Multiple drafts alert</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<p className={'message'}>You have previously created <strong>{manuscriptDetails.count}</strong> submission drafts for this journal. Would you like to create a new submission or continue working on a previous draft? </p>
					<section>
						{isBtnLoader.isDraftBtn ? 
						<FieldLoader className={'w-100 mb-2 customButton'} isLoading={isBtnLoader.isDraftBtn} /> :
						<button className={'w-100 mb-2 customButton'} onClick={handleDrafts}>Continue working on a draft</button>}
						{isBtnLoader.isCreateBtn ? 
						<FieldLoader className="w-100" isLoading={isBtnLoader.isCreateBtn} /> :
						<button className="w-100" onClick={forceToCreateManuscript}>Create a new submission</button>
						}
					</section>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default DuplicateCheckModal;