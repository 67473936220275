import React from 'react';
import { toast } from 'react-toastify';
import './CommonButtonStyle.scss'

function EditingSuggestion({getSuggestion}) {

  return (
    <button className='btn-sm btn btn-transparent editingBtnStyle' onClick={getSuggestion}>Spell checker</button>
  );
}

export default EditingSuggestion;